import { useEffect, useRef, useState, forwardRef } from 'react'
import { IAutoWidthInputProps } from './type'

const AutoWidthInput = forwardRef<HTMLInputElement, IAutoWidthInputProps>(
  (props, ref) => {
    const { value, onChange, minWidth = 50, className, ...inputProps } = props

    const [inputWidth, setInputWidth] = useState(minWidth)

    const spanRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {
      if (spanRef.current) {
        setInputWidth(Math.max(spanRef.current.offsetWidth, minWidth))
      }
    }, [value, minWidth])

    return (
      <div className={className}>
        <input
          type='text'
          value={value}
          onChange={onChange}
          style={{ width: inputWidth }}
          ref={ref}
          {...inputProps}
        />
        <span
          ref={spanRef}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            whiteSpace: 'pre',
          }}
        >
          {value || ' '}
        </span>
      </div>
    )
  },
)

export default AutoWidthInput
