import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import { EFieldType } from '~/types/enums/ECommonEnum'
import moment from 'moment'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import convertToMilitaryTime from '~/utils/convertToMilitaryTime'
import clsx from 'clsx'

export interface IRTEditCellTextFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  type?: string
}

const RTEditCellTextField = <TData extends IReusableTableRowData>(
  props: IRTEditCellTextFieldProps<TData>,
) => {
  const { cell, table, type = 'text' } = props

  const {
    getState,
    options: { onCellEditEnd },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()

  const cellValue = cell.getValue<any>()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const [value, setValue] = useState(() => cell.getValue<string>())

  const defaultValue = type === EFieldType.color ? '#ffffff' : undefined

  const textFieldProps =
    typeof column.columnDef.editTextFieldProps === 'function'
      ? column.columnDef.editTextFieldProps({
          cell,
          column,
          row,
          table,
        })
      : {}

  const { getTimeFormatType } = useDateTimeFormat()

  const isHoursWithoutDay = useCallback((datetime: string) => {
    try {
      const splitTextValue = (datetime?.toString() || '').split(':')
      return splitTextValue.length === 2
    } catch (error) {
      console.log('error', error)
      return ''
    }
  }, [])

  const timeValue = useMemo(() => {
    if (EFieldType.time) {
      let result = ''
      if (isHoursWithoutDay(value)) {
        result = value
      } else if (value) {
        result = moment(value).format(getTimeFormatType)
      }

      return convertToMilitaryTime(result)
    }
    return ''
  }, [getTimeFormatType, isHoursWithoutDay, value])

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleBlur = () => {
    if (value !== cell.getValue<string>()) {
      saveInputValueToRowCache(value)
      setEditingCell(null)
      onCellEditEnd && onCellEditEnd(value, cell)
    }
  }

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      editInputRefs.current[column.id]?.blur()
    }
  }

  useEffect(() => {
    setValue(cellValue)
  }, [cellValue])

  return (
    <input
      {...textFieldProps}
      type={type}
      className={clsx('RTEditCellTextField__container', column.columnDef.align)}
      ref={inputRef => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef
        }
      }}
      value={type === EFieldType.time ? timeValue : value || defaultValue || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleEnterKeyDown}
    />
  )
}

export default RTEditCellTextField
