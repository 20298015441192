import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '~/hooks/useRouter'
import { useIsMobile } from '~/hooks/useIsMobile'

import {
  IonAvatar,
  IonChip,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
} from '@ionic/react'
import { ToolTipOverlay } from '~/components/shared'
import DriverFleetModal from '~/components/driver/DriverFleetModal'

import clsx from 'clsx'
import {
  selectMyCurrentCompany,
  selectCurrentScope,
  selectIsScopeDriver,
  selectDriverDetails,
  selectSessionUser,
} from '~/redux/selectors'
import getInitName from '~/utils/getInitName'

import { isExpiredAWSLink } from '~/utils/utils'
import { useQueryTruckFleets } from '~/hooks/useQueryData'

import './HeaderCompanyChip.scss'
import { Badge } from 'react-bootstrap'
import _ from 'lodash'
import { EScope } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
import { updateUserInfo } from '~/redux/actions/sessionActions'
import { sessionService } from 'redux-react-session'

function HeaderCompanyChip() {
  const router = useRouter()
  const isMobile = useIsMobile()

  const [showDriverFleetModal, setShowDriverFleetModal] = useState(false)
  const [isImageError, setIsImageError] = useState(false)
  const [popoverState, setPopoverState] = useState({
    event: null,
    isOpen: false,
  })
  const [fetchedLogoWhenError, setFetchedLogoWhenError] = useState(false)

  const dispatch = useDispatch()
  const currentCompany = useSelector(selectMyCurrentCompany)
  const userCurrentScope = useSelector(selectCurrentScope)
  const isDriver = useSelector(selectIsScopeDriver)
  const driverDetails = useSelector(selectDriverDetails)
  const currentUser = useSelector(selectSessionUser)

  const scopeOptions = currentUser.scopeOptions || []

  const { findTruckFleetById } = useQueryTruckFleets()

  const mapTruck = useMemo(() => {
    return findTruckFleetById(driverDetails.currentTruckFleetId)?.truck || {}
  }, [driverDetails.currentTruckFleetId, findTruckFleetById])

  const companyAvatar = currentCompany.logo

  const onClickCompanyChip = useCallback(() => {
    if (isDriver) {
      setShowDriverFleetModal(true)
    } else {
      router.push(`/companies/${currentCompany.id}/edit`)
    }
  }, [currentCompany.id, isDriver, router])

  const renderAvatar = useCallback(() => {
    if (companyAvatar && !isExpiredAWSLink(companyAvatar) && !isImageError) {
      return (
        <IonAvatar
          style={{ width: 24, height: 24 }}
          onClick={onClickCompanyChip}
        >
          <img
            src={companyAvatar}
            alt='company_avatar'
            onError={async () => {
              if (fetchedLogoWhenError) {
                setIsImageError(true)
              } else {
                const response = await apiClient.companies.getById(
                  currentCompany.id,
                )
                sessionService.saveUser({
                  ...currentUser,
                  company: response,
                })
                setFetchedLogoWhenError(true)
              }
            }}
          />
        </IonAvatar>
      )
    }

    return (
      <div
        className={clsx(
          'HeaderCompanyChip__initialNameAvatar',
          userCurrentScope,
        )}
        onClick={onClickCompanyChip}
      >
        {getInitName(currentCompany.name)}
      </div>
    )
  }, [
    companyAvatar,
    isImageError,
    userCurrentScope,
    onClickCompanyChip,
    currentCompany.name,
    currentCompany.id,
    fetchedLogoWhenError,
    currentUser,
  ])

  const onClosePopover = () => {
    setPopoverState({
      isOpen: false,
      event: undefined,
    })
  }

  const onChangeScope = scope => async () => {
    if (scope !== userCurrentScope) {
      const response = await apiClient.companies.toggleAccess(
        currentCompany.id,
        { newUserAccessId: currentUser.userAccess?.id },
        { scope },
      )

      dispatch(
        updateUserInfo({
          ...currentUser,
          ...response,
        }),
      )

      window.location.reload()
    }
    onClosePopover()
  }

  return (
    <>
      {!isMobile && (
        <IonChip color={userCurrentScope} onClick={onClickCompanyChip}>
          {renderAvatar()}
          <IonLabel style={{ fontWeight: 600 }}>{currentCompany.name}</IonLabel>
        </IonChip>
      )}
      {isMobile && renderAvatar()}
      {isDriver && !isMobile && (
        <ToolTipOverlay content='Truck' placement='bottom'>
          <h2 className='DriverFleetsShow__truck'>{mapTruck?.name}</h2>
        </ToolTipOverlay>
      )}
      {userCurrentScope !== EScope.driverFleet ? (
        <Badge
          className={clsx(
            'HeaderCompanyChip__scopeBadge clickable',
            userCurrentScope,
          )}
          bg='purple'
          onClick={event => {
            if (scopeOptions?.length > 1) {
              setPopoverState({
                isOpen: true,
                event,
              })
            }
          }}
        >
          {_.startCase(userCurrentScope)}
        </Badge>
      ) : null}
      <DriverFleetModal
        showModal={showDriverFleetModal}
        dismissModal={() => setShowDriverFleetModal(false)}
      />
      <IonPopover
        {...popoverState}
        className='StartTimeBadge__popover'
        mode='ios'
        onDidDismiss={onClosePopover}
      >
        <IonList>
          {scopeOptions.map(scope => (
            <IonItem
              className='clickable'
              key={scope}
              onClick={onChangeScope(scope)}
            >
              {_.startCase(scope)}
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
    </>
  )
}

export default HeaderCompanyChip
