import { components } from 'react-select'

import './styles.scss'

function CustomColorMenuList(props) {
  const { children } = props
  return (
    <components.MenuList {...props} className='CustomColorMenuList__container'>
      {children}
    </components.MenuList>
  )
}

export default CustomColorMenuList
