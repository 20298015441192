import { getYear } from 'date-fns'

const formatDateBasedOnYear = (opt: string, dateInput: any) => {
  const currentYear = getYear(new Date())
  if (dateInput) {
    const obj: Record<string, string> = {
      'yyyy/MM/dd': 'MM/dd',
      'dd/MM/yyyy': 'dd/MM',
      'MMM-d yyyy (eee)': 'MMM-d (eee)',
      'MMM-d-yyyy': 'MMM-d',
    }
    const dateYear =
      typeof dateInput === 'string'
        ? getYear(new Date(dateInput))
        : getYear(dateInput)

    if (currentYear === dateYear) {
      return obj[opt]
    }
  }

  return opt
}

export default formatDateBasedOnYear
