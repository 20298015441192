import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { CheckMarkIcon, ConcordFormStructure } from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import {
  PHONE_TYPE_OPTIONS,
  REGEX_PHONE_NUMBERS,
  COMPANY,
} from '~/utils/constants'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { EPhoneNumberTypes } from '~/types/enums/EPhoneNumber'
import { useQueryBuyerSellers } from '~/hooks/useQueryData'
import { Alert } from 'react-bootstrap'

const PhoneNumberForm = (props, ref) => {
  const {
    afterCreate,
    afterUpdate,
    phoneNumberData,
    isPhoneableTypeDisabled,
    isPhoneableTypeReadOnly,
    formData,
    hiddenFields,
    ...formProps
  } = props

  const currentCompany = useSelector(selectMyCurrentCompany)

  const [isLoading, setIsLoading] = useState(false)
  const [backendError, setBackendError] = useState('')

  const formRef = useRef()

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const { allCompanyOptions, isLoadingBuyerSellers } = useQueryBuyerSellers()

  const fields = useMemo(
    () => [
      {
        name: 'number',
        label: 'Phone Number',
        isRequired: true,
        type: EFieldType.number,
        isPhoneNumber: true,
      },
      {
        name: 'phoneableId',
        label: 'Company',
        isRequired: true,
        type: EFieldType.singleSelect,
        options: [
          {
            label: `${currentCompany.name} (Current Company)`,
            value: currentCompany.id,
          },
          ...allCompanyOptions,
        ],
        isLoading: isLoadingBuyerSellers,
      },
      {
        name: 'phoneTypes',
        label: 'Phone Types',
        isRequired: true,
        type: EFieldType.multipleSelect,
        options: PHONE_TYPE_OPTIONS,
        extraIcons: ({ watch, name, setValue }) => {
          const types = watch(name, [])
          const allTypes = PHONE_TYPE_OPTIONS.map(({ value }) => value)
          const areAllTypesSelected = allTypes.length === types.length
          const Icon = CheckMarkIcon
          const iconProps = {
            color: areAllTypesSelected
              ? 'var(--ion-color-success)'
              : 'var(--ion-color-medium)',
            style: {
              fontSize: 18,
            },
          }

          return [
            {
              Icon: <Icon {...iconProps} />,
              onClick() {
                if (areAllTypesSelected) {
                  setValue(name, [])
                } else {
                  setValue(name, allTypes)
                }
              },
            },
          ]
        },
      },
    ],
    [
      allCompanyOptions,
      currentCompany.id,
      currentCompany.name,
      isLoadingBuyerSellers,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        phoneableType: Yup.string().required('Phoneable type is required!'),
        number: Yup.string()
          .required('Phone number is required!')
          .matches(REGEX_PHONE_NUMBERS, 'Phone number is invalid'),
        phoneTypes: Yup.array().test(
          'min',
          'At least one is selected!',
          val => {
            return val?.length > 0
          },
        ),
        phoneableId: Yup.mixed(() => {
          if (hiddenFields?.includes('phoneableId')) {
            return Yup.number().nullable()
          }
          return Yup.number()
            .required('Company is required')
            .typeError('Company is required')
        }),
      }),
    [hiddenFields],
  )

  const defaultValues = useMemo(
    () => ({
      phoneableType: COMPANY,
      number: '',
      phoneTypes: [],
      phoneableId: null,
    }),
    [],
  )

  const updatePhoneNumber = useCallback(
    async formData => {
      const { number, phoneTypes, id, phoneableId, phoneableType } = formData
      const response = await apiClient.phoneNumbers.update(id, {
        number,
        phoneTypes,
        phoneableId,
        phoneableType,
      })
      if (response.errors.length > 0) {
        setBackendError(
          _.get(response.errors, '[0]') || toastMessages.createError,
        )
      } else {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      }
    },
    [afterUpdate],
  )

  const createPhoneNumber = useCallback(
    async formData => {
      const response = await apiClient.phoneNumbers.create(formData)
      if (response.id) {
        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        setBackendError(
          _.get(response.errors, '[0]') || toastMessages.createError,
        )
      }
    },
    [afterCreate],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await updatePhoneNumber(formData)
        } else {
          await createPhoneNumber(formData)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createPhoneNumber, isUpdating, updatePhoneNumber],
  )

  useEffect(() => {
    if (formData?.id) {
      const types = formData.phoneTypes.map(num => EPhoneNumberTypes[num])
      formRef.current?.setValue('phoneTypes', types)
    } else {
      formRef.current?.setValue('phoneTypes', [])
      formRef.current?.setValue(
        'phoneableId',
        formData?.phoneableId || currentCompany.id,
      )
    }
  }, [currentCompany.id, formData])

  return (
    <div>
      {backendError && (
        <Alert variant='danger' style={{ margin: '8px 0', fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      <ConcordFormStructure
        fields={fields}
        schema={schema}
        defaultValues={defaultValues}
        formData={formData}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        hiddenFields={hiddenFields}
        ref={node => {
          formRef.current = node
          if (ref) {
            ref.current = {
              ...node,
              onSubmitForm: handleSubmit,
            }
          }
        }}
        submitText={isUpdating ? 'Update' : 'Create'}
        {...formProps}
      />
    </div>
  )
}

export default React.forwardRef(PhoneNumberForm)
