import useScheduleLoadsTable from './useScheduleLoadsTable'

import {
  DialogTruckFormData,
  ReusableTable,
  TicketModal,
} from '~/components/shared'

import type { IScheduleLoadsTableProps } from './type'

import './styles.scss'
import clsx from 'clsx'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import { ITruck } from '~/types/models/ITruck'

function ScheduleLoadsTable(props: IScheduleLoadsTableProps) {
  const {
    columns,
    tableHeight,
    state,
    onCellEditEnd,
    filterOptions,
    columnFilters,
    setColumnFilters,
    tableData,
    isTableLoading,
    showScheduleLoadsAndLoads,
    showLoads,
    loadColumns,
    loadsData,
    onLoadCellEditEnd,
    ticketModal,
    onCloseTicketModal,
    truckFleetModal,
    onCloseTruckFleetForm,
    initialState,
  } = useScheduleLoadsTable(props)

  return (
    <>
      <ReusableTable
        data={tableData}
        columns={showLoads ? (loadColumns as any) : columns}
        onCellEditEnd={onCellEditEnd}
        tableHeight={tableHeight || 400}
        state={{
          ...state,
          isLoading: isTableLoading,
          columnFilters,
          columnVisibility: {
            [ERTDisplayColumnId.expand]: false,
          },
          expanded: true,
        }}
        initialState={initialState}
        enableTopToolbar
        manualFiltering
        enableExpanding={showScheduleLoadsAndLoads}
        filterOptions={filterOptions}
        onColumnFiltersChange={setColumnFilters}
        getRowClassName={({ row }) =>
          clsx({ ScheduleLoadsTable__childRow: row.parentId })
        }
        renderDetailPanel={({ row }) => {
          if (showScheduleLoadsAndLoads) {
            const loadsDataFiltered = loadsData.filter(
              ({ scheduleLoadId }) => scheduleLoadId === row.original.id,
            )
            if (loadsDataFiltered.length) {
              return (
                <ReusableTable
                  data={loadsDataFiltered}
                  columns={loadColumns}
                  tableHeight={200}
                  onCellEditEnd={onLoadCellEditEnd}
                />
              )
            }
          }
          return null
        }}
      />
      <TicketModal
        isOpen={ticketModal.isOpen}
        onClose={onCloseTicketModal}
        load={ticketModal.load}
      />

      <DialogTruckFormData
        truckFleet={truckFleetModal?.truckFleet as ITruckFleet}
        truck={truckFleetModal?.truckFleet?.truck as ITruck}
        isOpen={truckFleetModal.isOpen}
        onClose={onCloseTruckFleetForm}
      />
    </>
  )
}
export default ScheduleLoadsTable
