import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { apiClient } from '~/api/ApiClient'

import type { IAddressInputOnChangeParams } from '~/components/shared/ConcordForm/AddressInput/type'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { useQueryTerminals } from '~/hooks/useQueryData'
import isLocationInsideGeofence from '~/utils/isLocationInsideGeofence'
import { formatLatLng } from '~/utils/formatLatLng'
import { getCoordinates } from '~/utils/utils'
import _ from 'lodash'
import buildFullAddress from '~/utils/buildFullAddress'
import { EAddressableType } from '~/types/enums/EAddress'
import { ELocationableType } from '~/types/enums/ELocation'

interface Props {
  id: number
}

const useTerminalContainer = (props: Props) => {
  const { id } = props

  const [location, setLocation] =
    useState<{ lat: number | string; lng: number | string } | null>(null)
  const [coordinates, setCoordinates] = useState<
    {
      lat: number | string
      lng: number | string
      id?: number
    }[]
  >([])
  const [geofence, setGeofence] =
    useState<{
      latN: number | string
      latS: number | string
      longW: number | string
      longE: number | string
    } | null>(null)
  const [address, setAddress] = useState<Record<string, any>>({})
  const [isLoading, setIsLoading] = useState(false)

  const fullAddress = buildFullAddress(address)

  const { confirmation } = useConfirmationProvider()

  const {
    terminalsData: [terminalData],
    isFetchedTerminals,
    refetchTerminalsData,
  } = useQueryTerminals(
    {
      id: id,
    },
    { enabled: Boolean(id) },
  )

  const isLocationsDiff = useMemo(
    () =>
      terminalData?.location
        ? !_.isEqual(location, terminalData?.location)
        : false,
    [location, terminalData?.location],
  )

  const isCoordinatesDiff = useMemo(
    () =>
      terminalData?.coordinates
        ? !_.isEqual(coordinates, terminalData?.coordinates)
        : false,
    [terminalData?.coordinates, coordinates],
  )

  const showUpdateButton = isLocationsDiff || isCoordinatesDiff

  const onChangeAddress = useCallback(
    (
      selectedOpt: unknown,
      { location, address }: IAddressInputOnChangeParams,
    ) => {
      setLocation(location)
      setAddress(address)
      setCoordinates([])
    },
    [],
  )

  const onChangeLocation = (
    location: { lat: number; lng: number },
    address: any,
  ) => {
    setLocation(location)
    setAddress(address)
  }

  const onRevert = () => {
    setLocation(terminalData.location)
    setGeofence(terminalData.geofence)
    setCoordinates(terminalData.coordinates)
    setAddress(terminalData.address || {})
  }

  const updateCoordinates = () => {
    if (coordinates.some(({ id }) => !id)) {
      const oldCoordinates = terminalData.coordinates.map(c => ({
        id: c.id,
        _destroy: true,
      }))

      const newCoordinates = coordinates.map(c => ({
        lat: c.lat,
        lng: c.lng,
      }))

      return apiClient.geofences.update(terminalData.geofence?.id as number, {
        geofence: {
          coordinatesAttributes: [...oldCoordinates, ...newCoordinates],
        },
      })
    }
  }

  const updateLocation = () => {
    if (isLocationsDiff) {
      if (terminalData.location) {
        return apiClient.locations.update(terminalData.location?.id as number, {
          lat: location?.lat as string,
          lng: location?.lng as string,
        })
      }
      return apiClient.locations.create({
        lat: location?.lat as string,
        lng: location?.lng as string,
        locationableId: terminalData.id,
        locationableType: ELocationableType.Terminal,
        fullAddress: buildFullAddress(address) || '',
      })
    }
  }

  const updateAddress = () => {
    if (fullAddress !== buildFullAddress(terminalData.address)) {
      if (terminalData.address) {
        return apiClient.addresses.update(terminalData.address?.id as number, {
          street: address.street,
          city: address.city,
          country: address.country,
          state: address.state,
          zip: address.zip,
        })
      }
      return apiClient.addresses.create({
        street: address.street,
        city: address.city,
        country: address.country,
        state: address.state,
        zip: address.zip,
        addressableId: terminalData.id,
        addressableType: EAddressableType.terminal,
        addressTypes: ['location'] as any,
      })
    }
  }

  const onUpdateTerminal = async () => {
    setIsLoading(true)
    try {
      await Promise.all([
        updateCoordinates(),
        updateAddress(),
        updateLocation(),
      ])
      refetchTerminalsData()
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  const onDrawGeofence = (
    points: {
      lat: number | string
      lng: number | string
    }[],
  ) => {
    setCoordinates(points)
  }

  useEffect(() => {
    if (isFetchedTerminals && terminalData) {
      setLocation(terminalData.location)
      setGeofence(terminalData.geofence)
      setCoordinates(terminalData.coordinates)
      setAddress(terminalData.address || {})
    }
  }, [isFetchedTerminals, terminalData])

  useEffect(() => {
    if (isFetchedTerminals) {
      const terminalLocation = formatLatLng(
        terminalData.location?.lat,
        terminalData.location?.lng,
      )
      const terminalCoordinates = getCoordinates(
        terminalData.coordinates,
        terminalData.geofence,
      )
      if (terminalLocation) {
        const isInsideGeofence = isLocationInsideGeofence(
          terminalLocation,
          terminalCoordinates,
        )
        if (!isInsideGeofence) {
          confirmation({
            message:
              'This marker is not inside the current geofence, please create a new geofence by dragging and drawing it',
            buttons: [
              {
                text: 'Ok',
                action: 'Yes',
              },
            ],
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedTerminals, terminalData])

  return {
    onChangeAddress,
    terminalData,
    location,
    geofence,
    coordinates,
    showUpdateButton,
    fullAddress,
    isLoading,
    onChangeLocation,
    onRevert,
    onDrawGeofence,
    onUpdateTerminal,
  }
}

export default useTerminalContainer
