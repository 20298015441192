import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '~/redux/actions/sessionActions'
import { resetReduxStore } from '~/redux/actions/userAccessActions'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'

const useAccesses = ({ onClosePopover } = {}) => {
  const session = useSelector(state => state.session)
  const [requestList, setRequestList] = useState([])
  const [accessesList, setAccessesList] = useState()
  const [userAccess, setUserAccess] = useState(session.user.userAccess)
  const [currentAccess, setCurrentAccess] = useState()
  const [firstLoad, setFirstLoad] = useState(true)
  const updateSessionRef = useRef()
  const dispatch = useDispatch()
  const myCompany = useSelector(selectMyCurrentCompany)

  const loadAccessInfo = useCallback(async () => {
    if (firstLoad && session.user.currentScope) {
      setFirstLoad(false)
      const response = await apiClient.accessRequests.get()
      const access = await apiClient.userAccesses.get()
      if (!response.error) {
        const { accessRequests, accessList, userable } = response
        accessRequests && setRequestList(accessRequests)
        const newList = accessList
          ? accessList.sort((a, b) => (a.code > b.code ? 1 : -1))
          : []
        const listWithAccess = newList.map(item => {
          const accessValue = access.userAccesses.find(
            access => access.companyId === item.id,
          )

          return {
            ...item,
            currentUserAccess: accessValue,
          }
        })
        setAccessesList(listWithAccess)
        updateSessionRef.current(newList, session)
        userable && setUserAccess(userable)
      }
    }
  }, [session, firstLoad])

  const onUpdateSession = useCallback((accessables, sess) => {
    if (Object.keys(sess.user).length > 0) {
      dispatch(
        updateUserInfo({
          ...sess.user,
          userAccessables: accessables,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userScope = useRef(session.user.currentScope)

  const onUpdateFilter = useCallback(
    async ({ value }) => {
      if (userScope.current !== value) {
        const data = await apiClient.companies.toggleAccess(
          myCompany.id,
          { newUserAccessId: userAccess?.id },
          { scope: value },
        )
        userScope.current = value
        dispatch(
          updateUserInfo({
            ...session.user,
            ...data,
          }),
        )
        window.location.reload()
      }
    },
    [dispatch, myCompany.id, session.user, userAccess?.id],
  )

  useEffect(() => {
    updateSessionRef.current = onUpdateSession
  }, [onUpdateSession])

  useEffect(() => {
    loadAccessInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDeleteRequest = useCallback(
    companyId => {
      apiClient.companies
        .createAccessRequest(companyId, session.user)
        .then(() => {
          setRequestList(requestList.filter(r => r.companyId != companyId))
        })
    },
    [session, requestList],
  )

  const onToggleAccess = useCallback(
    async accessId => {
      onClosePopover && onClosePopover()
      const accesses = await apiClient.companies.toggleAccess(accessId, {
        newUserAccessId: userAccess?.id,
      })
      setUserAccess(accesses.userAccess)
      dispatch(updateUserInfo(accesses))
      dispatch(resetReduxStore())
      toast.success(toastMessages.accessChanges)
    },
    [dispatch, onClosePopover, userAccess?.id],
  )

  const onToggleDefault = useCallback(
    async accessId => {
      const defaultAccess = accessesList.filter(
        sub => sub.currentUserAccess.default == 'Yes',
      )[0]
      const accessToNo = await apiClient.userAccesses.update(
        defaultAccess.currentUserAccess?.id,
        { default: 'No' },
      )
      const accessToYes = await apiClient.userAccesses.update(accessId, {
        default: 'Yes',
      })

      const listAfterToggle = accessesList.map(item => {
        if (item.currentUserAccess.id == defaultAccess.currentUserAccess?.id) {
          return {
            ...item,
            currentUserAccess: accessToNo['userAccess'],
          }
        } else if (item.currentUserAccess.id == accessId) {
          return {
            ...item,
            currentUserAccess: accessToYes['userAccess'],
          }
        } else {
          return item
        }
      })
      setAccessesList(listAfterToggle)
    },
    [accessesList],
  )

  useEffect(() => {
    accessesList &&
      session?.user?.company?.id &&
      setCurrentAccess(
        accessesList?.find(access => access?.id == session.user.company.id),
      )
  }, [accessesList, session])

  return {
    session,
    requestList,
    accessesList,
    userAccess,
    onDeleteRequest,
    onToggleAccess,
    onUpdateFilter,
    onToggleDefault,
    currentAccess,
  }
}

export default useAccesses
