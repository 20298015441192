import moment from 'moment'
import { REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS } from './constants'

const isInfinity = (input: string) => input.includes('Infinity')

const getDotLength = (str: string) => {
  const match = str.match(/\.{2,}/) // Match two or more consecutive dots
  return match ? match[0].length : 0 // Return the length of the matched dots or 0 if none
}

export const getPricingDate = (dateRange: string | null) => {
  if (dateRange) {
    const matches = dateRange.match(REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS)
    const dotLength = getDotLength(dateRange)
    if (matches) {
      const startDate = matches[1]
      const currentEndDate = matches[3]
      const endDate = currentEndDate
        ? isInfinity(currentEndDate)
          ? null
          : currentEndDate
        : null
      const formattedEndDate = endDate
        ? dotLength === 3
          ? moment(endDate).add({ days: -1 }).format('YYYY-MM-DD')
          : endDate
        : null

      return {
        startDate: startDate
          ? isInfinity(startDate)
            ? null
            : startDate
          : null,
        endDate: formattedEndDate,
      }
    }
  }

  return null
}

const formatPricingDate = (dateRange: string) => {
  const pricingDate = getPricingDate(dateRange)
  if (pricingDate) {
    const { startDate, endDate } = pricingDate
    const format = 'MMM-D-YY'
    if (startDate && endDate) {
      return `${moment(startDate).format(format)} to ${moment(endDate).format(
        format,
      )}`
    }
    if (startDate) {
      return moment(startDate).format(format)
    }
    if (endDate) {
      return moment(endDate).format(format)
    }
  }

  return 'All'
}

export default formatPricingDate
