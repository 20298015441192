import { IonButtons } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import {
  Button,
  CommonDialogV2,
  ContainerSearchBar,
  ICommonDialogProps,
} from '~/components/shared'
import PaymentDataAsPlainText from '~/components/shared/ConcordForm/FormData/PaymentsForm/PaymentDataAsPlainText'
import { toastMessages } from '~/constants/toast-status-text'
import { StripePaymentMethodCard } from '~/containers/PaymentMethodsContainer/StripePaymentMethodCard'
import {
  useQueryBuyerSellers,
  useQueryPaymentMethods,
  useQueryStripeAccount,
} from '~/hooks/useQueryData'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { IPayment } from '~/types/models/IPayment'
import { IStripePaymentMethod } from '~/types/models/IStripe'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import StripePaymentMethodElement from '~/containers/PaymentMethodsContainer/StripePaymentMethodElement'
import { useUpdateEffect } from 'react-use'
import useFuzzy from '~/hooks/useFuzzy'
import { Link } from 'react-router-dom'
import { EScope } from '~/types/enums/ECommonEnum'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

export interface IProcessPaymentModalProps extends ICommonDialogProps {
  payment: IPayment | undefined
  afterProcessPayment: () => void
}

function ProcessPaymentModal(props: IProcessPaymentModalProps) {
  const { isOpen, payment, afterProcessPayment, ...modalProps } = props

  const { stripeAccount, isStripeAccountFetched } = useQueryStripeAccount()

  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState<IStripePaymentMethod | undefined>(undefined)
  const [clientSecret, setClientSecret] = useState('')
  const [isFetchingSetupIntent, setIsFetchingSetupIntent] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [backendError, setBackendError] = useState('')

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const isBuyer = currentScope === EScope.buyer

  const { sellerRelationships, isFetchedBuyerSellers } = useQueryBuyerSellers()

  const { isPaymentMethodsLoading, paymentMethodsData, refetchPaymentMethods } =
    useQueryPaymentMethods({
      stripe: {
        companyId: currentCompany.id,
      },
    })

  const sellerRelationship = useMemo(() => {
    if (currentScope === EScope.seller) {
      return undefined
    }
    return sellerRelationships.find(
      ({ sellerId }) => payment?.sellerId === sellerId,
    )
  }, [currentScope, payment?.sellerId, sellerRelationships])

  const isChangesEnabled = useMemo(() => {
    if (currentScope === EScope.seller) {
      return Boolean(isStripeAccountFetched && stripeAccount?.chargesEnabled)
    }
    if (isFetchedBuyerSellers) {
      return sellerRelationship?.seller?.chargesEnabled
    }
    return false
  }, [
    currentScope,
    isFetchedBuyerSellers,
    isStripeAccountFetched,
    sellerRelationship?.seller?.chargesEnabled,
    stripeAccount?.chargesEnabled,
  ])

  const shouldShowSubmitButton = useMemo(
    () => isChangesEnabled && paymentMethodSelected,
    [isChangesEnabled, paymentMethodSelected],
  )

  const { seachedList, searchValue, onSearch } = useFuzzy(paymentMethodsData, {
    keys: [
      'type',
      'card.brand',
      'card.last4',
      'card.expMonth',
      'card.expYear',
      'usBankAccount.routingNumber',
      'usBankAccount.last4',
    ],
  })

  const onFetchSetupIntent = async () => {
    setIsFetchingSetupIntent(true)
    try {
      const response = await apiClient.stripe.setupIntent({
        stripe: {
          companyId: currentCompany.id,
        },
      })
      setClientSecret(response.setupIntent.clientSecret)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsFetchingSetupIntent(false)
    }
  }

  const onProcessPayment = async () => {
    setIsProcessing(true)
    setBackendError('')
    try {
      if (payment && paymentMethodSelected) {
        const response = await apiClient.stripe.createPaymentIntent({
          stripe: {
            paymentId: payment.id,
            companyId: currentCompany.id,
            paymentMethodId: paymentMethodSelected.id,
          },
        })
        if (response?.errors?.length) {
          setBackendError(response?.errors[0])
        } else {
          afterProcessPayment()
        }
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setIsProcessing(false)
    }
  }

  useUpdateEffect(() => {
    if (!isOpen) {
      setClientSecret('')
      setPaymentMethodSelected(undefined)
    }
  }, [isOpen])

  return (
    <CommonDialogV2
      isOpen={isOpen}
      isLoading={isPaymentMethodsLoading}
      isHiddenOkButton
      size='lg'
      title='Process Payment'
      {...modalProps}
    >
      {payment && <PaymentDataAsPlainText paymentData={payment} />}

      {!currentCompany.stripeCustomerId && (
        <Alert style={{ fontSize: 13 }}>
          Go to <Link to='/payment_methods'>Payment methods</Link> to setup a
          payment card
        </Alert>
      )}

      {!isChangesEnabled && (
        <Alert style={{ fontSize: 13 }} variant='danger'>
          {isBuyer
            ? `${sellerRelationship?.seller?.name} has not completed payment processing setup.`
            : 'Please complete payment processing setup.'}
          {!isBuyer ? (
            <>
              <span>&nbsp;Go to &nbsp;</span>
              <Link to='/embedded_payments'>Embedded Payments</Link>
              <span>&nbsp;to finish it</span>
            </>
          ) : (
            ''
          )}
        </Alert>
      )}

      {currentCompany.stripeCustomerId && (
        <>
          {!paymentMethodSelected && (
            <Alert variant='info' style={{ fontSize: 13, marginTop: '12px' }}>
              Select one Payment method
            </Alert>
          )}
          {backendError && (
            <Alert variant='danger' style={{ fontSize: 13, marginTop: '12px' }}>
              {backendError}
            </Alert>
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ContainerSearchBar
              searchBarValue={searchValue}
              onSearchBarChange={onSearch}
            />
            <IonButtons>
              <Button
                icon={addCircleOutline}
                onClick={onFetchSetupIntent}
                loading={isFetchingSetupIntent}
                tooltipProps={{
                  content: 'Add Payment Method',
                  placement: 'top',
                }}
              />
            </IonButtons>
          </div>

          {clientSecret && (
            <div style={{ marginTop: 4 }}>
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <StripePaymentMethodElement
                  afterCreate={() => {
                    refetchPaymentMethods()
                    setClientSecret('')
                  }}
                  onClose={() => {
                    setClientSecret('')
                  }}
                />
              </Elements>
            </div>
          )}

          {seachedList.map(paymentMethod => (
            <div
              key={paymentMethod.id}
              style={{
                display: 'inline-block',
                opacity:
                  paymentMethodSelected?.id === paymentMethod.id ? 1 : 0.75,
                cursor: 'pointer',
              }}
              onClick={() => {
                setPaymentMethodSelected(prev => {
                  if (prev?.id === paymentMethod.id) {
                    return undefined
                  }
                  return paymentMethod
                })
              }}
            >
              <StripePaymentMethodCard data={paymentMethod} />
            </div>
          ))}
          {shouldShowSubmitButton && (
            <Button
              expand='full'
              label='Process'
              loading={isProcessing}
              onClick={onProcessPayment}
            />
          )}
        </>
      )}
    </CommonDialogV2>
  )
}

export default ProcessPaymentModal
