import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { buildGetUrl } from '~/utils/buildUrl'
import { IGetSentEmailsParams, ISentEmail } from '~/types/models/IInvoice'

const useQuerySentEmails = (
  params: IGetSentEmailsParams,
  options?: Partial<UseQueryOptions<ISentEmail[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'sentEmails',
      sessionUser?.id,
      buildGetUrl(apiClient.sentEmails.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.sentEmails.get(params)
      return response.sentEmails
    },
    ...DEFAULT_QUERY_OPTIONS,
    staleTime: 20000,
    refetchOnMount: true,
    ...options,
  })

  return {
    sentEmailsData: data || [],
    isSentEmailsDataLoading: isLoading,
    isSentEmailsDataFetched: isFetched,
  }
}

export default useQuerySentEmails
