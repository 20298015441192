import { EEventSourceType } from '~/types/enums/EEvent'
import { useQueryEvents } from '../useQueryEvents'
import { useQueryLoads } from '../useQueryLoads'
import { useQuerySchedules } from '../useQuerySchedules'
import { useQueryTerminals } from '../useQueryTerminals'
import { useQueryTruckFleets } from '../useQueryTruckFleets'
import { useQueryUserInfo } from '../useQueryUserInfo'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'

const useCustomQueryDataOfDriver = () => {
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const {
    userInfo,
    refetchUserInfo,
    updateDriverFleet,
    isUserInfoFetched,
    isUserInfoLoading,
  } = useQueryUserInfo({
    enabled: currentScope === EScope.driverFleet,
  })
  const {
    loadsData: [loadData],
    updateLoad,
    isLoadsDataFetched,
    refetchLoadsData,
  } = useQueryLoads(
    {
      id: userInfo?.driverFleet?.loadId as number,
    },
    { enabled: Boolean(userInfo?.driverFleet?.loadId) },
  )

  const { refetchEventsData } = useQueryEvents(
    {
      filters: {
        sourceType: EEventSourceType.Load,
        sourceId: loadData?.id,
      },
    },
    { enabled: Boolean(loadData?.id) },
  )

  const { findTruckFleetById, isLoadingTruckFleetsData, updateTruck } =
    useQueryTruckFleets()

  const currentTruckFleet = findTruckFleetById(
    userInfo?.driverFleet?.currentTruckFleetId,
  )

  const {
    schedulesData: [scheduleData],
    refetchSchedulesData,
  } = useQuerySchedules(
    {
      id: loadData?.scheduleId,
    } as any,
    { enabled: Boolean(loadData?.scheduleId) },
  )

  const { findTerminalById } = useQueryTerminals({
    filters: {
      companyId: currentCompany.id,
    },
  })

  const truckFleetTerminal = findTerminalById(currentTruckFleet?.terminalId)
  const truckParkTerminal = findTerminalById(
    currentTruckFleet?.truck?.parkTerminalId,
  )

  const isTerminalAndParkTerminalDifferent =
    truckFleetTerminal?.id !== truckParkTerminal?.id

  return {
    userInfo,
    currentTruckFleet,
    truckFleetTerminal,
    scheduleData,
    loadData,
    isLoadsDataFetched,
    isUserInfoFetched,
    isUserInfoLoading,
    isLoadingTruckFleetsData,
    truckParkTerminal,
    isTerminalAndParkTerminalDifferent,
    updateLoad,
    refetchUserInfo,
    updateDriverFleet,
    updateTruck,
    refetchLoadsData,
    refetchSchedulesData,
    refetchEventsData,
  }
}

export default useCustomQueryDataOfDriver
