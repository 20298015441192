import { useMemo } from 'react'
import { Badge } from 'react-bootstrap'
import { FlagIcon } from '~/components/shared'
import { ELoadPrimaryParserStatusString } from '~/types/enums/ELoad'
import { ILoad } from '~/types/models/ILoad'
import ParserDataTooltipContent from '../ParserDataTooltipContent'
import Tooltip from 'rc-tooltip'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'

export interface ILoadStatusCellProps {
  load: ILoad
  field?: 'status' | 'primaryParserStatus'
}

function LoadStatusCell(props: ILoadStatusCellProps) {
  const { load, field = 'primaryParserStatus' } = props

  const { getLoadStatus } = useQueryLoadStatuses()

  const color = useMemo(
    () => ({
      [ELoadPrimaryParserStatusString.done]: 'success',
      [ELoadPrimaryParserStatusString.updated]: 'primary',
      [ELoadPrimaryParserStatusString.noCollection]: 'warning',
      [ELoadPrimaryParserStatusString.waiting]: 'secondary',
      [ELoadPrimaryParserStatusString.notSent]: '#ffd4e3',
      [ELoadPrimaryParserStatusString.error]: '#danger',
    }),
    [],
  )

  const statusValue = load[field]

  const tooltip = useMemo(() => {
    if (statusValue === 'No Collection') {
      return (
        <div style={{ textAlign: 'left', fontSize: 12 }}>
          <div style={{ textAlign: 'center', marginBottom: 4 }}>
            <strong>No Collection</strong>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 4,
            }}
          >
            <i>
              Parsing service did not automatically match this ticket to a
              seller
            </i>
          </div>
          <ParserDataTooltipContent data={load} />
        </div>
      )
    }
    return <ParserDataTooltipContent data={load} />
  }, [load, statusValue])

  const bgColor = useMemo(() => {
    if (field === 'primaryParserStatus') {
      return color[load.primaryParserStatus]
    }
    const status = getLoadStatus(load.status)
    if (status?.color) {
      return `var(--ion-color-${status.color})`
    }
    return 'var(--bs-primary)'
  }, [color, field, getLoadStatus, load.primaryParserStatus, load.status])

  return (
    <Tooltip
      placement='left'
      overlay={tooltip}
      overlayClassName='LoadIndexTable__tooltipOverlay'
    >
      <Badge
        bg={bgColor}
        style={{
          fontSize: 13,
          background: bgColor,
          marginLeft: 4,
        }}
      >
        {load.flags?.length ? (
          <FlagIcon color='red' style={{ marginRight: 4 }} />
        ) : null}
        <span>{load[field]}</span>
      </Badge>
    </Tooltip>
  )
}

export default LoadStatusCell
