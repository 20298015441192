import _ from 'lodash'
import filterWhere from 'filter-where'
import isDateInRange from './isDateInRange'

function getFilteredData(data, filterData) {
  const mapFilterData = {}

  _.forEach(filterData || {}, (value, key) => {
    if (
      typeof value === 'boolean' ||
      _.size(value) > 0 ||
      typeof value === 'number'
    ) {
      if (['upper', 'lower'].some(field => _.has(value, field))) {
        const { upper, lower } = value
        const parseUpper = _.isNil(upper) ? null : Number(upper)
        const parseLower = _.isNil(lower) ? null : Number(lower)

        if (!_.isNil(parseUpper) && !_.isNil(parseLower)) {
          mapFilterData[key] = compareField =>
            compareField <= parseUpper && compareField >= parseLower
        } else if (_.isNil(parseUpper) && !_.isNil(parseLower)) {
          mapFilterData[key] = compareField => compareField >= parseLower
        } else if (!_.isNil(parseUpper) && _.isNil(parseLower)) {
          mapFilterData[key] = compareField => compareField <= parseUpper
        }
      } else if (['startDate', 'endDate'].some(field => _.has(value, field))) {
        const { startDate, endDate } = value
        if (startDate && endDate) {
          mapFilterData[key] = compareField => {
            return isDateInRange(compareField, startDate, endDate)
          }
        }
      } else {
        mapFilterData[key] = value
      }
    }
  })

  return (data || []).filter(filterWhere(mapFilterData))
}

export default getFilteredData
