import { colord } from 'colord'
import { EColor } from '~/types/enums/ECommonEnum'

const getColor = (color: string | null | undefined) => {
  if (color) {
    if (colord(color).isValid()) {
      return color
    }

    return (EColor as any)[color] || undefined
  }

  return null
}

export default getColor
