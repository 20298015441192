import _ from 'lodash'
import { useMemo } from 'react'
import { Badge, Form } from 'react-bootstrap'
import { EOrderType, EQtyType } from '~/types/enums/ESellerProduct'
import { DeleteIcon, ReusableButton } from '../shared'
import { useQueryUoms } from '~/hooks/useQueryData'

const LoadFormOrderExtrasCard = ({
  sellerProduct,
  orderExtraRange,
  changeOrderExtra,
  spUoms,
  onDelete,
}) => {
  const { currentUom } = useQueryUoms()

  const onChange = key => e => {
    changeOrderExtra && changeOrderExtra({ [key]: parseFloat(e.target.value) })
  }

  const onCheck = e => {
    changeOrderExtra && changeOrderExtra({ checked: e.target.checked })
  }

  const checked = useMemo(
    () =>
      orderExtraRange?.checked === undefined ? true : orderExtraRange?.checked,
    [orderExtraRange],
  )

  const orderType = _.startCase(
    Object.values(EOrderType)[sellerProduct?.orderType],
  )

  const extraSource = orderExtraRange?.scheduleId
    ? 'Schedule'
    : orderExtraRange?.orderId
    ? 'Order'
    : 'Load'

  const qtyTypeText =
    EQtyType.per_qty == sellerProduct?.qtyType
      ? `${spUoms[sellerProduct?.uomId]?.code || '-'} / ${
          currentUom?.code || '-'
        }`
      : EQtyType.per_load == sellerProduct?.qtyType
      ? '/ LD'
      : 'Flat fee' // EQtyType.per_order/flat_fee == sellerProduct?.qtyType

  return (
    <tr>
      <td>
        {orderExtraRange?.scheduleId || orderExtraRange?.orderId ? (
          <Form.Check
            type='switch'
            label=''
            checked={checked}
            onChange={onCheck}
            className='mb-0'
          />
        ) : (
          <ReusableButton
            variant='danger'
            onClick={() => {
              onDelete && onDelete(orderExtraRange)
            }}
          >
            <DeleteIcon color='white' />
          </ReusableButton>
        )}
      </td>
      <td>{sellerProduct?.name}</td>
      <td className='d-flex align-items-center'>
        <Form.Control
          type='number'
          size='sm'
          value={orderExtraRange?.qty}
          onChange={onChange('qty')}
          className='w-auto me-1'
        />
        <div>
          <Badge>{qtyTypeText}</Badge>
        </div>
        <Badge pill className='align-self-center ms-1'>
          {orderType}
        </Badge>
      </td>
      <td>
        <Badge pill className='align-self-center ms-1'>
          {extraSource}
        </Badge>
      </td>
      <td>{orderExtraRange?.startLoad || 1}</td>
      <td>{orderExtraRange?.endLoad || 'All'}</td>
    </tr>
  )
}

export default LoadFormOrderExtrasCard
