import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyEvents from './useModifyEvents'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { IGetEventsParams, IEvent } from '~/types/models/IEvent'

const useQueryEvents = (
  params: IGetEventsParams,
  options?: Partial<UseQueryOptions<IEvent[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'events',
      sessionUser?.id,
      buildGetUrl(apiClient.events.endpoint, params),
    ],
    async queryFn() {
      if (options?.enabled === false) {
        return []
      }
      const response = await apiClient.events.get(params)
      return response.events
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    staleTime: 20000,
    refetchOnMount: true,
    ...options,
  })

  const eventsData = useMemo(() => data || [], [data])

  const { addEvent, updateEvent, removeEvent } = useModifyEvents(params)

  return {
    eventsData,
    isEventsDataLoading: isLoading,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEventsData: refetch,
  }
}

export default useQueryEvents
