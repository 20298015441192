import { IWeightCardProps } from './type'

import './styles.scss'
import { useQueryBuyerSellerProducts, useQueryUoms } from '~/hooks/useQueryData'
import buildObjectName from '~/utils/buildObjectName'
import { Badge } from 'react-bootstrap'

function WeightCard(props: IWeightCardProps) {
  const { weightData } = props

  const { findSellerProductById } = useQueryBuyerSellerProducts({
    filters: {
      joinsSellerProduct: true,
    },
  })

  const { currentUom } = useQueryUoms()

  const sellerProduct = findSellerProductById(weightData.sellerProductId)

  const diffTargetValue =
    Number(weightData.target) - Number(weightData.actual || 0)

  const diffPercentage =
    Number(weightData.target) / Number(weightData.actual || 0)

  return (
    <div className='WeightCard__container'>
      <Badge className='WeightCard__rank' bg='secondary'>
        {weightData.rank}
      </Badge>
      <div>
        {buildObjectName(sellerProduct)}{' '}
        {weightData.code && (
          <>
            | <Badge>Code 123</Badge>
          </>
        )}
      </div>
      <div style={{ marginTop: 4 }}>
        <strong>Target</strong>: {weightData.target} {currentUom?.code}
        {weightData.actual && (
          <>
            | <strong>Actual</strong>: {weightData.actual} {currentUom?.code} |{' '}
            <strong>Diff</strong>: {diffTargetValue} ({diffPercentage}%)
          </>
        )}
      </div>
    </div>
  )
}

export default WeightCard
