import React, { useMemo } from 'react'
import {
  CommonDialogV2,
  ICommonDialogProps,
} from '~/components/shared/CommonDialogV2'
import { IUpload } from '~/types/models/IUpload'
import { UploadForm } from './UploadForm'

export interface IUploadsProps {
  afterCreate?: (item: IUpload) => void
  afterUpdate?: (item: IUpload) => void
  formData?: IUpload
}

export interface IDialogUploadsProps
  extends ICommonDialogProps,
    IUploadsProps {}
export const DialogUploadForm = (props: IDialogUploadsProps) => {
  const { afterCreate, afterUpdate, onClose, formData, ...dialogaProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])
  return (
    <CommonDialogV2
      {...dialogaProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Work Pay Type'
      isHiddenOkButton
      onClose={onClose}
    >
      <UploadForm
        formData={formData}
        // companyId={companyId}
        afterCreate={(formData: IUpload) => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={(formData: IUpload) => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
