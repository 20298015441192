import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { buildGetUrl } from '~/utils/buildUrl'
import { IWeight, IWeightGetListParams } from '~/types/models/IWeight'

const useQueryWeights = (
  params: IWeightGetListParams,
  options?: Partial<UseQueryOptions<IWeight[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'weights',
      sessionUser?.id,
      buildGetUrl(apiClient.weights.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.weights.get(params)
      return response.weights
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const weightsData = useMemo(() => data || [], [data])

  return {
    weightsData,
    isWeightsDataLoading: isLoading,
    isWeightsDataFetched: isFetched,
  }
}

export default useQueryWeights
