import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { ConcordFormNumberField } from '~/components/shared'

function ConcordNumberFieldWithFormControl(props) {
  const { control, name, type, hint, ...numberFieldProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const handleChange = useCallback(
    (event, { value }) => {
      field.onChange(value)
    },
    [field],
  )

  return (
    <ConcordFormNumberField
      {...numberFieldProps}
      name={name}
      value={field.value}
      onChange={handleChange}
      error={fieldState.error?.message}
      hint={hint}
    />
  )
}

export default ConcordNumberFieldWithFormControl
