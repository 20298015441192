export function isMatch(sentence, searchText) {
  try {
    let regex

    if (searchText.startsWith('/') && searchText.lastIndexOf('/') > 0) {
      const lastSlashIndex = searchText.lastIndexOf('/')
      const pattern = searchText.slice(1, lastSlashIndex)
      const flags = searchText.slice(lastSlashIndex + 1)
      regex = new RegExp(pattern, (flags || '').toLowerCase())
    } else {
      const escapedSearchText = searchText
        .trim()
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      regex = new RegExp(escapedSearchText)
    }

    return regex.test(sentence)
  } catch (error) {
    console.log('error', error)
    return false
  }
}

function matchMultipleWords(sentence, searchText) {
  if (searchText.startsWith('/') && searchText.lastIndexOf('/') > 0) {
    const lastSlashIndex = searchText.lastIndexOf('/')
    const pattern = searchText.slice(1, lastSlashIndex)
    const flags = searchText.slice(lastSlashIndex + 1)
    const partialRegex = new RegExp(pattern.split(' ').join('|'), flags)
    return partialRegex.test(sentence)
  }

  const words = searchText
    .trim()
    .split(' ')
    .filter(word => word.length > 0)
  return words.some(word => isMatch(sentence, word))
}

export default matchMultipleWords
