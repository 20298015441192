import {
  ClockIcon,
  ColorPaletteIcon,
  CompanyAvatar,
  CustomTerminalOption,
  DeleteIcon,
  DriverCard,
  DropdownWithCustomChildren,
  LocationIcon,
  ToolTipOverlay,
  TruckIcon,
  TruckNumberSection,
  UserPlusIcon,
} from '~/components/shared'

import type { INewStartTime } from '../StartTimeForm'
import { ICommonOption } from '~/types/models/ICommonModel'
import { Badge, Button, ButtonGroup } from 'react-bootstrap'
import {
  useQueryCompanies,
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { useCallback, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '~/components/fleet/StartTimes/helpers'
import { IDriverFleet } from '~/types/models/IDriverFleet'

import './styles.scss'
import { NoteButton } from '~/components/fleet/StartTimes/StartTimesTable/NoteButton'
import { produce } from 'immer'
import { useMeasure } from 'react-use'
import buildFullName from '~/utils/buildFullName'
import pluralize from 'pluralize'
import { colord } from 'colord'
import _ from 'lodash'
import getFilteredData from '~/utils/getFilteredData'
import { EStatus } from '~/types/enums/ECommonEnum'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import { COLOR_OPTIONS } from '../../../ColorSelector/colorOptions'
import CustomColorOption from '../../../ColorSelector/CustomColorOption'
import getColor from '~/utils/getColor'
import ColorPickerDialog from '../../../ColorSelector/ColorPickerDialog'
import CustomColorMenu from '../../../ColorSelector/CustomColorMenu'
import CustomColorMenuList from '../../../ColorSelector/CustomColorMenuList'
import { IonChip, IonText } from '@ionic/react'
import buildObjectName from '~/utils/buildObjectName'

export interface ITimeSlotCardProps {
  startTime: INewStartTime
  timeSlotOptions: ICommonOption[]
  startTimesSelected: string[]
  assignedDriverFleetIds: number[]
  sortDriverFleetOptions?: any
  onUpdateTimeSlot: (
    newValue: any,
    time: string,
    field: keyof INewStartTime,
  ) => void
  onDeleteStartTime: (startTime: INewStartTime) => () => void
  onAssignDriver: (time: string, driverFleet: IDriverFleet) => void
  onChangeStartTimeDriver: (
    startTime: INewStartTime,
    driverFleetId: number,
    payload: Partial<{
      sellerTerminalId: number
      truckFleetId: number
      notes: string
      terminalId: number
    }>,
  ) => void
}

function TimeSlotCard(props: ITimeSlotCardProps) {
  const {
    startTime,
    timeSlotOptions,
    startTimesSelected,
    assignedDriverFleetIds,
    sortDriverFleetOptions = [],
    onUpdateTimeSlot,
    onDeleteStartTime,
    onAssignDriver,
    onChangeStartTimeDriver,
  } = props

  const [measureRef, { width }] = useMeasure()

  const [showColorPicker, setShowColorPicker] = useState(false)

  const isSmallSection = useMemo(() => width <= 536, [width])
  const { findCompanyById } = useQueryCompanies({})
  const {
    findTerminalById,
    companyTerminalOptions,
    otherSellerTerminalOptions,
  } = useQueryTerminals()

  const { currentTruckFleetOptions, findTruckFleetById } = useQueryTruckFleets()

  const startTimeColor = getColor(startTime.color)

  const terminalsOptions = [
    {
      label: 'My Terminals',
      options: companyTerminalOptions,
    },
    {
      label: 'Seller Terminals',
      options: otherSellerTerminalOptions,
    },
  ]

  const { driverFleetUsers, findUserByDriverFleetId } = useQueryUsers()

  const driverFleetOptions = useMemo(() => {
    const sortingOptions = sortDriverFleetOptions.filter(
      ({ sorted }: any) => sorted,
    )

    let sortedList = []

    if (sortingOptions.length) {
      const fields = sortingOptions.map(({ sortField }: any) => sortField)
      const sortingDirections = sortingOptions.map(({ isAsc }: any) =>
        isAsc ? 'asc' : 'desc',
      )
      sortedList = _.orderBy(driverFleetUsers, fields, sortingDirections)
    } else {
      sortedList = driverFleetUsers
    }
    const activeDriverFleets: IUserData[] = getFilteredData(sortedList, {
      worker: {
        status: EStatus.Active.toLowerCase(),
      },
    })

    return activeDriverFleets.map(({ driverFleet, person, worker }) => {
      const workerTerminal = findTerminalById(worker?.terminalId)
      const truckFleet = findTruckFleetById(driverFleet?.currentTruckFleetId)
      const label = [
        driverFleet?.rank,
        buildFullName(person),
        workerTerminal?.code,
        truckFleet?.truck?.name,
      ]
        .filter(Boolean)
        .join(' - ')
      return {
        value: driverFleet?.id,
        label: label,
      }
    })
  }, [
    driverFleetUsers,
    findTerminalById,
    findTruckFleetById,
    sortDriverFleetOptions,
  ])

  const findColorByValue = useCallback((value: string | undefined) => {
    const find = COLOR_OPTIONS.find(({ value: v }) => v === value)
    return find
  }, [])

  const [droppingParams, drop] = useDrop<any>(
    () =>
      ({
        accept: ItemTypes.DRIVER,
        drop: ({ driverFleet }: { driverFleet: IDriverFleet }) => {
          onAssignDriver(startTime.time, driverFleet)
        },
        collect: (monitor: any) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      } as any),
    [],
  )

  const border = useMemo(() => {
    const { canDrop, isOver } = droppingParams as any
    const isActive = canDrop && isOver
    if (isActive) {
      return '1px dashed #2dd36f'
    }
    if (canDrop) {
      return '1px dashed var(--ion-color-success)'
    }

    return undefined
  }, [droppingParams])

  return (
    <>
      <div
        key={startTime.time}
        style={{
          border,
          // background: startTime.color
        }}
        className='TimeSlotCard__container'
        ref={node => {
          drop(node)
          measureRef(node as any)
        }}
      >
        <div
          className='leftColorBadge'
          style={{ background: startTimeColor }}
        />
        <div style={{ marginLeft: '12px' }}>
          <ButtonGroup style={{ marginRight: 8 }}>
            <DropdownWithCustomChildren
              options={timeSlotOptions}
              className='StartTimeForm__dropdown timeCreateButton'
              onChange={(event, { value }) => {
                onUpdateTimeSlot(value, startTime.time, 'time')
              }}
              isOptionDisabled={option =>
                startTimesSelected.includes(option.value)
              }
              value={startTime.time}
            >
              <Button
                variant='primary'
                className='StartTimeForm__button'
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <ClockIcon color='white' />
                <span className='StartTimeForm__buttonLabel'>
                  {startTime.time}
                </span>
              </Button>
            </DropdownWithCustomChildren>

            <DropdownWithCustomChildren
              options={COLOR_OPTIONS}
              className='StartTimeForm__dropdown'
              onChange={(event, { value }) => {
                if (value === 'custom') {
                  setShowColorPicker(true)
                } else {
                  onUpdateTimeSlot(value, startTime.time, 'color')
                }
              }}
              value={startTime.color}
              components={{
                Option: CustomColorOption,
                Menu: CustomColorMenu,
                MenuList: CustomColorMenuList,
              }}
            >
              <Button
                variant='secondary'
                className='StartTimeForm__button'
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background: startTimeColor,
                  borderColor: startTimeColor,
                  color: colord(startTimeColor).isDark() ? 'white' : 'black',
                }}
              >
                <ColorPaletteIcon color='white' />
                <span className='StartTimeForm__buttonLabel'>
                  {findColorByValue(startTime.color)?.label}
                </span>
              </Button>
            </DropdownWithCustomChildren>
          </ButtonGroup>

          <ButtonGroup style={{ marginRight: 8 }}>
            <DropdownWithCustomChildren
              options={driverFleetOptions}
              className='StartTimeForm__dropdown'
              isOptionDisabled={opt =>
                assignedDriverFleetIds.includes(opt.value)
              }
              onChange={(event, { value }) => {
                const newDriverFleets = produce(
                  startTime.driverFleets,
                  draft => {
                    value.forEach((id: number) => {
                      const find = draft.find(df => df.id === id)
                      if (!find) {
                        draft.push({
                          id,
                          notes: '',
                        })
                      }
                    })
                  },
                )
                onUpdateTimeSlot(
                  newDriverFleets,
                  startTime.time,
                  'driverFleets',
                )
              }}
              value={startTime.driverFleets.map(({ id }) => id)}
              isMulti
            >
              <Button className='StartTimeForm__button'>
                <UserPlusIcon color='white' />
              </Button>
            </DropdownWithCustomChildren>
          </ButtonGroup>

          <ButtonGroup style={{ marginTop: isSmallSection ? 8 : 0 }}>
            <NoteButton
              note={startTime.notes}
              onSave={notes => {
                onUpdateTimeSlot(notes, startTime.time, 'notes')
              }}
              renderNoteTooltip={() =>
                `Add note for all ${startTime.time} start times`
              }
            />
            <DropdownWithCustomChildren
              options={terminalsOptions}
              className='StartTimeForm__dropdown'
              onChange={(event, { value }) => {
                onUpdateTimeSlot(value, startTime.time, 'terminalId')
              }}
              value={startTime.terminalId}
              components={{
                Option: CustomTerminalOption,
              }}
              getOptionLabel={opt => {
                const company = findCompanyById(opt.companyId)
                const labels = [company?.name, company?.code, opt.label]
                  .filter(Boolean)
                  .join(' - ')
                return labels
              }}
            >
              <ToolTipOverlay
                placement='top'
                content='Clockin and Truck Pickup Location'
              >
                <Button
                  variant='secondary'
                  style={{
                    height: 29,
                    borderRadius: 0,
                  }}
                >
                  <TruckIcon color='white' />
                  <span className='StartTimeForm__buttonLabel'>
                    {findTerminalById(startTime.terminalId as number)?.name ||
                      'Truck pickup terminal'}
                  </span>
                </Button>
              </ToolTipOverlay>
            </DropdownWithCustomChildren>

            <DropdownWithCustomChildren
              options={terminalsOptions}
              className='StartTimeForm__dropdown'
              onChange={(event, { value }) => {
                onUpdateTimeSlot(value, startTime.time, 'sellerTerminalId')
              }}
              value={startTime.sellerTerminalId}
              components={{
                Option: CustomTerminalOption,
              }}
              getOptionLabel={opt => {
                const company = findCompanyById(opt.companyId)
                const labels = [company?.name, company?.code, opt.label]
                  .filter(Boolean)
                  .join(' - ')
                return labels
              }}
            >
              <ToolTipOverlay
                placement='top'
                content='Seller Terminal for first Load Pickup'
              >
                <Button
                  variant='dark'
                  className='StartTimeForm__button'
                  style={{
                    height: 29,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <LocationIcon color='white' />
                  <span className='StartTimeForm__buttonLabel'>
                    {findTerminalById(startTime.sellerTerminalId as number)
                      ?.name || 'First load terminal'}
                  </span>
                </Button>
              </ToolTipOverlay>
            </DropdownWithCustomChildren>
          </ButtonGroup>

          <Button
            variant='danger'
            style={{
              marginLeft: 4,
              height: 29,
              marginTop: isSmallSection ? 8 : 0,
            }}
            onClick={onDeleteStartTime(startTime)}
          >
            <DeleteIcon color='white' />
          </Button>
          {startTime.driverFleets.length ? (
            <Badge style={{ marginLeft: 4, fontSize: 11 }}>
              {startTime.driverFleets.length}{' '}
              {pluralize('Start time', startTime.driverFleets.length)}
            </Badge>
          ) : null}
        </div>
        {startTime.driverFleets.length > 0 && (
          <div style={{ marginLeft: 12, marginTop: 12 }}>
            {startTime.driverFleets.map((driverFleet, index) => {
              const user = findUserByDriverFleetId(driverFleet.id)
              const sellerTerminal = findTerminalById(
                driverFleet.sellerTerminalId,
              )
              const terminal = findTerminalById(driverFleet.terminalId)
              const truckFleet = findTruckFleetById(driverFleet.truckFleetId)

              return (
                <div
                  key={driverFleet.id}
                  style={{
                    marginRight: 12,
                    marginBottom: 8,
                    display: 'inline-block',
                  }}
                >
                  <DriverCard
                    hideWorkerTerminal
                    hideTruckFleet
                    driverFleetId={driverFleet.id}
                    isDraggable={false}
                    isCloseable
                    isHiddenStatusTime
                    isHiddenNotesButton={false}
                    isShowingDriverName
                    isDriverLeft
                    index={index + 1}
                    renderExtraElements={() => {
                      return (
                        <>
                          <DropdownWithCustomChildren
                            className='no-hover make-custom-dropdown-inline'
                            options={terminalsOptions}
                            value={driverFleet.terminalId}
                            onChange={(event, { value }) => {
                              onChangeStartTimeDriver(
                                startTime,
                                driverFleet.id,
                                {
                                  terminalId: value,
                                },
                              )
                            }}
                          >
                            <ToolTipOverlay
                              placement='top'
                              content={
                                terminal
                                  ? `Truck pickup terminal: ${buildObjectName(
                                      terminal,
                                    )}`
                                  : ''
                              }
                            >
                              <IonChip>
                                <CompanyAvatar
                                  width={20}
                                  height={20}
                                  company={terminal as any}
                                />
                                <IonText style={{ marginLeft: 4 }}>
                                  {terminal?.code || 'Truck pickup terminal'}
                                </IonText>
                              </IonChip>
                            </ToolTipOverlay>
                          </DropdownWithCustomChildren>

                          <DropdownWithCustomChildren
                            className='no-hover make-custom-dropdown-inline'
                            options={terminalsOptions}
                            value={driverFleet.sellerTerminalId}
                            onChange={(event, { value }) => {
                              onChangeStartTimeDriver(
                                startTime,
                                driverFleet.id,
                                {
                                  sellerTerminalId: value,
                                },
                              )
                            }}
                          >
                            <ToolTipOverlay
                              placement='top'
                              content={
                                sellerTerminal
                                  ? `First load terminal: ${buildObjectName(
                                      sellerTerminal,
                                    )}`
                                  : ''
                              }
                            >
                              <IonChip>
                                <CompanyAvatar
                                  width={20}
                                  height={20}
                                  company={sellerTerminal as any}
                                />
                                <IonText style={{ marginLeft: 4 }}>
                                  {sellerTerminal?.code ||
                                    'First load terminal'}
                                </IonText>
                              </IonChip>
                            </ToolTipOverlay>
                          </DropdownWithCustomChildren>

                          <DropdownWithCustomChildren
                            value={driverFleet.truckFleetId}
                            options={currentTruckFleetOptions}
                            className='no-hover make-custom-dropdown-inline'
                            onChange={(event, { value }) => {
                              onChangeStartTimeDriver(
                                startTime,
                                driverFleet.id,
                                {
                                  truckFleetId: value,
                                },
                              )
                            }}
                          >
                            <IonChip
                              style={{
                                background:
                                  getColor(truckFleet?.color) ||
                                  colord('dark').lighten(0.2).toRgbString(),
                                color: 'white',
                              }}
                            >
                              <TruckNumberSection
                                truckId={truckFleet?.truckId as number}
                              />
                            </IonChip>
                          </DropdownWithCustomChildren>
                        </>
                      )
                    }}
                    renderNoteTooltip={({ note }) => {
                      return note ? (
                        <div>
                          Note for {`${buildFullName(user?.person)}'s`}&nbsp;
                          {startTime.time} start time: <span>{note}</span>
                        </div>
                      ) : (
                        `Add note for ${buildFullName(user?.person)}'s ${
                          startTime.time
                        } start time`
                      )
                    }}
                    extraIndexTooltipContent={
                      <div>
                        <span>
                          Count:{' '}
                          {assignedDriverFleetIds.indexOf(driverFleet.id) + 1}
                        </span>{' '}
                        / <span>{assignedDriverFleetIds.length}</span>
                      </div>
                    }
                    indexBadgeStyle={{
                      backgroundColor: startTime.color,
                      color: 'white',
                    }}
                    onChangeNotes={(_df, notes) => {
                      onChangeStartTimeDriver(startTime, driverFleet.id, {
                        notes,
                      })
                    }}
                    onClose={() => {
                      onUpdateTimeSlot(
                        produce(startTime.driverFleets, draft => {
                          draft.splice(index, 1)
                        }),
                        startTime.time,
                        'driverFleets',
                      )
                    }}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
      <ColorPickerDialog
        isOpen={showColorPicker}
        onClose={() => {
          setShowColorPicker(false)
        }}
        onSelectColor={(color: string) => {
          onUpdateTimeSlot(color, startTime.time, 'color')
        }}
      />
    </>
  )
}

export default TimeSlotCard
