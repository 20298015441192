import qs from 'qs'
import moment from 'moment'
import _ from 'lodash'

export const prepareQueryString = (
  filters,
  sorts,
  notSetDateFields = [],
  extraParams = {},
) => {
  let queryString = ''

  const snakeFilters = snakeCaseKeys(filters)
  queryString += filterString(snakeFilters)

  const sortsQuery = sortString(sorts)
  if (sortsQuery) queryString += '&' + sortsQuery

  notSetDateFields.forEach(field => {
    if (filters[field] === null) {
      queryString += `&filters[${field}]=null`
    }
  })

  Object.keys(extraParams).forEach(key => {
    queryString += `&${key}=${extraParams[key]}`
  })

  return queryString
}

const snakeCaseKeys = obj => {
  if (_.isObject(obj)) {
    const snakeCaseObj = {}
    Object.keys(obj).forEach(key => {
      snakeCaseObj[_.snakeCase(key)] = obj[key]
    })
    return snakeCaseObj //doesnt work with nested keys
  }
}

const filterString = filters => {
  return qs.stringify(
    { filters },
    {
      encode: false,
      skipNulls: true,
      arrayFormat: 'brackets',
      filter: filterParams,
    },
  )
}

const filterParams = (prefix, value) => {
  //date, createdAt
  if (value?.startDate && value?.endDate) {
    return `${moment(value.startDate).startOf('day').toString()}...${moment(
      value.endDate,
    )
      .endOf('day')
      .toString()}`
  }

  //qty asp total
  if (value?.lower && value?.upper) {
    return `${
      _.isNil(value.lower) || value.lower === '' ? null : value.lower
    }...${_.isNil(value.upper) || value.upper === '' ? null : value.upper}`
  }

  if (prefix == 'filters[search_word]' && value && value.length < 1) return
  if (prefix == 'filters[access_type]' && value && value.length < 1) return
  if (prefix == 'filters[date][key]') return
  // if (prefix == 'filters[order_by]') return
  // if (prefix == 'filters[visible_data]') return
  // if (prefix == 'filters[ascending_sort]') return

  return value
}

const sortString = sorts => {
  if (_.isArray(sorts)) {
    const dataSort = {}
    sorts
      .filter(({ sorted }) => sorted)
      .forEach(({ value, isAsc, columnName }) => {
        dataSort[_.snakeCase(value || columnName)] = isAsc ? 'asc' : 'desc' //snake case here(if needed)
      })

    return qs.stringify(
      { order: dataSort },
      {
        arrayFormat: 'brackets',
        encodeValuesOnly: true,
        skipNulls: true,
      },
    )
  }
}

export const isObjectWithinFilters = (obj, filters) => {
  return Object.entries(filters).every(([key, value]) => {
    if (value && !_.isEmpty(value) && Object.hasOwn(obj, key)) {
      if (value?.startDate || value?.endDate) {
        if (
          //check if diff day
          moment(value?.startDate).isSame(
            moment(value?.endDate).endOf('day'),
            'day',
          ) &&
          !moment(obj[key]).isSame(moment(value?.startDate), 'day')
        ) {
          return false
        }

        if (
          //check if within range
          (value?.startDate == null ||
            moment(obj[key]).isAfter(moment(value?.startDate))) &&
          (value?.endDate == null ||
            moment(obj[key]).isBefore(moment(value?.endDate).endOf('day')))
        ) {
          return true
        }

        return false
      }

      if (value?.lower || value?.upper) {
        if (
          (value?.lower == null || obj[key] <= Number(value?.upper)) &&
          (value?.upper == null || obj[key] >= Number(value?.lower))
        ) {
          return true
        }

        return false
      }

      if (value.includes(obj[key])) return true

      return false
    }

    return true
  })
}
