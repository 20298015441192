import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordFormLayout,
  ConcordFormStructure,
  IConcordFormField,
} from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IPricingFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQueryUoms,
} from '~/hooks/useQueryData'
import HierarchyForm from '~/containers/invoices/AutomaticChargesDialog/HierarchyForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import { Alert, Badge } from 'react-bootstrap'
import { selectCurrentScope } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import buildObjectName from '~/utils/buildObjectName'
import { EPricingType } from '~/types/enums/EPricing'
import { IPricingFormValues } from '~/types/models/IPricing'
import { format } from 'date-fns'
import isDateInRange from '~/utils/isDateInRange'
import moment from 'moment'
import { getPricingDate } from '~/utils/formatPricingDate'
import { EQtyType } from '~/types/enums/ESellerProduct'

const PricingForm = (props: IPricingFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    hierarchyItem,
    includeInvoiceId,
    invoiceId,
    ...formProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const formRef = useRef<any>()
  const hierarchyFormRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)

  const { findCompanyById } = useQueryCompanies({})

  const { findUomById } = useQueryUoms()

  const {
    buyerSellerProductOptions,
    sellerProductOptions,
    isLoadingBuyerSellerProducts,
    isLoadingSellerProducts,
    sellerProducts,
    findSellerProductById,
  } = useQueryBuyerSellerProducts({
    filters: {
      joinsSellerProduct: true,
    },
  })

  const hierarchyItemSellerProd = findSellerProductById(
    hierarchyItem?.sellerProductId,
  )

  const { hierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.Pricing],
        hierarchableId: formData?.id as number,
      },
    },
    { enabled: isUpdating, refetchOnMount: true },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, isHierarchiesDataFetched } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0, refetchOnMount: true },
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'dateRange',
        label: 'Date Range',
        type: EFieldType.dateRange,
        isRequired: true,
        size: 6,
      },
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        type: EFieldType.singleSelect,
        options:
          currentScope === EScope.buyer
            ? buyerSellerProductOptions
            : sellerProductOptions,
        size: 6,
        isRequired: true,
        isHidden: Boolean(hierarchyItem?.sellerProductId),
        isLoading: isLoadingBuyerSellerProducts || isLoadingSellerProducts,
      },
      {
        name: 'sellerProductElement',
        type: EFieldType.custom,
        size: 6,
        isHidden: !hierarchyItem?.sellerProductId,
        render({ errors }) {
          console.log('errors', errors)
          const sellerProd = sellerProducts.find(
            ({ id }) => id === hierarchyItem?.sellerProductId,
          )
          return (
            <ConcordFormLayout label='Seller Product'>
              <div>
                <Badge>
                  {buildObjectName({
                    name: sellerProd?.name,
                    code: sellerProd?.code,
                  })}
                </Badge>
                {sellerProd?.qtyType && (
                  <Badge style={{ marginLeft: 4 }}>
                    {_.startCase(sellerProd.qtyType)}
                  </Badge>
                )}
              </div>
            </ConcordFormLayout>
          )
        },
      },
      {
        name: 'amount',
        label: 'Amount',
        type: EFieldType.number,
        isRequired: true,
        prefix: '$',
        size: 6,
      },
      {
        name: 'pricingType',
        label: 'Pricing Type',
        isRequired: true,
        type: EFieldType.singleSelect,
        size: 6,
        options({ watch }) {
          const sellerProductId = watch('sellerProductId')
          const sellerProd = sellerProducts.find(
            ({ id }) => id === sellerProductId,
          )
          const sellerCompany = findCompanyById(sellerProd?.sellerId)
          const uom = findUomById(sellerCompany?.uomId)

          return [
            {
              label: uom ? `Per Qty (${uom.code})` : 'Per Qty',
              value: EPricingType.perQty,
            },
            {
              label: 'Per Load',
              value: EPricingType.perLoad,
            },
            {
              label: 'Flat Fee',
              value: EPricingType.flatFee,
            },
            {
              label: 'Percentage',
              value: EPricingType.percentage,
            },
          ]
        },
      },
      {
        name: 'hierarchyAttributes',
        render() {
          return (
            <HierarchyForm
              ref={hierarchyFormRef}
              className='AutoChargeForm__hierarchyForm'
              isUpdating={isUpdating}
              item={hierarchyItem}
            />
          )
        },
      },
    ],
    [
      buyerSellerProductOptions,
      currentScope,
      findCompanyById,
      findUomById,
      hierarchyItem,
      isLoadingBuyerSellerProducts,
      isLoadingSellerProducts,
      isUpdating,
      sellerProductOptions,
      sellerProducts,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        amount: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        pricingType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        sellerProductId: Yup.number()
          .required('This field is required!')
          .typeError('This field is required!'),
        dateRange: Yup.lazy(value => {
          if (value && hierarchyItem?.lineDate && !isUpdating) {
            return Yup.mixed()
              .test(
                'within-range',
                `Bill line (${format(
                  new Date(hierarchyItem?.lineDate as any),
                  'yyyy/MM/dd',
                )}) is not within this date range`,
                (date: any) => {
                  return isDateInRange(
                    hierarchyItem?.lineDate,
                    date?.startDate,
                    date?.endDate,
                  )
                },
              )
              .transform(value => {
                if (value.startDate) {
                  return {
                    startDate: moment(value.startDate).format('YYYY-MM-DD'),
                    endDate: moment(value.endDate).format('YYYY-MM-DD'),
                  }
                }
                return value
              })
          }
          return Yup.mixed()
            .nullable()
            .transform(value => {
              if (value.startDate) {
                return {
                  startDate: moment(value.startDate).format('YYYY-MM-DD'),
                  endDate: moment(value.endDate).format('YYYY-MM-DD'),
                }
              }
              return value
            })
        }),
      }),
    [hierarchyItem?.lineDate, isUpdating],
  )

  const defaultValues = useMemo<IPricingFormValues>(
    () => ({
      dateRange: null,
      sellerProductId: null,
      amount: null,
      pricingType: null,
    }),
    [],
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCreate = useCallback(async () => {
    // USE HIERARCHY ROW FORM INSTEAD
    // const { errors, ...response } = await apiClient.pricings.create({
    //   setting: {
    //     settingField: formValues.settingField,
    //     settingValue: formValues.settingValue,
    //   },
    // })
    // if (errors.length > 0) {
    //   setErrors(errors)
    // } else {
    //   const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
    //     hierarchableType: EHierarchableType.Setting,
    //     hierarchableId: response.id,
    //   })
    //   const keepOpenForm = res2.backendErrors.length > 0
    //   afterCreate && afterCreate(response, keepOpenForm)
    //   toast.success(toastMessages.createSuccess)
    // }
  }, [])

  const onUpdate = useCallback(
    async (formValues: IPricingFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['amount', 'pricingType'])
        const { errors, ...response } = await apiClient.pricings.update(
          formValues.id,
          {
            pricing: {
              ...payload,
              dateRange: formValues.dateRange
                ? `${(formValues.dateRange as any).startDate || 'Infinity'}..${
                    (formValues.dateRange as any).endDate || '-Infinity'
                  }`
                : null,
              invoiceId: includeInvoiceId ? invoiceId : undefined,
            },
          },
        )
        if (errors.length > 0) {
          setErrors([errors[0]])
        } else {
          const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
            hierarchableType: EHierarchableType.Pricing,
            hierarchableId: response.id,
          })
          const keepOpenForm = res2.backendErrors.length > 0
          afterUpdate && afterUpdate(response, keepOpenForm)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setErrors(['Id is not found'])
      }
    },
    [afterUpdate, includeInvoiceId, invoiceId],
  )

  const handleSubmit = useCallback(
    async (formValues: IPricingFormValues) => {
      hierarchyFormRef.current?.handleSubmit(async () => {
        setIsLoading(true)
        setErrors([])
        try {
          if (isUpdating) {
            await onUpdate(formValues)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      })()
    },
    [isUpdating, onUpdate],
  )

  useEffect(() => {
    if (isHierarchiesDataFetched && isUpdating && hierarchiesData.length > 0) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        hierarchiesData,
      )
    }
  }, [isHierarchiesDataFetched, hierarchiesData, isUpdating])

  useEffect(() => {
    if (formData) {
      const dateRange = getPricingDate(formData.dateRange as any)
      formRef.current?.setValue('dateRange', dateRange)
    }
  }, [formData])

  useEffect(() => {
    if (hierarchyItemSellerProd && !isUpdating) {
      formRef.current?.setValue('sellerProductId', hierarchyItemSellerProd?.id)
      if (hierarchyItemSellerProd?.qtyType) {
        const pricingType =
          hierarchyItemSellerProd?.qtyType === EQtyType.per_load
            ? EPricingType.perLoad
            : hierarchyItemSellerProd?.qtyType === EQtyType.per_order
            ? EPricingType.flatFee
            : hierarchyItemSellerProd?.qtyType
        formRef.current?.setValue(
          'hierarchableAttributes.pricingType',
          pricingType,
        )
      }
    }
  }, [hierarchyItemSellerProd, isUpdating])

  return (
    <>
      {errors.length > 0 ? (
        <Alert style={{ fontSize: 13 }} variant='danger'>
          <ul style={{ marginBottom: 0 }}>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </>
  )
}

export default PricingForm
