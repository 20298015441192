import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import SettingForm from './SettingForm'

import type { IDialogSettingFormProps } from './type'

function DialogSettingForm(props: IDialogSettingFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Setting'
      isHiddenOkButton
      onClose={onClose}
    >
      <SettingForm
        formData={formData}
        afterCreate={(formData, keepOpenForm) => {
          afterCreate && afterCreate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
        afterUpdate={(formData, keepOpenForm) => {
          afterUpdate && afterUpdate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogSettingForm
