import { useCallback, useState } from 'react'
import * as yup from 'yup'
import { ConcordFormStructure, IConcordFormField } from '../../FormStructure'
import { toastMessages } from '~/constants/toast-status-text'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
import { FileField } from '../../FileField'
import { IUpload } from '~/types/models/IUpload'

interface IUploadFormValues {
  uploadedFile: File
  uploadType: string
}

interface IUploadFormProps {
  afterCreate?: (item: IUpload) => void
  afterUpdate?: (item: IUpload) => void
  formData?: IUpload
  className?: string
}

export const UploadForm = (props: IUploadFormProps) => {
  const { afterCreate, afterUpdate, formData, className, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)
  const [_backendError, setBackendError] = useState('')

  const schema = yup.object({
    uploadedFile: yup.mixed().required('A CSV is required'),
    uploadType: yup.string().required('This field is required'),
  })

  const defaultValues = {
    uploadedFile: '',
    uploadType: 'invoice_import',
  }

  const isUpdating = Boolean(formData?.id)

  const fields: IConcordFormField[] = [
    {
      label: 'Uploaded File',
      name: 'uploadedFile',
      render({ label, setValue, watch, name }) {
        const logo = watch(name, null)
        return (
          <FileField
            label={label}
            value={[logo]}
            placeholder='Accept: csv' //xls/x?
            onChange={([file]: File[]) => {
              setValue(name, file)
            }}
            acceptedFiles='text/csv'
          />
        )
      },
    },
    {
      label: 'Upload Type',
      name: 'uploadType',
      isRequired: true,
      type: EFieldType.singleSelect,
      options: [{ label: 'Invoice Import', value: 'invoice_import' }],
    },
  ]

  const createUpload = useCallback(
    async (formValues: IUploadFormValues) => {
      const formData = new FormData()
      formData.append('upload[upload_type]', formValues.uploadType)
      formData.append('upload[uploaded_file]', formValues.uploadedFile)
      formData.append('upload[name]', formValues.uploadedFile.name)

      const response = await apiClient.uploads.create(formData)
      afterCreate && afterCreate(response)
    },
    [afterCreate],
  )

  // const updateUpload = useCallback(
  //   async (formValues: IUploadFormValues) => {
  //     if (!formData?.id) return
  //     const response = await apiClient.uploads.update(formData.id, formValues)
  //     afterUpdate && afterUpdate(response)
  //   },
  //   [afterUpdate, formData?.id],
  // )

  const handleSubmit = useCallback(
    async (formValues: IUploadFormValues) => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await createUpload(formValues)
        } else {
          await createUpload(formValues)
        }
      } catch (error) {
        console.log('error', error)
        setBackendError(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [createUpload, isUpdating],
  )

  return (
    <ConcordFormStructure
      isLoading={isLoading}
      defaultValues={defaultValues}
      formData={formData}
      fields={fields}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      schema={schema}
      submitText={'Create'} //isUpdating ? 'Update' :
      {...formProps}
    />
  )
}
