import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyStatements from './useModifyStatements'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { IStatement } from '~/types/models/IStatement'

const useQueryStatements = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params = {},
  options?: Partial<UseQueryOptions<IStatement[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['statements', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.statements.get()
      return response.statements
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const statementsData = useMemo(() => data || [], [data])

  const { addStatement, removeStatement } = useModifyStatements()

  return {
    statementsData,
    isStatementsDataLoading: isLoading,
    addStatement,
    removeStatement,
    refetchStatements: refetch,
  }
}

export default useQueryStatements
