import { useQueryWeights } from '~/hooks/useQueryData'
import './styles.scss'
import { IWeightsListProps } from './type'
import Skeleton from 'react-loading-skeleton'
import { ToggleSection } from '~/components/shared'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { WeightCard } from './WeightCard'
import { useState } from 'react'
import { Alert } from 'react-bootstrap'

function WeightsList(props: IWeightsListProps) {
  const { load } = props

  const [show, setShow] = useState(false)

  const { weightsData, isWeightsDataLoading, isWeightsDataFetched } =
    useQueryWeights(
      {
        filters: {
          loadId: load?.id,
        },
      },
      { enabled: Boolean(load?.id) && show, refetchOnMount: true },
    )

  return (
    <ToggleSection
      isOpenByDefault={show}
      label='Weights'
      style={{ marginLeft: 8, marginTop: 8 }}
      badges={[
        {
          label: `${weightsData.length}`,
          isHidden: !isWeightsDataFetched || weightsData.length === 0,
        },
      ]}
      onChange={(event, checked) => {
        setShow(checked)
      }}
    >
      {isWeightsDataLoading ? <Skeleton height={20} /> : null}

      {!isWeightsDataLoading && weightsData.length === 0 ? (
        <Alert style={{ fontSize: 13 }} variant='info'>
          No Data Found!
        </Alert>
      ) : null}

      <IonGrid>
        <IonRow>
          {weightsData.map(weight => (
            <IonCol size='6' key={weight.id}>
              <WeightCard weightData={weight} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </ToggleSection>
  )
}

export default WeightsList
