import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useDrop } from 'react-dnd'
import { IonIcon, IonSpinner } from '@ionic/react'

import OrderTrackingStatusCard from '~/containers/OrderTracking/StatusCard'
import { CommonTab, CompanyAvatar, ToolTipOverlay } from '~/components/shared'
import { OrderFormDialog } from '~/components/shared/SchedulesTable/OrderFormDialog'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { ORDER_STATUSES } from '~/constants/orders/constants'
import { convertLoadStatusToInteger } from '~/types/enums/ELoadStatus'
import { DRIVER_TYPES } from '../Utils/driverTypes'
import { apiClient } from '~/api/ApiClient'

import './OrderTrackingCard.scss'
import {
  useQueryBuyerSellers,
  useQueryCompanies,
  useQueryTerminals,
} from '~/hooks/useQueryData'

const OrderTrackingCard = ({ data }) => {
  const [currentStatus, setCurrentStatus] = useState('')
  const [isExpandingCard, setIsExpandingCard] = useState(false)
  const [dataCompletedLoads, setDataCompletedLoads] = useState([])
  const [loadingCompleted, setLoadingCompleted] = useState(false)
  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultOrderFormTab, setDefaultOrderFormTab] = useState('')

  const { findTerminalById } = useQueryTerminals()

  const { buyerSellersData } = useQueryBuyerSellers()

  const { findCompanyById } = useQueryCompanies({})
  const { getLoadStatusesInSection, loadStatuses } = useQueryLoadStatuses()

  const statusList = useMemo(
    () => loadStatuses.map(status => status.name),
    [loadStatuses],
  )

  const buyerTerminal = useMemo(
    () => findTerminalById(data?.order.buyerTerminalId),
    [findTerminalById, data?.order.buyerTerminalId],
  )

  const companyInfo = useMemo(
    () => findCompanyById(buyerTerminal?.companyId),
    [findCompanyById, buyerTerminal?.companyId],
  )

  const buyer = useMemo(
    () => buyerSellersData.find(e => e.buyer?.id == data?.buyerId),
    [buyerSellersData, data?.buyerId],
  )

  const loadsWithToSellerStatus = useMemo(
    () =>
      data.loads.filter(
        ({ status }) =>
          statusList.findIndex(e => e === 'To Seller') <=
          statusList.findIndex(e => e === status) <
          statusList.findIndex(e => e === 'At Seller'),
      ),
    [data.loads, statusList],
  )

  const loadsWithAtSellerStatus = useMemo(
    () =>
      data.loads.filter(
        ({ status }) =>
          statusList.findIndex(e => e === 'At Seller') <=
          statusList.findIndex(e => e === status) <
          statusList.findIndex(e => e === 'To Buyer'),
      ),
    [data.loads, statusList],
  )

  const loadsWithAtBuyerStatus = useMemo(
    () =>
      data.loads.filter(
        ({ status }) =>
          statusList.findIndex(e => e === 'To Buyer') <=
          statusList.findIndex(e => e === status) <
          statusList.findIndex(e => e === 'At Buyer'),
      ),
    [data.loads, statusList],
  )

  const loadsWithDeliverCompleteStatus = useMemo(
    () =>
      data.loads.filter(
        ({ status }) =>
          statusList.findIndex(e => e === 'At Buyer') <=
          statusList.findIndex(e => e === status) <
          statusList.findIndex(e => e === 'Delivery Complete'),
      ),
    [data.loads, statusList],
  )

  const textStatusClassName = useMemo(() => {
    const classNames = {
      Requested: 'text-secondary',
      'Will Call': 'text-info',
      Confirmed: 'text-primary',
      Active: 'text-success',
      Hold: 'text-warning',
      Complete: 'text-dark',
      Canceled: 'text-danger',
    }

    return classNames[data.status]
  }, [data.status])

  // gray progress
  const qtyNotShipped = useMemo(() => {
    const { qty, qtyCounter } = data
    return qty - qtyCounter
  }, [data])

  const completedLoads = useMemo(
    () =>
      data.loads.filter(({ status }) =>
        ['Delivery Complete', 'Completed'].includes(status),
      ),
    [data.loads],
  )

  // baby blue
  const sumQtyOfCompletedLoads = useMemo(
    () => completedLoads.reduce((qty, nextVal) => qty + Number(nextVal.qty), 0),
    [completedLoads],
  )

  // purple
  const sumLoadQtyAtTwoLastColumns = useMemo(() => {
    const sumLoadsAtBuyerStatus = loadsWithAtBuyerStatus.reduce(
      (qty, nextVal) => qty + Number(nextVal.qty),
      0,
    )

    const sumLoadsDeliverCompleteStatus = loadsWithDeliverCompleteStatus.reduce(
      (qty, nextVal) => qty + Number(nextVal.qty),
      0,
    )

    return sumLoadsAtBuyerStatus + sumLoadsDeliverCompleteStatus
  }, [loadsWithAtBuyerStatus, loadsWithDeliverCompleteStatus])

  // black
  const sumLoadQtyAtTwoFirstColumns = useMemo(() => {
    const sumLoadsAtBuyerStatus = loadsWithToSellerStatus.reduce(
      (qty, nextVal) => qty + Number(nextVal.qty),
      0,
    )

    const sumLoadsDeliverCompleteStatus = loadsWithAtSellerStatus.reduce(
      (qty, nextVal) => qty + Number(nextVal.qty),
      0,
    )

    return sumLoadsAtBuyerStatus + sumLoadsDeliverCompleteStatus
  }, [loadsWithAtSellerStatus, loadsWithToSellerStatus])

  const {
    percentageOfGray,
    percentageOfBabyBlue,
    percentageOfPurple,
    percentageOfBlack,
  } = useMemo(() => {
    const totalProgress =
      qtyNotShipped +
      sumQtyOfCompletedLoads +
      sumLoadQtyAtTwoLastColumns +
      sumLoadQtyAtTwoFirstColumns

    const percentageOfGray = (qtyNotShipped / totalProgress) * 100
    const percentageOfBabyBlue = (sumQtyOfCompletedLoads / totalProgress) * 100
    const percentageOfPurple =
      (sumLoadQtyAtTwoLastColumns / totalProgress) * 100
    const percentageOfBlack =
      (sumLoadQtyAtTwoFirstColumns / totalProgress) * 100

    return {
      percentageOfGray,
      percentageOfBabyBlue,
      percentageOfPurple,
      percentageOfBlack,
    }
  }, [
    qtyNotShipped,
    sumLoadQtyAtTwoFirstColumns,
    sumLoadQtyAtTwoLastColumns,
    sumQtyOfCompletedLoads,
  ])

  const loadsBasedOnStatus = useMemo(() => {
    if (currentStatus) {
      if (['completed', 'all'].includes(currentStatus)) {
        return []
      }
      const statuses = getLoadStatusesInSection(currentStatus).map(
        ({ name }) => name,
      )
      return (data.loads || []).filter(({ status }) =>
        statuses.includes(status),
      )
    }

    return []
  }, [currentStatus, data.loads, getLoadStatusesInSection])

  const checkLoadValue = useCallback(
    statusValue => {
      const statuses = getLoadStatusesInSection(statusValue).map(
        ({ name }) => name,
      )
      return (
        (data.loads || []).filter(({ status }) => statuses.includes(status))
          .length > 0
      )
    },
    [data.loads, getLoadStatusesInSection],
  )

  const renderLoads = useCallback(() => {
    if (loadsBasedOnStatus.length === 0) {
      return <div style={{ fontSize: 12 }}>There are no loads!</div>
    }
    return (
      <div>
        {loadsBasedOnStatus.map(load => (
          <OrderTrackingStatusCard
            key={load.id}
            column={currentStatus}
            load={load}
            order={data}
            draggable={false}
            clickable={false}
            removable={false}
          />
        ))}
      </div>
    )
  }, [currentStatus, data, loadsBasedOnStatus])

  const tabs = useMemo(
    () => [
      {
        name: 'to_seller',
        label: 'To seller',
        isHidden: !checkLoadValue('to_seller'),
        render: renderLoads,
      },
      {
        name: 'at_seller',
        label: 'At seller',
        isHidden: !checkLoadValue('at_seller'),
        render: renderLoads,
      },
      {
        name: 'to_buyer',
        label: 'To buyer',
        isHidden: !checkLoadValue('to_buyer'),
        render: renderLoads,
      },
      {
        name: 'at_buyer',
        label: 'At buyer',
        isHidden: !checkLoadValue('at_buyer'),
        render: renderLoads,
      },
      {
        name: 'returning',
        label: 'Returning',
        isHidden: !checkLoadValue('returning'),
        render: renderLoads,
      },
      {
        name: 'completed',
        label: 'Completed',
        render() {
          if (loadingCompleted) {
            return (
              <div style={{ display: 'flex', justtifyContent: 'center' }}>
                <IonSpinner name='lines-sharp-small' />
              </div>
            )
          }
          if (dataCompletedLoads.length === 0) {
            return <div style={{ fontSize: 12 }}>There are no loads!</div>
          }
          return (
            <div>
              {dataCompletedLoads.map(load => (
                <OrderTrackingStatusCard
                  key={load.id}
                  // column={currentStatus}
                  load={load}
                  order={data}
                  draggable={false}
                  clickable={false}
                  removable={false}
                />
              ))}
            </div>
          )
        },
      },
      {
        name: 'all',
        label: 'All',
        render() {
          if (loadingCompleted) {
            return (
              <div style={{ display: 'flex', justtifyContent: 'center' }}>
                <IonSpinner name='lines-sharp-small' />
              </div>
            )
          }
          const allData = [...(data?.loads || []), ...dataCompletedLoads]
          if (allData.length === 0) {
            return <div style={{ fontSize: 12 }}>There are no loads!</div>
          }
          return (
            <div>
              {allData.map(load => (
                <OrderTrackingStatusCard
                  key={load.id}
                  // column={currentStatus}
                  load={load}
                  order={data}
                  draggable={false}
                  clickable={false}
                  removable={false}
                />
              ))}
            </div>
          )
        },
      },
    ],
    [checkLoadValue, data, dataCompletedLoads, loadingCompleted, renderLoads],
  )
  const formatTime = useCallback(time => {
    if (time) {
      return moment(time).format('ddd, MMM-DD HH:mm')
    }
  }, [])

  const renderColorStatus = useCallback(() => {
    switch (data.status) {
      case 'Requested':
        return '#6f767e'
      case 'Will Call':
        return '#1090ff'
      case 'Confirmed':
        return '#0037ff'
      case 'Active':
        return '#30823a'
      case 'Hold':
        return '#ffa200'
      case 'Complete':
        return '#000'
      case 'Canceled':
        return '#fe3a42'
      default:
        return '#6f767e'
    }
  }, [data.status])

  const renderStatus = useCallback(
    () => (
      <div
        className='statusCard'
        style={{ border: `1px solid ${renderColorStatus()}` }}
      >
        <span
          className={textStatusClassName}
          style={{
            fontWeight: 600,
            marginLeft: 6,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IonIcon
            icon={ORDER_STATUSES[data.status]?.icon}
            style={{ marginRight: 4 }}
          />
          {data.status}
        </span>
      </div>
    ),
    [data.status, textStatusClassName, renderColorStatus],
  )

  const onPressCard = useCallback(() => {
    setCurrentStatus('')
    setIsExpandingCard(prev => !prev)
  }, [])

  const fetchCompletedLoads = useCallback(async () => {
    if (dataCompletedLoads.length === 0) {
      const status = convertLoadStatusToInteger('deliveryComplete')
      setLoadingCompleted(true)
      try {
        const response = await apiClient.loads.get({
          filters: {
            orderId: [data.id],
            status: [status],
          },
        })
        setDataCompletedLoads(response.loads || [])
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoadingCompleted(false)
      }
    }
  }, [data.id, dataCompletedLoads.length])

  useEffect(() => {
    if (['completed', 'all'].includes(currentStatus)) {
      fetchCompletedLoads()
    }
  }, [currentStatus, fetchCompletedLoads])

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DRIVER_TYPES.WORKING,
    drop: () => ({ order: data }),
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    canDrop: draggingItem =>
      draggingItem?.working === 'working' && data.status === 'Active',
  }))

  const border = useMemo(() => {
    const isActive = canDrop && isOver
    if (isActive) {
      return '3px dashed green'
    }
    if (canDrop) {
      return '3px dashed #e5e5e5'
    }

    return 'none'
  }, [canDrop, isOver])

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
    setDefaultOrderFormTab('')
  }, [])

  const onOpenOrderDialog = useCallback(
    tab => event => {
      event.stopPropagation()
      setIsOpenOrderForm(true)
      setDefaultOrderFormTab(tab)
    },
    [],
  )

  return (
    <>
      <div
        className='OrderTrackingCard__container'
        ref={drop}
        style={{ border }}
      >
        <div className='progress-bar'>
          <ToolTipOverlay content='Delivery Complete' placement='bottom'>
            <div
              style={{
                backgroundColor: '#89CFF0',
                height: 5,
                width: `${percentageOfBabyBlue}%`,
              }}
            />
          </ToolTipOverlay>

          <ToolTipOverlay content='At Buyer Site' placement='bottom'>
            <div
              style={{
                backgroundColor: '#A020F0',
                height: 5,
                width: `${percentageOfPurple}%`,
              }}
            />
          </ToolTipOverlay>

          <ToolTipOverlay content='To Buyer Site' placement='bottom'>
            <div
              style={{
                backgroundColor: '#000000',
                height: 5,
                width: `${percentageOfBlack}%`,
              }}
            />
          </ToolTipOverlay>

          <div
            style={{
              backgroundColor: 'rgb(204 204 204)',
              height: 5,
              width: `${percentageOfGray}%`,
            }}
          />
        </div>
        <div
          className='OrderTrackingCard__body'
          style={{
            borderColor: renderColorStatus(),
            borderTop: 0,
            borderWidth: 2,
          }}
          onClick={onPressCard}
        >
          <div>
            <div className='header'>
              <div className='OrderTrackingCard__row'>
                <CompanyAvatar company={companyInfo} />
                <div style={{ marginLeft: 6 }}>
                  <div className='title hyperLink'>
                    #{' '}
                    <span onClick={onOpenOrderDialog('')}>
                      {data.order.num}
                    </span>{' '}
                    -{' '}
                    <span onClick={onOpenOrderDialog(data.scheduleNumber)}>
                      {data.scheduleNumber || '-'}{' '}
                    </span>
                  </div>

                  <div className='title'>
                    {buyerTerminal?.code || '--'} -{' '}
                    {buyerTerminal?.name || '--'}
                  </div>
                  <div className='buyerName'>{buyer?.buyer?.name}</div>
                </div>
              </div>
              <div className='description'>
                <div className='tags'>
                  <span className='item'>
                    {parseFloat(data?.qtyCounter || 0)} /{' '}
                    {parseFloat(data?.qty || 0)}
                  </span>
                  <span className='item'>{formatTime(data?.startTime)}</span>
                  {data?.bsp?.code && (
                    <span className='item'>{data?.bsp?.code}</span>
                  )}
                </div>
                <div className='tags rowItem'>
                  <span className='item'>#{data.num}</span>
                  <div className='item'>{renderStatus()}</div>
                  <span className='item'>
                    Loads:{' '}
                    {loadsWithToSellerStatus.length +
                      loadsWithAtSellerStatus.length}
                  </span>
                </div>
              </div>
            </div>
            <div className='body'>
              {isExpandingCard && (
                <CommonTab
                  currentTab={currentStatus}
                  tabs={tabs}
                  className='tabsContainer'
                  onChangeTab={tabValue => {
                    setCurrentStatus(tabValue)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        defaultTab={defaultOrderFormTab}
        orderData={data.order}
      />
    </>
  )
}

export default OrderTrackingCard
