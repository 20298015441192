import { useState } from 'react'
import { ReusableTable } from '~/components/shared'
import { useUploadsTable } from './useUploadsTable'
import { DialogUploadForm } from '~/components/shared/ConcordForm/FormData/UploadForm/DialogUploadForm'

export const UploadsContainerTable = () => {
  const { columns, uploads, isUploadsLoading, createUpload } = useUploadsTable()
  const [isFormOpen, setIsFormOpen] = useState(false)
  return (
    <div>
      <ReusableTable
        columns={columns}
        data={uploads}
        enableTopToolbar
        enableCompanyView
        enableRowActions
        state={{ isLoading: isUploadsLoading }}
        companyViewProps={{
          name: 'uploads',
          description: 'Manage your uploads.',
          onClickTopButton: () => setIsFormOpen(true),
        }}
      />
      <DialogUploadForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        afterCreate={upload => {
          createUpload(upload)
          setIsFormOpen(false)
        }}
      />
    </div>
  )
}
