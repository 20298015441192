import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormStructure,
  ConcordRadioWithFormControl,
  ConcordTextFieldWithFormControl,
  IConcordFormField,
} from '~/components/shared'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { ISettingFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import {
  useQueryAllowedHierarchies,
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQueryRetrieveSettingValues,
} from '~/hooks/useQueryData'
import { ISettingFormValues } from '~/types/models/ISetting'
import HierarchyForm from '~/containers/invoices/AutomaticChargesDialog/HierarchyForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'

const SettingForm = (props: ISettingFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const {
    settingNewValuesData,
    settingFieldOptions,
    getSettingOptionsFromValues,
    getSettingFieldTypeFromValues,
  } = useQueryRetrieveSettingValues()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const formRef = useRef<any>()
  const hierarchyFormRef = useRef<any>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { allowedHierarchiesData, isAllowedHierarchiesFetched } =
    useQueryAllowedHierarchies()

  const { hierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.Setting],
        hierarchableId: formData?.id as number,
      },
    },
    { enabled: isUpdating, refetchOnMount: true },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, isHierarchiesDataFetched } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0, refetchOnMount: true },
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Setting Field',
        name: 'settingField',
        options: settingFieldOptions,
        type: EFieldType.singleSelect,
        isRequired: true,
        render({ control, name, label, setValue }) {
          return (
            <ConcordDropdownV2WithFormControl
              name={name}
              control={control}
              label={label}
              isRequired
              options={settingFieldOptions}
              onChange={() => {
                setValue('settingValue', null)
              }}
            />
          )
        },
      },
      {
        label: 'Setting Value',
        name: 'settingValue',
        render({ control, name, label, watch }) {
          const settingField = watch('settingField', '')
          const values = settingNewValuesData
            ? settingNewValuesData[_.camelCase(settingField)]
            : undefined
          if (values) {
            const type = getSettingFieldTypeFromValues(settingField, values)
            const options = getSettingOptionsFromValues(settingField, values)
            if (type === EFieldType.singleSelect) {
              return (
                <ConcordDropdownV2WithFormControl
                  name={name}
                  control={control}
                  label={label}
                  options={options}
                  isRequired
                />
              )
            }

            if (type === EFieldType.radio) {
              return (
                <ConcordRadioWithFormControl
                  control={control}
                  name={name}
                  label={label}
                  options={options}
                  isRequired
                />
              )
            }

            return (
              <ConcordTextFieldWithFormControl
                name={name}
                control={control}
                label={label}
                isRequired
              />
            )
          }
          return null
        },
      },
      {
        name: 'hierarchyAttributes',
        render() {
          return (
            <HierarchyForm
              ref={hierarchyFormRef}
              className='AutoChargeForm__hierarchyForm'
              isUpdating={isUpdating}
            />
          )
        },
      },
    ],
    [
      getSettingFieldTypeFromValues,
      getSettingOptionsFromValues,
      isUpdating,
      settingFieldOptions,
      settingNewValuesData,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        settingField: Yup.string().required('This field is required!'),
        settingValue: Yup.mixed().required('This field is required!'),
      }),
    [],
  )

  const defaultValues = useMemo<ISettingFormValues>(
    () => ({
      settingField: '',
      settingValue: null,
    }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: ISettingFormValues) => {
      const { errors, ...response } = await apiClient.settings.create({
        setting: {
          settingField: formValues.settingField,
          settingValue: formValues.settingValue,
        },
      })
      if (errors.length > 0) {
        setErrors(errors)
      } else {
        const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
          hierarchableType: EHierarchableType.Setting,
          hierarchableId: response.id,
        })
        const keepOpenForm = res2.backendErrors.length > 0
        afterCreate && afterCreate(response, keepOpenForm)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: ISettingFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, ['settingField', 'settingValue'])
        const { errors, ...response } = await apiClient.settings.update(
          formValues.id,
          {
            setting: payload,
          },
        )
        if (errors.length > 0) {
          setErrors([errors[0]])
        } else {
          const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
            hierarchableType: EHierarchableType.Setting,
            hierarchableId: response.id,
          })
          const keepOpenForm = res2.backendErrors.length > 0
          afterUpdate && afterUpdate(response, keepOpenForm)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setErrors(['Id is not found'])
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: ISettingFormValues) => {
      hierarchyFormRef.current?.handleSubmit(async () => {
        setIsLoading(true)
        setErrors([])
        try {
          if (isUpdating) {
            await onUpdate(formValues)
          } else {
            await onCreate(formValues)
          }
        } catch (error) {
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      })()
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isHierarchiesDataFetched && isUpdating && hierarchiesData.length > 0) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        hierarchiesData,
      )
    }
  }, [isHierarchiesDataFetched, hierarchiesData, isUpdating])

  useEffect(() => {
    if (!isUpdating && isAllowedHierarchiesFetched) {
      const soleSellerHierarchy = allowedHierarchiesData.find(
        ({ sellerSideType, buyerSideType }) =>
          sellerSideType === 'Seller' && !buyerSideType,
      )
      if (soleSellerHierarchy) {
        hierarchyFormRef.current?.setValue('hierarchiesAttributes', [
          {
            sellerSideType: soleSellerHierarchy.sellerSideType,
            sellerSideId: currentCompany?.id,
            buyerSideType: null,
            buyerSideId: null,
            allowedHierarchyId: soleSellerHierarchy.id,
          },
        ])
      }
    }
  }, [
    allowedHierarchiesData,
    isUpdating,
    isAllowedHierarchiesFetched,
    currentCompany?.id,
  ])

  return (
    <>
      {errors.length > 0 ? (
        <Alert style={{ fontSize: 13 }} variant='danger'>
          <ul style={{ marginBottom: 0 }}>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </>
  )
}

export default SettingForm
