import useScheduleCardOnMap from './useScheduleCardOnMap'

import { IonSpinner, IonText } from '@ionic/react'
import { When } from 'react-if'
import { OrderFormDialog } from '~/components/shared/SchedulesTable/OrderFormDialog'
import { LoadStatusModal } from '../LoadStatusModal'
import { CompanyChip } from './CompanyChip'

import moment from 'moment'
import clsx from 'clsx'
import { EScope } from '~/types/enums/ECommonEnum'

import type { IScheduleCardOnMapProps } from './type'
import type { ILoad } from '~/types/models/ILoad'

import './styles.scss'
import LoadProgress from './LoadProgress'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

const ScheduleCardOnMap: React.FC<IScheduleCardOnMapProps> = props => {
  const {
    percentage,
    loadBuyerTerminal,
    isLoadingLoadData,
    truck,
    loadData,
    orderNumber,
    isOpenOrderForm,
    scheduleData,
    defaultOrderTab,
    isOpenLoadStatusModal,
    loadBuyer,
    loadSeller,
    loadSellerTerminal,
    loadBuyerSellerProduct,
    loadQty,
    currentUom,
    loadDriverFullName,
    onCloseOrderForm,
    onOpenLoadStatusesDialog,
    onCloseLoadStatusModal,
    onChangeLoadStatus,
  } = useScheduleCardOnMap(props)

  if (!loadData?.id) {
    return null
  }

  return (
    <>
      <div className='ScheduleCardOnMap__container'>
        <div className='body'>
          <div className='progressBarContainer'>
            <div
              className='progress-bar'
              style={{
                background: `
              radial-gradient(closest-side, white 79%, transparent 80% 100%),
              conic-gradient(var(--ion-color-concord) ${percentage}%, #e2e2e2 0)`,
              }}
              role='progressbar'
            >
              <div className='truckName'>
                <IonText>{truck.name}</IonText>
              </div>
              <div className='percentage'>{percentage}%</div>
            </div>

            <When condition={Boolean(loadData?.status)}>
              <div className='loadStatus' onClick={onOpenLoadStatusesDialog}>
                <span className='chip'>{loadData?.status}</span>
              </div>
            </When>
          </div>

          <div className='loadInfo'>
            <div style={{ display: 'inline-block' }}>
              <CompanyChip
                company={{
                  label: loadBuyerTerminal?.name as string,
                  value: loadBuyerTerminal?.id as number,
                  image: loadBuyer?.logo,
                }}
                companyType={EScope.buyer}
                tooltipMessage='Buyer Terminal'
              />
            </div>
            <div className='loadBuyerName'>
              <CompanyChip
                company={{
                  label: loadSellerTerminal?.name as string,
                  value: loadSellerTerminal?.id as number,
                  image: loadSeller?.logo,
                }}
                companyType={EScope.seller}
                tooltipMessage='Seller Terminal'
              />
            </div>
            <div style={{ marginBottom: 4 }}>
              <span>{loadQty}</span>&nbsp;/&nbsp;
              <span>
                {Number(scheduleData?.qty || 0)} {currentUom?.code}
              </span>
              &nbsp;
              <CompanyChip
                company={{
                  label: loadBuyerSellerProduct?.sellerProduct
                    ? `${loadBuyerSellerProduct?.sellerProduct.code} - ${loadBuyerSellerProduct?.sellerProduct.name}`
                    : '',
                  value: loadBuyerSellerProduct?.sellerProduct?.id as number,
                }}
                isHiddenAvatar
                companyType={EScope.fleet}
                tooltipMessage='Buyer Seller Product'
              />
            </div>
            <ToolTipOverlay
              content={moment(loadData?.statusTime).format('MMM-D, H:mm')}
              placement='top'
            >
              <span className=''>{moment(loadData?.statusTime).fromNow()}</span>
            </ToolTipOverlay>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <LoadProgress load={loadData?.status} />
        </div>
        <div className='footer'>
          <div style={{ marginRight: 12 }}>
            <span
              className={clsx('order', {
                isDisabled: isLoadingLoadData,
              })}
            >
              <span style={{ fontWeight: 'bold' }}>Order</span> {orderNumber}
            </span>
            {isLoadingLoadData && (
              <IonSpinner
                style={{ verticalAlign: 'middle' }}
                name='lines-sharp-small'
              />
            )}
          </div>
          <div>
            <span>
              <span style={{ fontWeight: 'bold' }}>LD</span> #{loadData?.num}
            </span>
            <CompanyChip
              company={{
                label: loadDriverFullName as string,
              }}
              companyType={EScope.driver}
              tooltipMessage='Driver'
            />
          </div>
        </div>
      </div>
      <OrderFormDialog
        isOpen={isOpenOrderForm}
        onClose={onCloseOrderForm}
        orderData={scheduleData?.order}
        defaultTab={defaultOrderTab}
        afterUpdate={onCloseOrderForm}
      />
      <When condition={Boolean(loadData)}>
        <LoadStatusModal
          loadData={loadData as ILoad}
          isOpen={isOpenLoadStatusModal}
          onClose={onCloseLoadStatusModal}
          onChangeLoadStatus={onChangeLoadStatus}
        />
      </When>
    </>
  )
}

export default ScheduleCardOnMap
