export const ELoadStatus = {
  ticketed: 'Ticketed',
  deadHead: 'Dead Head',
  atPlant: 'At Plant',
  checkIn: 'Check In',
  onScaleEmpty: 'On Scale - Empty',
  toLoadingArea: 'To Loading Area',
  startLoad: 'Start Load',
  endLoad: 'End Load',
  onScale: 'On Scale - Full',
  exitingPlant: 'Exiting Plant',
  checkOut: 'Check Out',
  toJob: 'To Job',
  arrivedJob: 'Arrived Job',
  jobScaleFull: 'Job Scale - Full',
  toDischarge: 'To Discharge',
  discharging: 'Discharging',
  dischargeComplete: 'Discharge Complete',
  jobScaleEmpty: 'Job Scale - Empty',
  exitingJob: 'Exiting Job',
  deliveryComplete: 'Delivery Complete',
  returning: 'Returning',
}

export const convertLoadStatusToInteger = (key: keyof typeof ELoadStatus) =>
  Object.values(ELoadStatus).indexOf(ELoadStatus[key])
