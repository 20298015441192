import { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { IonModal, IonContent } from '@ionic/react'
import { toast } from 'react-toastify'
import { LoadFlags } from '~/components/shared'
import LoadGridCard from '~/components/load/LoadGridCard'
import { DocumentView } from '~/components/shared'
import { updateLoadPdf } from '~/redux/actions/loadActions'
import { isExpiredAWSLink } from '~/utils/utils'
import { openOutline } from 'ionicons/icons'
import clsx from 'clsx'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'

const LoadDocumentView = ({
  containerStyle,
  ticketPdf,
  loadId,
  className,
  documentViewerHeight,
  extraButtons,
  onFetchCurrentLoad,
  onToggleLoading,
  currentLoad,
}) => {
  const transformComponentRef = useRef()
  const imgRef = useRef()
  const dispatch = useDispatch()

  const [toggleBillLines, setToggleBillLines] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const onImageLoaded = useCallback(() => {
    transformComponentRef?.current?.zoomIn(1)
    transformComponentRef?.current?.resetTransform()
    transformComponentRef?.current?.centerView(1)
  }, [])

  const getLoadFlags = useCallback(async () => {
    onToggleLoading && onToggleLoading(true)
    try {
      const loadResponse = await apiClient.loads.getById(currentLoad.id)
      const error = loadResponse.message || loadResponse.error

      if (error) return toast.error(error)

      if (isExpiredAWSLink(ticketPdf)) {
        const response = await apiClient.url.getById(loadId, {
          model: 'Load'
        })

        dispatch(updateLoadPdf({ loadId, pdfUrl: response.url }))
      }
      onFetchCurrentLoad && onFetchCurrentLoad(loadResponse)
    } catch (error) {
      console.log('error', error)
      toast.error('Server occurs an error!')
    } finally {
      onToggleLoading && onToggleLoading(false)
    }
  }, [
    currentLoad.id,
    dispatch,
    loadId,
    onFetchCurrentLoad,
    onToggleLoading,
    ticketPdf,
  ])

  useEffect(() => {
    const imgElCurrent = imgRef?.current
    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded)

      return () => imgElCurrent.removeEventListener('load', onImageLoaded)
    }
  }, [onImageLoaded])

  useEffect(() => {
    if (toggleBillLines) {
      getLoadFlags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBillLines])

  const onShowBillLine = useCallback(() => {
    setToggleBillLines(prev => !prev)
  }, [])

  const onShowDetailsModal = useCallback(() => {
    setShowDetailsModal(true)
  }, [])

  const renderLoadFlags = useCallback(() => {
    if (toggleBillLines) {
      if (currentLoad?.flags?.length > 0) {
        return (
          <div
            style={{
              margin: '20px 10px',
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            {currentLoad.flags.map((flag, index) => (
              <LoadFlags key={index} flag={flag} index={index} />
            ))}
          </div>
        )
      }

      return <div>No Flags</div>
    }

    return null
  }, [currentLoad.flags, toggleBillLines])

  return (
    <div style={containerStyle}>
      <div className={clsx('wrap-ticket-pdf', className)}>
        <DocumentView
          contentHeight={documentViewerHeight}
          document={ticketPdf}
          ratio={1.4}
          mapButtons={[
            {
              icon: openOutline,
              onClick: onShowDetailsModal,
              // label: 'More details',
              iconProps: {
                slot: 'start',
              },
              buttonProps: {
                fill: 'solid',
                color: 'primary',
              },
              tooltipProps: {
                title: 'Open Load Detail Page',
                color: 'grey',
              },
            },
            {
              label: 'Bill Lines & Flags',
              type: 'checkbox',
              onClick: onShowBillLine,
            },
            ...extraButtons,
          ]}
        />
      </div>
      <IonModal
        className='loadDetails-modal'
        isOpen={showDetailsModal}
        onDidDismiss={() => setShowDetailsModal(false)}
        canDismiss
      >
        <IonContent>
          {_.size(currentLoad) > 0 && (
            <LoadGridCard load={currentLoad} expandGrid={true} />
          )}
        </IonContent>
      </IonModal>

      {renderLoadFlags()}
    </div>
  )
}

LoadDocumentView.defaultProps = {
  documentViewerHeight: 400,
  extraButtons: [],
  currentLoad: {},
}

export default LoadDocumentView
