import { useMemo } from 'react'
import {
  useQueryTerminals,
  useQueryUsers,
  useQueryWorkPayTypes,
} from '~/hooks/useQueryData'

import { CompanyInfo, IRTColumnDef } from '~/components/shared'
import { EScope } from '~/types/enums/ECommonEnum'
import { IShift } from '~/types/models/IShift'
import buildFullName from '~/utils/buildFullName'
import moment from 'moment'
import buildObjectName from '~/utils/buildObjectName'

const useShiftsTableColumns = () => {
  const { usersData } = useQueryUsers()
  const { findTerminalById } = useQueryTerminals()
  const { workPayTypes } = useQueryWorkPayTypes()

  const columns = useMemo<IRTColumnDef<IShift>[]>(
    () => [
      {
        header: 'Driver',
        accessorKey: 'driverFleetId',
        minSize: 200,
        Cell({ cell }) {
          const cellValue = cell.getValue<number>()
          const user = usersData.find(
            ({ driverFleet }) => driverFleet?.id === cellValue,
          )
          return (
            <CompanyInfo
              hideAnchor
              hideSubtitle
              companyType={EScope.driver}
              searchableGoogle={false}
              company={{
                label: buildFullName(user?.person),
                name: buildFullName(user?.person),
                value: user?.id,
              }}
            />
          )
        },
      },
      {
        header: 'In Service',
        id: 'inService',
        enableGrouping: false,
        minSize: 200,
        accessorFn: row =>
          row.inService ? moment(row.inService).format('MMM DD H:m:ss') : '',
      },
      {
        header: 'Clock in',
        id: 'clockIn',
        enableGrouping: false,
        minSize: 200,
        accessorFn: row =>
          row.clockIn ? moment(row.clockIn).format('MMM DD H:m:ss') : '',
      },
      {
        header: 'Clock out',
        id: 'clockOut',
        enableGrouping: false,
        minSize: 200,
        accessorFn: row =>
          row.clockOut ? moment(row.clockOut).format('MMM DD H:m:ss') : '',
      },
      {
        header: 'End terminal',
        accessorKey: 'endTerminalId',
        enableGrouping: false,
        Cell({ row }) {
          const obj = findTerminalById(row.original.endTerminalId)
          return buildObjectName(obj)
        },
      },

      {
        header: 'Payroll start',
        id: 'payrollStart',
        enableGrouping: false,
        accessorFn: row =>
          row.payrollStart
            ? moment(row.payrollStart).format('MMM DD H:m:ss')
            : '',
      },
      {
        header: 'Payroll end',
        id: 'payrollEnd',
        enableGrouping: false,
        accessorFn: row =>
          row.payrollEnd ? moment(row.payrollEnd).format('MMM DD H:m:ss') : '',
      },
      {
        header: 'Work pay type',
        accessorKey: 'workPayTypeId',
        enableGrouping: false,
        Cell: ({ row: { original } }) =>
          original.workPayTypeId
            ? workPayTypes.find(({ id }) => original.workPayTypeId === id)?.name
            : '',
      },
      {
        header: 'Total hours',
        accessorKey: 'totalHrs',
        enableGrouping: false,
      },
    ],
    [findTerminalById, usersData, workPayTypes],
  )

  return { columns }
}

export default useShiftsTableColumns
