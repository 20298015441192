import { useCallback, useMemo, useState } from 'react'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import { useQueryCompanies, useQueryPaymentTypes } from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'

import { CompanyInfo } from '~/components/shared'

import { EScope } from '~/types/enums/ECommonEnum'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { selectMyCurrentCompany } from '~/redux/selectors'
import clsx from 'clsx'

import type { ICompany } from '~/types/models/ICompany'
import type { IPaymentDataAsPlainTextProps } from './type'
import type { ICommonOption } from '~/types/models/ICommonModel'

import './styles.scss'
import { When } from 'react-if'
import DialogImageModifier from './DialogImageModifier'

function PaymentDataAsPlainText(props: IPaymentDataAsPlainTextProps) {
  const { paymentData, className, onSaveImage } = props

  const [isOpenModifierModal, setIsOpenImageModifierModal] = useState(false)

  const { getDateTimeFormat } = useDateTimeFormat()
  const { findPaymentTypeById } = useQueryPaymentTypes()
  const { findCompanyById } = useQueryCompanies({})

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const seller = useMemo(() => {
    if (currentCompany.id === paymentData.sellerId) {
      return currentCompany
    }
    return findCompanyById(paymentData.sellerId)
  }, [currentCompany, findCompanyById, paymentData.sellerId])

  const buyer = useMemo(() => {
    if (currentCompany.id === paymentData.buyerId) {
      return currentCompany
    }
    return findCompanyById(paymentData.buyerId)
  }, [currentCompany, findCompanyById, paymentData.buyerId])

  const data = useMemo(
    () => [
      {
        label: 'Date',
        value: getDateTimeFormat(paymentData.paymentDate, 'YYYY-MM-DD'),
      },
      {
        label: 'Amount',
        value: formatCurrencyToDollar.format(Number(paymentData.amount)),
      },
      {
        label: 'Fees',
        value: formatCurrencyToDollar.format(Number(paymentData.fees)),
        isHidden: !paymentData.fees,
      },
      {
        label: 'Payment Type',
        value: findPaymentTypeById(paymentData.paymentTypeId)?.name,
      },
      {
        label: 'Manual Transaction #',
        value: paymentData.manualTransactionId,
        isHidden: !paymentData.manualTransactionId,
      },
      {
        label: 'Auto Transaction #',
        value: paymentData.autoTransactionId,
        isHidden: !paymentData.autoTransactionId,
      },
    ],
    [paymentData, getDateTimeFormat, findPaymentTypeById],
  )

  const isShowSeparateDot = useCallback(
    (item: ICommonOption, index: number, options: ICommonOption[]) => {
      return options.length - 1 > index
    },
    [],
  )

  const renderFields = useMemo(() => {
    const dataShown = data.filter(({ isHidden }) => !isHidden)
    return dataShown.map((item, index, original) => {
      const { label, value } = item
      return (
        <div key={index} className='itemContainer'>
          {label && <span className='label'>{label}: </span>}
          <span className='value'>{value}</span>
          {isShowSeparateDot(item, index, original) && (
            <span className='separateDot' />
          )}
        </div>
      )
    })
  }, [data, isShowSeparateDot])

  const onCloseImageModifier = useCallback(() => {
    setIsOpenImageModifierModal(false)
  }, [])

  // const onOpenImageModifier = useCallback(() => {
  //   setIsOpenImageModifierModal(true)
  // }, [])

  const onSaveNewImage = useCallback(
    (newImage: string) => {
      onCloseImageModifier()
      onSaveImage && onSaveNewImage(newImage)
    },
    [onCloseImageModifier, onSaveImage],
  )

  return (
    <>
      <div className={clsx('PaymentDataAsPlainText__container', className)}>
        <div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
          >
            <CompanyInfo
              searchableGoogle={false}
              hideAnchor
              company={{
                value: buyer?.id,
                label: buyer?.name || '',
                code: 'Buyer',
                name: buyer?.name,
                image: buyer?.logo,
              }}
            />
            <CompanyInfo
              searchableGoogle={false}
              hideAnchor
              company={{
                value: seller?.id,
                label: seller?.name || '',
                code: 'Seller',
                name: seller?.name,
                image: seller?.logo,
              }}
              companyType={EScope.seller}
              style={{ marginLeft: 8 }}
            />
          </div>
          {renderFields}
          {/* <Button
            className='clickable editButton'
            onClick={onClickEdit}
            variant='secondary'
          >
            <EditIcon color='white' />
          </Button> */}
        </div>
        <When condition={Boolean(paymentData.image)}>
          <div className='imageContainer'>
            <img src={paymentData.image} width='300' height='250' />
          </div>
        </When>
      </div>

      <DialogImageModifier
        image={paymentData.image}
        isOpen={isOpenModifierModal}
        onClose={onCloseImageModifier}
        onSave={onSaveNewImage}
      />
    </>
  )
}
export default PaymentDataAsPlainText
