import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import {
  ILoadTimesGetListParams,
  ILoadTimesGetListResponse,
} from '~/types/models/ILoadTime'
import { produce } from 'immer'

const useModifyLoadTimes = (params: ILoadTimesGetListParams) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'loadTimes',
      sessionUser?.id,
      buildGetUrl(apiClient.loadTimes.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const removeLoadTime = (id: number) => {
    queryClient.setQueryData<ILoadTimesGetListResponse | undefined>(
      queryKey,
      oldData =>
        produce(oldData, draft => {
          if (draft?.loadTimes) {
            const index = draft.loadTimes[0].load.loadTimes.findIndex(
              loadTime => loadTime.id === id,
            )
            if (index !== -1) {
              draft.loadTimes[0].load.loadTimes.splice(index, 1)
            }
          }
        }),
    )
  }

  return { removeLoadTime }
}

export default useModifyLoadTimes
