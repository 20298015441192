import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetFlagsParams, IGetFlagsResponse } from '~/types/models/IFlag'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryFlags = (
  params: Partial<IGetFlagsParams> = {},
  options?: Partial<UseQueryOptions<IGetFlagsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'flags',
      sessionUser?.id,
      buildGetUrl(apiClient.flags.endpoint, params),
    ],
    queryFn() {
      return apiClient.flags.get(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const flagsData = useMemo(() => data?.flags || [], [data?.flags])

  return {
    flagsData,
    isLoadingFlagsData: isLoading,
    refetchFlagsData: refetch,
  }
}

export default useQueryFlags
