import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConfirmationProvider } from '~/contexts'

import { Badge } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'

import { close } from 'ionicons/icons'

import type { IBundleBadgeProps } from './type'
import { ToolTipOverlay } from '~/components/shared'

import { EYesNo } from '~/types/enums/ECommonEnum'
import { ISellerTerminalProduct } from '~/types/models/ISellerTerminalProduct'
import { Unless, When } from 'react-if'
import { IBundle } from '~/types/models/IBundle'
import { useDrop } from 'react-dnd'
import { SellerTerminalProductsList } from '../SellerTerminalProductsList'
import { BundleItemsList } from '../BundleItemsList'
import { useQuerySellerTerminalProducts } from '~/hooks/useQueryData'
import { BundleBadgeForm } from './BundleBadgeForm'

import './styles.scss'

function BundleBadge(props: IBundleBadgeProps) {
  const {
    bundleData,
    sellerProductId,
    sellerTerminalOptions,
    onRemove,
    afterUpdateBundle,
  } = props

  const { confirmation } = useConfirmationProvider()

  const [isShowingBundleInputs, setIsShowingBundleInputs] = useState(false)
  const [bundleFormValues, setBundleFormValues] = useState({
    name: '',
    num: null as number | null,
    sellerTerminalIds: [] as number[],
  })

  const { sellerTerminalProductsData } = useQuerySellerTerminalProducts(
    {
      filters: {
        bundleId: bundleData?.id,
      },
    },
    { enabled: Boolean(bundleData?.id) },
  )

  const [{ canDrop, isOver, item }, drop] = useDrop(() => ({
    accept: 'BUNDLE_CARD',
    drop: () => ({ bundle: bundleData }),
    collect: monitor => {
      const item: ISellerTerminalProduct = monitor.getItem()
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        item,
      }
    },
  }))

  const style = useMemo(() => {
    if (item && item.bundleId === bundleData?.id) {
      return {}
    }
    const isActive = canDrop && isOver

    if (isActive) {
      return {
        borderColor: 'var(--ion-color-success)',
        boxShadow: 'rgba(9,199,41, 0.2) 0px 2px 8px 0px',
      }
    }

    if (canDrop) {
      return {
        borderColor: 'var(--ion-color-concord)',
        boxShadow: 'rgb(6, 59, 226, 0.2) 0px 2px 8px 0px',
      }
    }

    return {}
  }, [bundleData?.id, canDrop, isOver, item])

  const onToggleBundleInputs = useCallback(() => {
    setIsShowingBundleInputs(prev => !prev)
  }, [])

  const onClickRemove = useCallback(async () => {
    const result = await confirmation({
      message: 'Are you sure you want to remove this item?',
    })
    if (result === EYesNo.Yes) {
      onRemove && onRemove(bundleData as IBundle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setBundleFormValues({
      name: bundleData?.name || '',
      num: bundleData?.num || null,
      sellerTerminalIds: [],
    })

    if (!bundleData?.id) {
      setIsShowingBundleInputs(true)
    }
  }, [bundleData])

  const updateAndClose = (bundle: IBundle) => {
    setIsShowingBundleInputs(false)
    afterUpdateBundle && afterUpdateBundle(bundle)
  }

  return (
    <div className='BundleBadge__container' ref={drop} style={style}>
      <Unless condition={isShowingBundleInputs}>
        <Badge bg='secondary' className='badgeItem'>
          <span onClick={onToggleBundleInputs}>
            <span>#{bundleFormValues.num}</span>
            {bundleFormValues.name && <span> - {bundleFormValues.name}</span>}
          </span>
        </Badge>
      </Unless>
      <When condition={isShowingBundleInputs}>
        <BundleBadgeForm
          sellerProductId={sellerProductId}
          sellerTerminalOptions={sellerTerminalOptions}
          onCancel={onToggleBundleInputs}
          afterUpdate={updateAndClose}
          formData={bundleData}
        />
      </When>

      <When condition={sellerTerminalProductsData.length === 0}>
        <ToolTipOverlay content='Remove this Bundle' placement='top'>
          <Badge
            bg='danger'
            className='badgeItem icon ms-1'
            onClick={onClickRemove}
          >
            <IonIcon icon={close} />
          </Badge>
        </ToolTipOverlay>
      </When>

      <div />
      {bundleData ? (
        <SellerTerminalProductsList
          bundleId={bundleData.id}
          sellerProductId={sellerProductId}
          sellerTerminalOptions={sellerTerminalOptions}
        />
      ) : null}
      <div />

      {bundleData ? (
        <BundleItemsList
          bundleId={bundleData.id}
          sellerProductId={sellerProductId}
        />
      ) : null}
    </div>
  )
}

export default BundleBadge
