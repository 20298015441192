import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { useQuerySellerProducts } from '../useQuerySellerProducts'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICommonOption } from '~/types/models/ICommonModel'
import type {
  IBuyerSellerProduct,
  IGetBuyerSellerProductsParams,
  IGetBuyerSellerProductsResponse,
} from '~/types/models/IBuyerSellerProduct'
import _ from 'lodash'
import { produce } from 'immer'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryBuyerSellerProducts = (
  params: IGetBuyerSellerProductsParams & { id?: number } = {},
  options?: Partial<UseQueryOptions<IGetBuyerSellerProductsResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'buyerSellerProducts',
      sessionUser?.id,
      buildGetUrl(apiClient.buyerSellerProducts.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const queryClient = useQueryClient()
  const { data, isLoading, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (params?.id) {
        const response = await apiClient.buyerSellerProducts.getById(params.id)
        return {
          buyerSellerProducts: [response.buyerSellerProduct],
        }
      }

      return apiClient.buyerSellerProducts.get(params)
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const sellerId = useMemo(
    () => (params.sellerId as number[]) || [],
    [params.sellerId],
  )

  const {
    sellerProducts,
    sellerProductOptions,
    isLoadingSellerProducts,
    sellerProductsObj,
    findSellerProductsBySellerId,
    findSellerProductById,
    addSellerProduct,
    refetchSellerProductsData,
    updateSellerProduct,
    deleteSellerProduct,
  } = useQuerySellerProducts(
    { filters: { sellerId } },
    { enabled: typeof sellerId === 'number' || sellerId.length > 0 },
  )

  const buyerSellerProducts = useMemo(
    () => data?.buyerSellerProducts || [],
    [data?.buyerSellerProducts],
  )

  const buyerSellerProductsObj = useMemo(
    () => Object.fromEntries(buyerSellerProducts.map(bsp => [bsp.id, bsp])),
    [buyerSellerProducts],
  )

  const buyerSellerProductOptions = useMemo<ICommonOption[]>(
    () =>
      buyerSellerProducts.map(({ id, name, code, sellerProductName }) => ({
        value: id,
        label: sellerProductName || `${code} - ${name}`,
      })),
    [buyerSellerProducts],
  )

  const findBuyerSellerProductById = useCallback(
    (id: number | null | undefined) =>
      buyerSellerProducts.find(obj => obj.id === id),
    [buyerSellerProducts],
  )

  const findBuyerSellerProductByProductId = useCallback(
    (id: number | null | undefined) =>
      buyerSellerProducts.find(obj => obj.id === id),
    [buyerSellerProducts],
  )

  const getSellerProduct = useCallback(
    (buyerSellerProducId: number) => {
      const buyerSellerProduct = findBuyerSellerProductById(buyerSellerProducId)
      return findSellerProductById(buyerSellerProduct?.sellerProductId)
    },
    [findBuyerSellerProductById, findSellerProductById],
  )

  const createProductOptionsByBuyerSeller = useCallback(
    (buyerSellerId: number, sellerId: number) => {
      const findBuyerSellerProducts = buyerSellerProducts.filter(
        item => item.buyerSellerId === buyerSellerId,
      )

      const findSellerProducts = findSellerProductsBySellerId(sellerId)

      if (
        findBuyerSellerProducts.length === 0 &&
        findSellerProducts.length === 0
      ) {
        return []
      }
      const unallocatedSellerProducts = findSellerProducts.filter(({ id }) => {
        return !buyerSellerProducts
          .map(({ sellerProductId }) => sellerProductId)
          .includes(id)
      })

      const spOptions = unallocatedSellerProducts.map(sp => ({
        label: `${sp.code} - ${sp.name}  ${
          sp?.orderType ? '- ' + _.startCase(sp.orderType) : ''
        }/${sp?.qtyType ? _.startCase(sp.qtyType) : ''}`,
        value: sp.id,
        code: sp.code,
        isSellerProduct: true,
      }))

      const bspOptions = buyerSellerProducts.map(bsp => ({
        label: `${bsp.sellerProductCode} - ${bsp.sellerProductName}`,
        value: bsp.id,
        code: bsp.sellerProductCode,
      }))

      return [
        {
          label: 'Buyer Seller Products',
          options: bspOptions,
        },
        {
          label: 'Seller Products',
          options: spOptions,
        },
      ]
    },
    [buyerSellerProducts, findSellerProductsBySellerId],
  )

  const productOptions = useMemo(() => {
    if (sellerProducts.length === 0 && buyerSellerProducts.length === 0) {
      return []
    }

    const unallocatedSellerProducts = sellerProducts.filter(({ id }) => {
      return !buyerSellerProducts
        .map(({ sellerProductId }) => sellerProductId)
        .includes(id)
    })

    const spOptions = unallocatedSellerProducts.map(sp => ({
      label: `${sp.code} - ${sp.name} ${
        sp?.orderType ? '- ' + _.startCase(sp.orderType) : ''
      }/${sp?.qtyType ? _.startCase(sp.qtyType) : ''}`,
      value: sp.id,
      code: sp.code,
      isSellerProduct: true,
    }))

    const bspOptions = buyerSellerProducts.map(bsp => ({
      label: `${bsp.code || bsp.sellerProductCode} - ${
        bsp.name || bsp.sellerProductName
      }
      `,
      value: bsp.id,
      code: bsp.sellerProductCode,
    }))

    return [
      {
        label: 'Buyer Seller Products',
        options: bspOptions,
      },
      {
        label: 'Seller Products',
        options: spOptions,
      },
    ]
  }, [buyerSellerProducts, sellerProducts])

  const addBuyerSellerProduct = useCallback(
    (bsp: IBuyerSellerProduct) => {
      queryClient.setQueryData<IGetBuyerSellerProductsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              draft.buyerSellerProducts.push(bsp)
            } else {
              draft = {
                buyerSellerProducts: [bsp],
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )
  const updateBuyerSellerProduct = useCallback(
    (id: number, bsp: Partial<IBuyerSellerProduct>) => {
      queryClient.setQueryData<IGetBuyerSellerProductsResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.buyerSellerProducts.findIndex(
                bsp => bsp.id === id,
              )
              console.log('bsp', bsp)
              if (index !== -1) {
                draft.buyerSellerProducts[index] = {
                  ...draft.buyerSellerProducts[index],
                  ...bsp,
                }
              }
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    buyerSellerProducts,
    buyerSellerProductsObj,
    buyerSellerProductOptions,
    isLoadingBuyerSellerProducts: isLoading,
    sellerProducts,
    productOptions,
    isLoadingSellerProducts,
    sellerProductOptions,
    sellerProductsObj,
    createProductOptionsByBuyerSeller,
    findBuyerSellerProductById,
    findSellerProductsBySellerId,
    findSellerProductById,
    findBuyerSellerProductByProductId,
    getSellerProduct,
    addBuyerSellerProduct,
    addSellerProduct,
    refetchBuyerSellerProduct: refetch,
    refetchSellerProductsData,
    updateSellerProduct,
    deleteSellerProduct,
    updateBuyerSellerProduct,
  }
}

export default useQueryBuyerSellerProducts
