import { DEFAULT_ICON_SIZE } from './constants'
import type { ISvgIconProps } from './type'

const LockIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M4 6V4a4 4 0 1 1 8 0v2h2v10H2V6h2Zm2-2a2 2 0 1 1 4 0v2H6V4Zm1 9V9h2v4H7Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
export default LockIcon
