import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { CheckMarkIcon, ConcordFormStructure } from '~/components/shared'

import * as Yup from 'yup'
import {
  FORM_FIELD_TYPE,
  EMAIL_TYPE_OPTIONS,
  PERSON,
  COMPANY,
} from '~/utils/constants'
import {
  selectIsScopeDriver,
  selectSessionUser,
  selectMyCurrentCompany,
} from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { EEmailTypes } from '~/types/enums/EEmail'

const EmailForm = (props, ref) => {
  const {
    afterCreate,
    afterUpdate,
    companyEmailsData = [],
    formData,
    ...restProps
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const sessionUser = useSelector(selectSessionUser)
  const isDriver = useSelector(selectIsScopeDriver)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const formRef = useRef()

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const emailTypeOptions = useMemo(() => {
    let options = EMAIL_TYPE_OPTIONS.filter(({ numValue }) => {
      const filteredEmailTypes = companyEmailsData.filter(({ emailTypes }) =>
        emailTypes.includes(numValue),
      )

      return filteredEmailTypes.length === 0
    })

    if (isUpdating) {
      const emailTypes = formData.emailTypes.map(num =>
        EMAIL_TYPE_OPTIONS.find(({ numValue }) => numValue === num),
      )
      options = [...options, ...emailTypes]
    }

    return options
  }, [companyEmailsData, isUpdating, formData?.emailTypes])

  const fields = useMemo(
    () => [
      {
        name: 'email',
        label: 'Email',
        type: FORM_FIELD_TYPE.email,
        isRequired: true,
      },
      {
        name: 'emailTypes',
        label: 'Email Types',
        type: FORM_FIELD_TYPE.multipleSelect,
        options: emailTypeOptions,
        isRequired: true,
        extraIcons: ({ watch, name, setValue }) => {
          const addressTypes = watch(name, [])
          const allTypes = emailTypeOptions.map(({ value }) => value)
          const areAllTypesSelected = allTypes.length === addressTypes.length
          const Icon = CheckMarkIcon
          const iconProps = {
            color: areAllTypesSelected
              ? 'var(--ion-color-success)'
              : 'var(--ion-color-medium)',
            style: {
              fontSize: 18,
            },
          }

          return [
            {
              Icon: <Icon {...iconProps} />,
              onClick() {
                if (areAllTypesSelected) {
                  setValue(name, [])
                } else {
                  setValue(name, allTypes)
                }
              },
            },
          ]
        },
      },
    ],
    [emailTypeOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().required('Email is required!').email(),
        emailTypes: Yup.array().test(
          'min',
          'At least one is selected!',
          val => {
            return val?.length > 0
          },
        ),
      }),
    [],
  )

  const defaultValues = useMemo(
    () => ({
      email: '',
      emailTypes: [],
    }),
    [],
  )

  const updateEmail = useCallback(
    async formData => {
      const { email, emailTypes, id } = formData
      const response = await apiClient.emails.update(id, {
        email: {
          email,
          emailTypes,
        },
      })
      if (response.id) {
        toast.success(toastMessages.updateSuccess)
        afterUpdate && afterUpdate(response)
      } else {
        toast.error(response?.error || toastMessages.updateError)
      }
    },
    [afterUpdate],
  )

  const createEmail = useCallback(
    async formData => {
      const payload = { ...formData }
      if (isDriver) {
        payload.emailableId = sessionUser.driver?.id
        payload.emailableType = PERSON
      } else {
        payload.emailableId = currentCompany.id
        payload.emailableType = COMPANY
      }

      const response = await apiClient.emails.create({
        ...payload,
        companyId: currentCompany.id,
      })
      if (response.id) {
        toast.success(toastMessages.createSuccess)
        afterCreate && afterCreate(response)
      } else {
        toast.error(response?.error || toastMessages.createError)
      }
    },
    [afterCreate, currentCompany.id, isDriver, sessionUser.driver?.id],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateEmail(formData)
        } else {
          await createEmail(formData)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createEmail, isUpdating, updateEmail],
  )

  useEffect(() => {
    if (formData?.id) {
      const emailTypes = formData.emailTypes.map(num => EEmailTypes[num])
      formRef.current?.setValue('emailTypes', emailTypes)
    } else {
      formRef.current?.setValue('emailTypes', [])
    }
  }, [formData])

  return (
    <ConcordFormStructure
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      formData={formData}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      submitText={isUpdating ? 'Update' : 'Create'}
      ref={node => {
        formRef.current = node
        if (ref) {
          ref.current = {
            ...node,
            onSubmitForm: handleSubmit,
          }
        }
      }}
      {...restProps}
    />
  )
}

export default React.forwardRef(EmailForm)
