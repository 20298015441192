import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'
import PaymentsForm from './PaymentsForm'

import type { IDialogPaymentsFormProps } from './type'

import './styles.scss'

function DialogPaymentsForm(props: IDialogPaymentsFormProps) {
  const {
    isOpen,
    formData,
    onClose,
    afterCreate,
    afterUpdate,
    afterProcessPayment,
    ...dialogProps
  } = props

  return (
    <>
      <CommonDialogV2
        {...dialogProps}
        isOpen={isOpen}
        title='Payment'
        isHiddenOkButton
        size='xl'
        onClose={onClose}
      >
        <PaymentsForm
          afterCreate={afterCreate}
          afterUpdate={formData => {
            afterUpdate && afterUpdate(formData)
          }}
          formData={formData}
          afterProcessPayment={afterProcessPayment}
        />
      </CommonDialogV2>
    </>
  )
}
export default DialogPaymentsForm
