import useOrderCardOnMap from './useOrderCardOnMap'

import { IonText } from '@ionic/react'
import { Modal } from 'react-bootstrap'
import OrderTrackingCard from '~/containers/OrderTracking/OrderTrackingCard'

import moment from 'moment'
import clsx from 'clsx'

import './styles.scss'

import type { IOrderCardOnMapProps } from './type'
import { ViewMapIcon } from '~/components/shared'
import buildObjectName from '~/utils/buildObjectName'

const OrderCardOnMap: React.FC<IOrderCardOnMapProps> = props => {
  const { load, truck } = props

  const {
    percentage,
    loadBuyerTerminal,
    loadBuyer,
    schedule,
    isShowingOrderCard,
    bsp,
    onShowLoadCards,
    setIsShowingOrderCard,
  } = useOrderCardOnMap(props)

  return (
    <>
      <div className='OrderCardOnMap__container'>
        <div className='loadStatus'>
          <span className='chip'>{load.status}</span>
        </div>
        <div className='body'>
          <div
            className='progress-bar'
            style={{
              background: `
              radial-gradient(closest-side, white 79%, transparent 80% 100%),
              conic-gradient(var(--ion-color-concord) ${percentage}%, var(--ion-color-fleet) 0)`,
            }}
            role='progressbar'
          >
            <div className='truckName'>
              <IonText>{truck.name}</IonText>
            </div>
            <div className='percentage'>{percentage}%</div>
          </div>
          <div className='loadInfo'>
            <div className='loadBuyerTerminalName'>
              <span style={{ marginRight: 10 }}>
                <ViewMapIcon />
              </span>
              <span>{loadBuyerTerminal?.name}</span>
            </div>
            <div className='loadBuyerName'>
              <IonText>Buyer: </IonText>
              <IonText color='buyer' style={{ fontWeight: 600 }}>
                {loadBuyer?.name}
              </IonText>
            </div>
            {bsp && (
              <div className='loadBuyerName'>
                <IonText>Product: </IonText>
                <IonText color='fleet' style={{ fontWeight: 600 }}>
                  {buildObjectName({
                    name: bsp.sellerProductName,
                    code: bsp.sellerProductCode,
                  })}
                </IonText>
              </div>
            )}
            <div className=''>
              {moment(load.statusTime).fromNow()} since&nbsp;
              {moment(load.statusTime).format('LL')}
            </div>
          </div>
        </div>
        <div className='footer'>
          <span className={clsx('order clickable')} onClick={onShowLoadCards}>
            Order #{schedule?.scheduleNumber} - {schedule?.order?.num}
          </span>
        </div>
      </div>
      <Modal
        centered
        show={isShowingOrderCard}
        onHide={() => {
          setIsShowingOrderCard(false)
        }}
      >
        <Modal.Body>
          <OrderTrackingCard data={schedule} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderCardOnMap
