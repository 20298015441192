import { useEffect } from 'react'
import { useMarker } from 'react-mark.js'

import { getRegex } from './utils'

import './styles.scss'

const HighLightText = props => {
  const { children, regex, className, onCountMatchedString } = props

  const { markerRef, marker } = useMarker()

  useEffect(() => {
    const formatRegex = regex instanceof RegExp ? regex : getRegex(regex)
    if (marker) {
      marker.markRegExp(formatRegex, {
        element: 'span',
        className,
        acrossElements: true,
      })
      const matchedText = markerRef.current?.getElementsByClassName(className)
      onCountMatchedString && onCountMatchedString(matchedText?.length)
    }

    return () => {
      if (marker) {
        marker.unmark({
          className,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [className, marker, regex])

  return <span ref={markerRef}>{children}</span>
}

HighLightText.defaultProps = {
  className: '',
}

export default HighLightText
