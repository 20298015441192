import { Badge } from 'react-bootstrap'
import { components } from 'react-select'

function CustomAllowedHierarchyOption(props) {
  const { data } = props

  return (
    <components.Option {...props}>
      <Badge bg='secondary' style={{ marginRight: 4, verticalAlign: 'middle' }}>
        {data.item?.rank}
      </Badge>
      <span style={{ verticalAlign: 'middle' }}>{data.label}</span>
    </components.Option>
  )
}

export default CustomAllowedHierarchyOption
