import { Button, ButtonProps } from 'react-bootstrap'
import { IonSpinner } from '@ionic/react'
import { IToolTipOverlayProps, ToolTipOverlay } from '~/components/shared'

import clsx from 'clsx'

import './styles.scss'
import React from 'react'

export interface IReusableButtonProps extends ButtonProps {
  tooltipContent?: IToolTipOverlayProps['content']
  tooltipPlacement?: IToolTipOverlayProps['placement']
  tooltipContentWhenDisabled?: IToolTipOverlayProps['content']
  isDisabled?: boolean
  isLoading?: boolean
  isHidden?: boolean
  label?: string
  tooltipProps?: Partial<IToolTipOverlayProps>
  to?: any
  state?: any
}

function ReusableButton(props: IReusableButtonProps, ref: any) {
  const {
    children,
    className,
    tooltipContent,
    tooltipPlacement = 'top',
    isDisabled,
    isLoading,
    label,
    onClick,
    tooltipContentWhenDisabled,
    tooltipProps,
    ...buttonProps
  } = props

  const isButtonDisabled = isLoading || isDisabled

  return (
    <ToolTipOverlay
      {...tooltipProps}
      className={clsx('ReusableButton__tooltip', tooltipProps?.className)}
      content={isDisabled ? tooltipContentWhenDisabled : tooltipContent}
      placement={tooltipPlacement}
    >
      <Button
        {...buttonProps}
        ref={ref}
        className={clsx('ReusableButton__container', className, {
          isButtonDisabled: isDisabled,
        })}
        disabled={isLoading}
        onClick={event => {
          if (!isButtonDisabled) {
            onClick && onClick(event)
          }
        }}
      >
        {label || children}
        {isLoading && (
          <div className='ReusableButton__spinner'>
            <IonSpinner name='lines-small' />
          </div>
        )}
      </Button>
    </ToolTipOverlay>
  )
}

export default React.forwardRef(ReusableButton)
