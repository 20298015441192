import { colord } from 'colord'
import { useMemo } from 'react'
import { components } from 'react-select'

function CustomColorSingleValue(props) {
  const { data, children } = props

  const color = useMemo(() => {
    if (data.color) {
      return data.color
    }
    if (colord(data.value).isValid()) {
      return data.value
    }

    return null
  }, [data.color, data.value])

  return (
    <components.SingleValue {...props} className='d-flex align-items-center'>
      {children}

      {color && (
        <div
          style={{
            width: 15,
            height: 15,
            background: color,
            marginLeft: 8,
          }}
        />
      )}
    </components.SingleValue>
  )
}

export default CustomColorSingleValue
