import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import PricingForm from './PricingForm'

import type { IDialogPricingFormProps } from './type'

function DialogPricingForm(props: IDialogPricingFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    hierarchyItem,
    includeInvoiceId,
    invoiceId,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Pricing'
      isHiddenOkButton
      onClose={onClose}
    >
      <PricingForm
        hierarchyItem={hierarchyItem}
        includeInvoiceId={includeInvoiceId}
        invoiceId={invoiceId}
        formData={formData}
        afterCreate={(formData, keepOpenForm) => {
          afterCreate && afterCreate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
        afterUpdate={(formData, keepOpenForm) => {
          afterUpdate && afterUpdate(formData, keepOpenForm)
          if (!keepOpenForm) {
            onClose && onClose()
          }
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogPricingForm
