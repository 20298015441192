import { useState, useRef } from 'react'
import { Document, Page } from 'react-pdf'
import { createWorker } from 'tesseract.js'
import { pdfjs } from 'react-pdf'
import _ from 'lodash'
import { IonProgressBar } from '@ionic/react'

import './styles.scss'
import matchMultipleWords from '~/utils/matchMultipleWords'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

function OcrViewer(props) {
  const { link, pdfWidth, pdfHeight, setTotalPages, pageNumber, searchText } =
    props

  const [ocrBlocks, setOcrBlocks] = useState([])
  const [scale] = useState(1)
  const [loadingOcr, setLoadingOcr] = useState(false)

  const canvasRef = useRef(null)

  const performOCR = async (pdfPageImage, scaleX, scaleY) => {
    setLoadingOcr(true)
    try {
      const worker = await createWorker('eng')
      const { data } = await worker.recognize(
        pdfPageImage,
        {
          pdfTextOnly: true,
        },
        {
          text: true,
          blocks: true,
          layoutBlocks: true,
          pdf: true,
        },
      )

      const words = data.blocks[0].paragraphs[0].lines.map(({ words }) => words)
      const flatArray = _.flatten(words)

      return flatArray.map(word => ({
        text: word.text,
        bbox: {
          x0: word.bbox.x0 * scaleX,
          y0: word.bbox.y0 * scaleY,
          x1: word.bbox.x1 * scaleX,
          y1: word.bbox.y1 * scaleY,
        },
      }))
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingOcr(false)
    }
  }

  const handlePageRenderSuccess = () => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current.querySelector('canvas')
    if (!canvas) return

    const originalWidth = canvas.width
    const originalHeight = canvas.height
    const scaleX = pdfWidth / originalWidth
    const scaleY = pdfHeight / originalHeight

    const highResImage = canvas.toDataURL()

    performOCR(highResImage, scaleX, scaleY).then(extractedBlocks => {
      setOcrBlocks(extractedBlocks)
    })
  }

  return (
    <div className='OcrViewer__container'>
      {loadingOcr && (
        <div>
          <IonProgressBar type='indeterminate' />
        </div>
      )}
      <Document
        file={link}
        onLoadSuccess={({ numPages }) => {
          setTotalPages(numPages)
        }}
      >
        <Page
          pageNumber={pageNumber}
          scale={scale}
          onRenderSuccess={handlePageRenderSuccess}
          inputRef={canvasRef}
        />
      </Document>

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      >
        {ocrBlocks
          .filter(({ text }) => {
            if (searchText) {
              console.log('text', text)
              return matchMultipleWords(text, searchText)
            }
            return false
          })
          .map(({ text, bbox }, idx) => (
            <div
              key={idx}
              data-title={text}
              style={{
                position: 'absolute',
                border: '1px solid red',
                background: 'rgba(255, 0, 0, 0.1)',
                left: bbox.x0,
                top: bbox.y0,
                width: bbox.x1 - bbox.x0,
                height: bbox.y1 - bbox.y0,
              }}
              title={text}
            />
          ))}
      </div>
    </div>
  )
}

export default OcrViewer
