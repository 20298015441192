import type { IPaymentsTableProps } from './type'

import './styles.scss'
import {
  CompanyInfo,
  IRTColumnDef,
  ReusableTable,
  StripeIcon,
  EditIcon,
  DeleteIcon,
} from '~/components/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'
import { IPayment } from '~/types/models/IPayment'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import {
  useQueryCompanies,
  useQueryPaymentPayables,
  useQueryPaymentTypes,
} from '~/hooks/useQueryData'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import { ICompany } from '~/types/models/ICompany'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
} from '~/types/enums/ECommonEnum'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import _ from 'lodash'
import { ICommonOption } from '~/types/models/ICommonModel'
import { VisibilityState } from '@tanstack/react-table'
import { ProcessPaymentModal } from '../ProcessPaymentModal'
import { EPaymentStatus } from '~/types/enums/EPayment'
import { Badge } from 'react-bootstrap'

function PaymentsTable(props: IPaymentsTableProps) {
  const {
    paymentsData,
    isLoading,
    tableHeight,
    onCellEditEnd,
    onClickEdit,
    onClickRemove,
    onToggleFormModal,
    columnFilters,
    onColumnFiltersChange,
    afterProcessPayment,
  } = props

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [paymentProcessModal, setPaymentProcessModal] = useState({
    isOpen: false,
    payment: undefined as IPayment | undefined,
  })

  const { paymentPayablesData, isLoadingPaymentPayableData } =
    useQueryPaymentPayables(
      {
        filters: {
          paymentId: paymentsData.map(({ id }) => id),
        },
      },
      { enabled: paymentsData.length > 0 },
    )

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const currentScope: EScope = useSelector(selectCurrentScope)

  const isBuyer = currentScope === EScope.buyer

  const windowSize = useWindowSize()

  const { paymentTypeOptions } = useQueryPaymentTypes()
  const { companiesData, isLoadingCompaniesData, buyerCompanyOptions } =
    useQueryCompanies({})

  const isTableLoading =
    isLoading || isLoadingCompaniesData || isLoadingPaymentPayableData

  const { getDateTimeFormat } = useDateTimeFormat()

  const filterOptions = useMemo(
    () => [
      {
        field: 'paymentDate',
        label: 'Date',
        type: EFieldType.dateRange,
      },
      {
        field: 'buyerId',
        label: 'Buyer',
        type: EFieldType.multipleSelect,
        options: buyerCompanyOptions,
      },
      {
        field: 'paymentTypeId',
        label: 'Payment Type',
        type: EFieldType.multipleSelect,
        options: paymentTypeOptions,
      },
      {
        field: 'amount',
        label: 'Amount',
        type: EFieldType.number,
      },
    ],
    [buyerCompanyOptions, paymentTypeOptions],
  )

  const onClosePaymentProcess = useCallback(() => {
    setPaymentProcessModal({
      isOpen: false,
      payment: undefined,
    })
  }, [])

  // const renderDetailPanel = useCallback(({ row }: { row: RTRow<IPayment> }) => {
  //   return (
  //     <div style={{ padding: 12, paddingTop: 0 }}>
  //       <ExpandedPaymentSection paymentData={row.original} />
  //     </div>
  //   )
  // }, [])

  const onProcessPayment = useCallback(async (rowData: IPayment) => {
    setPaymentProcessModal({
      isOpen: true,
      payment: rowData,
    })
  }, [])

  const onClickButton = useCallback(
    (action: string, rowData: IPayment) => () => {
      switch (action) {
        case 'edit':
          onClickEdit && onClickEdit(rowData)
          break
        case 'remove':
          onClickRemove && onClickRemove(rowData)
          break

        case 'processPayment':
          onProcessPayment(rowData)
          break

        default:
          break
      }
    },
    [onClickEdit, onClickRemove, onProcessPayment],
  )

  const getPaymentStatusBadgeColor = useCallback((status: EPaymentStatus) => {
    const obj = {
      [EPaymentStatus.completed]: 'success',
      [EPaymentStatus.notSet]: 'danger',
      [EPaymentStatus.processing]: 'warning',
      default: 'secondary',
    }
    return obj[status] || obj.default
  }, [])

  const columns = useMemo<IRTColumnDef<IPayment>[]>(
    () => [
      {
        header: 'Date',
        id: 'paymentDate',
        align: 'center',
        size: 120,
        accessorFn(rowData) {
          return getDateTimeFormat(rowData.paymentDate, 'YYYY-MM-DD')
        },
      },
      {
        header: 'Buyer',
        accessorKey: 'buyerId',
        size: 200,
        enableEditing: !isBuyer,
        editSelectOptions: buyerCompanyOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps: () => ({
          formatOptionLabel: (
            opt: ICommonOption<{ code: string; name: string }>,
          ) => (
            <CompanyInfo
              company={{
                value: opt?.value || '',
                label: opt?.label || '',
                name: opt?.name || '',
                code: opt?.code || '',
              }}
              hideAnchor
              searchableGoogle={false}
              companyType={EScope.buyer}
            />
          ),
        }),
      },
      {
        header: 'Seller',
        accessorKey: 'sellerId',
        size: 200,
        Cell({ cell }) {
          const cellData = cell.getValue<number>()
          let company: ICompany | undefined
          if (cellData !== currentCompany.id) {
            company = companiesData.find(({ id }) => id === cellData)
          } else {
            company = currentCompany
          }
          return (
            <CompanyInfo
              company={{
                value: company?.id,
                label: company ? `${company?.code} - ${company?.name}` : '',
                name: company?.name,
                code: company?.code,
              }}
              hideAnchor
              searchableGoogle={false}
              companyType={EScope.seller}
            />
          )
        },
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        align: 'right',
        size: 110,
        Footer({ table }) {
          const data = table.options.data
          const result = data.reduce(
            (total, item) => total + Number(item.amount),
            0,
          )
          return formatCurrencyToDollar.format(result)
        },
        enableEditing: !isBuyer,
        editVariant: EFieldType.number,
        editNumberFieldProps: () => ({
          prefix: '$',
          decimalScale: 2,
          fixedDecimalScale: true,
        }),
      },
      {
        header: 'Amount Applied',
        id: 'amountApplied',
        align: 'right',
        size: 155,

        accessorFn(rowData) {
          const payables = paymentPayablesData.filter(
            ({ paymentId }) => paymentId === rowData.id,
          )
          const result = payables.reduce(
            (total, { amount }) => (total += Math.abs(Number(amount))),
            0,
          )
          return formatCurrencyToDollar.format(result)
        },
        Footer: () => {
          const total = paymentPayablesData.reduce(
            (total, { amount }) => (total += Math.abs(Number(amount))),
            0,
          )

          return <span>{formatCurrencyToDollar.format(total)}</span>
        },
      },
      {
        header: 'Fees',
        id: 'fees',
        align: 'right',
        size: 100,
        accessorFn(rowData) {
          if (rowData.fees) {
            return formatCurrencyToDollar.format(Number(rowData.fees))
          }
          return ''
        },
      },
      {
        header: 'Payment Type',
        accessorKey: 'paymentTypeId',
        enableEditing: !isBuyer,
        editVariant: EFieldType.singleSelect,
        editSelectOptions: paymentTypeOptions,
      },
      {
        header: 'Status',
        id: 'status',
        align: 'center',
        size: 100,
        accessorFn(rowData) {
          return _.startCase(rowData.status)
        },
        Cell({ renderedCellValue, row }) {
          const cellValue = row.original.status
          return (
            <Badge bg={getPaymentStatusBadgeColor(cellValue)}>
              {renderedCellValue}
            </Badge>
          )
        },
      },
      {
        header: 'Image',
        accessorKey: 'image',
        size: 100,
        Cell({ cell }) {
          const cellData = cell.getValue<string | null | File>()
          const image =
            cellData instanceof File ? (cellData as any).preview : cellData
          if (image) {
            return <img src={image} width={50} height={50} />
          }
          return null
        },
      },
      {
        header: 'Manual Transaction #',
        accessorKey: 'manualTransactionId',
        enableEditing: !isBuyer,
        size: 200,
      },
      {
        header: 'Auto Transaction #',
        accessorKey: 'autoTransactionId',
        size: 200,
      },
    ],
    [
      isBuyer,
      buyerCompanyOptions,
      paymentTypeOptions,
      getDateTimeFormat,
      currentCompany,
      companiesData,
      paymentPayablesData,
      getPaymentStatusBadgeColor,
    ],
  )

  useEffect(() => {
    if (isBuyer) {
      setColumnVisibility({
        buyerId: false,
      })
    }
  }, [isBuyer])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={paymentsData}
          tableHeight={tableHeight || windowSize.height - 220}
          enableCompanyView
          enableTopToolbar
          enableRowActions
          enableColumnPinning
          manualFiltering
          companyViewProps={{
            name: 'payments',
            description: 'Manage your Payments',
            onClickTopButton: onToggleFormModal,
            isHiddenTopButton: isBuyer,
          }}
          filterOptions={filterOptions}
          state={{
            isLoading: isTableLoading,
            columnFilters,
            columnVisibility,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions, ERTDisplayColumnId.expand],
              right:
                windowSize.width <= 480
                  ? ['status']
                  : ['amount', 'amountApplied', 'status'],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              minSize: isBuyer ? 100 : 120,
            },
          }}
          renderRowActions={({ row }) => {
            const rowData = row.original
            return [
              {
                icon: <EditIcon color='white' />,
                onClick: onClickButton('edit', rowData),
                tooltipProps: {
                  content: 'Edit',
                  placement: 'top',
                },
                isHidden: isBuyer,
              },
              {
                icon: <StripeIcon color='white' />,
                onClick: onClickButton('processPayment', rowData),
                className: 'stripeButton',
                tooltipProps: {
                  content: 'Process Payment',
                  placement: 'top',
                },
              },

              {
                icon: <DeleteIcon color='white' />,
                onClick: onClickButton('remove', rowData),
                color: 'danger',
                tooltipProps: {
                  content: 'Remove',
                  placement: 'top',
                },
                isHidden: isBuyer,
              },
            ]
          }}
          onCellEditEnd={onCellEditEnd}
          onColumnFiltersChange={onColumnFiltersChange}
        />
      </div>
      <ProcessPaymentModal
        isOpen={paymentProcessModal.isOpen}
        onClose={onClosePaymentProcess}
        payment={paymentProcessModal.payment}
        afterProcessPayment={() => {
          afterProcessPayment && afterProcessPayment()
          onClosePaymentProcess()
        }}
      />
    </>
  )
}

export default PaymentsTable
