import { useState, useCallback, useMemo, useEffect } from 'react'

import { DateRangePicker } from 'react-date-range'
import { Button } from 'react-bootstrap'

import './DateRangeDropdownPicker.scss'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { format, getYear } from 'date-fns'
import Tooltip from 'rc-tooltip'

function DateRangeDropdownPicker(props) {
  const { date, label, onChange, children, datePickerProps, className } = props

  const [dateValue, setDateValue] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)

  const formatDateBasedOnYear = useCallback((opt, dateInput) => {
    const currentYear = getYear(new Date())
    if (dateInput) {
      const obj = {
        'yyyy/MM/dd': 'MM/dd',
        'dd/MM/yyyy': 'dd/MM',
        'MMM-d yyyy (eee)': 'MMM-d (eee)',
        'MMM-d-yyyy': 'MMM-d',
      }
      const dateYear = getYear(dateInput)
      if (currentYear === dateYear) {
        return obj[opt]
      }
    }

    return opt
  }, [])

  const companyDateFormat = useMemo(
    () => formatDateBasedOnYear(currentCompany.dateFormat, dateValue.startDate),
    [currentCompany.dateFormat, dateValue.startDate, formatDateBasedOnYear],
  )

  const labelRendered = useMemo(() => {
    try {
      if (date.startDate && date.endDate) {
        const startDate = date.startDate
          ? format(new Date(date.startDate), companyDateFormat)
          : '-'
        const endDate = date.endDate
          ? format(new Date(date.endDate), companyDateFormat)
          : '-'
        if (startDate === endDate) {
          return startDate
        }
        return `${startDate} → ${endDate}`
      }
      return 'All'
    } catch (error) {
      console.log('error', error)
      return 'All'
    }
  }, [companyDateFormat, date])

  const handleChangeDate = useCallback(selectedDate => {
    setDateValue(selectedDate.selection)
  }, [])

  const handleOk = useCallback(() => {
    onChange && onChange(dateValue)
    setShowDropdown(false)
  }, [dateValue, onChange])

  const handleResetDate = useCallback(() => {
    setDateValue({
      ...date,
      key: 'selection',
    })
  }, [date])

  const onToggleDropdown = () => {
    setShowDropdown(prev => !prev)
  }

  const renderLabelButton = useCallback(() => {
    if (children) {
      return children
    }

    return (
      <>
        {label && <span>{label}</span>}
        <div className='DateRangeDropdownPicker__filterLabel'>
          {labelRendered}
        </div>
      </>
    )
  }, [children, label, labelRendered])

  useEffect(() => {
    setDateValue({
      ...date,
      startDate: date.startDate ? new Date(date.startDate) : undefined,
      endDate: date.endDate ? new Date(date.endDate) : undefined,
      key: 'selection',
    })
  }, [date])

  return (
    <Tooltip
      visible={showDropdown}
      placement='bottom'
      showArrow={false}
      overlay={
        <div>
          <DateRangePicker
            {...datePickerProps}
            ranges={[dateValue]}
            onChange={handleChangeDate}
            className=''
          />
          <div className='DateRangeDropdownPicker__buttons'>
            <Button variant='danger' onClick={onToggleDropdown}>
              Close
            </Button>
            <Button variant='warning' onClick={handleResetDate}>
              Reset
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </div>
        </div>
      }
      overlayClassName='DateRangeDropdownPicker__overlay'
    >
      <div
        style={{ height: '100%' }}
        onClick={onToggleDropdown}
        className={className}
      >
        {renderLabelButton()}
      </div>
    </Tooltip>
  )
}
DateRangeDropdownPicker.defaultProps = {
  // label: 'Date',
}

export default DateRangeDropdownPicker
