import _ from 'lodash'
import { Badge } from 'react-bootstrap'
import { CloseIcon } from '~/components/shared'

import clsx from 'clsx'

import './styles.scss'
import { useConfirmationProvider } from '~/contexts'
import { EYesNo } from '~/types/enums/ECommonEnum'

export interface ICompanyItemCardProps {
  content: string | null
  types?: any[]
  onClick?: () => void
  selected?: boolean
  canDelete?: boolean
  showConfirmationWhenDelete?: boolean
  onClickDelete?: () => void
  index?: number
}

function CompanyItemCard(props: ICompanyItemCardProps) {
  const {
    content,
    types,
    selected,
    canDelete,
    showConfirmationWhenDelete,
    index,
    onClick,
    onClickDelete,
  } = props

  const { confirmation } = useConfirmationProvider()

  return (
    <div
      onClick={onClick}
      className={clsx('CompanyItemCard__container', { selected })}
    >
      <div
        style={{
          display: 'inline-block',
          marginRight: 4,
          verticalAlign: 'middle',
        }}
      >
        {content}
      </div>
      {(types || []).map(type => (
        <Badge key={type} style={{ marginRight: 4 }}>
          {_.startCase(type.toString())}
        </Badge>
      ))}

      <Badge bg='secondary' className='CompanyItemCard__indexBadge'>
        {index}
      </Badge>

      {canDelete && (
        <span
          className='CompanyItemCard__closeIcon clickable'
          onClick={async () => {
            if (showConfirmationWhenDelete) {
              const result = await confirmation({
                message: 'Are you sure you want to delete this item?',
              })
              if (result === EYesNo.Yes) {
                onClickDelete && onClickDelete()
              }
            } else {
              onClickDelete && onClickDelete()
            }
          }}
        >
          <CloseIcon color='var(--bs-danger)' />
        </span>
      )}
    </div>
  )
}

export default CompanyItemCard
