import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IAutoExtraFormProps, IAutoExtraFormValues } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryHierarchies,
  useQueryHierarchyRows,
  useQuerySellerProducts,
} from '~/hooks/useQueryData'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import {
  EApplicationType,
  EAutoExtraDateRangeType,
  ICreateAutoExtraPayload,
  IUpdateAutoExtraPayload,
} from '~/types/models/IAutoExtra'
import { DateTime } from 'luxon'
import { DAYS_OF_WEEK_OPTIONS } from '~/utils/constants'
import { makeOptions } from '~/utils/utils'
import HierarchyForm from '~/containers/invoices/AutomaticChargesDialog/HierarchyForm'
import { EHierarchableType } from '~/types/enums/EHierarchyRow'
import './styles.scss'

const activeOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]

const AutoExtraForm = (props: IAutoExtraFormProps, ref: any) => {
  const { afterCreate, afterUpdate, formData, hierarchyItem, ...formProps } =
    props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<any>()
  const hierarchyFormRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { hierarchyRowsData } = useQueryHierarchyRows(
    {
      filters: {
        hierarchableType: [EHierarchableType.AutoExtra],
        hierarchableId: formData?.id as number,
      },
    },
    { enabled: isUpdating, refetchOnMount: true },
  )

  const hierarchyIds = hierarchyRowsData.map(({ hierarchyId }) => hierarchyId)

  const { hierarchiesData, isHierarchiesDataFetched } = useQueryHierarchies(
    {
      filters: {
        id: hierarchyIds,
      },
    },
    { enabled: hierarchyIds.length > 0, refetchOnMount: true },
  )

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const applicationTypeOptions = useMemo(
    () => makeOptions(EApplicationType),
    [],
  )

  const dateRangeTypeOptions = useMemo(
    () => makeOptions(EAutoExtraDateRangeType),
    [],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        options: sellerProductOptions,
        isRequired: true,
        type: EFieldType.singleSelect,
        size: 9,
      },
      {
        label: 'Active',
        name: 'active',
        size: 3,
        options: activeOptions,
        type: EFieldType.singleSelect,
      },
      {
        label: 'Start date',
        name: 'dateRangeStart',
        size: 4,
        type: EFieldType.date,
      },
      {
        label: 'End date',
        name: 'dateRangeEnd',
        size: 4,
        type: EFieldType.date,
      },
      {
        label: 'Date range type',
        name: 'dateRangeType',
        size: 4,
        type: EFieldType.singleSelect,
        options: dateRangeTypeOptions,
      },
      {
        label: 'Start time',
        name: 'startTime',
        size: 6,
        type: EFieldType.time,
      },
      {
        label: 'End time',
        name: 'endTime',
        size: 6,
        type: EFieldType.time,
      },
      {
        label: 'Days of the week',
        name: 'daysOfTheWeek',
        size: 12,
        type: EFieldType.multipleSelect,
        options: DAYS_OF_WEEK_OPTIONS,
      },
      {
        label: 'Application type',
        name: 'applicationType',
        size: 4,
        type: EFieldType.singleSelect,
        options: applicationTypeOptions,
      },
      {
        label: 'Qty',
        name: 'qty',
        size: 2,
        type: EFieldType.number,
      },
      {
        label: 'Start Load',
        name: 'startLoad',
        size: 3,
        type: EFieldType.number,
      },
      {
        label: 'End Load',
        name: 'endLoad',
        size: 3,
        type: EFieldType.number,
      },
      {
        name: 'hierarchyAttributes',
        render() {
          return (
            <HierarchyForm
              item={hierarchyItem}
              ref={hierarchyFormRef}
              className='AutoExtraForm__hierarchyForm'
              isUpdating={isUpdating}
            />
          )
        },
      },
    ],
    [
      applicationTypeOptions,
      dateRangeTypeOptions,
      hierarchyItem,
      isUpdating,
      sellerProductOptions,
    ],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        dateRangeType: Yup.string(),
        startTime: Yup.string(), //validation
        endTime: Yup.string(),
        active: Yup.boolean(),
        sellerProductId: Yup.string().required('This field is required!'),
        qty: Yup.number().required('This field is required!'),
        dateRangeStart: Yup.string().nullable(),
        dateRangeEnd: Yup.string()
          .nullable()
          .test({
            name: 'is-greater',
            message: 'End date must be after start date',
            test: function (value) {
              if (!value && !this.parent.dateRangeStart) return true
              if (this.parent.dateRangeType === 'seasonal') {
                return true
              }
              return (
                DateTime.fromISO(value as any) >
                DateTime.fromISO(this.parent.dateRangeStart)
              )
            },
          }),
        startLoad: Yup.number(),
        endLoad: Yup.number().min(
          Yup.ref('startLoad'),
          'End load must be greater than start load',
        ),
        applicationType: Yup.string().required('This field is required!'),
        daysOfTheWeek: Yup.array().min(1),
      }),
    [],
  )

  const defaultValues = useMemo<IAutoExtraFormValues>(
    () => ({
      active: true,
      applicationType: EApplicationType.schedule,
      daysOfTheWeek: [1, 2, 3, 4, 5],
      dateRangeStart: undefined,
      dateRangeEnd: undefined,
      dateRangeType: EAutoExtraDateRangeType.exact,
      qty: 1,
      sellerProductId: undefined,
      startTime: '00:00',
      endTime: '23:59',
      startLoad: 1,
      endLoad: 2,
    }),
    [],
  )

  const onCreate = useCallback(
    async (payload: ICreateAutoExtraPayload) => {
      const { errors, ...res } = await apiClient.autoExtras.create(payload)
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
          hierarchableType: EHierarchableType.AutoExtra,
          hierarchableId: res.id,
        })
        const keepOpenForm = res2.backendErrors.length > 0
        afterCreate && afterCreate(res, keepOpenForm)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: IUpdateAutoExtraPayload) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.autoExtras.update(
          formData.id,
          payload,
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          const res2 = await hierarchyFormRef.current?.createOrUpdateHierarchy({
            hierarchableType: EHierarchableType.AutoExtra,
            hierarchableId: response.id,
          })
          const keepOpenForm = res2.backendErrors.length > 0
          afterUpdate && afterUpdate(response, keepOpenForm)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IAutoExtraFormValues) => {
      hierarchyFormRef.current?.handleSubmit(async () => {
        setIsLoading(true)
        setError('')
        try {
          const {
            dateRangeEnd,
            dateRangeStart,
            startLoad,
            endLoad,
            dateRangeType,
            ...restOfForm
          } = formValues

          const start = dateRangeStart
            ? DateTime.fromISO(dateRangeStart).toFormat('yyyy-MM-dd')
            : undefined
          const end = dateRangeEnd
            ? DateTime.fromISO(dateRangeEnd).toFormat('yyyy-MM-dd')
            : undefined
          const low = '-infinity'
          const hi = 'infinity'

          if (isUpdating) {
            const payload = {
              autoExtra: {
                ...restOfForm,
                dateRange: `${start || low}..${end || hi}`,
                applicationRange: `${startLoad || low}..${endLoad || hi}`,
              },
            } as IUpdateAutoExtraPayload

            return await onUpdate(payload)
          } else {
            const payload = {
              ...restOfForm,
              dateRange: `${start || low}..${end || hi}`,
              applicationRange: `${startLoad || low}..${endLoad || hi}`,
            } as ICreateAutoExtraPayload

            return await onCreate(payload)
          }
        } catch (error) {
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      })()
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (isHierarchiesDataFetched && isUpdating && hierarchiesData.length > 0) {
      hierarchyFormRef.current?.setValue(
        'hierarchiesAttributes',
        hierarchiesData,
      )
    }
  }, [isHierarchiesDataFetched, hierarchiesData, isUpdating])

  return (
    <ConcordFormStructure
      error={error}
      isLoading={isLoading}
      ref={node => {
        formRef.current = node
        if (ref) {
          ref.current = {
            ...node,
            onSubmitForm: handleSubmit,
          }
        }
      }}
      formData={formData}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={isUpdating ? 'Update' : 'Create'}
      {...formProps}
    />
  )
}

export default React.forwardRef(AutoExtraForm)
