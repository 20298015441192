import { IPaymentsFormProps, OnScanPayment } from './type'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ConcordFormStructure, IConcordFormField } from '../../FormStructure'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { useSelector } from 'react-redux'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import * as Yup from 'yup'
import { FileField } from '../../FileField'
import { cameraOutline } from 'ionicons/icons'
import './styles.scss'
import { isMobileApp } from '~/utils/getCurrentPlatform'
import { USER_SCOPE } from '~/utils/constants'
import moment from 'moment'
import { useQueryCompanies, useQueryPaymentTypes } from '~/hooks/useQueryData'
import {
  ICreatePaymentPayload,
  IPaymentFormValues,
  IUpdatePaymentPayload,
} from '~/types/models/IPayment'

import 'cropperjs/dist/cropper.css'
import { Button, IButtonProps } from '~/components/shared/Button'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { toBase64 } from '~/utils'
import _ from 'lodash'
import { useIsMobile } from '~/hooks/useIsMobile'
import { IUser } from '~/types/models/IUser'
import DialogImageModifier from './DialogImageModifier'
import isBase64 from 'is-base64'
import PaymentBillLinesTable from './PaymentBillLinesTable'
import { IBillLine } from '~/types/models/IBillLine'
import clsx from 'clsx'
import { ProcessPaymentModal } from '~/containers/Payments/ProcessPaymentModal'
import { EPaymentStatus } from '~/types/enums/EPayment'

function PaymentsForm(props: IPaymentsFormProps) {
  const { afterCreate, afterUpdate, afterProcessPayment, formData } = props

  const [isLoading, setIsLoading] = useState(false)
  const [imageModifierModal, setImageModifierModal] = useState({
    isOpen: false,
    image: '',
  })
  const [isOpenPaymentProcess, setIsOpenPaymentProcess] = useState(false)

  const userScope: EScope = useSelector(selectCurrentScope)
  const currentCompany = useSelector(selectMyCurrentCompany)
  const currentUser: IUser = useSelector(selectSessionUser)

  const isMobile = useIsMobile()

  const billLinesFormRef =
    useRef<{
      billLinesSelected: IBillLine[]
      amountUserEnters: Record<string, string>
      canSubmit: boolean
    }>()

  const { paymentTypeOptions } = useQueryPaymentTypes()
  const { sellerCompanyOptions, buyerCompanyOptions } = useQueryCompanies({})

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const onScan = useCallback((callback: OnScanPayment) => {
    const onDeviceReady = () => {
      const successCallback = (imageData: string) => {
        callback('data:image/jpeg;base64,' + imageData)
      }

      const errorCallback = async () => {
        alert('Scan Canceled')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).scan.scanDoc(successCallback, errorCallback, {
        sourceType: 1,
        fileName: 'myfilename',
        quality: 1.0,
        returnBase64: true,
      })
    }
    document.addEventListener('deviceready', onDeviceReady, false)
  }, [])

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'Date',
        name: 'paymentDate',
        type: EFieldType.date,
        isRequired: true,
        size: isMobile ? 4 : 2,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Amount',
        name: 'amount',
        type: EFieldType.number,
        isRequired: true,
        size: isMobile ? 4 : 2,
        isHidden: ({ watch }) => watch('isSelectingBillLines', false),
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Payment Type',
        name: 'paymentTypeId',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: paymentTypeOptions,
        size: isMobile ? 4 : 2,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Buyer',
        name: 'buyerId',
        type: EFieldType.singleSelect,
        options: buyerCompanyOptions,
        isRequired: true,
        size: isMobile ? 6 : 2,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Seller',
        name: 'sellerId',
        type: EFieldType.singleSelect,
        options: sellerCompanyOptions,
        isRequired: true,
        size: isMobile ? 6 : 2,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Manual Transaction #',
        name: 'manualTransactionId',
        type: EFieldType.number,
        size: isMobile ? 12 : 2,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Select Invoice / Bill lines to pay',
        name: 'isSelectingBillLines',
        type: EFieldType.checkbox,
        isReadOnly: userScope === EScope.buyer,
      },
      {
        label: 'Image',
        name: 'image',
        type: EFieldType.custom,
        isReadOnly: userScope === EScope.buyer,
        render({ label, setValue, watch, name }) {
          const image = watch(name, null)

          return (
            <div className={clsx({ isReadOnly: userScope === EScope.buyer })}>
              <FileField
                label={label}
                value={[image]}
                placeholder='Accept: image, pdf'
                onChange={async ([file]: File[]) => {
                  setValue(name, file)
                }}
              />
              {isMobileApp() && (
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      color: 'var(--ion-color-fleet)',
                      marginTop: 4,
                    }}
                  >
                    Or
                  </div>
                  <Button
                    expand='full'
                    label='Scan Image'
                    icon={cameraOutline}
                    onClick={() => {
                      onScan((imageData: string) => {
                        setValue(name, imageData)
                      })
                    }}
                  />
                </div>
              )}
            </div>
          )
        },
      },
      {
        label: '',
        name: 'billLinesTable',
        isReadOnly: userScope === EScope.buyer,
        render({ watch }) {
          const buyerId = watch('buyerId', null)
          const billLinesDetails = watch('billLinesDetails', [])
          const paymentDate = watch('paymentDate', null)

          return (
            <PaymentBillLinesTable
              buyerId={buyerId}
              ref={billLinesFormRef}
              billLinesDetails={billLinesDetails}
              paymentDate={paymentDate}
            />
          )
        },
        isHidden: ({ watch }) => {
          const buyerId = watch('buyerId', '')
          const isSelectingBillLines = watch('isSelectingBillLines', false)
          return !buyerId || !isSelectingBillLines
        },
      },
    ],
    [
      isMobile,
      userScope,
      paymentTypeOptions,
      buyerCompanyOptions,
      sellerCompanyOptions,
      onScan,
    ],
  )

  const defaultValues = useMemo(
    () => ({
      paymentDate: moment().format('YYYY-MM-DD'),
      amount: null,
      paymentTypeId: null,
      buyerId: null,
      sellerId: null,
      image: '',
      manualTransactionId: '',
      isSelectingBillLines: true,
    }),
    [],
  )

  const updatePayment = useCallback(
    async (formValues: IPaymentFormValues) => {
      if (formValues.id) {
        setIsLoading(true)
        try {
          const paymentPayload: any = {
            amount: formValues.amount,
            buyerId: formValues.buyerId,
            paymentDate: formValues.paymentDate,
            paymentTypeId: formValues.paymentTypeId,
            manualTransactionId: formValues.manualTransactionId,
          }
          paymentPayload.billLinesDetails =
            billLinesFormRef.current?.billLinesSelected
              .map(({ id }) => ({
                billLineId: id,
                amount: billLinesFormRef.current?.amountUserEnters?.[id] || 0,
              }))
              .filter(({ amount }) => Number(amount) > 0)
          if (paymentPayload.billLinesDetails?.length) {
            paymentPayload.amount = paymentPayload.billLinesDetails.reduce(
              (total: number, { amount }: any) => (total += Number(amount)),
              0,
            )
          }

          const payload: IUpdatePaymentPayload = {
            payment: paymentPayload,
          }

          if (formValues.image instanceof File) {
            payload.image = await toBase64(formValues.image)
          }

          if (
            typeof formValues.image === 'string' &&
            isBase64(formValues.image, { allowMime: true })
          ) {
            payload.image = formValues.image
          }

          const { errors, ...response } = await apiClient.payments.update(
            formValues.id,
            payload,
          )

          if (errors.length) {
            toast.error(errors[0])
          } else {
            afterUpdate && afterUpdate(response)
            toast.success(toastMessages.updateSuccess)
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [afterUpdate],
  )

  const createPayment = useCallback(
    async (formValues: IPaymentFormValues, process?: boolean) => {
      setIsLoading(true)
      try {
        const paymentPayload: IPaymentFormValues = _.pick(formValues, [
          'amount',
          'buyerId',
          'paymentDate',
          'paymentTypeId',
          'manualTransactionId',
          'sellerId',
        ])
        paymentPayload.userAccessId = currentUser.userAccess.id
        paymentPayload.subTotal = paymentPayload.amount
        paymentPayload.billLinesDetails =
          billLinesFormRef.current?.billLinesSelected
            .map(({ id }) => ({
              billLineId: id,
              amount: billLinesFormRef.current?.amountUserEnters?.[id] || 0,
            }))
            .filter(({ amount }) => Number(amount) > 0)
        if (paymentPayload.billLinesDetails?.length) {
          paymentPayload.amount = paymentPayload.billLinesDetails.reduce(
            (total, { amount }) => (total += Number(amount)),
            0,
          )
        }
        const payload: ICreatePaymentPayload = {
          payment: paymentPayload,
        }

        if (formValues.image instanceof File) {
          payload.image = await toBase64(formValues.image)
        }

        if (
          typeof formValues.image === 'string' &&
          isBase64(formValues.image, { allowMime: true })
        ) {
          payload.image = formValues.image
        }

        const { errors, ...response } = await apiClient.payments.create(
          payload,
          { process },
        )
        if (errors.length > 0) {
          toast.error(errors[0])
        } else {
          afterCreate && afterCreate(response)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [afterCreate, currentUser.userAccess.id],
  )

  const onSubmit = useCallback(
    (formValues: IPaymentFormValues) => {
      if (billLinesFormRef.current?.canSubmit) {
        if (isUpdating) {
          updatePayment(formValues)
        } else {
          createPayment(formValues)
        }
      }
    },
    [isUpdating, updatePayment, createPayment],
  )

  const onProcessPayment = useCallback(async () => {
    setIsOpenPaymentProcess(true)
  }, [])

  const buttons = useMemo<IButtonProps[]>(
    () => [
      {
        label: 'Process Payment',
        color: 'tertiary',
        onClick: onProcessPayment,
        loading: isLoading,
        isHidden: !isUpdating || userScope !== EScope.buyer,
      },
    ],
    [isLoading, isUpdating, onProcessPayment, userScope],
  )

  const onCloseImageModifier = useCallback(() => {
    setImageModifierModal({
      isOpen: false,
      image: '',
    })
  }, [])

  const onSaveNewImage = useCallback(
    (newImage: string) => {
      onCloseImageModifier()
      formRef.current?.setValue('image', newImage)
    },
    [onCloseImageModifier],
  )

  const onClosePaymentProcess = () => {
    setIsOpenPaymentProcess(false)
  }

  const schema = useMemo(
    () =>
      Yup.object({
        paymentDate: Yup.string().required('Date is required!'),
        amount: Yup.string().when('isSelectingBillLines', {
          is: (isSelectingBillLines: boolean) => isSelectingBillLines,
          then: schema => schema.nullable(),
          otherwise: schema =>
            schema
              .required('Amount is required!')
              .typeError('Amount is required!'),
        }),
        buyerId: Yup.number()
          .required('Buyer is required!')
          .typeError('Buyer is required!'),
        sellerId: Yup.number()
          .required('Seller is required!')
          .typeError('Seller is required!'),
        paymentTypeId: Yup.number()
          .required('Amount is required!')
          .typeError('Amount is required!'),
        manualTransactionId: Yup.string(),
      }),
    [],
  )

  useEffect(() => {
    if (isUpdating) {
      if (formData?.billLinesDetails.length) {
        formRef.current?.setValue('isSelectingBillLines', true)
      }
      return
    }
    switch (userScope) {
      case USER_SCOPE.buyer: {
        formRef.current?.setValue('buyerId', currentCompany.id)
        break
      }
      case USER_SCOPE.seller: {
        formRef.current?.setValue('sellerId', currentCompany.id)
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany.id, isUpdating, userScope])

  return (
    <>
      <ConcordFormStructure
        ref={formRef}
        fields={fields}
        defaultValues={defaultValues}
        submitText={isUpdating ? 'Update' : 'Create'}
        onSubmit={onSubmit}
        isLoading={isLoading}
        schema={schema}
        formData={formData}
        buttons={buttons}
        isHiddenCancelButton
        isHiddenSubmitButton={Boolean(
          userScope === EScope.buyer ||
            (formData?.id && formData?.status !== EPaymentStatus.notSet),
        )}
      />
      <DialogImageModifier
        image={imageModifierModal.image}
        isOpen={imageModifierModal.isOpen}
        onClose={onCloseImageModifier}
        onSave={onSaveNewImage}
      />

      {formData && (
        <ProcessPaymentModal
          isOpen={isOpenPaymentProcess}
          onClose={onClosePaymentProcess}
          payment={formData}
          afterProcessPayment={() => {
            afterProcessPayment && afterProcessPayment()
            onClosePaymentProcess()
          }}
        />
      )}
    </>
  )
}
export default PaymentsForm
