import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import type { IStatementFormProps } from './type'
import { EFieldType } from '~/types/enums/ECommonEnum'
import * as Yup from 'yup'
import { useQueryCompanies } from '~/hooks/useQueryData'
import { IStatementFormData } from '~/types/models/IStatement'
import moment from 'moment'

const StatementForm = (props: IStatementFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const { buyerCompanyOptions } = useQueryCompanies({})

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        label: 'All Buyers',
        name: 'allBuyers',
        size: 12,
        type: EFieldType.checkbox,
        onChange() {
          formRef.current?.setValue('buyerIds', [])
        },
      },
      {
        label: 'Buyers',
        name: 'buyerIds',
        size: 12,
        type: EFieldType.multipleSelect,
        options: buyerCompanyOptions,
        isHidden({ watch }) {
          return Boolean(watch('allBuyers'))
        },
        isRequired: true,
      },
      {
        label: 'Date',
        name: 'date',
        type: EFieldType.date,
      },
      {
        label: 'Per Buyer Terminal',
        name: 'perBuyerTerminal',
        type: EFieldType.checkbox,
      },
    ],
    [buyerCompanyOptions],
  )

  const defaultValues = useMemo<IStatementFormData>(
    () => ({
      allBuyers: true,
      date: new Date().toISOString(),
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        allBuyers: Yup.boolean(),
        buyerIds: Yup.array()
          .nullable()
          .when('allBuyers', ([allBuyers], schema) => {
            if (!allBuyers) {
              return schema.min(1, 'This field is required')
            }
            return schema
          }),
      }),
    [],
  )

  const onCreate = useCallback(
    async (formValues: IStatementFormData) => {
      const payload: IStatementFormData = {
        allBuyers: formValues.allBuyers ? formValues.allBuyers : undefined,
        buyerIds: formValues.buyerIds,
        date: formValues.date
          ? moment(formValues.date).format('YYYY-MM-DD')
          : undefined,
        perBuyerTerminal: formValues.perBuyerTerminal ? true : undefined,
      }

      const response = await apiClient.statements.create(payload)
      afterCreate && afterCreate(response)
      toast.success(toastMessages.createSuccess)
    },
    [afterCreate],
  )

  // const onUpdate = useCallback(
  //   async (formValues: IPaymentTermFormValues) => {
  //     if (formValues.id) {
  //       const payload = _.pick(formValues, ['name', 'termType', 'termQty'])
  //       const { errors, ...response } = await apiClient.paymentTerms.update(
  //         formValues.id,
  //         {
  //           paymentTerm: payload,
  //         },
  //       )
  //       if (errors.length > 0) {
  //         setError(errors[0])
  //       } else {
  //         afterUpdate && afterUpdate(response)
  //         toast.success(toastMessages.updateSuccess)
  //       }
  //     } else {
  //       setError('Id is not found')
  //     }
  //   },
  //   [afterUpdate],
  // )

  const handleSubmit = useCallback(
    async (formValues: IStatementFormData) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          // await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default StatementForm
