import { Badge } from 'react-bootstrap'
import { components } from 'react-select'
import { useQueryCompanies } from '~/hooks/useQueryData'

function CustomSideTypeOption(props) {
  const { data } = props

  const { findCompanyById } = useQueryCompanies({})

  const company = findCompanyById(data.companyId)

  return (
    <components.Option {...props}>
      {company && (
        <Badge
          style={{ marginRight: 4 }}
          bg={data.type === 'SellerTerminal' ? 'dark' : 'primary'}
        >
          {company?.code}
        </Badge>
      )}
      <span>{data.label}</span>
    </components.Option>
  )
}

export default CustomSideTypeOption
