import { IStripePaymentMethodCardProps } from './type'

import './styles.scss'
import { useMemo } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import {
  AmexIcon,
  BankIcon,
  DiscoverCardIcon,
  JCBIcon,
  MasterCardIcon,
  VisaIcon,
} from '~/components/shared'

function StripePaymentMethodCard(props: IStripePaymentMethodCardProps) {
  const { data } = props

  const cardType = data.card?.brand || ''
  const last4 = data.card?.last4 || data.usBankAccount?.last4
  const cardName = data.usBankAccount?.bankName

  const email = data.billingDetails.email
  const phone = data.billingDetails.phone
  const name = data.billingDetails.name
  const routingNumber = data.usBankAccount?.routingNumber
  const accountHolderType = data.usBankAccount?.accountHolderType

  const expDate = useMemo(() => {
    try {
      if (data.card) {
        const { expMonth, expYear } = data.card
        return [expMonth, expYear].join('/')
      }
      return ''
    } catch (error) {
      console.log('error', error)
      return ''
    }
  }, [data.card])

  const billingAddress = useMemo(() => {
    try {
      const { line1, city, country, state } = data.billingDetails.address

      return [line1, city, country, state].filter(text => text).join(', ')
    } catch (error) {
      console.log('error', error)
      return ''
    }
  }, [data])

  const createdAt = useMemo(() => {
    const milliseconds = data.created * 1000
    const date = new Date(milliseconds)

    return moment(date).format('D/M/YYYY')
  }, [data.created])

  const getCardLogo = (type: string) => {
    const logoProps = {
      className: 'StripePaymentMethodCard__logo',
      size: '3em',
      color: 'white',
    }

    const logos = {
      amex: <AmexIcon {...logoProps} />,
      visa: <VisaIcon {...logoProps} />,
      mastercard: <MasterCardIcon {...logoProps} />,
      discover: <DiscoverCardIcon {...logoProps} />,
      jcb: <JCBIcon {...logoProps} />,
      default: <BankIcon {...logoProps} />,
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (logos as any)[type] || logos.default
  }

  return (
    <div className={clsx('StripePaymentMethodCard__container', cardType)}>
      <div className='StripePaymentMethodCard__number'>
        **** **** **** {last4}
      </div>
      {cardName && (
        <div className='StripePaymentMethodCard__name'>{cardName}</div>
      )}
      <div className='StripePaymentMethodCard__expiry'>
        {expDate && (
          <div>
            <span className='StripePaymentMethodCard__expiry--label'>
              VALID THRU&nbsp;&nbsp;&nbsp;
            </span>
            <span className='StripePaymentMethodCard__expiry--date'>
              {expDate}
            </span>
          </div>
        )}

        {routingNumber && (
          <div>
            <span className='StripePaymentMethodCard__expiry--label'>
              ROUTING #&nbsp;&nbsp;&nbsp;
            </span>
            <span className='StripePaymentMethodCard__expiry--date'>
              {routingNumber}
            </span>
          </div>
        )}

        {accountHolderType && (
          <div>
            <span className='StripePaymentMethodCard__expiry--label'>
              ACCOUNT TYPE&nbsp;&nbsp;&nbsp;
            </span>
            <span className='StripePaymentMethodCard__expiry--date'>
              {accountHolderType}
            </span>
          </div>
        )}

        <div>
          <span className='StripePaymentMethodCard__expiry--label'>
            CREATED AT:&nbsp;&nbsp;&nbsp;
          </span>
          <span className='StripePaymentMethodCard__expiry--date'>
            {createdAt}
          </span>
        </div>
      </div>
      {getCardLogo(cardType)}
      <div style={{ marginTop: 12 }}>
        {billingAddress && (
          <div className='StripePaymentMethodCard__billing-address'>
            <span style={{ fontWeight: 600 }}>Billing Address: </span>
            <span>{billingAddress}</span>
          </div>
        )}

        {name && (
          <div className='StripePaymentMethodCard__billing-address'>
            <span style={{ fontWeight: 600 }}>Name: </span>
            <span>{name}</span>
          </div>
        )}

        {email && (
          <div className='StripePaymentMethodCard__billing-address'>
            <span style={{ fontWeight: 600 }}>Email: </span>
            <span>{email}</span>
          </div>
        )}

        {phone && (
          <div className='StripePaymentMethodCard__billing-address'>
            <span style={{ fontWeight: 600 }}>Phone: </span>
            <span>{phone}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default StripePaymentMethodCard
