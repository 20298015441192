import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyHierarchy from './useModifyHierarchy'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetHierarchiesParams,
  IHierarchy,
} from '~/types/models/IHierarchy'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryHierarchies = (
  params: Partial<IGetHierarchiesParams> = {},
  options?: Partial<UseQueryOptions<IHierarchy[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'hierarchies',
      sessionUser?.id,
      buildGetUrl(apiClient.hierarchies.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.hierarchies.get(params)
      return response.hierarchies
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const {
    addHierarchy,
    updateHierarchy,
    removeHierarchy,
    renewAllHierarchies,
  } = useModifyHierarchy(params)

  const hierarchiesData = useMemo(() => data || [], [data])

  const hierarchyOptions = useMemo(
    () =>
      hierarchiesData.map(({ sellerSideType, buyerSideType, id }) => ({
        value: id,
        label: `${_.startCase(sellerSideType)} - ${_.startCase(buyerSideType)}`,
        sellerSideType,
        buyerSideType,
      })),
    [hierarchiesData],
  )

  const findHierarchyById = (id: number | undefined | null) =>
    hierarchiesData.find(i => i.id === id)

  return {
    hierarchiesData,
    isLoadingHierarchies: isLoading,
    hierarchyOptions,
    isHierarchiesDataFetched: isFetched,
    refetchHierarchies: refetch,
    addHierarchy,
    updateHierarchy,
    removeHierarchy,
    renewAllHierarchies,
    findHierarchyById,
  }
}

export default useQueryHierarchies
