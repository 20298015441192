import { components } from 'react-select'

import './styles.scss'

function CustomColorMenu(props) {
  const { children } = props
  return (
    <components.Menu {...props} className='CustomColorMenu__container'>
      {children}
    </components.Menu>
  )
}

export default CustomColorMenu
