import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IUploadGetListResponse, IUpload } from '~/types/models/IUpload'

const useModifyUploads = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const createUpload = useCallback(
    (data: IUpload) => {
      queryClient.setQueryData<IUploadGetListResponse | undefined>(
        ['uploads', sessionUser?.id],
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              draft.uploads.unshift(data)
            }
          }),
      )
    },
    [queryClient, sessionUser?.id],
  )
  return { createUpload }
}

export default useModifyUploads
