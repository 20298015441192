import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { buildGetUrl } from '~/utils/buildUrl'
import { IGetLoadsParams, IGetLoadsResponse, ILoad } from '~/types/models/ILoad'

const useModifyLoads = (params: Partial<IGetLoadsParams> = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'loads',
      sessionUser?.id,
      buildGetUrl(apiClient.loads.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const addLoad = (data: ILoad) => {
    queryClient.setQueryData<IGetLoadsResponse | undefined>(queryKey, oldData =>
      produce(oldData, draft => {
        if (draft?.loads) {
          const index = draft?.loads.findIndex(({ id }) => id === data.id)
          if (index === -1) {
            draft.loads.unshift(data)
          }
        }
      }),
    )
  }

  const updateLoad = (id: number, data: Partial<ILoad>) => {
    queryClient.setQueryData<IGetLoadsResponse | undefined>(queryKey, oldData =>
      produce(oldData, draft => {
        if (draft) {
          const index = draft.loads.findIndex(item => item.id === id)
          if (draft.loads[index]) {
            draft.loads[index] = {
              ...draft.loads[index],
              ...data,
            }
          }
        }
      }),
    )
  }

  const removeLoad = (id: number) => {
    queryClient.setQueryData<IGetLoadsResponse | undefined>(queryKey, oldData =>
      produce(oldData, draft => {
        if (draft) {
          const index = draft.loads.findIndex(item => item.id === id)
          if (draft.loads[index]) {
            draft.loads.splice(index, 1)
          }
        }
      }),
    )
  }

  return {
    addLoad,
    updateLoad,
    removeLoad,
  }
}

export default useModifyLoads
