const mergeArrayById = (array1, array2) => {
  const map = new Map()

  array1.forEach(item => map.set(item.id, item.value))

  array2.forEach(item => map.set(item.id, item.value))

  return Array.from(map, ([id, value]) => ({ id, value }))
}

export default mergeArrayById
