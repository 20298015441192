import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { loadSections } from '~/utils/loadUtils'

const SECTIONS = [
  'Ticketed',
  'At Plant',
  'To Job',
  'Arrived Job',
  'Returning',
  'Delivery Complete',
]

const useQueryLoadStatuses = () =>
  // options?: Partial<UseQueryOptions<IloadStatusTable>>,
  {
    const sessionUser: IUser | null = useSelector(selectSessionUser)

    const loadStatusTableId = useMemo(
      () => sessionUser?.company?.loadStatusTableId,
      [sessionUser],
    )

    const { data, isLoading, refetch } = useQuery({
      queryKey: ['loadStatuses', sessionUser?.id, loadStatusTableId],
      async queryFn() {
        const { loadStatusRows } = await apiClient.loadStatusTable.get({
          filters: {
            loadStatusTableId: loadStatusTableId ? [loadStatusTableId] : [],
          },
        })
        const loadStatuses = loadStatusRows
          .map(({ loadStatus }) => loadStatus)
          .sort((a, b) => a.rank - b.rank)
        return loadStatuses
      },
      enabled: Boolean(sessionUser?.id && loadStatusTableId),
      ...DEFAULT_QUERY_OPTIONS,
    })

    const loadStatusesData = useMemo(() => data || [], [data])

    const getSectionByStatus = useCallback((status: string) => {
      for (const [key, value] of Object.entries(loadSections)) {
        if (value.statusName === status) {
          return key
        }
      }
      return null
    }, [])

    const getLoadStatus = useCallback(
      (name: string) => loadStatusesData.find(status => status.name === name),
      [loadStatusesData],
    )

    const getLoadStatusesInSection = useCallback(
      (name: string) => {
        const currentIndex = loadStatusesData.findIndex(
          status => status.name === name,
        )
        const lowerIndex = loadStatusesData.findLastIndex(
          (status, index) =>
            index <= currentIndex && SECTIONS.includes(status.name),
        )
        const nextIndex = loadStatusesData.findIndex(
          (status, index) =>
            index > currentIndex && SECTIONS.includes(status.name),
        )
        return loadStatusesData.slice(lowerIndex, nextIndex)
      },
      [loadStatusesData],
    )

    const isStatusesInSameSection = useCallback(
      (status1: string, status2: string) => {
        const section = getLoadStatusesInSection(status1)
        return section.some(status => status.name === status2) ? true : false
      },
      [getLoadStatusesInSection],
    )

    const getRemainingLoadStatuses = useCallback(
      (name: string) => {
        const currentIndex = loadStatusesData.findIndex(
          status => status.name === name,
        )
        const nextIndex = loadStatusesData.findIndex(
          (status, index) =>
            index > currentIndex && SECTIONS.includes(status.name),
        )
        const remainingStatuses =
          nextIndex !== -1
            ? loadStatusesData.slice(currentIndex + 1, nextIndex + 1)
            : []
        return remainingStatuses
      },
      [loadStatusesData],
    )

    const getLoadStatusOptionsBySection = useCallback(
      (name: string) => {
        const remainingStatuses = getRemainingLoadStatuses(name)

        return remainingStatuses.map(s => ({
          label: `${s.code} ${s.name}`,
          value: s.code,
          icon: s.icon,
        }))
      },
      [getRemainingLoadStatuses],
    )

    const getNextLoadSection = useCallback(
      (name: string) => {
        const currentIndex =
          loadStatusesData.findIndex(status => status.name === name) ?? -1
        const nextIndex =
          loadStatusesData.findIndex(
            (status, index) =>
              index > currentIndex && SECTIONS.includes(status.name),
          ) ?? -1
        return loadStatusesData[nextIndex]
      },
      [loadStatusesData],
    )

    const getPreviousLoadStatus = (name: string) => {
      const index = SECTIONS.indexOf(name)
      const previousIndex = index - 1
      if (previousIndex > 0) {
        const previousSection = SECTIONS[previousIndex]
        const loadStatus = loadStatusesData.find(
          ({ name }) => name === previousSection,
        )
        return loadStatus
      }
      return undefined
    }

    return {
      loadStatuses: loadStatusesData, //sorted by rank
      isLoading,
      refetch,
      getLoadStatus,
      getLoadStatusesInSection,
      getRemainingLoadStatuses,
      getLoadStatusOptionsBySection,
      getNextLoadSection,
      isStatusesInSameSection,
      getSectionByStatus,
      SECTIONS,
      getPreviousLoadStatus,
    }
  }

export default useQueryLoadStatuses
