import { useCallback, useEffect } from 'react'
import {
  useLocation,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min'
import { prepareQueryString } from '~/utils'
import qs from 'qs'
import _ from 'lodash'
import { useRouter } from '../useRouter'

export const useFiltersInURL = ({ filters, sorts }) => {
  const { search } = useLocation()
  const history = useHistory()
  const router = useRouter()

  useEffect(() => {
    if (filters && sorts) {
      const search = prepareQueryString(filters, sorts, [], router.query)

      history.replace({
        location: history.location,
        search,
      })
    }
  }, [sorts, filters, history, router.query])

  const pullFiltersFromURL = useCallback(
    emptyFilters => {
      const parsed = qs.parse(search, { ignoreQueryPrefix: true })

      if (parsed?.filters) {
        const camelize = _.mapKeys(parsed.filters, (v, k) => _.camelCase(k))
        const formatted = _.mapValues(camelize, v => {
          if (_.isArray(v)) {
            return v.map(i => (parseInt(i) ? parseInt(i) : i))
          }
          return parseInt(v) ? parseInt(v) : v
        })

        const full = {
          ...emptyFilters,
          ...formatted,
        }
        _.forEach(full, (value, key) => {
          if (value === 'null') {
            full[key] = null
          }
        })
        return full
      }
    },
    [search],
  )

  return { pullFiltersFromURL }
}
