import { Button } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import clsx from 'clsx'
import { addOutline } from 'ionicons/icons'
import { When } from 'react-if'

import useOrderFormDialog from './useOrderFormDialog'
import { CommonDialogV2, CommonTab } from '~/components/shared'
import OrderForm from '~/components/shared/OrderForm/OrderForm'
import { EditTerminalSection } from '~/components/shared/OrderForm/EditTerminalSection'

import type { IOrderFormDialogProps } from './type'

import './styles.scss'

function OrderFormDialog(props: IOrderFormDialogProps) {
  const {
    isOpen,
    dialogTitle,
    defaultValues,
    isUpdating,
    className,
    tabs,
    currentTab,
    isToggleMap,
    orderBuyerTerminal,
    submitButtonText,
    onAddSchedule,
    handleClose,
    onChangeOrderForm,
    onClickSubmit,
    onChangeTab,
    onToggleShowTerminalMap,
    setDefaultValues,
  } = useOrderFormDialog(props)

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={handleClose}
      title={dialogTitle}
      size='lg'
      className={clsx('OrderFormDialog__container', className)}
      isHiddenOkButton
      backdrop='static'
    >
      <When condition={isToggleMap}>
        <EditTerminalSection
          terminal={orderBuyerTerminal}
          onComplete={onToggleShowTerminalMap}
          onCreateLocation={(locationId: number) => {
            setDefaultValues(
              prev =>
                ({
                  ...prev,
                  locationId,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any),
            )
          }}
          onClickClose={onToggleShowTerminalMap}
        />
      </When>
      <div className='OrderFormDialog__body'>
        <OrderForm
          isHiddenScheduleForm
          isHiddenButtons
          isHiddenBuyer
          isHiddenSeller
          isHiddenBuyerTerminal
          hiddenButtons={['addSchedule']}
          defaultValues={defaultValues}
          onChange={onChangeOrderForm}
        />

        <div className='OrderFormDialog__tabContainer'>
          <CommonTab
            tabs={tabs}
            currentTab={currentTab}
            onChangeTab={onChangeTab}
          />
          <Button
            size='lg'
            className='addButton'
            variant='outline-primary'
            onClick={onAddSchedule}
          >
            <IonIcon icon={addOutline} />
          </Button>
        </div>

        <When condition={submitButtonText.length}>
          <div className='d-grid gap-2'>
            <Button
              size='lg'
              type='submit'
              disabled={isUpdating}
              onClick={onClickSubmit}
            >
              {submitButtonText}
            </Button>
          </div>
        </When>
      </div>
    </CommonDialogV2>
  )
}

export default OrderFormDialog
