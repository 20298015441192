import { useState, useCallback } from 'react'
import { useWindowSize } from 'react-use'

import useTableTrucksColumns from './useTableTrucksColumns'
import {
  DialogTruckFormData,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import './TrucksContainerTable.scss'
import {
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUoms,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'

function TrucksContainerTable() {
  const [formState, setFormState] = useState({
    initialValues: null,
    isOpen: false,
  })
  const windowSize = useWindowSize()

  const {
    truckFleetsData,
    isLoadingTruckFleetsData,
    refetchTruckFleetsData,
    updateTruck,
  } = useQueryTruckFleets()

  const {
    isLoadingUsersData,
    assignTruckFleetToDriverFleet,
    driverFleetUsers,
  } = useQueryUsers()
  const { isLoadingUoms } = useQueryUoms()
  const { isLoadingTerminals } = useQueryTerminals()

  const isTableLoading =
    isLoadingTruckFleetsData ||
    isLoadingUsersData ||
    isLoadingUoms ||
    isLoadingTerminals

  const handleOpenCreateForm = useCallback(() => {
    setFormState({
      initialValues: null,
      isOpen: true,
    })
  }, [])

  const handleCloseForm = useCallback(() => {
    setFormState({
      initialValues: null,
      isOpen: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenEditForm = useCallback(
    truckFleet => () => {
      setFormState({
        isOpen: true,
        initialValues: {
          truck: truckFleet?.truck,
          truckFleet,
        },
      })
    },
    [],
  )

  const onCellEditEnd = useCallback(
    async (value, cell, selectedOpt) => {
      const { column, row } = cell
      const columnField = column.id
      const rowId = row.original.id

      const user = driverFleetUsers.find(
        ({ driverFleet }) => driverFleet?.currentTruckFleetId === rowId,
      )
      const driverFleetId =
        selectedOpt?.value === null ? user?.driverFleet?.id : value
      if (driverFleetId) {
        try {
          if (columnField === 'currentTruckFleetId') {
            await apiClient.driverFleets.update(driverFleetId, {
              currentTruckFleetId: selectedOpt?.value === null ? null : rowId,
            })
            assignTruckFleetToDriverFleet(rowId, value)
            toast.success(toastMessages.updateSuccess)
          } else {
            if (columnField.includes('truck')) {
              const [, field] = columnField.split('.')
              const response = await apiClient.trucks.update(
                row.original.truckId,
                {
                  truck: {
                    [field]: value,
                  },
                },
              )
              updateTruck(response.id, response)
            }
          }
          toast.success(toastMessages.updateSuccess)
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.serverError)
        }
      } else {
        toast.error('Driver fleet is not found')
      }
    },
    [assignTruckFleetToDriverFleet, driverFleetUsers, updateTruck],
  )

  const { columns } = useTableTrucksColumns()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          columns={columns}
          data={truckFleetsData}
          tableHeight={windowSize.height - 220}
          enableTopToolbar
          enableCompanyView
          enableRowActions
          enableColumnPinning
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              maxSize: 65,
              minSize: 65,
            },
          }}
          companyViewProps={{
            name: 'trucks',
            description: 'Manage your company trucks.',
            onClickTopButton: handleOpenCreateForm,
          }}
          state={{
            isLoading: isTableLoading,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              onClick: handleOpenEditForm(row.original),
              tooltipProps: {
                content: 'Edit',
                placement: 'top',
              },
            },
          ]}
          onCellEditEnd={onCellEditEnd}
        />
      </div>

      <DialogTruckFormData
        isOpen={formState.isOpen}
        onClose={handleCloseForm}
        truck={formState.initialValues?.truck}
        truckFleet={formState.initialValues?.truckFleet}
        onSubmitTruck={() => {
          handleCloseForm()
          refetchTruckFleetsData()
        }}
        onSubmitTruckFleet={() => {
          handleCloseForm()
          refetchTruckFleetsData()
        }}
      />
    </>
  )
}

export default TrucksContainerTable
