import { useCallback, useMemo, useState } from 'react'

import { IonCardHeader, IonCardTitle, IonIcon } from '@ionic/react'
import { GoogleMap, ToolTipOverlay, TruckIcon } from '~/components/shared'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

import * as Icon from 'ionicons/icons'
import _ from 'lodash'
import moment from 'moment'
import clsx from 'clsx'

import type { ISensorCardProps } from './type'

import './styles.scss'
import { toast } from 'react-toastify'
import getGoogleLocation from '~/utils/getGoogleLocation'
import AssignLoadsMap from '~/components/load/AssignLoads/AssignLoadsMap'
import { useQueryTruckFleets } from '~/hooks/useQueryData'

function SensorCard(props: ISensorCardProps) {
  const { sensorData, className, onClick, load } = props

  const [showLoadMap, setShowLoadMap] = useState(false)

  const { findTruckFleetById } = useQueryTruckFleets()

  const truckFleet = findTruckFleetById(load?.truckFleetId)

  const isLocation = sensorData.code === 'location'

  const mostRecentItem = useMemo(() => {
    if (sensorData.data.length > 0) {
      return sensorData.data.reduce((mostRecent, currentItem) => {
        const mostRecentTimestamp = new Date(mostRecent.timestamp)
        const currentItemTimestamp = new Date(currentItem.timestamp)

        return mostRecentTimestamp > currentItemTimestamp
          ? mostRecent
          : currentItem
      })
    }
    return null
  }, [sensorData.data])

  const locationValues = useMemo(() => {
    try {
      if (isLocation) {
        const value = mostRecentItem
          ? JSON.parse(mostRecentItem?.value as string)
          : null

        return {
          location: getGoogleLocation(value?.lat, value?.lng),
          speed: value?.speed,
          alt: value?.alt,
        }
      }
      return null
    } catch (error) {
      console.log('error', error)
      toast.error('Error happens while trying to get location')
      return null
    }
  }, [isLocation, mostRecentItem])

  const renderValue = useMemo(() => {
    if (sensorData.code === 'key_switch') {
      if (mostRecentItem?.value === 1) {
        return 'On'
      }
      return 'Off'
    }

    if (
      sensorData.code === 'volume' &&
      (mostRecentItem?.value === '--' || !mostRecentItem?.value)
    ) {
      return '-'
    }

    if (sensorData.code === 'drum_empty') {
      if (mostRecentItem?.value === 1) {
        return 'Empty'
      }
      return 'Not Empty'
    }

    if (mostRecentItem) {
      if (mostRecentItem.value === '--' || mostRecentItem.value === 1) {
        return <IonIcon icon={Icon.radioButtonOnOutline} />
      }
      return mostRecentItem.value
    }

    return <IonIcon icon={Icon.radioButtonOffOutline} />
  }, [mostRecentItem, sensorData.code])

  const onClickCard = useCallback(() => {
    onClick && onClick(sensorData)
  }, [onClick, sensorData])

  return (
    <>
      <ToolTipOverlay
        content={
          locationValues?.location ? (
            <div>
              <div>Lat: {locationValues?.location.lat()}</div>
              <div>Lng: {locationValues?.location.lng()}</div>
              {locationValues?.speed && (
                <div>Speed: {locationValues?.speed}</div>
              )}
              {locationValues?.alt && (
                <div>Altitude: {locationValues?.alt}</div>
              )}
            </div>
          ) : null
        }
        placement='top'
      >
        <div
          className={clsx('SensorCard__container', className, { isLocation })}
          style={{ color: sensorData.font, background: sensorData.background }}
          onClick={onClickCard}
        >
          <div className='headerContainer'>
            <div className='icon'>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              <IonIcon icon={(Icon as any)[_.camelCase(sensorData.icon)]} />
            </div>
            <IonCardHeader class='titleContainer'>
              <IonCardTitle style={{ color: sensorData.font }}>
                {sensorData.name}
              </IonCardTitle>
              {isLocation && (
                <ToolTipOverlay
                  content={moment(mostRecentItem?.timestamp).format(
                    'MMM-D HH:mm',
                  )}
                  placement='top'
                >
                  <div className='timestamp'>
                    {mostRecentItem
                      ? moment(mostRecentItem.timestamp).fromNow()
                      : ''}
                  </div>
                </ToolTipOverlay>
              )}
            </IonCardHeader>
          </div>
          <div className='bodyContainer'>
            {isLocation ? null : (
              <>
                <div className='valueContainer'>
                  <div className='value'>{renderValue}</div>
                  <div className='unit'>{sensorData.unit}</div>
                </div>
                <ToolTipOverlay
                  content={moment(mostRecentItem?.timestamp).format(
                    'MMM-D HH:mm',
                  )}
                  placement='top'
                >
                  <div className='timestamp'>
                    {mostRecentItem
                      ? moment(mostRecentItem.timestamp).fromNow()
                      : ''}
                  </div>
                </ToolTipOverlay>
              </>
            )}
          </div>

          {isLocation ? (
            <GoogleMap
              defaultCenter={locationValues?.location as any}
              zoom={15}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
              }}
            >
              {locationValues?.location && (
                <InfoBox
                  zIndex={1000}
                  position={locationValues.location}
                  options={{
                    closeBoxURL: '',
                    enableEventPropagation: true,
                    alignBottom: true,
                    pixelOffset: new window.google.maps.Size(-12, 10),
                  }}
                >
                  <div
                    style={{
                      background: 'var(--ion-color-concord)',
                      borderRadius: 4,
                    }}
                    onClick={event => {
                      event.stopPropagation()
                      setShowLoadMap(true)
                    }}
                  >
                    <TruckIcon size={20} style={{ padding: 3 }} color='white' />
                  </div>
                </InfoBox>
              )}
            </GoogleMap>
          ) : null}
        </div>
      </ToolTipOverlay>
      {isLocation && (
        <AssignLoadsMap
          showModal={showLoadMap}
          onDismissModal={() => {
            setShowLoadMap(false)
          }}
          load={load}
          truckFleet={truckFleet}
        />
      )}
    </>
  )
}

export default SensorCard
