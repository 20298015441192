import { useCallback, useEffect, useMemo, useState } from 'react'

import { ConcordFormLayout } from '~/components/shared'
import { Form } from 'react-bootstrap'
import NumericFormat from 'react-number-format'

import _ from 'lodash'
import clsx from 'clsx'

import './styles.scss'

function ConcordFormNumberField(props) {
  const {
    onChange,
    value,
    label,
    isDisabled,
    loading,
    className,
    error,
    name,
    isRequired,
    isPhoneNumber,
    isReadOnly,
    onBlur,
    hint,
    ...numberFieldProps
  } = props

  const [numberValue, setNumberValue] = useState(null)

  const phoneFieldProps = useMemo(() => {
    if (typeof isPhoneNumber === 'boolean') {
      return {
        format: '+1 (###) ### ####',
        allowEmptyFormatting: true,
        mask: '_',
      }
    }
    return {}
  }, [isPhoneNumber])

  const handleChange = useCallback(({ value }) => {
    setNumberValue(value)
  }, [])

  useEffect(() => {
    const formatValue = _.isNil(value) ? '' : value
    setNumberValue(formatValue)
  }, [value])

  return (
    <ConcordFormLayout
      label={label}
      className={clsx('ConcordFormNumberField__container', className, {
        hasError: error,
        isReadOnly,
      })}
      error={error}
      isRequired={isRequired}
      hint={hint}
    >
      <NumericFormat
        className={clsx({ isDisabled })}
        value={numberValue}
        customInput={Form.Control}
        onValueChange={handleChange}
        onBlur={event => {
          onChange && onChange(event, { value: numberValue, name })
          onBlur && onBlur(event)
        }}
        error={error}
        {...phoneFieldProps}
        {...numberFieldProps}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </ConcordFormLayout>
  )
}

export default ConcordFormNumberField
