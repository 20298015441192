import { useQueryShifts } from '~/hooks/useQueryData/useQueryShifts'
import useShiftsTableColumns from './useShiftsTableColumns'
import { useWindowSize } from 'react-use'

import { ReusableTable } from '~/components/shared'
import { moduleName } from '~/utils/constants'
import { useMemo, useState } from 'react'
import { EFieldType } from '~/types/enums/ECommonEnum'

const ShiftsSubTab = () => {
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'inStartDateRange',
      value: {},
    },
  ])

  const filterData = useMemo(() => {
    const filter = {}
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  console.log('filterData', filterData)
  console.log('columnFilters', columnFilters)

  const { shiftsData, isLoadingShifts } = useQueryShifts({
    filters: filterData,
  })

  const windowSize = useWindowSize()

  const filterOptions = useMemo(
    () => [
      {
        label: 'Date',
        field: 'inStartDateRange',
        type: EFieldType.dateRange,
      },
    ],
    [],
  )

  const { columns } = useShiftsTableColumns()

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableCompanyView
          enableGrouping
          enableColumnPinning
          enableExpandAll
          initialState={{
            columnPinning: {
              left: ['driverFleetId'],
            },
            grouping: ['driverFleetId'],
          }}
          companyViewProps={{
            name: moduleName.company.shifts,
            description: 'Manage your company shifts.',
            isHiddenTopButton: true,
          }}
          data={shiftsData}
          columns={columns}
          state={{
            isLoading: isLoadingShifts,
            columnFilters,
          }}
          tableHeight={windowSize.height - 220}
          filterOptions={filterOptions}
          onColumnFiltersChange={setColumnFilters}
        />
      </div>
    </>
  )
}

export default ShiftsSubTab
