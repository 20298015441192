import useBundleItemTableColumns from './useBundleFormWithBadges'

import { IonIcon } from '@ionic/react'
import { When } from 'react-if'

import { addCircle, closeCircleOutline } from 'ionicons/icons'

import { IBundleFormWithBadgesProps } from './type'
import { BundleBadgeForm } from '../BundleBadge/BundleBadgeForm'

import './styles.scss'

function BundleFormWithBadges(props: IBundleFormWithBadgesProps) {
  const {
    isCreatingBundle,
    renderBundleBadges,
    sellerProductId,
    onOpenCreateForm,
    onCloseForm,
    afterCreateBundle,
    availableSellerTerminalOptions,
  } = useBundleItemTableColumns(props)

  return (
    <div>
      <div className='BundleFormWithBadges__badges'>
        <div className='label'>
          <span>Bundles</span>
          <span
            className='icon'
            onClick={isCreatingBundle ? onCloseForm : onOpenCreateForm}
          >
            <IonIcon
              icon={isCreatingBundle ? closeCircleOutline : addCircle}
              color={isCreatingBundle ? 'danger' : 'concord'}
            />
          </span>
        </div>

        <When condition={isCreatingBundle}>
          <BundleBadgeForm
            sellerProductId={sellerProductId}
            sellerTerminalOptions={availableSellerTerminalOptions}
            afterCreate={afterCreateBundle}
            onCancel={onCloseForm}
          />
        </When>

        <div>{renderBundleBadges}</div>
      </div>
    </div>
  )
}

export default BundleFormWithBadges
