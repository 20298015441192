import { useCallback, useState } from 'react'
import { LoadExtraLineForm } from './LoadExtraLineForm'
import useQueryLoadExtras from '~/hooks/useQueryData/useQueryLoadExtras/useQueryLoadExtras'
import { ILoadExtraTableRowProps, LoadExtraTableFormProps } from './type'
import { useQueryBuyerSellerProducts, useQueryUoms } from '~/hooks/useQueryData'
// import { EOrderType } from '~/types/enums/ESellerProduct'
import { ILoadExtra } from '~/types/models/ILoadExtra'
import { ReusableButton } from '~/components/shared'
import { Placeholder } from 'react-bootstrap'
import clsx from 'clsx'
import './styles.scss'

// const extraOrderTypes = [EOrderType.extra, EOrderType.both]

export const LoadExtraTableForm = ({
  loadId,
  sellerId,
  buyerId,
  children,
  loadQty,
  loadSellerProductId,
  hideAddBtn = false,
  showExtraForm,
  existingIds = [],
  setShowExtraForm,
  onContextMenu,
  afterCreate,
  afterUpdate,
  afterDelete,
  shouldRunBillLine,
}: LoadExtraTableFormProps) => {
  const {
    loadExtrasData,
    isLoadingLoadExtras,
    createLoadExtra,
    updateLoadExtra,
    deleteLoadExtra,
  } = useQueryLoadExtras({
    filters: { loadId },
  })

  const existingIdsFiltered = existingIds.filter(Boolean)

  const existingLoadExtras = loadExtrasData.filter(({ id }) =>
    existingIdsFiltered.includes(id),
  )
  const noneExistingLoadExtras = loadExtrasData.filter(
    ({ id }) => !existingIdsFiltered.includes(id),
  )

  const loadExtras = [...existingLoadExtras, ...noneExistingLoadExtras]

  const { sellerProductsObj } = useQueryBuyerSellerProducts({
    filters: {
      joinsSellerProduct: true,
    },
  })

  const { uomsObj, currentUom } = useQueryUoms()

  const uom = useCallback(
    (sellerProductId: number) => {
      const sellerProduct = sellerProductsObj[sellerProductId]
      return sellerProduct?.uomId ? uomsObj[sellerProduct.uomId] : undefined
    },
    [uomsObj, sellerProductsObj],
  )

  return (
    <table className='w-100 fs-4'>
      <thead>
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Qty</th>
          <th>Unit</th>
        </tr>
      </thead>
      <tbody>
        {children}
        {isLoadingLoadExtras && (
          <tr>
            <td>
              <Placeholder animation='wave'>
                <Placeholder xs={10} />
              </Placeholder>
            </td>
            <td>
              <Placeholder animation='wave'>
                <Placeholder xs={10} />
              </Placeholder>
            </td>
            <td>
              <Placeholder animation='wave'>
                <Placeholder xs={10} />
              </Placeholder>
            </td>
            <td>
              <Placeholder animation='wave'>
                <Placeholder xs={10} />
              </Placeholder>
            </td>
          </tr>
        )}

        {loadExtras.map(extra => {
          return (
            <LoadExtraRow
              className={clsx({
                LoadExtraTableform__existingExtra: existingIds.includes(
                  extra.id,
                ),
              })}
              extra={extra}
              loadId={loadId}
              sellerId={sellerId}
              buyerId={buyerId}
              loadQty={loadQty}
              loadSellerProductId={loadSellerProductId}
              sellerProduct={sellerProductsObj[extra.sellerProductId]}
              uom={uom(extra.sellerProductId)?.code || currentUom?.code}
              onContextMenu={onContextMenu}
              onCreate={extra => {
                createLoadExtra(extra)
                setShowExtraForm && setShowExtraForm(false)
                afterCreate && afterCreate(extra)
              }}
              onUpdate={extra => {
                updateLoadExtra(extra)
                setShowExtraForm && setShowExtraForm(false)
                afterUpdate && afterUpdate(extra)
              }}
              onDelete={id => {
                deleteLoadExtra(id)
                afterDelete && afterDelete(id)
              }}
              key={extra.id}
              shouldRunBillLine={shouldRunBillLine}
            />
          )
        })}
        {!showExtraForm && !hideAddBtn && (
          <ReusableButton
            onClick={() => setShowExtraForm && setShowExtraForm(true)}
          >
            Add Extra
          </ReusableButton>
        )}
        {showExtraForm && (
          <tr>
            <td colSpan={4}>
              <LoadExtraLineForm
                loadId={loadId}
                sellerId={sellerId}
                buyerId={buyerId}
                onCreate={extra => {
                  createLoadExtra(extra)
                  setShowExtraForm && setShowExtraForm(false)
                  afterCreate && afterCreate(extra)
                }}
                onClickCancel={() =>
                  setShowExtraForm && setShowExtraForm(false)
                }
                loadQty={loadQty}
                loadSellerProductId={loadSellerProductId}
                shouldRunBillLine={shouldRunBillLine}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const LoadExtraRow = ({
  uom,
  onClickClose,
  extra,
  loadId,
  sellerId,
  buyerId,
  loadQty,
  loadSellerProductId,
  sellerProduct,
  className,
  shouldRunBillLine,
  onCreate,
  onUpdate,
  onDelete,
  onContextMenu,
}: ILoadExtraTableRowProps) => {
  const [show, setShow] = useState(false)

  const onCreateExtra = (newExtra: ILoadExtra) => {
    onCreate && onCreate(newExtra)
    setShow(false)
  }
  const onUpdateExtra = (newExtra: ILoadExtra) => {
    onUpdate && onUpdate(newExtra)
    setShow(false)
  }

  const closeForm = () => {
    setShow(false)
    onClickClose && onClickClose()
  }

  return (
    <tr className={className}>
      {!show && (
        <td
          onContextMenu={event => {
            onContextMenu && onContextMenu(event, sellerProduct)
          }}
          onClick={() => setShow(true)}
        >
          {sellerProduct?.code || '-'}
        </td>
      )}
      {!show && (
        <td
          onContextMenu={event => {
            onContextMenu && onContextMenu(event, sellerProduct)
          }}
          onClick={() => setShow(true)}
        >
          {sellerProduct?.name || '-'}
        </td>
      )}
      {!show && <td onClick={() => setShow(true)}>{Number(extra?.qty)}</td>}
      {!show && <td onClick={() => setShow(true)}>{uom}</td>}

      {show && (
        <td colSpan={4}>
          <LoadExtraLineForm
            extra={extra}
            loadId={loadId}
            sellerId={sellerId}
            buyerId={buyerId}
            loadQty={loadQty}
            loadSellerProductId={loadSellerProductId}
            onCreate={onCreateExtra}
            onUpdate={onUpdateExtra}
            onClickCancel={closeForm}
            onDelete={onDelete}
            shouldRunBillLine={shouldRunBillLine}
          />
        </td>
      )}
    </tr>
  )
}
