import { IGetSentEmailsParams } from '~/types/models/IInvoice'
import { CommonDialogV2, ICommonDialogProps } from '../CommonDialogV2'
import { useQuerySentEmails } from '~/hooks/useQueryData'
import SentEmailCard from './SentEmailCard'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'

interface ISentEmailsDialogProps extends ICommonDialogProps {
  emailableType: IGetSentEmailsParams['filters']['emailableType']
  emailableId: IGetSentEmailsParams['filters']['emailableId']
}

function SentEmailsDialog(props: ISentEmailsDialogProps) {
  const { emailableId, emailableType, isOpen, ...dialogProps } = props

  const { sentEmailsData, isSentEmailsDataLoading } = useQuerySentEmails(
    {
      filters: {
        emailableId,
        emailableType,
      },
    },
    { enabled: Boolean(emailableId && isOpen) },
  )

  return (
    <CommonDialogV2
      title={
        <div>
          <span>
            Emails:{' '}
            <Badge style={{ fontSize: 9, verticalAlign: 'middle' }}>
              {sentEmailsData.length}
            </Badge>
            <Link
              to={`${emailableType}s/${emailableId}`}
              style={{ marginLeft: 8 }}
            >
              {emailableType} - {emailableId}
            </Link>
          </span>
        </div>
      }
      size='lg'
      isOpen={isOpen}
      isHiddenOkButton
      isLoading={isSentEmailsDataLoading}
      {...dialogProps}
    >
      {sentEmailsData.map((sentEmail, index) => (
        <SentEmailCard key={sentEmail.id} sentEmail={sentEmail} index={index} />
      ))}
    </CommonDialogV2>
  )
}

export default SentEmailsDialog
