import { HeaderContent } from '~/components/shared'
import './styles.scss'
import { Body } from '~/components'
import usePaymentsContainer from './usePaymentsContainer'
import { DialogPaymentsForm } from '~/components/shared/ConcordForm/FormData/PaymentsForm'
import { PaymentsTable } from './PaymentsTable'

interface IPaymentsContainerProps {
  hideHeader?: boolean
  hideBody?: boolean
}

function PaymentsContainer({ hideBody, hideHeader }: IPaymentsContainerProps) {
  const {
    onCloseDialog,
    isShowCreate,
    isLoadingPaymentsData,
    paymentsData,
    paymentSelected,
    columnFilters,
    setColumnFilters,
    afterCreatePayment,
    afterUpdatePayment,
    onCellEditEnd,
    renderHeader,
    // renderTable,
    afterProcessPayment,
    onClickEdit,
    onClickRemove,
    onToggleFormModal,
  } = usePaymentsContainer()

  return (
    <>
      {!hideHeader && <HeaderContent renderContent={renderHeader} />}
      <Body hideBody={hideBody}>
        <PaymentsTable
          paymentsData={paymentsData}
          onCellEditEnd={onCellEditEnd}
          // renderTable={renderTable}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          isLoading={isLoadingPaymentsData}
          onClickEdit={onClickEdit}
          onClickRemove={onClickRemove}
          afterProcessPayment={afterProcessPayment}
          isShowFormModal={isShowCreate}
          onToggleFormModal={onToggleFormModal}
        />
      </Body>

      <DialogPaymentsForm
        isOpen={isShowCreate}
        formData={paymentSelected}
        onClose={onCloseDialog}
        afterCreate={afterCreatePayment}
        afterUpdate={afterUpdatePayment}
        afterProcessPayment={afterProcessPayment}
      />
    </>
  )
}

export default PaymentsContainer
