import * as types from './actionTypes'
import loadsSlice from '../reducers/data/loadsSlice'
import loadsContainerSlice from '../reducers/ui/loadsSlice'

export const createConsumer = () => ({
  type: types.CREATE_CONSUMER,
})

export const fetchLoadsSuccess = loads => ({
  loads,
  type: types.FETCH_LOADS_SUCCESS,
})

export const fetchChangeLoadsSuccess = loads => ({
  loads,
  type: types.FETCH_LOADS_CHANGE_SUCCESS,
})

export const fetchCurrentLoad = () => ({
  type: types.FETCH_CURRENT_LOAD,
})

export const fetchLoads = () => ({
  type: types.FETCH_LOADS,
})

export const errorLoads = () => ({
  type: types.ERROR_LOAD,
})

export const fetchUpdateLoad = object => ({
  object,
  type: types.EDIT_LOAD,
})

export const updatingCurrentLoadSuccess = currentLoad => ({
  currentLoad,
  type: types.UPDATE_CURRENT_LOAD_SUCCESS,
})

export const updatingCurrentLoad = () => ({
  type: types.UPDATE_CURRENT_LOAD,
})

export const updateLoad = payload => dispatch => {
  dispatch(loadsSlice.actions.updateLoad(payload))
}

export const updateUi = payload => dispatch => {
  dispatch(loadsContainerSlice.actions.updateUi(payload))
}

export const updateUiFilter = payload => dispatch => {
  dispatch(loadsContainerSlice.actions.updateUiFilter(payload))
}

export const resetFilters = () => ({
  type: types.RESET_LOAD_FILTERS,
})

export const fetchPastLoadSuccess = pastLoad => ({
  pastLoad,
  type: types.FETCH_PAST_LOAD_SUCCESS,
})


export const toggleOnEditSellerTerminal = (
  selectedSellerTerminal,
  selectedLoadId,
) => ({
  selectedSellerTerminal,
  selectedLoadId,
  type: types.TOGGLE_ON_EDIT_SELLER_TERMINAL,
})

export const toggleOnAddSellerTerminal = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_ON_ADD_SELLER_TERMINAL,
})

export const toggleOffSellerTerminal = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_OFF_SELLER_TERMINAL,
})

export const toggleOnEditBuyerTerminal = (
  selectedBuyerTerminal,
  selectedLoadId,
) => ({
  selectedBuyerTerminal,
  selectedLoadId,
  type: types.TOGGLE_ON_EDIT_BUYER_TERMINAL,
})

export const toggleOnAddBuyerTerminal = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_ON_ADD_BUYER_TERMINAL,
})

export const toggleOffBuyerTerminal = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_OFF_BUYER_TERMINAL,
})

export const toggleOnAddProduct = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_ON_ADD_PRODUCT,
})

export const toggleOffProduct = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_OFF_PRODUCT,
})

export const toggleOnEditProduct = (selectedProduct, selectedLoadId) => ({
  selectedProduct,
  selectedLoadId,
  type: types.TOGGLE_ON_EDIT_PRODUCT,
})

export const toggleOnAddFleet = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_ON_ADD_FLEET,
})

export const toggleoffAddFleet = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_OFF_ADD_FLEET,
})

export const toggleOnAddSeller = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_ON_ADD_SELLER,
})

export const toggleOffAddSeller = selectedLoadId => ({
  selectedLoadId,
  type: types.TOGGLE_OFF_ADD_SELLER,
})

export const setInlineFormData = formData => ({
  formData,
  type: types.SET_INLINE_LOAD_DATA,
})

export const setInlineFormEdit = (isEdit, loadId) => ({
  isEdit,
  loadId,
  type: types.SET_INLINE_LOAD_EDIT,
})

export const setInlineFormDelete = (data, entry, showDelete) => ({
  data,
  entry,
  showDelete,
  type: types.SET_INLINE_LOAD_DELETE,
})

export const updateLoadTagLinks = data => ({
  type: types.UPDATE_LOAD_TAGS_LINKS,
  data,
})

/**
 * @param {{ loadId: number, pdfUrl: string }} payload
 */
export const updateLoadPdf = payload => ({
  type: types.UPDATE_LOAD_PDF_URL,
  payload,
})

/**
 *
 * @param {{ id: number, field: string, value: any }} payload
 * @returns
 */

export const loadUpdateLoadById = payload => ({
  type: types.LOAD_UPDATE_LOAD_BY_ID,
  payload,
})
