import { useCallback } from 'react'

import { CommonDialog } from '~/components/shared'
import LoadDocumentView from '~/components/load/LoadDocumentView'
import ImageZoom from '~/components/shared/ImageZoom'

import PropTypes from 'prop-types'

import './DialogLoadPfdViewer.scss'

const DialogLoadPfdViewer = props => {
  const { load, ...dialogProps } = props

  const renderViewer = useCallback(() => {
    const loadImage = load.loadImage || load.imgThumbnailUrl
    const { loadPdf } = load

    if (loadPdf) {
      return (
        <LoadDocumentView
          loadPdf={loadPdf}
          loadId={load?.id}
          documentViewerHeight={455}
        />
      )
    }

    return (
      <ImageZoom
        url={loadImage}
        alt={'Ticket Image'}
        reponsive
        imageClassName='DialogLoadPfdViewer__imageContainer'
      />
    )
  }, [load])

  return (
    <CommonDialog
      {...dialogProps}
      hideHeader
      className='DialogLoadPfdViewer__container'
      contentClassName='DialogLoadPfdViewer__content'
    >
      {renderViewer()}
    </CommonDialog>
  )
}

DialogLoadPfdViewer.propTypes = {
  load: PropTypes.object,
}

export default DialogLoadPfdViewer
