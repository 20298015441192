import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

import type { IUser } from '~/types/models/IUser'
import type { IBuyerSellerRegexpsNewResponse } from '~/types/models/IBuyerSellerRegexp'

const useQueryBuyerSellerRegexpsNew = (
  options?: Partial<UseQueryOptions<IBuyerSellerRegexpsNewResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['buyer_seller_regexps_new', sessionUser?.id],
    queryFn() {
      return apiClient.buyerSellerRegexps.new()
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const regexpFieldOptions = useMemo(() => {
    if (data) {
      return Object.keys(data.field).map(key => ({
        value: data.field[key],
        label: _.startCase(key),
        field: key,
      }))
    }
    return []
  }, [data])

  const regexpFields = data?.field || {}

  return {
    isLoadingNewRegexps: isLoading,
    regexpFieldOptions,
    regexpFields,
    isRegexpNewDataFetched: isFetched,
  }
}

export default useQueryBuyerSellerRegexpsNew
