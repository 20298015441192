import { useMemo } from 'react'

import { IRTColumnDef } from '~/components/shared'
import { ITag } from '~/types/models/ITag'
import getColor from '~/utils/getColor'

const useTableCompanyTagSubTab = () => {
  const columns = useMemo<IRTColumnDef<ITag>[]>(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        size: 60,
        Cell({ cell, row }) {
          const cellValue = cell.getValue<number | undefined>()

          return <span>{cellValue || row.index + 1}</span>
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: true,
        size: 150,
      },
      {
        header: 'Code',
        accessorKey: 'code',
        enableEditing: true,
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableEditing: true,
        size: 200,
      },
      {
        header: 'Models',
        accessorKey: 'models',
        Cell: ({ row: { original } }) => {
          return (original.models || []).join(', ')
        },
      },
      {
        header: 'Color',
        accessorKey: 'color',
        Cell({ cell }) {
          const cellValue = cell.getValue<string>()
          const color = getColor(cellValue)

          if (!color) {
            return ''
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{cellValue}</span>
              <span
                style={{
                  height: 12,
                  width: 12,
                  border: `1px solid ${color}`,
                  backgroundColor: `${color}`,
                  display: 'inline-block',
                  marginLeft: 6,
                }}
              ></span>
            </div>
          )
        },
      },
    ],
    [],
  )

  return { columns }
}

export default useTableCompanyTagSubTab
