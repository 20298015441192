export const routesPaths = {
  login: '/login',
  index: '/',
  driver: '/driver',
  company: '/company',
  admin: '/admin',
  signUp: '/sign_up',
  signUpCompany: '/sign_up_company',
  signUpClient: '/sign_up_client',
  signUpDriver: '/sign_up_driver',
  profile: '/profile',
  orderTracking: '/order_tracking',
  payroll: '/payroll',
  accountsReceivable: '/ar',
  createBuyerCompany: '/credit_app',
}

export const driverRoutesPaths = {
  index: '/loads',
  pastLoad: '/loads/search',
  load: '/loads/:load',
  driverProfile: '/driver_profile',
  truckProfile: '/truck_profile',
  map: '/map',
  chat: '/chat',
  itinerary: '/itinerary',
  scanTicket: '/scan_tickets',
  startTimes: '/start_times',
}

export const fleetRoutesPaths = {
  orders: '/orders',
  trucks: '/trucks',
  workers: '/workers',
  assignLoads: '/loads',
  map: '/map',
  createTruck: '/trucks/new',
  createDriver: '/drivers/new',
  createOrder: '/orders/new',
  startTimes: '/start_times',
  createStartTimes: '/start_times/new',
  dispatchDashboard: '/dispatch_dashboard',
  payrollsDetails: '/payrolls/:id',
  flagableConfigurationsDetail: '/flagable_configurations/:id',
  projectedInvoice: '/projected_invoices/:id',
}

export const clientRoutesPaths = {
  index: '/client/homepage',
}

export const companyRoutesPaths = {
  index: '/order_reminders',
  requestSeller: '/access/:id/seller',
  requestBuyer: '/access/:id/buyer',
  requestDataParser: '/access/:id/dataparser',
  requestSetup: '/access/setup',
  requestUser: '/access/user',
  requestAccess: '/access/request',
  accessRequests: '/accesses',
  terminal: '/companies/terminals/:id',
  barcode: '/companies/barcode',
  fleet: '/companies/fleets',
  products: '/companies/products',
  edit: '/companies/:id/edit',
  showCompany: '/companies/:id',
  profile: '/profile',
  loads: '/loads',
  loadsDetail: '/loads/:id',
  payrollsDetails: '/payrolls/:id',
  flagableConfigurationsDetail: '/flagable_configurations/:id',
  uploads: '/uploads',
}

export const documentRoutesPaths = {
  documents: '/documents',
  detail: '/documents/:id',
}

export const invoicesPaths = {
  index: '/invoices',
  detail: '/invoices/:id',
}

export const ordersRoutesPaths = {
  index: '/orders',
  detail: '/orders/:id/',
}
export const chatRoutesPaths = {
  channels: '/chats/channels',
  chatContainer: '/chat',
  chats: '/chats',
}

export const adminRoutesPaths = {
  index: '/admin/dashboard',
  editCompanies: '/admin/edit_companies',
}

export const userDashboardPaths = {
  dashboard: '/my_dashboard',
}

export const loadsCreatePaths = {
  loadsCapture: '/loads_capture',
}

export const orderTrackingPaths = {
  index: '/order_tracking',
}

export const salesProposalPaths = {
  index: '/sales_proposals',
  detail: '/sales_proposals/:id',
}
