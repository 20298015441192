import { REGEX_SPLIT_REGEX_TO_STRING } from '~/utils/constants'

function removeDuplicateWords(input) {
  return input
    .split(' ') // Split the string into an array of words
    .filter((word, index, array) => array.indexOf(word) === index) // Filter out duplicates
    .join(' ') // Join the words back into a string
}

export const getRegex = (regex, additionalFlags = []) => {
  try {
    if (!regex) {
      return new RegExp('')
    }
    const [, , regexStr, flags] = regex.match(REGEX_SPLIT_REGEX_TO_STRING)
    const extraFlags = additionalFlags.join('')
    const newFlags = removeDuplicateWords(`${flags}${extraFlags}`)
    return new RegExp(regexStr, newFlags)
  } catch (error) {
    console.log('error', error)
    return new RegExp('')
  }
}
