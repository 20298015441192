import { useCallback } from 'react'
import { useController } from 'react-hook-form'

import { ConcordFormCheckbox } from '~/components/shared'

function ConcordCheckboxWithFormControl(props) {
  const { control, name, onChange, ...radioProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const handleChange = useCallback(
    (event, value) => {
      field.onChange(value)
      onChange &&
        onChange({
          name,
          value,
        })
    },
    [field, name, onChange],
  )

  return (
    <ConcordFormCheckbox
      {...radioProps}
      name={name}
      value={field.value}
      onChange={handleChange}
      error={fieldState.error?.message}
    />
  )
}

export default ConcordCheckboxWithFormControl
