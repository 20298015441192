import { IPaymentCardProps } from './type'

import './styles.scss'
import { Button, CompanyInfo } from '~/components/shared'
import { EScope } from '~/types/enums/ECommonEnum'
import { Badge } from 'react-bootstrap'
import { IonButtons } from '@ionic/react'
import {
  arrowDownCircle,
  arrowUpCircleOutline,
  closeCircleOutline,
  createOutline,
  wallet,
} from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { ICompany } from '~/types/models/ICompany'
import { useMemo, useState } from 'react'
import {
  useQueryCompanies,
  useQueryGlCodes,
  useQueryPaymentTypes,
} from '~/hooks/useQueryData'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { When } from 'react-if'
import _ from 'lodash'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'

function PaymentCard(props: IPaymentCardProps) {
  const { paymentData, onClickEdit, onClickRemove, onProcessPayment } = props

  const [isShowDetails, setIsShowDetails] = useState(false)

  const { getDateTimeFormat } = useDateTimeFormat()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { findGlCodeById } = useQueryGlCodes()
  const { findPaymentTypeById } = useQueryPaymentTypes()
  const { findCompanyById } = useQueryCompanies({})

  const buyer = useMemo(
    () => findCompanyById(paymentData.buyerId),
    [findCompanyById, paymentData.buyerId],
  )

  const glCode = useMemo(() => {
    if (paymentData.glCodeId) {
      return findGlCodeById(paymentData.glCodeId)
    }
    return undefined
  }, [findGlCodeById, paymentData.glCodeId])

  const paymentType = useMemo(() => {
    if (paymentData.paymentTypeId) {
      return findPaymentTypeById(paymentData.paymentTypeId)
    }
    return undefined
  }, [findPaymentTypeById, paymentData.paymentTypeId])

  const transactionId = useMemo(
    () => paymentData.manualTransactionId || paymentData.autoTransactionId,
    [paymentData.autoTransactionId, paymentData.manualTransactionId],
  )

  return (
    <div className='PaymentCard__container'>
      <div className='headerContainer'>
        <div>
          <CompanyInfo
            company={{
              value: buyer?.id,
              label: buyer?.name || '',
              name: buyer?.name,
              code: buyer?.code,
              image: buyer?.logo,
            }}
            companyType={EScope.buyer}
            hideAnchor
            searchableGoogle={false}
          />
        </div>

        <div>
          <CompanyInfo
            company={{
              value: currentCompany.id,
              label: currentCompany.name,
              name: currentCompany.name,
              code: currentCompany.code,
              image: currentCompany.logo,
            }}
            companyType={EScope.seller}
            hideAnchor
            searchableGoogle={false}
          />
        </div>

        {glCode && (
          <div>
            <Badge bg='secondary'>{glCode.glCode}</Badge>
          </div>
        )}
      </div>

      <div className='bodyContainer'>
        <div className='amount'>
          {formatCurrencyToDollar.format(Number(paymentData.amount))}
        </div>
        <div className='paydate'>
          {getDateTimeFormat(paymentData.paymentDate, 'ddd, MMM-DD')}
        </div>
        <div className='payType'>
          <span>{paymentType?.paymentType}</span>&nbsp;
          {transactionId && (
            <span className='transactionId'>{`#${transactionId}`}</span>
          )}
        </div>
      </div>

      <div className='footerContainer'>
        <IonButtons>
          <Button
            icon={isShowDetails ? arrowDownCircle : arrowUpCircleOutline}
            size='small'
            color={isShowDetails ? 'concord' : 'fleet'}
            onClick={() => {
              setIsShowDetails(prev => !prev)
            }}
          />
          {paymentData.status && (
            <Badge>{_.startCase(paymentData.status)}</Badge>
          )}
        </IonButtons>
        <IonButtons>
          <Button
            icon={createOutline}
            color='warning'
            size='small'
            onClick={() => {
              onClickEdit && onClickEdit(paymentData)
            }}
          />
          <Button
            icon={closeCircleOutline}
            color='danger'
            size='small'
            onClick={() => {
              onClickRemove && onClickRemove(paymentData)
            }}
          />
          <When
            condition={
              paymentData.autoTransactionId && paymentData.status === 'not_sent'
            }
          >
            <Button
              size='small'
              icon={wallet}
              color='concord'
              onClick={() => {
                onProcessPayment && onProcessPayment(paymentData)
              }}
            />
          </When>
        </IonButtons>
      </div>
    </div>
  )
}

export default PaymentCard
