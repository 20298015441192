import { useCallback, useMemo, useState } from 'react'

import ConfirmationContext from './ConfirmationContext'
import { CommonDialogV2 } from '~/components/shared'
import { Button, Form } from 'react-bootstrap'

import clsx from 'clsx'

import type {
  EConfirmationAction,
  IConfirmationProviderProps,
  IConfirmationProviderOptions,
} from './type'

import './styles.scss'

const DEFAULT_OPTIONS: IConfirmationProviderOptions = {
  header: 'Confirmation',
  message: 'Need to confirm...',
  buttons: [
    {
      text: 'Ok',
      action: 'Yes',
      color: 'primary',
    },
    {
      text: 'Cancel',
      action: 'Cancel',
      color: 'danger',
    },
  ],
}

function ConfirmationProvider(props: IConfirmationProviderProps) {
  const { children } = props

  const [resolveReject, setResolveReject] = useState<
    ((action: EConfirmationAction) => void)[]
  >([])
  const [options, setOptions] =
    useState<IConfirmationProviderOptions>(DEFAULT_OPTIONS)
  const [resolve] = resolveReject

  const isOpen = resolveReject.length > 0

  const confirmation = useCallback(
    (options: Partial<IConfirmationProviderOptions> = {}) =>
      new Promise<EConfirmationAction>((resolve, reject) => {
        setOptions({ ...DEFAULT_OPTIONS, ...options })
        setResolveReject([resolve, reject])
        setOptions({ ...DEFAULT_OPTIONS, ...options })
      }),
    [],
  )

  const onCloseDialog = () => {
    setResolveReject([])
  }

  const onClickButton = useCallback(
    (action: EConfirmationAction) => {
      resolve(action)
      onCloseDialog()
    },
    [resolve],
  )

  const renderButtons = useMemo(() => {
    return options.buttons.map((button, index) => (
      <Button
        key={index}
        variant={button.color}
        onClick={() => onClickButton(button.action)}
      >
        {button.text}
      </Button>
    ))
  }, [onClickButton, options.buttons])

  return (
    <>
      <ConfirmationContext.Provider value={{ confirmation }}>
        {children}
      </ConfirmationContext.Provider>
      <CommonDialogV2
        title={options.header}
        isOpen={isOpen}
        isHiddenOkButton
        isHiddenCloseButton
        className={clsx('ConfirmationProvider__dialog', options.className)}
      >
        {!options.hideMessage && (
          <div className='contentMessage'>{options.message}</div>
        )}

        {options.showRadio && (
          <div style={{ marginTop: 8 }}>
            {(options.radioOptions || []).map(
              ({ label, value, defaultChecked }) => (
                <Form.Check
                  type='radio'
                  key={value}
                  label={label}
                  style={{ marginBottom: 8 }}
                  defaultChecked={defaultChecked}
                  onChange={event => {
                    options.onChangeRadio && options.onChangeRadio(event, value)
                  }}
                  name={options.radioName}
                />
              ),
            )}
          </div>
        )}

        {options.showCheckbox && (
          <div style={{ marginTop: 8 }}>
            <Form.Check
              label={options.checkboxLabel}
              onChange={event => {
                const checked = event.target.checked
                options.onChangeCheckbox &&
                  options.onChangeCheckbox(event, checked)
              }}
            />
          </div>
        )}

        {options.showInput && (
          <input
            autoFocus
            placeholder={options.inputPlaceholder}
            onChange={event => {
              options.onChangeInput &&
                options.onChangeInput(event, event.target.value)
            }}
            className='ConfirmationProvider__input'
          />
        )}

        <div className='buttonsContainer'>{renderButtons}</div>
      </CommonDialogV2>
    </>
  )
}

export default ConfirmationProvider
