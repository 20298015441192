import { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

const MINUTE_MS = 60000

const useLiveTime = (waitTime = MINUTE_MS) => {
  const [time, setTime] = useState(moment())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment())
    }, waitTime)

    return () => clearInterval(interval)
  }, [waitTime])

  return { time }
}

useLiveTime.propTypes = {
  waitTime: PropTypes.number,
}

export default useLiveTime
