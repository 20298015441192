import { useQueryLoad } from '~/hooks/useQueryData'
import ViewPDF from '../ViewPDF'
import ViewImage from '../ViewImage'
import LoadPrint from '~/components/order/LoadPrint'
import { useMemo } from 'react'
import { isParserButler } from '~/utils/checkParser'
import ButlerBlock from '../ButlerBlock'
import { When } from 'react-if'
import Skeleton from 'react-loading-skeleton'
import { OcrViewer } from './OcrViewer'

export interface ITicketViewProps {
  loadId: number
  className?: string
  useOcrView?: boolean
  pdfWidth?: number
  pdfHeight?: number
  setTotalPages?: React.Dispatch<React.SetStateAction<number>>
  pageNumber?: number
  searchText?: string
}

function TicketView(props: ITicketViewProps) {
  const {
    loadId,
    className,
    useOcrView,
    pdfWidth,
    pdfHeight,
    setTotalPages,
    pageNumber,
    searchText,
  } = props

  const { loadData, isLoadDataFetched, isLoadingLoadData } = useQueryLoad(
    loadId,
    {
      enabled: Boolean(loadId),
    },
  )

  const renderElement = useMemo(() => {
    if (loadData && isLoadDataFetched) {
      if (
        isParserButler(loadData.parserName || '') &&
        loadData.uid &&
        loadData.modelId
      ) {
        return (
          <ButlerBlock modelId={loadData.modelId} documentId={loadData.uid} />
        )
      }

      if (loadData.ticketPdf) {
        if (useOcrView) {
          return (
            <OcrViewer
              link={loadData.ticketPdf}
              pdfWidth={pdfWidth}
              pdfHeight={pdfHeight}
              setTotalPages={setTotalPages}
              pageNumber={pageNumber}
              searchText={searchText}
            />
          )
        }

        return <ViewPDF link={loadData.ticketPdf} />
      }
      if (loadData.ticketImage) {
        return <ViewImage link={loadData.ticketImage} />
      }
      return <LoadPrint load={loadData} />
    }
    return null
  }, [
    isLoadDataFetched,
    loadData,
    pageNumber,
    pdfHeight,
    pdfWidth,
    setTotalPages,
    useOcrView,
    searchText,
  ])

  return (
    <div className={className}>
      <When condition={isLoadingLoadData}>
        <div className='CheckSignatoryForm__loading'>
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
          <Skeleton height={14} width='100%' className='item' />
        </div>
      </When>
      {renderElement}
    </div>
  )
}

export default TicketView
