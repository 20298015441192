import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import _ from 'lodash'

const useQueryDocTypes = () => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'docTypes',
      sessionUser?.id,
      buildGetUrl(apiClient.documents.new),
    ],
    async queryFn() {
      const response = await apiClient.documents.new()
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const docTypesData = useMemo(
    () => (data?.docType && Object.keys(data.docType)) || [],
    [data],
  )

  const documentStatusesData = useMemo(
    () => (data?.status && Object.keys(data.status)) || [],
    [data],
  )

  const docTypeOptions = useMemo(
    () =>
      docTypesData.map(key => ({
        value: _.startCase(key),
        label: _.startCase(key),
      })),
    [docTypesData],
  )

  const documentStatusOptions = useMemo(
    () =>
      documentStatusesData.map(key => ({
        value: _.startCase(key),
        label: _.startCase(key),
      })),
    [documentStatusesData],
  )

  return {
    docTypesData,
    documentStatusesData,
    isDocTypesLoading: isLoading,
    docTypeOptions,
    documentStatusOptions,
  }
}

export default useQueryDocTypes
