import {
  DialogBuyerSellerRegexpForm,
  PlusIcon,
  ToggleSection,
} from '~/components/shared'
import { IBuyerSellerRegexp } from '~/types/models/IBuyerSellerRegexp'
import { RegexpItem } from '../RegexpItem'
import { useCallback, useMemo, useState } from 'react'
import { Alert } from 'react-bootstrap'

export interface IRegexpListProps {
  regexpsData: IBuyerSellerRegexp[]
  label: string
  isOpenByDefault?: boolean
  content?: string
  canClick?: boolean
  canCreate?: boolean
  regexSelected?: IBuyerSellerRegexp | null
  onClick?: (regexpData: IBuyerSellerRegexp) => void
  afterUpdate?: (regex: IBuyerSellerRegexp) => void
  onClickCreate?: () => void
}

function RegexpList(props: IRegexpListProps) {
  const {
    label,
    regexpsData,
    isOpenByDefault,
    content,
    regexSelected,
    onClick,
    canClick,
    canCreate,
    afterUpdate,
    onClickCreate,
  } = props

  const [regexUpdateForm, setRegexUpdateForm] = useState({
    isOpen: false,
    formData: undefined as IBuyerSellerRegexp | undefined,
  })

  const onOpenUpdateForm = useCallback((regex: IBuyerSellerRegexp) => {
    setRegexUpdateForm({
      isOpen: true,
      formData: regex,
    })
  }, [])

  const onCloseUpdateForm = useCallback(() => {
    setRegexUpdateForm({
      isOpen: false,
      formData: undefined,
    })
  }, [])

  const renderRegexpsData = useMemo(() => {
    if (regexpsData.length === 0) {
      return (
        <Alert variant='warning' style={{ fontSize: 13 }}>
          No Result Found!{' '}
          {canCreate && (
            <span className='hyperLink' onClick={onClickCreate}>
              Create one <PlusIcon color='var(--bs-primary)' />
            </span>
          )}
        </Alert>
      )
    }

    return (regexpsData || []).map(item => (
      <RegexpItem
        key={item.id}
        regexp={item}
        content={content}
        canClick={canClick}
        onClick={onClick}
        onClickEdit={onOpenUpdateForm}
        selected={regexSelected?.id === item.id}
      />
    ))
  }, [
    canClick,
    canCreate,
    content,
    onClick,
    onOpenUpdateForm,
    regexSelected?.id,
    regexpsData,
    onClickCreate,
  ])

  return (
    <>
      <ToggleSection label={label} isOpenByDefault={isOpenByDefault}>
        {renderRegexpsData}
      </ToggleSection>

      <DialogBuyerSellerRegexpForm
        isOpen={regexUpdateForm.isOpen}
        formData={regexUpdateForm.formData}
        onClose={onCloseUpdateForm}
        afterUpdate={regex => {
          onCloseUpdateForm()
          afterUpdate && afterUpdate(regex)
        }}
      />
    </>
  )
}

export default RegexpList
