/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from './apiService'
import { buildGetUrl } from '~/utils/buildUrl'

import type {
  ICreateSellerProductResponse,
  IDeleteSellerProductResponse,
  IGetSellerProductQueryParams,
  IGetSellerProductResponse,
  ISellerProductFormValues,
  IUpdateSellerProductResponse,
} from '~/types/models/ISellerProduct'
import {
  ICreateSellerTerminalProductPayload,
  ICreateSellerTerminalProductResponse,
  IGetSellerTerminalProduct,
  IGetSellerTerminalProductResponse,
  IUpdateSellerTerminalProductPayload,
  IUpdateSellerTerminalProductResponse,
} from '~/types/models/ISellerTerminalProduct'
import {
  ICreateProductGroupPayload,
  ICreateProductGroupResponse,
  IDeleteProductGroupResponse,
  IProductGroup,
  IProductGroupFormData,
  IUpdateProductGroupResponse,
} from '~/types/models/IProductGroup'
import {
  ICreateWorkPayTypeResponse,
  IGetWorkPayTypesResponse,
  IWorkPayTypeFormValues,
} from '~/types/models/IWorkPayType'
import {
  ICreateWorkPayRuleResponse,
  IGetWorkPayRulesQueryParams,
  IGetWorkPayRulesResponse,
} from '~/types/models/IWorkPayRule'
import {
  IBuyerSellerProductFormValues,
  ICreateBuyerSellerProductPayload,
  ICreateBuyerSellerProductResponse,
  IGetBuyerSellerProductResponse,
  IGetBuyerSellerProductsParams,
  IGetBuyerSellerProductsResponse,
  IUpdateBuyerSellerProductResponse,
} from '~/types/models/IBuyerSellerProduct'
import {
  ICreateWorkerPayload,
  IGetWorkersParams,
  IGetWorkersResponse,
  IWorker,
  IWorkerFormData,
} from '~/types/models/IWorker'
import {
  ICreateUserPayload,
  ICreateUserResponse,
  IDeleteUserResponse,
  IForgotPasswordPayload,
  IGetUserParams,
  ISignInPayload,
  ISignUpPayload,
  IUpdatePasswordPayload,
  IUpdateUserPayload,
  IUpdateUserResponse,
  IUser,
  IUserUpdateAccountPayload,
} from '~/types/models/IUser'
import {
  ICreateUserAccessResponse,
  IGetUserAccessesResponse,
  IGetUserAcessParams,
  IUpdateUserAccessResponse,
  IUserAccess,
  IUserAccessFormData,
} from '~/types/models/IUserAccess'
import {
  ICreatePersonResponse,
  IGetPeopleParams,
  IGetPeopleResponse,
  IPerson,
  IUpdatePersonPayload,
  IUpdatePersonResponse,
} from '~/types/models/IPerson'
import {
  IAddressFormData,
  IAddressGetNewListParams,
  IAddressGetNewListResponse,
  ICreateAddressResponse,
  IGetAddressesParams,
  IGetAddressesResponse,
  IUpdateAddressResponse,
} from '~/types/models/IAddress'
import {
  IGetPhoneNumbersParams,
  IGetPhoneNumbersResponse,
  IPhoneNumber,
  IPhoneNumberFormData,
} from '~/types/models/IPhoneNumber'
import {
  IBankAccountFormData,
  IGetBankAccountsResponse,
} from '~/types/models/IBankAccount'
import {
  ICreateChatPayload,
  ICreateChatResponse,
  IGetChatsParams,
  IGetChatsResponse,
} from '~/types/models/IChat'
import {
  ICreateMessageResponse,
  IGetMessageParams,
  IGetMessagesResponse,
  IMessageFormData,
} from '~/types/models/IMessage'
import {
  IEmailFormData,
  IGetEmailsParams,
  IGetEmailsResponse,
  IUpdateEmailPayload,
} from '~/types/models/IEmail'
import {
  ICreateTagResponse,
  IGetTagsParams,
  IGetTagsResponse,
  ITag,
  ITagFormData,
  IUpdateTagResponse,
} from '~/types/models/ITag'
import { ICreateSortRankPayload } from '~/types/models/ISortRank'
import {
  ICreateStartTimePayload,
  ICreateStartTimeResponse,
  IGetStartTimeResponse,
  IGetStartTimesParams,
  IGetStartTimesResponse,
  IStartTimeFormData,
  IStartTimePutInServicePayload,
  IStartTimePutInServiceResponse,
  IStartTimeUpdateSeenAtPayload,
  IStartTimeUpdateSeenAtResponse,
  IUpdateStartTimeResponse,
} from '~/types/models/IStartTime'
import {
  ICreateShiftPayload,
  ICreateShiftResponse,
  IShiftGetListParams,
  IShiftGetListResponse,
  IUpdateShiftPayload,
  IUpdateShiftResponse,
} from '~/types/models/IShift'
import {
  IGetShiftPayRowsParams,
  IGetShiftPayRowsResponse,
} from '~/types/models/IShiftPayRow'
import {
  IGetAccessRequestsResponse,
  IUpdateAccessRequestPayload,
} from '~/types/models/IAccessRequest'
import {
  IAutoCharge,
  IAutoChargeFormData,
  ICreateAutoChargeResponse,
  IDeleteAutoChargeResponse,
  IGetAutoChargesParams,
  IGetAutoChargesResponse,
  IUpdateAutoChargePayload,
  IUpdateAutoChargeResponse,
} from '~/types/models/IAutoCharge'
import {
  IBillLine,
  IBillLineFormData,
  IGetBillLinesParams,
  IGetBillLinesResponse,
  IGetSuggestedBillLine,
  IMultipleUpdateBillLinesPayload,
  IMultipleUpdateBillLinesResponse,
  IUpdateBillLineResponse,
} from '~/types/models/IBillLine'
import {
  ICreateBreadcumbLoadPayload,
  IGetBreadcrumbLoadsResponse,
} from '~/types/models/IBreadcrumbLoad'
import {
  IBuyerSellerFormData,
  ICreateBuyerSellerResponse,
  IDeleteBuyerSellerResponse,
  IGetBuyerSellerResponse,
  IGetBuyerSellersParams,
  IGetBuyerSellersResponse,
  IUpdateBuyerSellerResponse,
} from '~/types/models/IBuyerSeller'
import {
  IBuyerSellerRegexp,
  IBuyerSellerRegexpFormData,
  ICreateBuyerSellerRegexpsResponse,
  IGetBuyerSellerRegexpsParams,
  IGetBuyerSellerRegexpsResponse,
} from '~/types/models/IBuyerSellerRegexp'
import {
  ICreateBtfstpPayload,
  ICreateBtfstpResponse,
  IDeleteBtfstpResponse,
  IGetBtfstpsParams,
  IGetBtfstpsResponse,
  IUpdateBtfstpPayload,
  IUpdateBtfstpResponse,
} from '~/types/models/IBtfstp'
import {
  IBuyerTerminalReferenceFormData,
  ICreateBuyerTerminalReferenceResponse,
  IDeleteBuyerTerminalReferenceResponse,
  IGetBuyerTerminalReferencesParams,
  IGetBuyerTerminalReferencesResponse,
  IUpdateBuyerTerminalReferenceResponse,
} from '~/types/models/IBuyerTerminalReference'
import {
  IGetOrdersParams,
  ICreateOrderResponse,
  IUpdateOrderResponse,
  IGetOrderResponse,
  IGetOrdersResponse,
  IOrderFleet,
  ICreateOrderPayload,
  IUpdateOrderParams,
  IUpdateOrderPayload,
  IDeleteOrderParams,
} from '~/types/models/IOrder'
import {
  ICompany,
  ICreateAccessRequestResponse,
  ICreateCompanyPayload,
  ICreateCompanyResponse,
  IDeleteCompanyResponse,
  IGetCompaniesParams,
  IGetCompaniesResponse,
  IGetCompanyResponse,
  IGetInitialCompanyDataResponse,
  ISendToCompanyEmailPayload,
  IToggleAccessParams,
  IToggleAccessPayload,
  IUpdateCompanyPayload,
  IUpdateCompanyResponse,
} from '~/types/models/ICompany'
import {
  IGetRegionsParams,
  IGetRegionsResponse,
  IRegionFormData,
} from '~/types/models/IRegion'
import { ERegionName } from '~/types/enums/ERegion'
import {
  ICostCodeFormData,
  ICreateCostCodeResponse,
  IDeleteCostCodeResponse,
  IGetCostCodesParams,
  IGetCostCodesResponse,
} from '~/types/models/ICostCode'
import { ICostCodeConfiguration } from '~/types/models/ICostCodeConfiguration'
import {
  ICreatePayrollResponse,
  IDeletePayrollResponse,
  IGetPayrollResponse,
  IGetPayrollsResponse,
  IPayrollFormData,
  IUpdatePayrollResponse,
} from '~/types/models/IPayroll'
import {
  ICreateDataCorrectionMethodResponse,
  IDataCorrectionMethodFormData,
  IDeleteDataCorrectionMethodResponse,
  IGetDataCorrectionMethodsParams,
  IGetDataCorrectionMethodsResponse,
  IUpdateDataCorrectionMethodResponse,
} from '~/types/models/IDataCorrectionMethod'
import {
  ICreateDriverPayload,
  ICreateDriverResponse,
  IDeleteDriverResponse,
  IDriverGetImageLoadParams,
  IDriverGetNewLoadParams,
  IDriverSearchLoadParams,
  IDriverSearchLoadResponse,
  IGetDriverResponse,
  IGetDriversNewResponse,
  IGetDriversResponse,
  IUpdateDriverPayload,
  IUpdateDriverResponse,
} from '~/types/models/IDriver'
import { sessionService } from 'redux-react-session'
import { IBreakFormData } from '~/types/models/IBreak'
import { ICodeScanFormData } from '~/types/models/ICodeScan'
import {
  ICreateDriverFleetResponse,
  IDriverFleetFormData,
  IGetDriverFleetsParams,
  IGetDriverFleetsResponse,
  IUpdateDriverFleetResponse,
} from '~/types/models/IDriverFleet'
import {
  ICreateEarningRowResponse,
  IDeleteEarningRowResponse,
  IEarningRowFormData,
  IGetEarningRowsParams,
  IGetEarningRowsResponse,
  IUpdateEarningRowResponse,
} from '~/types/models/IEarningRow'
import {
  ICreateEmailParserResponse,
  IDeleteEmailParserResponse,
  IEmailParserFormData,
  IGetEmailParsersParams,
  IGetEmailParsersResponse,
  IUpdateEmailParserResponse,
} from '~/types/models/IEmailParser'
import {
  ICreateDashboardParams,
  ICreateDashboardPayload,
  IDashboard,
} from '~/types/models/IDashboard'
import {
  ICreateDataParserResponse,
  IDataParserFormData,
  IDeleteDataParserResponse,
  IGetDataParsersParams,
  IGetDataParsersResponse,
  IUpdateDataParserResponse,
} from '~/types/models/IDataParser'
import {
  ICreateDocumentResponse,
  IDeleteDocumentResponse,
  IDocumentFormData,
  IGetDocumentResponse,
  IGetDocumentsNewResponse,
  IGetDocumentsParams,
  IGetDocumentsResponse,
  IUpdateDocumentResponse,
} from '~/types/models/IDocument'
import {
  ICreateFixedPriceResponse,
  IDeleteFixedPriceResponse,
  IFixedPriceFormData,
  IGetFixedPricesParams,
  IGetFixedPricesResponse,
  IUpdateFixedPriceResponse,
} from '~/types/models/IFixedPrice'
import {
  IGetLoadNotesParams,
  IGetLoadNotesResponse,
  ILoadNoteFormData,
} from '~/types/models/ILoadNote'
import {
  ICreateTerminalResponse,
  IDeleteTerminalResponse,
  IGetTerminalParams,
  IGetTerminalsParams,
  ITerminal,
  ITerminalFormData,
  IUpdateTerminalPayload,
  IUpdateTerminalResponse,
} from '~/types/models/ITerminal'
import {
  IGeofenceUpdatePayload,
  IGetGeofencesParams,
  IGetGeofencesResponse,
} from '~/types/models/IGeofence'
import { IGetPlantsParams, IGetPlantsResponse } from '~/types/models/IPlant'
import { IGetSilosParams, IGetSilosResponse } from '~/types/models/ISilo'
import { IGetFlagsParams, IGetFlagsResponse } from '~/types/models/IFlag'
import {
  ICreateInvoiceParams,
  ICreateInvoiceResponse,
  IEmailInvoicesCreatePayload,
  IGetInvoicesParams,
  IGetInvoicesResponse,
  IGetSentEmailsParams,
  IInvoice,
  IInvoiceFormData,
  IParseActualInvoicePayload,
  IParseActualInvoiceResponse,
  IParserInvoicePayload,
  ISentEmail,
  IUpdateInvoiceResponse,
} from '~/types/models/IInvoice'
import {
  ICreateOrderExtraResponse,
  IDeleteOrderExtraResponse,
  IGetOrderExtrasParams,
  IGetOrderExtrasResponse,
  IOrderExtraCreatePayload,
  IOrderExtraUpdatePayload,
  IUpdateOrderExtraResponse,
} from '~/types/models/IOrderExtra'
import {
  ICreateOrderExtraRangePayload,
  ICreateOrderExtraRangeResponse,
  IGetOrderExtraRangesParams,
  IGetOrderExtraRangesResponse,
  IUpdateOrderExtraRangePayload,
  IUpdateOrderExtraRangeResponse,
} from '~/types/models/IOrderExtraRange'
import {
  ICreateLocation,
  IGetLocationsParams,
  IGetLocationsResponse,
  ILocation,
  ILocationFormData,
} from '~/types/models/ILocation'
import { IGetBuyerTerminalProductScheduleParams } from '~/types/models/IBuyerTerminalProductSchedule'
import { ICoordinate, ICoordinatePayload } from '~/types/models/ICoordinate'
import { IGetReasonsResponse } from '~/types/models/IReason'
import {
  ICreateDismissedReminderPayload,
  IDeleteDismissedReminderPayload,
} from '~/types/models/IDismissedReminder'
import {
  ICreateNotePayload,
  ICreateNoteResponse,
  IGetNotesParams,
  IGetNotesResponse,
  IUpdateNotePayload,
  IUpdateNoteResponse,
} from '~/types/models/INote'
import {
  ICreateTerminalAccessPayload,
  ICreateTerminalAccessResponse,
  IGetTerminalAccessesParams,
  ITerminalAccess,
  IUpdateTerminalAccessPayload,
} from '~/types/models/ITerminalAccess'
import {
  IGetCompanyFleetsParams,
  IGetCompanyFleetsResponse,
} from '~/types/models/ICompanyFleet'
import {
  ICreateFleetRoutePayload,
  IGetFleetRoutesParams,
  IGetFleetRoutesResponse,
  IUpdateFleetRoutePayload,
} from '~/types/models/IFleetRoute'
import {
  ICreateTruckPayload,
  ICreateTruckResponse,
  IDeleteTruckResponse,
  IGetTrucksParams,
  IGetTrucksResponse,
  ITruck,
  IUpdateTruckPayload,
  IUpdateTruckResponse,
} from '~/types/models/ITruck'
import {
  ICreateFieldValidationPayload,
  ICreateFieldValidationResponse,
  IGetFieldValidationsResponse,
  IUpdateFieldValidationPayload,
  IUpdateFieldValidationResponse,
} from '~/types/models/IFieldValidation'
import {
  ICreatePreprocessingTestPayload,
  IGetPreprocessingTestsParams,
  IGetPreprocessingTestsResponse,
} from '~/types/models/IPreprocessingTest'
import {
  ICreateFleetRouteProductPayload,
  IGetFleetRouteProductsParams,
  IGetFleetRouteProductsResponse,
  IUpdateFleetRouteProductPayload,
} from '~/types/models/IFleetRouteProduct'
import {
  ICreateProcessedPaymentPayload,
  ICreateProcessedPaymentResponse,
} from '~/types/models/IProcessedPayment'
import {
  ICreateFleetLoadPayload,
  ICreateFleetLoadResponse,
  ICreateLoadParserPayload,
  ICreateLoadPayload,
  IGetLoadParams,
  IGetLoadsParams,
  IGetLoadsResponse,
  ILoad,
  ILoadBulkRerunOcrJsonParams,
  ILoadDeletePayload,
  ILoadsSearchParams,
  ILoadsSearchResponse,
  ILoadSubmitHitlPayload,
  IUpdateLoadPayload,
} from '~/types/models/ILoad'
import {
  ICreateLoadTimePayload,
  ICreateLoadTimeResponse,
  ILoadTimesGetListParams,
  ILoadTimesGetListResponse,
} from '~/types/models/ILoadTime'
import {
  ICreateLoadExtraPayload,
  ICreateLoadExtraResponse,
  IDeleteLoadExtraResponse,
  IGetLoadExtrasPayload,
  IGetLoadExtrasResponse,
  IUpdateLoadExtraPayload,
} from '~/types/models/ILoadExtra'
import { IUpdateOrderProductSchedulePayload } from '~/types/models/IOrderProductSchedule'
import {
  ICreateUserGroupResponse,
  IDeleteUserGroupResponse,
  IGetNewGroupDataResponse,
  IGetUserGroupsResponse,
  IUpdateUserGroupResponse,
  IUserGroupFormData,
} from '~/types/models/IUserGroup'
import {
  IGetTruckFleetsResponse,
  ITruckFleet,
  IUpdateTruckFleetPayload,
  IUpdateTruckFleetResponse,
} from '~/types/models/ITruckFleet'
import {
  ICreateTagLinkResponse,
  IDeleteTagLinkResponse,
  IGetTagLinksParams,
  ITagLink,
  ITagLinkFormData,
} from '~/types/models/ITagLink'
import {
  ICreateReferenceNamePayload,
  ICreateReferenceNameResponse,
  IDeleteReferenceNameResponse,
  IGetReferenceNamesParams,
  IGetReferenceNamesResponse,
  IUpdateReferenceNamePayload,
  IUpdateReferenceNameResponse,
} from '~/types/models/IReferenceName'
import {
  ICreateSalesProposalResponse,
  IDeleteSalesProposalResponse,
  IGetSalesProposalsParams,
  IGetSalesProposalsResponse,
  ISalesProposal,
  ISalesProposalFormData,
  IUpdateSalesProposalResponse,
} from '~/types/models/ISalesProposal'
import {
  ICreateRoutePayload,
  IGetRoutesParams,
  IRoute,
  IUpdateRoutePayload,
} from '~/types/models/IRoute'
import {
  ICreateProductPayload,
  ICreateProductResponse,
  IDeleteProductResponse,
  IGetProductsParams,
  IGetProductsResponse,
  IUpdateProductPayload,
  IUpdateProductResponse,
} from '~/types/models/IProduct'
import {
  IDeletePriceEscalationResponse,
  IGetPriceEscalationsParams,
  IGetPriceEscalationsResponse,
  IMultipleUpdatePriceEscalationsPayload,
  IPriceEscalationFormData,
} from '~/types/models/IPriceEscalation'
import {
  IUserTableConfigurationFormData,
  IGetUserTableConfigurationsParams,
  IUpdateUserTableConfigurationParams,
} from '~/types/models/IUserTableConfiguration'
import {
  ICreateParserColumnMappingPayload,
  ICreateParserColumnMappingResponse,
  IDeleteParserColumnMappingResponse,
  IGetParserColumnMappingsParams,
  IGetParserColumnMappingsResponse,
  IUpdateParserColumnMappingPayload,
  IUpdateParserColumnMappingResponse,
} from '~/types/models/IParserColumnMapping'
import {
  ICreateCheckPayrollItemPayload,
  ICreateCheckPayrollItemResponse,
  IDeleteCheckPayrollItemResponse,
  IGetCheckPayrollItemsParams,
  IGetCheckPayrollItemsResponse,
  IUpdateCheckPayrollItemPayload,
  IUpdateCheckPayrollItemResponse,
} from '~/types/models/ICheckPayrollItem'
import {
  IGetCheckSetupCompanyResponse,
  ICreateCheckEmployeeParams,
  ICreateCheckEmployeePayload,
  ICreateCheckCompanyLinkPayload,
  ICreateCheckCompanyLinkResponse,
  IGetCheckSetupTerminalResponse,
  IGetCheckSetupTerminalParams,
  ICreateCheckTerminalPayload,
  ICreateCheckTerminalParams,
  IUpdateCheckTerminalParams,
  ICreateCheckPayrollPayload,
  IPreviewCheckPayrollParams,
  IReopenCheckPayrollPayload,
  IGetPayrollPaperParams,
  IGetPayrollCashRequirementParams,
  IApprovePayrollPayload,
  ICreateWorkerLinkPayload,
  ICreateWorkerLinkResponse,
  ICreateNetPaySplitPayload,
  ICreateCheckBankAccountPayload,
  ICreateCheckPaySchedulePayload,
  IUpdateCheckPaySchedulePayload,
  IGetCheckEarningCodesParams,
  ICreateCheckEarningCodePayload,
  IRetryCheckPayment,
  IRefundCheckPayment,
  IGetCheckEarningRatesParams,
  ICheckEarningRateFormData,
  IUpdateCheckEarningRatePayload,
  IGetCheckSignatoriesParams,
  ICheckSetupWorkerParams,
  IGetCheckNetPaySplitsResponse,
  IGetCheckBankAccountsResponse,
  ICheckNetPaySplit,
  ICheckBankAccount,
  IGetCheckPayScheduleParams,
  ICreateCompanyFillingAuthorizationPayload,
  ICreateCompanyFillingAuthorizationResponse,
  ICreateRunPayrollParams,
  ICreateRunPayrollResponse,
  IGetCheckSignatoriesResponse,
  ICreateCheckSignatoryPayload,
  ICheckSignatory,
  ICheckCreateWorkerPayload,
  ICheckUpdateWorkerPayload,
  IGetCheckBankAccountsPayload,
  ICheckEnrollmentProfile,
  IUpdateCheckEnrollmentProfilePayload,
  IGetAppliedForIdsResponse,
  IGetRetrieveWorkerReciprocityElectionsParams,
  IGetWorkerCompanyAttributesParams,
  IGetWorkerCompanyAttributesResponse,
  IUpdateWorkerCompanyAttributesPayload,
  IUpdateWorkerReciprocityElectionPayload,
  ICheckUsageOptionsGetResponse,
  ICheckAccessesGetResponse,
  ICheckUsageSummariesGetParams,
  ICheckUsageRecordsGetParams,
  ICheckUsageRecordsGetResponse,
  ICheckUsageSummariesGetResponse,
} from '~/types/models/ICheck'
import {
  IFuseCreateLinkTokenPayload,
  IFuseCreateLinkTokenResponse,
  IFuseCreateSessionResponse,
  IFuseExchangePublicTokenPayload,
} from '~/types/models/IFuse'
import {
  IGetParsedEmailResponse,
  IGetParsedEmailsParams,
  IGetParsedEmailsResponse,
  IUpdateParsedEmailPayload,
} from '~/types/models/IParsedEmail'
import {
  ICreateFlagableConfigurationResponse,
  IFlagableConfigurationFormData,
  IUpdateFlagableConfigurationResponse,
  IFlagableConfiguration,
  IDeleteFlagableConfigurationResponse,
} from '~/types/models/IFlagableConfiguration'
import {
  ICreateCreditReferenceResponse,
  ICreditReferenceFormData,
} from '~/types/models/ICreditReference'
import { IPersonLinkFormData } from '~/types/models/IPersonLink'
import {
  ICreatePersonalGuarantee,
  IPersonalGuaranteeFormData,
} from '~/types/models/IPersonalGuarantee'
import {
  ICreateMockScheduleLoadPayload,
  ICreateMockScheduleLoadResponse,
  IGetMockScheduleLoadsParams,
  IGetMockScheduleLoadsPayload,
  IGetScheduleLoadsParams,
  IGetScheduleLoadsResponse,
  IUpdateScheduleLoadFormData,
  IUpdateScheduleLoadResponse,
} from '~/types/models/IScheduleLoad'
import {
  IGetSchedulesParams,
  IGetSchedulesResponse,
  ISchedule,
  IUpdateSchedulePayload,
  IUpdateScheduleResponse,
} from '~/types/models/ISchedule'
import { ICreateUomPayload, IGetUomsResponse, IUom } from '~/types/models/IUoms'
import {
  ICreateEventPayload,
  IEvent,
  IGetEventsParams,
  IGetEventsResponse,
  IUpdateEventPayload,
} from '~/types/models/IEvent'
import {
  ICreateOffdayPayload,
  ICreateOffdayResponse,
  IGetOffdaysParams,
  IGetOffdaysResponse,
  IOffday,
  IUpdateOffdayPayload,
  IUpdateOffdayResponse,
} from '~/types/models/IOffday'
import {
  ICreateAllowedHierarchyPayload,
  ICreateAllowedHierarchyResponse,
  IGetAllowedHierarchiesNewResponse,
  IGetAllowedHierarchiesParams,
  IGetAllowedHierarchiesResponse,
  IGetReorderAllowedHierarchies,
  IUpdateAllowedHierarchyPayload,
  IUpdateAllowedHierarchyResponse,
} from '~/types/models/IAllowedHierarchy'
import {
  ICreateGlCodePayload,
  ICreateGlCodeResponse,
  IGetGlCodesResponse,
  IUpdateGlCodePayload,
  IUpdateGlCodeResponse,
} from '~/types/models/IGlCode'
import { MAP_PRODUCT_GROUP_ORDER_TYPE } from '~/utils/constants'
import {
  IGetEarningCodeParams,
  IGetEarningCodeResponse,
} from '~/types/models/IEarningCode'
import {
  ICreateEarningRatePayload,
  IGetEarningRateParams,
  IGetEarningRateResponse,
  IUpdateEarningRatePayload,
} from '~/types/models/IEarningRate'
import { IGetSensorParams } from '~/types/models/ISensor'
import {
  ICreatePaymentTypePayload,
  IGetPaymentTypesResponse,
  IUpdatePaymentTypePayload,
} from '~/types/models/IPaymentType'
import {
  ICreatePaymentParams,
  ICreatePaymentPayload,
  ICreatePaymentResponse,
  IGetPaymentsParams,
  IGetPaymentsResponse,
  IPayment,
  IUpdatePaymentPayload,
  IUpdatePaymentResponse,
} from '~/types/models/IPayment'
import {
  ICreateAdjustmentNotePayload,
  ICreateAdjustmentNoteResponse,
  IGetAdjustmentNotesParams,
  IGetAdjustmentNotesResponse,
  IUpdateAdjustmentNotePayload,
  IUpdateAdjustmentNoteResponse,
} from '~/types/models/IAdjustmentNote'
import {
  ICreatePaymentPayablePayload,
  ICreatePaymentPayableResponse,
  IGetPaymentPayableResponse,
  IGetPaymentPayablesParams,
} from '~/types/models/IPaymentPayable'
import {
  ICreatePaymentSchedulePayload,
  IGetNewPaymentSchedulesPayload,
  IGetNewPaymentSchedulesResponse,
} from '~/types/models/IPaymentSchedule'
import {
  ICreateHierarchyPayload,
  ICreateHierarchyResponse,
  IGetHierarchiesParams,
  IGetHierarchiesResponse,
  IGetReorderHierarchies,
  IUpdateHierarchyPayload,
} from '~/types/models/IHierarchy'
import { ITerminalGroup } from '~/types/models/ITerminalGroup'
import { IMarket } from '~/types/models/IMarket'
import { IMinorMarket } from '~/types/models/IMinorMarket'
import { IGetAreasResponse } from '~/types/models/IArea'
import { IGetZonesResponse } from '~/types/models/IZone'
import {
  ICreateHierarchyRowPayload,
  IGetHierarchyRowsParams,
  IGetHierarchyRowsResponse,
  IGetReorderHierarchyRows,
  IHierarchyRowCreateResponse,
  IHierarchyRowUpdateResponse,
  IUpdateHierarchyRowPayload,
} from '~/types/models/IHierarchyRow'
import {
  ICreateAutoExtraPayload,
  ICreateAutoExtraResponse,
  IGetAutoExtrasParams,
  IGetAutoExtrasResponse,
  IUpdateAutoExtraPayload,
  IUpdateAutoExtraResponse,
} from '~/types/models/IAutoExtra'
import {
  ICreatePricingPayload,
  ICreatePricingResponse,
  IGetPricingsParams,
  IGetPricingsResponse,
  IPricing,
  IUpdatePricingPayload,
} from '~/types/models/IPricing'
import {
  ICreateProductGlCodePayload,
  ICreateProductGlCodeResponse,
  IGetProductGlCodesParams,
  IGetProductGlCodesResponse,
  IUpdateProductGlCodePayload,
  IUpdateProductGlCodeResponse,
} from '~/types/models/IProductGlCode'
import {
  ICreateTaxPayload,
  ICreateTaxResponse,
  IGetTaxesParams,
  IGetTaxesResponse,
  IUpdateTaxPayload,
  IUpdateTaxResponse,
} from '~/types/models/ITax'
import {
  ICreateProductTaxOverridePayload,
  ICreateProductTaxOverrideResponse,
  IGetProductTaxOverridesParams,
  IGetProductTaxOverridesResponse,
  IUpdateProductTaxOverridePayload,
  IUpdateProductTaxOverrideResponse,
} from '~/types/models/IProductTaxOverride'
import {
  ICreatePaymentTermPayload,
  ICreatePaymentTermResponse,
  IGetPaymentTermsParams,
  IGetPaymentTermsResponse,
  IUpdatePaymentTermPayload,
  IUpdatePaymentTermResponse,
} from '~/types/models/IPaymentTerm'
import {
  ICreateSettingPayload,
  ICreateSettingResponse,
  IGetSettingNewValuesResponse,
  IGetSettingsParams,
  IGetSettingsResponse,
  IUpdateSettingPayload,
  IUpdateSettingResponse,
} from '~/types/models/ISetting'
import {
  ICreateBundlePayload,
  ICreateBundleResponse,
  IGetBundlesParams,
  IGetBundlesResponse,
  IUpdateBundlePayload,
  IUpdateBundleResponse,
} from '~/types/models/IBundle'
import {
  ICreateBundleItemPayload,
  ICreateBundleItemResponse,
  IGetBundleItemsParams,
  IGetBundleItemsResponse,
  IUpdateBundleItemPayload,
  IUpdateBundleItemResponse,
} from '~/types/models/IBundleItem'
import { IRerankPayload } from '~/types/models/ICommonModel'
import { ICreateCOSAccountPayload } from '~/types/models/ICapitalOS'
import {
  ICreateCampaignPayload,
  ICreateCampaignResponse,
  IGetCampaignsParams,
  IGetCampaignsResponse,
  IUpdateCampaignPayload,
  IUpdateCampaignResponse,
} from '~/types/models/ICampaign'
import {
  ICalculation,
  ICreateCalculationPayload,
  ICreateCalculationResponse,
  IGetCalculationParams,
  IGetCalculationsResponse,
  IGetNewCalculationResponse,
  IUpdateCalculationPayload,
  IUpdateCalculationResponse,
} from '~/types/models/ICalculation'
import {
  ICreateRewardSettingPayload,
  ICreateRewardSettingResponse,
  IGetRewardSettingsResponse,
  IUpdateRewardSettingPayload,
  IUpdateRewardSettingResponse,
} from '~/types/models/IRewardSetting'
import {
  ICreatePrizePayload,
  ICreatePrizeResponse,
  IGetPrizesParams,
  IGetPrizesResponse,
  IUpdatePrizePayload,
  IUpdatePrizeResponse,
} from '~/types/models/IPrize'
import {
  ICreateContestPayload,
  ICreateContestResponse,
  IGetContestsParams,
  IGetContestsResponse,
  IUpdateContestPayload,
  IUpdateContestResponse,
} from '~/types/models/IContest'
import {
  ICreateRewardPayload,
  ICreateRewardResponse,
  IGetRewardsParams,
  IGetRewardsResponse,
  IUpdateRewardPayload,
  IUpdateRewardResponse,
} from '~/types/models/IReward'
import {
  ICreateMixDetailPayload,
  ICreateMixDetailResponse,
  IGetMixDetailsParams,
  IGetMixDetailResponse,
  IUpdateMixDetailPayload,
  IUpdateMixDetailResponse,
} from '~/types/models/IMixDetail'
import {
  ICreateRedemptionPayload,
  ICreateRedemptionResponse,
  IGetRedemptionsParams,
  IGetRedemptionsResponse,
} from '~/types/models/IRedemption'
import {
  IStripeCreateAccountPayload,
  IStripeCreateAccountResponse,
  IStripeCreateAccountSessionPayload,
  IStripeCreatePaymentIntentPayload,
  IStripeCreatePaymentIntentResponse,
  IStripeCustomerCreatePayload,
  IStripeGetAccountResponse,
  IStripePaymentMethodGetListParams,
  IStripePaymentMethodGetListResponse,
  IStripeSetupIntentPayload,
  IStripeSetupIntentResponse,
  IStripeSetupIntentSucceededPayload,
} from '~/types/models/IStripe'
import {
  ICreateGroupPayload,
  ICreateGroupResponse,
  IGetGroupsParams,
  IGetGroupsResponse,
  IUpdateGroupPayload,
  IUpdateGroupResponse,
} from '~/types/models/IGroup'
import { IGetProductTypesResponse } from '~/types/models/IProductType'
import {
  ISellerToBuyerTerminalCreatePayload,
  ISellerToBuyerTerminalCreateResponse,
  ISellerToBuyerTerminalGetListParams,
  ISellerToBuyerTerminalGetListResponse,
} from '~/types/models/ISellerToBuyerTerminal'
import {
  IWorkTypeCreatePayload,
  IWorkTypeCreateResponse,
  IWorkTypeGetListResponse,
  IWorkTypeUpdatePayload,
  IWorkTypeUpdateResponse,
} from '~/types/models/IWorkType'
import {
  IWorkForceCreatePayload,
  IWorkForceCreateResponse,
  IWorkForceGetListResponse,
  IWorkForceUpdatePayload,
  IWorkForceUpdateResponse,
} from '~/types/models/IWorkForce'
import { IGroupTerminalGetList } from '~/types/models/IGroupTerminal'
import {
  ITestResultCreatePayload,
  ITestResultCreateResponse,
  ITestResultGetParams,
  ITestResultGetResponse,
  ITestResultNewResponse,
  ITestResultUpdatePayload,
  ITestResultUpdateResponse,
} from '~/types/models/ITestResult'
import {
  ILoadStatusTableRowGetParams,
  ILoadStatusTableRowGetResponse,
} from '~/types/models/ILoadStatus'
import {
  IWeightGetListParams,
  IWeightGetListResponse,
} from '~/types/models/IWeight'
import {
  IUploadCreatePayload,
  IUploadCreateResponse,
  IUploadGetListParams,
  IUploadGetListResponse,
} from '~/types/models/IUpload'
import {
  ICreateStatementPayload,
  ICreateStatementResponse,
  IGetStatementsResponse,
} from '~/types/models/IStatement'
// import { EPhoneableType } from '~/types/enums/EPhoneNumber'

export interface ApiClientRequestOption<Query = any> {
  baseUrl: string
  query: Query
  headers: Record<string, string>
  isCsv: boolean
  isFormData: boolean
}

class ApiClient {
  private api: Api

  constructor() {
    this.api = new Api()
  }

  private get<Response = any, Query = any>(
    endpoint: string,
    options: Partial<ApiClientRequestOption<Query>> = {},
  ) {
    const { query, baseUrl, headers, isCsv } = options
    const url = buildGetUrl(endpoint, query)
    if (isCsv) {
      return this.api.getCsv(url, baseUrl, '') as Promise<Response>
    }
    return this.api.get(url, baseUrl, '', headers) as Promise<Response>
  }

  private post<Response = any, Body = any, Query = any>(
    endpoint: string,
    body?: Body,
    options: Partial<ApiClientRequestOption<Query>> = {},
  ) {
    const { query, baseUrl, isFormData } = options
    const url = buildGetUrl(endpoint, query)
    if (isFormData) {
      return this.api.postFormData(url, body, baseUrl) as Promise<Response>
    }
    return this.api.post(url, body, baseUrl) as Promise<Response>
  }

  private put<Response = any, Body = any, Query = any>(
    endpoint: string,
    body: Body,
    options: Partial<ApiClientRequestOption<Query>> = {},
  ) {
    const { query, baseUrl } = options
    const url = buildGetUrl(endpoint, query)
    return this.api.put(url, body, baseUrl) as Promise<Response>
  }

  private patch<Response = any, Body = any, Query = any>(
    endpoint: string,
    body?: Body,
    options: Partial<ApiClientRequestOption<Query>> = {},
  ) {
    const { query, baseUrl } = options
    const url = buildGetUrl(endpoint, query)
    return this.api.patch(url, body, baseUrl) as Promise<Response>
  }

  private delete<Response = any, Body = any, Query = any>(
    endpoint: string,
    body?: Body,
    options: Partial<ApiClientRequestOption<Query>> = {},
  ) {
    const { query, baseUrl } = options
    const url = buildGetUrl(endpoint, query)
    return this.api.delete(url, body, baseUrl) as Promise<Response>
  }

  get sellerProducts() {
    const endpoint = '/seller_products'

    return {
      endpoint,
      get: (query?: IGetSellerProductQueryParams) =>
        this.get<IGetSellerProductResponse>(endpoint, { query }),
      getById: (id: number) => this.get(`${endpoint}/${id}`),
      create: (sellerProduct: ISellerProductFormValues) =>
        this.post<ICreateSellerProductResponse>(endpoint, { sellerProduct }),
      update: (id: number, sellerProduct: ISellerProductFormValues) =>
        this.patch<IUpdateSellerProductResponse>(`${endpoint}/${id}`, {
          sellerProduct,
        }),
      delete: (id: number, replacementId?: number) =>
        this.delete<IDeleteSellerProductResponse>(
          `${endpoint}/${id}`,
          undefined,
          {
            query: { replacementId },
          },
        ),
    }
  }

  get sellerTerminalProducts() {
    const endpoint = '/seller_terminal_products'
    return {
      get: (query: IGetSellerTerminalProduct) =>
        this.get<IGetSellerTerminalProductResponse>(endpoint, { query }),
      create: (payload: ICreateSellerTerminalProductPayload) =>
        this.post<ICreateSellerTerminalProductResponse>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      update: (
        id: number,
        payload: Partial<IUpdateSellerTerminalProductPayload>,
      ) =>
        this.patch<IUpdateSellerTerminalProductResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
    }
  }

  get productGroups() {
    const endpoint = '/product_groups'
    return {
      get: () => this.get<IProductGroup[]>(endpoint),
      create: async (data: ICreateProductGroupPayload) => {
        const response = await this.post<ICreateProductGroupResponse>(
          endpoint,
          data,
        )
        response.productGroup.orderType = (MAP_PRODUCT_GROUP_ORDER_TYPE as any)[
          response.productGroup.orderType
        ]
        return response
      },
      update: async (id: number, data: Partial<IProductGroupFormData>) => {
        const response = await this.patch<IUpdateProductGroupResponse>(
          `${endpoint}/${id}`,
          data,
        )
        response.productGroup.orderType = (MAP_PRODUCT_GROUP_ORDER_TYPE as any)[
          response.productGroup.orderType
        ]
        return response
      },
      delete: (id: number) =>
        this.delete<IDeleteProductGroupResponse>(`${endpoint}/${id}`),
    }
  }

  get workPayTypes() {
    const endpoint = '/work_pay_types'
    return {
      get: () => this.get<IGetWorkPayTypesResponse>(endpoint),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      update: (id: number, data: Partial<IWorkPayTypeFormValues>) =>
        this.put<ICreateWorkPayTypeResponse>(`${endpoint}/${id}`, data),
      create: (data: IWorkPayTypeFormValues) =>
        this.post<ICreateWorkPayTypeResponse>(endpoint, data),
    }
  }

  get workPayRules() {
    const endpoint = '/work_pay_rules'
    return {
      endpoint,
      get: (query: Partial<IGetWorkPayRulesQueryParams> = {}) =>
        this.get<IGetWorkPayRulesResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (data: IWorkPayTypeFormValues) =>
        this.post<ICreateWorkPayRuleResponse>(endpoint, data),
      update: (id: number, data: Partial<Omit<IWorkPayTypeFormValues, 'id'>>) =>
        this.put<ICreateWorkPayRuleResponse>(`${endpoint}/${id}`, data),
    }
  }

  get buyerSellerProducts() {
    const endpoint = '/buyer_seller_products'

    return {
      endpoint,
      get: (query?: IGetBuyerSellerProductsParams) =>
        this.get<IGetBuyerSellerProductsResponse>(endpoint, { query }),
      create: (
        payload: ICreateBuyerSellerProductPayload,
        options?: Partial<ApiClientRequestOption>,
      ) =>
        this.post<ICreateBuyerSellerProductResponse>(
          endpoint,
          payload,
          options,
        ),
      getById: (id: number) =>
        this.get<IGetBuyerSellerProductResponse>(`${endpoint}/${id}`),
      update: (
        id: number,
        buyerSellerProduct: Partial<IBuyerSellerProductFormValues>,
      ) =>
        this.patch<IUpdateBuyerSellerProductResponse>(`${endpoint}/${id}`, {
          buyerSellerProduct,
        }),
      delete: (id: number, replacementId?: number) =>
        this.delete(`${endpoint}/${id}`, undefined, {
          query: { replacementId },
        }),
    }
  }

  get workers() {
    const endpoint = '/workers'

    return {
      endpoint,
      get: (query?: Partial<IGetWorkersParams>) =>
        this.get<IGetWorkersResponse>(endpoint, { query }),
      update: (id: number, data: Partial<IWorkerFormData>) =>
        this.put<IWorker>(`${endpoint}/${id}`, { worker: data }),
      create: (worker: ICreateWorkerPayload) => this.post(endpoint, { worker }),
      getById: (id: number) => this.get<IWorker>(`${endpoint}/${id}`),
      delete: (id: number) => this.delete<IWorker>(`${endpoint}/${id}`),
    }
  }

  get salesProposals() {
    const endpoint = '/sales_proposals'
    return {
      endpoint,
      get: (query?: Partial<IGetSalesProposalsParams>) =>
        this.get<IGetSalesProposalsResponse>(endpoint, { query }),
      getById: (id: number) => this.get<ISalesProposal>(`${endpoint}/${id}`),
      create: (payload: ISalesProposalFormData) =>
        this.post<ICreateSalesProposalResponse>(endpoint, payload),
      update: (id: number, payload: Partial<ISalesProposalFormData>) =>
        this.patch<IUpdateSalesProposalResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteSalesProposalResponse>(`${endpoint}/${id}`),
    }
  }

  get users() {
    const endpoint = '/users'

    return {
      endpoint,
      get: (query?: IGetUserParams) => this.get<IUser[]>(endpoint, { query }),
      getById: (id: number) => this.get<IUser[]>(`${endpoint}/${id}`),
      update: (id: number, payload: IUpdateUserPayload) =>
        this.patch<IUpdateUserResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteUserResponse>(`${endpoint}/${id}`),

      signIn: (user: ISignInPayload) =>
        this.post<IUser>(`${endpoint}/sign_in`, { user }),
      signUp: (payload: ISignUpPayload) => this.post<IUser>(endpoint, payload),
      signOut: () => this.delete(`${endpoint}/sign_out`),

      forgotPassword: (payload: IForgotPasswordPayload) =>
        this.post(`${endpoint}/password`, payload),
      updatePassword: (user: IUpdatePasswordPayload) =>
        this.patch(`${endpoint}/password`, { user }),

      getAccessables: () => this.get<IUser>(`${endpoint}/accessables`),
      getAccountInfo: () => this.get<IUser>(`${endpoint}/my_account`),
      updateAccountInfo: (payload: IUserUpdateAccountPayload) =>
        this.put(`${endpoint}/update_my_account`, payload),
    }
  }

  get user() {
    const endpoint = '/user'
    return {
      create: (user: ICreateUserPayload) =>
        this.post<ICreateUserResponse>(`${endpoint}/create_user`, user),
    }
  }

  get userAccesses() {
    const endpoint = '/user_accesses'
    return {
      endpoint,
      get: (query?: Partial<IGetUserAcessParams>) =>
        this.get<IGetUserAccessesResponse>(endpoint, { query }),
      create: (payload: IUserAccessFormData) =>
        this.post<ICreateUserAccessResponse>(endpoint, payload),
      update: (id: number, payload: Partial<IUserAccessFormData>) =>
        this.patch<IUpdateUserAccessResponse>(`${endpoint}/${id}`, {
          userAccess: payload,
        }),
      getById: (id: number) =>
        this.get<{ userAccess: IUserAccess }>(`${endpoint}/${id}`),
    }
  }

  get people() {
    const endpoint = '/people'
    return {
      endpoint,
      get: (query?: IGetPeopleParams) =>
        this.get<IGetPeopleResponse>(endpoint, { query }),
      create: (payload: IPerson) =>
        this.post<ICreatePersonResponse>(`${endpoint}`, payload),
      update: (id: number, payload: IUpdatePersonPayload) =>
        this.patch<IUpdatePersonResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get addresses() {
    const endpoint = '/addresses'
    return {
      endpoint,
      get: (query: IGetAddressesParams) =>
        this.get<IGetAddressesResponse>(endpoint, { query }),
      create: (address: IAddressFormData) =>
        this.post<ICreateAddressResponse>(endpoint, { address }),
      update: (id: number, address: Partial<IAddressFormData>) =>
        this.put<IUpdateAddressResponse>(`${endpoint}/${id}`, { address }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      new: (query: IAddressGetNewListParams) =>
        this.get<IAddressGetNewListResponse>(`${endpoint}/new`, { query }),
    }
  }

  get phoneNumbers() {
    const endpoint = '/phone_numbers'
    return {
      endpoint,
      get: (query?: IGetPhoneNumbersParams) =>
        this.get<IGetPhoneNumbersResponse>(endpoint, {
          query,
        }),
      update: (id: number, phoneNumber: Partial<IPhoneNumberFormData>) =>
        this.patch<IPhoneNumber>(`${endpoint}/${id}`, { phoneNumber }),
      create: (phoneNumber: IPhoneNumberFormData) =>
        this.post(endpoint, { phoneNumber }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get bankAccounts() {
    const endpoint = '/bank_accounts'
    return {
      endpoint,
      get: () => this.get<IGetBankAccountsResponse>(endpoint),
      create: (bankAccount: IBankAccountFormData) =>
        this.post(endpoint, { bankAccount }),
      update: (id: number, bankAccount: Partial<IBankAccountFormData>) =>
        this.patch(`${endpoint}/${id}`, { bankAccount }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get chats() {
    const endpoint = '/chats'
    return {
      get: (query?: IGetChatsParams) =>
        this.get<IGetChatsResponse>(endpoint, { query }),
      create: (payload: ICreateChatPayload) =>
        this.post<ICreateChatResponse>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),

      getMessages: (chatId: number, query?: IGetMessageParams) =>
        this.get<IGetMessagesResponse>(`/${endpoint}/${chatId}/messages`, {
          query,
        }),
      createMessage: (chatId: number, message: IMessageFormData) =>
        this.post<ICreateMessageResponse>(`${endpoint}/${chatId}/messages`, {
          message,
        }),
      updateMessage: (
        chatId: number,
        messageId: number,
        message: Partial<IMessageFormData>,
      ) =>
        this.patch(`${endpoint}/${chatId}/messages/${messageId}`, { message }),
    }
  }

  get emails() {
    const endpoint = '/emails'
    return {
      endpoint,
      get: (query?: IGetEmailsParams, options?: ApiClientRequestOption) =>
        this.get<IGetEmailsResponse>(endpoint, { query, ...options }),
      getById: (
        id: number,
        query?: IGetEmailsParams,
        options?: ApiClientRequestOption,
      ) =>
        this.get<IGetEmailsResponse>(`${endpoint}/${id}`, {
          query,
          ...options,
        }),
      create: (email: IEmailFormData) => this.post(endpoint, { email }),
      update: (
        id: number,
        payload: IUpdateEmailPayload,
        options?: ApiClientRequestOption,
      ) => this.patch(`${endpoint}/${id}`, payload, options),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get tags() {
    const endpoint = '/tags'
    return {
      endpoint,
      get: (query: IGetTagsParams) =>
        this.get<IGetTagsResponse>(endpoint, { query }),
      create: (tag: ITagFormData) =>
        this.post<ICreateTagResponse>(endpoint, tag),
      update: (id: number, tag: Partial<ITagFormData>) =>
        this.patch<IUpdateTagResponse>(`${endpoint}/${id}`, tag),
      delete: (id: number) => this.delete<ITag>(`${endpoint}/${id}`),
    }
  }

  get sortRank() {
    const endpoint = '/sort_rank'
    return {
      create: (data: ICreateSortRankPayload) => this.post(endpoint, data),
    }
  }

  get startTimes() {
    const endpoint = '/start_times'
    return {
      endpoint,
      get: (query?: Partial<IGetStartTimesParams>) =>
        this.get<IGetStartTimesResponse>(endpoint, { query }),
      create: (startTimes: ICreateStartTimePayload) =>
        this.post<ICreateStartTimeResponse>(endpoint, { startTimes }),
      update: (id: number, startTime: Partial<IStartTimeFormData>) =>
        this.patch<IUpdateStartTimeResponse>(`${endpoint}/${id}`, {
          startTime,
        }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      getById: (id: number) =>
        this.get<IGetStartTimeResponse>(`${endpoint}/${id}`),
    }
  }

  get startTime() {
    const endpoint = '/start_time'
    return {
      putInService: (data: IStartTimePutInServicePayload) =>
        this.patch<IStartTimePutInServiceResponse>(
          `${endpoint}/put_in_service`,
          data,
        ),
      updateSeenAt: (data: IStartTimeUpdateSeenAtPayload) =>
        this.patch<IStartTimeUpdateSeenAtResponse>(
          `${endpoint}/update_seen_at`,
          data,
        ),
    }
  }

  get shifts() {
    const endpoint = '/shifts'
    return {
      endpoint,
      get: (query?: IShiftGetListParams) =>
        this.get<IShiftGetListResponse>(endpoint, { query }),
      update: (id: number, payload: IUpdateShiftPayload) =>
        this.patch<IUpdateShiftResponse>(`${endpoint}/${id}`, payload),
      create: (payload: ICreateShiftPayload) =>
        this.post<ICreateShiftResponse>(endpoint, payload),
    }
  }

  get shiftPayRows() {
    const endpoint = '/shift_pay_rows'
    return {
      endpoint,
      get: (query?: IGetShiftPayRowsParams) =>
        this.get<IGetShiftPayRowsResponse>(endpoint, { query }),
    }
  }

  get accessRequests() {
    const endpoint = '/access_requests'
    return {
      get: () => this.get<IGetAccessRequestsResponse>(endpoint),
      update: (id: number, data: Partial<IUpdateAccessRequestPayload>) =>
        this.put(`${endpoint}/${id}`, data),
    }
  }

  get autoCharges() {
    const endpoint = '/auto_charges'
    return {
      endpoint,
      get: (query: IGetAutoChargesParams) =>
        this.get<IGetAutoChargesResponse>(endpoint, { query }),
      create: (autoCharge: IAutoChargeFormData) =>
        this.post<ICreateAutoChargeResponse>(endpoint, { autoCharge }),
      update: (id: number, payload: IUpdateAutoChargePayload) =>
        this.patch<IUpdateAutoChargeResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteAutoChargeResponse>(`${endpoint}/${id}`),
      getById: (id: number) => this.get<IAutoCharge>(`${endpoint}/${id}`),
    }
  }

  get billLines() {
    const endpoint = '/bill_lines'
    return {
      endpoint,
      get: (query?: IGetBillLinesParams) =>
        this.get<IGetBillLinesResponse>(endpoint, { query }),
      getById: (id: number) => this.get<IBillLine>(`${endpoint}/${id}`),
      update: (id: number, data: Partial<IBillLineFormData>) =>
        this.put<IUpdateBillLineResponse>(`${endpoint}/${id}`, data),
      delete: (id: number, payload?: { runInline?: boolean }) =>
        this.delete(`${endpoint}/${id}`, payload),
      multipleUpdate: (data: IMultipleUpdateBillLinesPayload) =>
        this.patch<IMultipleUpdateBillLinesResponse>(
          `${endpoint}/update_bill_lines`,
          data,
        ),
      getSuggestMatch: (id: number) =>
        this.get<IGetSuggestedBillLine>(`${endpoint}/${id}/edit`),
    }
  }

  get breadcrumbLoads() {
    const endpoint = '/breadcrumb_loads'
    return {
      get: (loadId: number) =>
        this.get<IGetBreadcrumbLoadsResponse>(`/loads/${loadId}/${endpoint}`),
      create: (payload: ICreateBreadcumbLoadPayload) =>
        this.post(`${endpoint}`, payload),
    }
  }

  get buyerSellers() {
    const endpoint = '/buyer_sellers'

    return {
      endpoint,
      get: (query?: Partial<IGetBuyerSellersParams>) =>
        this.get<IGetBuyerSellersResponse>(endpoint, { query }),
      getById: (id: number) =>
        this.get<IGetBuyerSellerResponse>(`${endpoint}/${id}`),
      create: (buyerSeller: IBuyerSellerFormData) =>
        this.post<ICreateBuyerSellerResponse>(endpoint, { buyerSeller }),
      delete: (id: number) =>
        this.delete<IDeleteBuyerSellerResponse>(`${endpoint}/${id}`),
      update: (id: number, buyerSeller: Partial<IBuyerSellerFormData>) =>
        this.patch<IUpdateBuyerSellerResponse>(`${endpoint}/${id}`, {
          buyerSeller,
        }),
    }
  }

  get buyerSellerRegexps() {
    const endpoint = '/buyer_seller_regexps'

    return {
      get: (query?: IGetBuyerSellerRegexpsParams) =>
        this.get<IGetBuyerSellerRegexpsResponse>(endpoint, { query }),
      getById: (id: number) =>
        this.get<IBuyerSellerRegexp>(`${endpoint}/${id}`),
      create: (payload: IBuyerSellerRegexpFormData) =>
        this.post<ICreateBuyerSellerRegexpsResponse>(endpoint, payload),
      update: (id: number, payload: Partial<IBuyerSellerRegexpFormData>) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      new: () => this.get(`${endpoint}/new`),
    }
  }

  get btfstps() {
    const endpoint = '/buyer_terminal_from_seller_terminal_products'
    return {
      get: (query?: IGetBtfstpsParams) =>
        this.get<IGetBtfstpsResponse>(endpoint, { query }),
      create: (payload: ICreateBtfstpPayload) =>
        this.post<ICreateBtfstpResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateBtfstpPayload) =>
        this.patch<IUpdateBtfstpResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteBtfstpResponse>(`${endpoint}/${id}`),
    }
  }

  get buyerTerminalReferences() {
    const endpoint = '/buyer_terminal_references'
    return {
      get: (query?: IGetBuyerTerminalReferencesParams) =>
        this.get<IGetBuyerTerminalReferencesResponse>(endpoint, { query }),
      create: (payload: IBuyerTerminalReferenceFormData) =>
        this.post<ICreateBuyerTerminalReferenceResponse>(endpoint, payload),
      update: (id: number, payload: Partial<IBuyerTerminalReferenceFormData>) =>
        this.patch<IUpdateBuyerTerminalReferenceResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) =>
        this.delete<IDeleteBuyerTerminalReferenceResponse>(`${endpoint}/${id}`),
    }
  }

  get orders() {
    const endpoint = '/orders'
    return {
      endpoint,
      get: (query?: IGetOrdersParams) =>
        this.get<IGetOrdersResponse>(endpoint, { query }),
      create: (payload: ICreateOrderPayload) =>
        this.post<ICreateOrderResponse>(endpoint, payload),
      update: (
        id: number,
        payload: IUpdateOrderPayload,
        query?: IUpdateOrderParams,
      ) =>
        this.patch<IUpdateOrderResponse>(`${endpoint}/${id}`, payload, {
          query,
        }),
      getById: (id: number) => this.get<IGetOrderResponse>(`${endpoint}/${id}`),
      delete: (id: number, query?: IDeleteOrderParams) =>
        this.delete(`${endpoint}/${id}`, undefined, { query }),
    }
  }

  get orderFleets() {
    const endpoint = '/order_fleets'
    return {
      delete: (fleetId: number, orderId: number) =>
        this.delete(`/${endpoint}/${fleetId}`, { query: { orderId } }),
      update: (id: number, orderFleet: Partial<IOrderFleet>) =>
        this.patch(`${endpoint}/${id}`, { orderFleet }),
    }
  }

  get drivers() {
    const endpoint = '/drivers'

    return {
      endpoint,
      get: async () => {
        const { company } = await sessionService.loadUser()
        return this.get<IGetDriversResponse>(endpoint, {
          query: { fleetId: company.id },
        })
      },
      getById: (id: number) =>
        this.get<IGetDriverResponse>(`${endpoint}/${id}`),
      create: (payload: ICreateDriverPayload) =>
        this.post<ICreateDriverResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateDriverPayload) =>
        this.patch<IUpdateDriverResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteDriverResponse>(`${endpoint}/${id}`),

      createLoad: (load: any) => this.post(`${endpoint}/load`, { load }),
      searchLoad: (query: Partial<IDriverSearchLoadParams>) =>
        this.get<IDriverSearchLoadResponse>(`${endpoint}/load/search`, {
          query,
        }),
      getNewLoad: (query: Partial<IDriverGetNewLoadParams>) =>
        this.get(`${endpoint}/load/new`, { query }),

      getImageLoad: (query: Partial<IDriverGetImageLoadParams>) =>
        this.get(`${endpoint}/load/image`, { query }),
      new: () => this.get<IGetDriversNewResponse>(`${endpoint}/new`),
    }
  }

  get breaks() {
    const endpoint = '/breaks'
    return {
      create: (payload: IBreakFormData) =>
        this.post(endpoint, { break: payload }),
      update: (id: number, payload: Partial<IBreakFormData>) =>
        this.put(`${endpoint}/${id}`, { break: payload }),
    }
  }

  get codeScans() {
    const endpoint = '/code_scans'
    return {
      create: (codeScan: ICodeScanFormData) =>
        this.post(endpoint, { codeScan }),
    }
  }

  get companies() {
    const endpoint = '/companies'
    return {
      endpoint,
      get: (query?: IGetCompaniesParams) =>
        this.get<IGetCompaniesResponse>(endpoint, { query }),
      getById: (id: number) =>
        this.get<IGetCompanyResponse>(`${endpoint}/${id}`),
      create: (payload: ICreateCompanyPayload, isUsingAuthToken = false) => {
        const query: { authToken?: string } = {}
        if (isUsingAuthToken) {
          query.authToken = 'LoXTIY9qbfCJ-cKZ85BPvw'
        }

        return this.post<ICreateCompanyResponse>(endpoint, payload, { query })
      },
      update: (id: number, payload: IUpdateCompanyPayload) =>
        this.patch<IUpdateCompanyResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteCompanyResponse>(`${endpoint}/${id}`),

      createAccessRequest: (companyId: number, currentUser: IUser) =>
        this.post<ICreateAccessRequestResponse>(
          `${endpoint}/${companyId}/access_request`,
          { currentUser },
        ),
      toggleAccess: (
        companyId: number,
        payload: IToggleAccessPayload,
        query: IToggleAccessParams,
      ) => this.post(`${endpoint}/${companyId}/toggle`, payload, { query }),
      getInitialCompanyData: () =>
        this.get<IGetInitialCompanyDataResponse>(`${endpoint}/new`),
    }
  }

  get company() {
    const endpoint = '/company'
    return {
      sendToCompanyEmail: (payload: ISendToCompanyEmailPayload) =>
        this.post(`${endpoint}/send_seller_email`, payload),
    }
  }

  get regions() {
    return {
      get: (name: ERegionName, query?: IGetRegionsParams) =>
        this.get<IGetRegionsResponse>(`/${name}`, { query }),
      create: (name: ERegionName, payload: IRegionFormData) =>
        this.post(`/${name}`, payload),
      update: (
        name: ERegionName,
        id: number,
        payload: Partial<IRegionFormData>,
      ) => this.patch(`/${name}/${id}`, payload),
      delete: (name: ERegionName, id: number) => this.delete(`/${name}/${id}`),
    }
  }

  get costCodes() {
    const endpoint = '/cost_codes'
    return {
      get: (query: IGetCostCodesParams) =>
        this.get<IGetCostCodesResponse>(endpoint, { query }),
      create: (costCode: ICostCodeFormData) =>
        this.post<ICreateCostCodeResponse>(endpoint, { costCode }),
      update: (id: number, costCode: Partial<ICostCodeFormData>) =>
        this.put(`${endpoint}/${id}`, { costCode }),
      delete: (id: number) =>
        this.delete<IDeleteCostCodeResponse>(`${endpoint}/${id}`),
    }
  }

  get costCodeConfigurations() {
    const endpoint = '/cost_code_configurations'
    return {
      update: (id: number, payload: Partial<ICostCodeFormData>) =>
        this.patch<ICostCodeConfiguration>(`${endpoint}/${id}`, payload),
    }
  }

  get payrolls() {
    const endpoint = '/payrolls'
    return {
      endpoint,
      get: () => this.get<IGetPayrollsResponse>(endpoint),
      create: (payroll: IPayrollFormData) =>
        this.post<ICreatePayrollResponse>(endpoint, { payroll }),
      update: (id: number, payroll: Partial<IPayrollFormData>) =>
        this.patch<IUpdatePayrollResponse>(`${endpoint}/${id}`, { payroll }),
      delete: (id: number) =>
        this.delete<IDeletePayrollResponse>(`${endpoint}/${id}`),
      getById: (id: number) =>
        this.get<IGetPayrollResponse>(`${endpoint}/${id}`),
    }
  }

  get dataCorrectionMethods() {
    const endpoint = '/data_correction_methods'
    return {
      get: (query?: IGetDataCorrectionMethodsParams) =>
        this.get<IGetDataCorrectionMethodsResponse>(endpoint, { query }),
      create: (dataCorrectionMethod: IDataCorrectionMethodFormData) =>
        this.post<ICreateDataCorrectionMethodResponse>(endpoint, {
          dataCorrectionMethod,
        }),
      update: (
        id: number,
        dataCorrectionMethod: Partial<IDataCorrectionMethodFormData>,
      ) =>
        this.patch<IUpdateDataCorrectionMethodResponse>(`${endpoint}/${id}`, {
          dataCorrectionMethod,
        }),
      delete: (id: number) =>
        this.delete<IDeleteDataCorrectionMethodResponse>(`${endpoint}/${id}`),
    }
  }

  get driverFleets() {
    const endpoint = '/driver_fleets'
    return {
      endpoint,
      get: async (query?: Partial<IGetDriverFleetsParams>) => {
        const { company } = await sessionService.loadUser()

        return this.get<IGetDriverFleetsResponse>(endpoint, {
          query: { fleetId: company.id, ...query },
        })
      },
      getById: (id: number) => this.get(`${endpoint}/${id}`),
      create: (driverFleet: IDriverFleetFormData) =>
        this.post<ICreateDriverFleetResponse>(endpoint, { driverFleet }),
      update: (id: number, driverFleet: Partial<IDriverFleetFormData>) =>
        this.patch<IUpdateDriverFleetResponse>(`${endpoint}/${id}`, {
          driverFleet,
        }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get earningRows() {
    const endpoint = '/earning_rows'
    return {
      get: (query?: IGetEarningRowsParams) =>
        this.get<IGetEarningRowsResponse>(endpoint, { query }),
      create: (earningRow: IEarningRowFormData) =>
        this.post<ICreateEarningRowResponse>(endpoint, { earningRow }),
      update: (id: number, earningRow: Partial<IEarningRowFormData>) =>
        this.patch<IUpdateEarningRowResponse>(`${endpoint}/${id}`, {
          earningRow,
        }),
      delete: (id: number) =>
        this.delete<IDeleteEarningRowResponse>(`${endpoint}/${id}`),
    }
  }

  get emailParsers() {
    const endpoint = '/email_parsers'
    return {
      get: (query?: Partial<IGetEmailParsersParams>) =>
        this.get<IGetEmailParsersResponse>(endpoint, { query }),
      create: (emailParser: IEmailParserFormData) =>
        this.post<ICreateEmailParserResponse>(endpoint, { emailParser }),
      update: (id: number, emailParser: Partial<IEmailParserFormData>) =>
        this.patch<IUpdateEmailParserResponse>(`${endpoint}/${id}`, {
          emailParser,
        }),
      delete: (id: number) =>
        this.delete<IDeleteEmailParserResponse>(`${endpoint}/${id}`),
    }
  }

  get dashboards() {
    const endpoint = '/dashboards'
    return {
      create: (
        payload: ICreateDashboardPayload,
        query: ICreateDashboardParams,
      ) => this.post<IDashboard>(endpoint, payload, { query }),
    }
  }

  get dataParsers() {
    const endpoint = '/data_parsers'
    return {
      endpoint,
      get: (query?: IGetDataParsersParams) =>
        this.get<IGetDataParsersResponse>(endpoint, { query }),
      create: (dataParser: IDataParserFormData) =>
        this.post<ICreateDataParserResponse>(endpoint, { dataParser }),
      update: (id: number, dataParser: Partial<IDataParserFormData>) =>
        this.patch<IUpdateDataParserResponse>(`${endpoint}/${id}`, {
          dataParser,
        }),
      delete: (id: number) =>
        this.delete<IDeleteDataParserResponse>(`${endpoint}/${id}`),
    }
  }

  get documents() {
    const endpoint = '/documents'
    return {
      endpoint,
      get: async (query?: IGetDocumentsParams) => {
        const { documents, ...response } =
          await this.get<IGetDocumentsResponse>(endpoint, { query })

        const newDocuments = documents.map(doc => {
          let extension = ''
          let urlHost = ''
          if (doc.url) {
            const url = new URL(doc.url)
            const value = url.searchParams.get('response-content-type')
            const n = value && value.indexOf('/')
            extension = value?.substring((n as number) + 1) || ''

            if (!doc.fileType) urlHost = url.hostname
          }

          return {
            ...doc,
            email: doc.user?.email || '', //assign empty a string instead of a null to sort
            fileExtension: extension?.toLocaleLowerCase()?.includes('pdf')
              ? 'pdf'
              : extension,
            urlHost,
          }
        })

        return { documents: newDocuments, ...response }
      },
      getById: (id: number) =>
        this.get<IGetDocumentResponse>(`${endpoint}/${id}`),
      create: (document: IDocumentFormData) =>
        this.post<ICreateDocumentResponse>(`${endpoint}`, { document }),
      update: (id: number, document: Partial<IDocumentFormData>) =>
        this.patch<IUpdateDocumentResponse>(`${endpoint}/${id}`, { document }),
      delete: (id: number) =>
        this.delete<IDeleteDocumentResponse>(`${endpoint}/${id}`),
      createLoad: (documentId: number, load: any) =>
        this.post(`${endpoint}/create_loads`, load, {
          query: { id: documentId },
        }),
      new: () => this.get<IGetDocumentsNewResponse>(`${endpoint}/new`),
    }
  }

  get processedDocument() {
    const endpoint = '/processed_document'
    return {
      createById: (id: number, processedDocument: any) =>
        this.post(`${endpoint}/${id}`, processedDocument),
    }
  }

  get fixedPrices() {
    const endpoint = '/fixed_prices'
    return {
      get: (query?: IGetFixedPricesParams) =>
        this.get<IGetFixedPricesResponse>(endpoint, { query }),
      create: (fixedPrice: IFixedPriceFormData) =>
        this.post<ICreateFixedPriceResponse>(endpoint, { fixedPrice }),
      update: (id: number, fixedPrice: IFixedPriceFormData) =>
        this.patch<IUpdateFixedPriceResponse>(`${endpoint}/${id}`, {
          fixedPrice,
        }),
      delete: (id: number) =>
        this.delete<IDeleteFixedPriceResponse>(`${endpoint}/${id}`),
    }
  }

  get loads() {
    const endpoint = '/loads'
    return {
      endpoint,
      get: (query?: Partial<IGetLoadsParams>) =>
        this.get<IGetLoadsResponse>(endpoint, { query }),
      getById: (id: number, query?: Partial<IGetLoadParams>) =>
        this.get<ILoad>(`${endpoint}/${id}`, { query }),
      create: (payload: ICreateLoadPayload) =>
        this.post<ILoad>(endpoint, payload),
      update: (id: number, payload: IUpdateLoadPayload) =>
        this.patch<ILoad>(`${endpoint}/${id}`, payload),
      delete: (id: number, payload: ILoadDeletePayload) =>
        this.delete<ILoad>(`${endpoint}/${id}`, payload),
      createFleetLoad: (payload: ICreateFleetLoadPayload) =>
        this.post<ICreateFleetLoadResponse>(
          `${endpoint}/create_fleet_load`,
          payload,
        ),
      bulkRerunOcrJson: (payload: ILoadBulkRerunOcrJsonParams) =>
        this.put(`${endpoint}/bulk_rerun_ocr_json`, payload),
      search: (query?: Partial<ILoadsSearchParams>) =>
        this.get<ILoadsSearchResponse>(`${endpoint}/search`, { query }),
      rerunLoadOcr: (loadId: number) =>
        this.patch<ILoad>(`${endpoint}/${loadId}/rerun_load_ocr`, {}),
    }
  }

  get load() {
    const endpoint = '/load'
    return {
      rerunOcrJson: (loadId: number) =>
        this.patch(`${endpoint}/rerun_ocr_json/${loadId}`),
      createParser: (payload: ICreateLoadParserPayload) =>
        this.post(`${endpoint}/parser`, payload),
      submitHitl: (payload: ILoadSubmitHitlPayload) =>
        this.post(`${endpoint}/submit_hitl`, payload),
    }
  }

  get loadExtras() {
    const endpoint = '/load_extras'
    return {
      get: (query: Partial<IGetLoadExtrasPayload>) =>
        this.get<IGetLoadExtrasResponse>(endpoint, { query }),
      update: (id: number, payload: IUpdateLoadExtraPayload) =>
        this.patch<ICreateLoadExtraResponse>(`${endpoint}/${id}`, payload),
      create: (payload: ICreateLoadExtraPayload) =>
        this.post<ICreateLoadExtraResponse>(endpoint, payload),
      delete: (id: number, payload?: { runInline?: boolean }) =>
        this.delete<IDeleteLoadExtraResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get loadTimes() {
    const endpoint = '/load_times'
    return {
      endpoint,
      create: (payload: ICreateLoadTimePayload) =>
        this.post<ICreateLoadTimeResponse>(endpoint, payload),
      get: (query: ILoadTimesGetListParams) =>
        this.get<ILoadTimesGetListResponse>(endpoint, { query }),
      update: (id: number, payload: any) =>
        apiClient.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => apiClient.delete(`${endpoint}/${id}`),
    }
  }

  get loadNotes() {
    const endpoint = '/notes'
    return {
      get: (loadId: number, query?: IGetLoadNotesParams) =>
        this.get<IGetLoadNotesResponse>(
          `${this.loads.endpoint}/${loadId}${endpoint}`,
          {
            query,
          },
        ),
      create: (loadId: number, payload: ILoadNoteFormData) =>
        this.post(`${this.loads.endpoint}/${loadId}${endpoint}`, payload),
      update: (
        loadId: number,
        noteId: number,
        payload: Partial<ILoadNoteFormData>,
      ) =>
        this.put(
          `${this.loads.endpoint}/${loadId}${endpoint}/${noteId}`,
          payload,
        ),
      delete: (loadId: number, noteId: number) =>
        this.delete(`${this.loads.endpoint}/${loadId}${endpoint}/${noteId}`),
    }
  }

  get terminals() {
    const endpoint = '/terminals'
    return {
      endpoint,
      get: (query?: IGetTerminalsParams) =>
        this.get<ITerminal[]>(endpoint, { query }),
      create: (payload: ITerminalFormData) =>
        this.post<ICreateTerminalResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateTerminalPayload) =>
        this.put<IUpdateTerminalResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number, replacementId?: number) =>
        this.delete<IDeleteTerminalResponse>(`${endpoint}/${id}`, undefined, {
          query: { replacementId },
        }),
      getById: (id: number, query: Partial<IGetTerminalParams> = {}) =>
        this.get<ITerminal>(`${endpoint}/${id}`, { query }),
    }
  }

  get geofences() {
    const endpoint = '/geofences'
    return {
      get: (query?: IGetGeofencesParams) =>
        this.get<IGetGeofencesResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (payload: any) => this.post(endpoint, payload),
      update: (id: number, payload: IGeofenceUpdatePayload) =>
        this.patch(`${endpoint}/${id}`, payload),
    }
  }

  get plants() {
    const endpoint = '/plants'
    return {
      get: (query?: IGetPlantsParams) =>
        this.get<IGetPlantsResponse>(endpoint, { query }),
    }
  }

  get silos() {
    const endpoint = '/silos'
    return {
      get: (query?: IGetSilosParams) =>
        this.get<IGetSilosResponse>(`${endpoint}`, { query }),
    }
  }

  get flags() {
    const endpoint = '/flags'
    return {
      endpoint,
      get: (query: IGetFlagsParams) =>
        this.get<IGetFlagsResponse>(endpoint, { query }),
    }
  }

  get invoices() {
    const endpoint = '/invoices'
    return {
      endpoint,
      get: (query?: Partial<IGetInvoicesParams>) =>
        this.get<IGetInvoicesResponse>(endpoint, { query }),
      update: (id: number, payload: IInvoiceFormData) =>
        this.patch<IUpdateInvoiceResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      getById: (id: number) => this.get<IInvoice>(`${endpoint}/${id}`),
      refresh: (id: number) =>
        this.get(`${endpoint}/${id}/edit`, { query: { urls: true } }),
      create: (
        invoice: IInvoiceFormData,
        query?: Partial<ICreateInvoiceParams>,
      ) => this.post<ICreateInvoiceResponse>(endpoint, { invoice }, { query }),
      exportCsv: (query: string) => {
        return this.api.getCsv(
          '/invoices.csv',
          process.env.REACT_APP_API_URL,
          query,
        )
      },
      exportXml: (query: string) => {
        return this.api.getCsv(
          '/invoices.xml',
          process.env.REACT_APP_API_URL,
          query,
        )
      },
      exportPdf: (query: string) => {
        return this.api.getCsv(
          '/invoices.pdf',
          process.env.REACT_APP_API_URL,
          query,
        )
      },
    }
  }

  get invoice() {
    const endpoint = '/invoice'
    return {
      parser: (payload: IParserInvoicePayload) =>
        this.post(`${endpoint}/parser`, payload),
      createInvoiceFromDocuments: (payload: IParseActualInvoicePayload) =>
        this.post<IParseActualInvoiceResponse>(
          `${endpoint}/parse_actual_invoice`,
          payload,
        ),
      submitToHitl: (invoice: IInvoiceFormData) =>
        this.post(`${endpoint}/submit_hitl`, { invoice }),
      rerunOcrJson: (invoiceId: number) =>
        this.patch(`${endpoint}/rerun_ocr_json/${invoiceId}`, {}),
      recalculate: (invoiceId: number) =>
        this.patch(`${endpoint}/recalculate/${invoiceId}`, {}),
    }
  }

  get orderExtras() {
    const endpoint = '/order_extras'
    return {
      endpoint,
      get: (query?: Partial<IGetOrderExtrasParams>) =>
        this.get<IGetOrderExtrasResponse>(endpoint, { query }),
      create: (payload: IOrderExtraCreatePayload) =>
        this.post<ICreateOrderExtraResponse>(endpoint, payload),
      update: (id: number, payload: IOrderExtraUpdatePayload) =>
        this.put<IUpdateOrderExtraResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteOrderExtraResponse>(`${endpoint}/${id}`),
    }
  }

  get orderExtraRanges() {
    const endpoint = '/order_extra_ranges'
    return {
      endpoint,
      get: (query?: Partial<IGetOrderExtraRangesParams>) =>
        this.get<IGetOrderExtraRangesResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (payload: ICreateOrderExtraRangePayload) =>
        this.post<ICreateOrderExtraRangeResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateOrderExtraRangePayload) =>
        this.patch<IUpdateOrderExtraRangeResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
    }
  }

  get locations() {
    const endpoint = '/locations'
    return {
      endpoint,
      get: (query?: Partial<IGetLocationsParams>) =>
        this.get<IGetLocationsResponse>(endpoint, { query }),
      getById: (id: number) => this.get<ILocation>(`${endpoint}/${id}`),
      update: (id: number, payload: Partial<ILocationFormData>) =>
        this.patch<ILocation>(`${endpoint}/${id}`, payload),
      create: (payload: ICreateLocation) =>
        this.post<ILocation>(endpoint, payload),
    }
  }

  get buyerTerminalProductSchedules() {
    const endpoint = '/buyer_terminal_product_schedules'
    return {
      get: (query?: Partial<IGetBuyerTerminalProductScheduleParams>) =>
        this.get(endpoint, { query }),
    }
  }

  get coordinates() {
    const endpoint = '/coordinates'
    return {
      create: (payload: ICoordinatePayload) =>
        this.post<ICoordinate[]>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get reasons() {
    const endpoint = '/reasons'
    return {
      get: () => this.get<IGetReasonsResponse>(endpoint),
    }
  }

  get dismissedReminders() {
    const endpoint = '/dismissed_reminders'
    return {
      create: (payload: ICreateDismissedReminderPayload) =>
        this.post(endpoint, payload),
      delete: (id: number, payload: IDeleteDismissedReminderPayload) =>
        this.delete(`${endpoint}/${id}`, payload),
    }
  }

  get notes() {
    const endpoint = '/notes'
    return {
      endpoint,
      get: (query?: Partial<IGetNotesParams>) =>
        this.get<IGetNotesResponse>(endpoint, { query }),
      create: (payload: ICreateNotePayload) =>
        this.post<ICreateNoteResponse>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      update: (id: number, payload: IUpdateNotePayload) =>
        this.patch<IUpdateNoteResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get terminalAccesses() {
    const endpoint = '/terminal_accesses'
    return {
      get: (query: IGetTerminalAccessesParams) =>
        this.get<ITerminalAccess[]>(endpoint, { query }),
      update: (id: number, payload: IUpdateTerminalAccessPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      create: (payload: ICreateTerminalAccessPayload) =>
        this.post<ICreateTerminalAccessResponse>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get companyFleets() {
    const endpoint = '/company_fleets'
    return {
      get: (query?: Partial<IGetCompanyFleetsParams>) =>
        this.get<IGetCompanyFleetsResponse>(endpoint, { query }),
    }
  }

  get fleetRoutes() {
    const endpoint = '/fleet_routes'
    return {
      get: (query?: Partial<IGetFleetRoutesParams>) =>
        this.get<IGetFleetRoutesResponse>(endpoint, { query }),
      create: (payload: ICreateFleetRoutePayload) =>
        this.post(endpoint, payload),
      update: (id: number, payload: IUpdateFleetRoutePayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get trucks() {
    const endpoint = '/trucks'
    return {
      get: (query: IGetTrucksParams) =>
        this.get<IGetTrucksResponse>(endpoint, { query }),
      getById: (id: number) => this.get<ITruck>(`${endpoint}/${id}`),
      create: (payload: ICreateTruckPayload) =>
        this.post<ICreateTruckResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateTruckPayload) =>
        this.put<IUpdateTruckResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteTruckResponse>(`${endpoint}/${id}`),
    }
  }

  get truckFleets() {
    const endpoint = '/truck_fleets'
    return {
      endpoint,
      get: async () => {
        const { company } = await sessionService.loadUser()
        return this.get<IGetTruckFleetsResponse>(endpoint, {
          query: {
            fleetId: company.id,
          },
        })
      },
      getById: (id: number) => this.get<ITruckFleet>(`${endpoint}/${id}`),
      update: (id: number, payload: IUpdateTruckFleetPayload) =>
        this.patch<IUpdateTruckFleetResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get fieldValidations() {
    const endpoint = '/field_validations'
    return {
      get: () => this.get<IGetFieldValidationsResponse>(endpoint),
      create: (payload: ICreateFieldValidationPayload) =>
        this.post<ICreateFieldValidationResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateFieldValidationPayload) =>
        this.patch<IUpdateFieldValidationResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
    }
  }

  get preprocessingTests() {
    const endpoint = '/preprocessing_tests'
    return {
      endpoint,
      get: (query: IGetPreprocessingTestsParams) =>
        this.get<IGetPreprocessingTestsResponse>(endpoint, { query }),
      create: (payload: ICreatePreprocessingTestPayload) =>
        this.post(endpoint, payload),
    }
  }

  get fleets() {
    const endpoint = '/fleets'
    return {
      delete: (id: number, replacementId?: number) =>
        this.delete(`${endpoint}/${id}`, undefined, {
          query: { replacementId },
        }),
    }
  }

  get fleetRouteProducts() {
    const endpoint = '/fleet_route_products'
    return {
      get: (query?: Partial<IGetFleetRouteProductsParams>) =>
        this.get<IGetFleetRouteProductsResponse>(endpoint, { query }),
      create: (payload: ICreateFleetRouteProductPayload) =>
        this.post(endpoint, payload),
      update: (id: number, payload: IUpdateFleetRouteProductPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get processedPayments() {
    const endpoint = '/processed_payments'
    return {
      create: (payload: ICreateProcessedPaymentPayload) =>
        this.post<ICreateProcessedPaymentResponse>(endpoint, payload),
    }
  }

  get orderProductSchedules() {
    const endpoint = '/order_product_schedules'
    return {
      update: (id: number, payload: IUpdateOrderProductSchedulePayload) =>
        this.patch(`${endpoint}/${id}`, payload),
    }
  }

  get userGroups() {
    const endpoint = '/user_groups'
    return {
      get: () => this.get<IGetUserGroupsResponse>(endpoint),
      new: () => this.get<IGetNewGroupDataResponse>(`${endpoint}/new`),
      create: (payload: IUserGroupFormData) =>
        this.post<ICreateUserGroupResponse>(endpoint, payload),
      update: (id: number, payload: Partial<IUserGroupFormData>) =>
        this.patch<IUpdateUserGroupResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteUserGroupResponse>(`${endpoint}/${id}`),
    }
  }

  get tagLinks() {
    const endpoint = '/tag_links'
    return {
      get: (query: IGetTagLinksParams) =>
        this.get<ITagLink[]>(`${endpoint}`, { query }),
      create: (payload: ITagLinkFormData) =>
        this.post<ICreateTagLinkResponse>(endpoint, payload),
      delete: (id: number) =>
        this.delete<IDeleteTagLinkResponse>(`${endpoint}/${id}`),
    }
  }

  get referenceNames() {
    const endpoint = '/reference_names'
    return {
      get: (query?: Partial<IGetReferenceNamesParams>) =>
        this.get<IGetReferenceNamesResponse>(endpoint, { query }),
      create: (payload: ICreateReferenceNamePayload) =>
        this.post<ICreateReferenceNameResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateReferenceNamePayload) =>
        this.patch<IUpdateReferenceNameResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteReferenceNameResponse>(`${endpoint}/${id}`),
    }
  }

  get notificationRegistrations() {
    const endpoint = '/notification_registrations'
    return {
      create: (payload: { deviceToken: string; currentUser: number }) =>
        this.post(endpoint, payload),
    }
  }

  get routes() {
    const endpoint = '/routes'
    return {
      get: (query: IGetRoutesParams) => this.get<IRoute[]>(endpoint, { query }),
      create: (payload: ICreateRoutePayload) => this.post(endpoint, payload),
      update: (id: number, payload: IUpdateRoutePayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get products() {
    const endpoint = '/products'
    return {
      endpoint,
      get: (query?: IGetProductsParams) =>
        this.get<IGetProductsResponse>(endpoint, { query }),
      create: (payload: ICreateProductPayload) =>
        this.post<ICreateProductResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateProductPayload) =>
        this.patch<IUpdateProductResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeleteProductResponse>(`${endpoint}/${id}`),
    }
  }

  get priceEscalations() {
    const endpoint = '/price_escalations'
    return {
      endpoint,
      get: (query: IGetPriceEscalationsParams) =>
        this.get<IGetPriceEscalationsResponse>(endpoint, { query }),
      create: (payload: IPriceEscalationFormData) =>
        this.post(endpoint, payload),
      update: (id: number, payload: Partial<IPriceEscalationFormData>) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) =>
        this.delete<IDeletePriceEscalationResponse>(`${endpoint}/${id}`),
      multipleUpdate: (payload: IMultipleUpdatePriceEscalationsPayload) =>
        this.patch(`${endpoint}/update_price_escalations`, payload),
    }
  }

  get userTableConfigurations() {
    const endpoint = '/user_table_configurations'
    return {
      get: (query: IGetUserTableConfigurationsParams) =>
        this.get(endpoint, { query }),
      create: (payload: IUserTableConfigurationFormData) =>
        this.post(endpoint, payload),
      update: (
        id: number,
        payload: Partial<IUserTableConfigurationFormData>,
        query: Partial<IUpdateUserTableConfigurationParams>,
      ) => this.put(`${endpoint}/${id}`, payload, { query }),
    }
  }

  get parserColumnMappings() {
    const endpoint = '/parser_column_mappings'
    return {
      get: (query?: Partial<IGetParserColumnMappingsParams>) =>
        this.get<IGetParserColumnMappingsResponse>(endpoint, { query }),
      create: (payload: ICreateParserColumnMappingPayload) =>
        this.post<ICreateParserColumnMappingResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateParserColumnMappingPayload) =>
        this.patch<IUpdateParserColumnMappingResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) =>
        this.delete<IDeleteParserColumnMappingResponse>(`${endpoint}/${id}`),
    }
  }

  get checkPayrollItems() {
    const endpoint = '/check_payroll_items'
    return {
      get: (query?: IGetCheckPayrollItemsParams) =>
        this.get<IGetCheckPayrollItemsResponse>(endpoint, { query }),
      create: (payload: ICreateCheckPayrollItemPayload) =>
        this.post<ICreateCheckPayrollItemResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateCheckPayrollItemPayload) =>
        this.patch<IUpdateCheckPayrollItemResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) =>
        this.delete<IDeleteCheckPayrollItemResponse>(`${endpoint}/${id}`),
    }
  }

  get check() {
    const endpoint = '/check'
    return {
      setupCompany: () =>
        this.get<IGetCheckSetupCompanyResponse>(`${endpoint}/setup_company`),

      createCompany: (payload: any) =>
        this.post(`${endpoint}/companies`, payload),

      updateCompany: (id: string, payload: any) =>
        this.patch(`${endpoint}/companies/${id}`, payload),

      createCompanyLink: (payload: ICreateCheckCompanyLinkPayload) =>
        this.post<ICreateCheckCompanyLinkResponse>(
          `${endpoint}/create_company_link`,
          payload,
        ),
      getCompanyById: (checkUid: string) =>
        this.get<IGetCheckSetupCompanyResponse>(
          `${endpoint}/companies/${checkUid}`,
        ),

      setupWorker: (query: ICheckSetupWorkerParams) =>
        this.get(`${endpoint}/setup_worker`, { query }),

      createEmployee: (
        payload: ICreateCheckEmployeePayload,
        query: ICreateCheckEmployeeParams,
      ) => this.post(`${endpoint}/create_employee`, payload, { query }),

      createWorker: (payload: ICheckCreateWorkerPayload) =>
        this.post<IWorker>(`${endpoint}/workers`, payload),
      updateWorker: (checkUid: string, payload: ICheckUpdateWorkerPayload) =>
        this.patch<IWorker>(`${endpoint}/workers/${checkUid}`, payload),

      createContractor: (payload: ICheckCreateWorkerPayload) =>
        this.post(`${endpoint}/contractors`, payload),
      updateContractor: (
        uid: string,
        payload: Partial<ICheckCreateWorkerPayload>,
      ) => this.patch(`${endpoint}/contractors/${uid}`, payload),

      getWorkerByUid: (checkUid: string) =>
        this.get(`${endpoint}/workers/${checkUid}`),

      getContractorByUid: (checkUid: string) =>
        this.get(`${endpoint}/contractors/${checkUid}`),

      createWorkerLink: (payload: ICreateWorkerLinkPayload) =>
        this.post<ICreateWorkerLinkResponse>(
          `${endpoint}/create_worker_link`,
          payload,
        ),

      setupTerminal: (query: IGetCheckSetupTerminalParams) =>
        this.get<IGetCheckSetupTerminalResponse>(
          `${endpoint}/terminals/setup_terminal`,
          { query },
        ),
      createTerminal: (
        payload: ICreateCheckTerminalPayload,
        query: ICreateCheckTerminalParams,
      ) => this.post(`${endpoint}/terminals`, payload, { query }),
      retrieveTerminal: (checkUid: string) =>
        this.get(`${endpoint}/terminals/${checkUid}`),
      updateTerminal: (id: string, payload: IUpdateCheckTerminalParams) =>
        this.patch(`${endpoint}/terminals/${id}`, payload),

      createPayroll: (payload: ICreateCheckPayrollPayload) =>
        this.post(`${endpoint}/payrolls`, payload),
      previewPayroll: (query: IPreviewCheckPayrollParams) =>
        this.get(`${endpoint}/preview_payroll`, { query }),
      reopenPayroll: (payload: IReopenCheckPayrollPayload) =>
        this.post(`${endpoint}/reopen_pending_payroll`, payload),
      getPayrollPaper: (query: IGetPayrollPaperParams) =>
        this.get(`${endpoint}/get_payroll_paper_checks`, { query }),
      getPayrollCashRequirement: (query: IGetPayrollCashRequirementParams) =>
        this.get(`${endpoint}/get_payroll_cash_requirement`, { query }),
      approvePayroll: (payload: IApprovePayrollPayload) =>
        this.post(`${endpoint}/approve_payroll`, payload),

      getNetPaySplits: (query: { workerUid: string }) =>
        this.get<IGetCheckNetPaySplitsResponse>(`${endpoint}/net_pay_splits`, {
          query,
        }),
      createNetPaySplit: (payload: ICreateNetPaySplitPayload) =>
        this.post<ICheckNetPaySplit>(`${endpoint}/net_pay_splits`, payload),

      getBankAccounts: (query: IGetCheckBankAccountsPayload) =>
        this.get<IGetCheckBankAccountsResponse>(`${endpoint}/bank_accounts`, {
          query,
        }),
      createBankAccount: (payload: ICreateCheckBankAccountPayload) =>
        this.post<ICheckBankAccount>(`${endpoint}/bank_accounts`, payload),
      getBankAccountById: (id: string) =>
        this.get(`${endpoint}/bank_accounts/${id}`),
      deleteBankAccount: (id: string) =>
        this.delete(`${endpoint}/bank_accounts/${id}`),

      getPaySchedules: (query: IGetCheckPayScheduleParams) =>
        this.get(`${endpoint}/pay_schedules`, { query }),

      createPaySchedule: (payload: ICreateCheckPaySchedulePayload) =>
        this.post(`${endpoint}/pay_schedules`, payload),
      updatePaySchedules: (
        id: string,
        payload: IUpdateCheckPaySchedulePayload,
      ) => this.patch(`${endpoint}/pay_schedules/${id}`, payload),

      getPayments: () => this.get(`${endpoint}/payments`),

      getEarningCodes: (query: IGetCheckEarningCodesParams) =>
        this.get(`${endpoint}/earning_codes`, { query }),
      createEarningCode: (payload: ICreateCheckEarningCodePayload) =>
        this.post(`${endpoint}/earning_codes`, payload),
      updateEarningCode: (id: number, payload: unknown) =>
        this.patch(`${endpoint}/earning_codes/${id}`, payload),

      retryPayment: (payload: IRetryCheckPayment) =>
        this.post(`${endpoint}/retry_payment`, payload),
      refundPayment: (payload: IRefundCheckPayment) =>
        this.post(`${endpoint}/refund_payment`, payload),

      getEarningRates: (query: IGetCheckEarningRatesParams) =>
        this.get(`${endpoint}/earning_rates`, { query }),
      createEarningRate: (payload: ICheckEarningRateFormData) =>
        this.post(`${endpoint}/earning_rates`, payload),
      updateEarningRate: (
        id: number,
        payload: IUpdateCheckEarningRatePayload,
      ) => this.patch(`${endpoint}/earning_rates/${id}`, payload),

      getSignatories: (query?: IGetCheckSignatoriesParams) =>
        this.get<IGetCheckSignatoriesResponse>('/check_signatories', { query }),
      deleteSignatory: (id: number) => this.delete(`/check_signatories/${id}`),

      getCheckSignatories: () =>
        this.get<IGetCheckSignatoriesResponse>(
          `${endpoint}/get_company_signatories`,
        ),
      createSignatory: (payload: ICreateCheckSignatoryPayload) =>
        this.post<ICheckSignatory>('/check_signatories', payload),

      postCompanyFilingAuthorization: async (
        payload: ICreateCompanyFillingAuthorizationPayload,
      ) =>
        this.post<ICreateCompanyFillingAuthorizationResponse>(
          `${endpoint}/post_company_filing_authorization`,
          payload,
        ),

      runPayroll: (query: ICreateRunPayrollParams) =>
        this.post<ICreateRunPayrollResponse>(
          `${endpoint}/run_payroll`,
          {},
          { query },
        ),

      listContractorPayments: (payload: any, { query }: any) =>
        this.post(`${endpoint}/list_contractor_payments`, payload, { query }),

      contractorTaxDocumentsComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_contractor_tax_documents_component`,
          payload,
        ),

      contractorOnboardComponent: (payload: any) =>
        this.post(`${endpoint}/post_contractor_onboard_component`, payload),

      employeePaystubs: (payload: any) =>
        this.post(`${endpoint}/post_employee_paystubs_component`, payload),

      employeeTaxDocumentsComponent: (payload: any) =>
        this.post(`${endpoint}/post_employee_tax_documents_component`, payload),
      employeeSsnComponent: (payload: any) =>
        this.post(`${endpoint}/post_employee_ssn_component`, payload),
      employeePaymentSetupComponent: (payload: any) =>
        this.post(`${endpoint}/post_employee_payment_setup_component`, payload),
      employeeWithholdingsSetupComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_employee_withholdings_setup_component`,
          payload,
        ),
      employeeProfileComponent: (payload: any) =>
        this.post(`${endpoint}/post_employee_profile_component`, payload),
      employeePostTaxDeductionsComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_employee_post_tax_deductions_component`,
          payload,
        ),

      employeeBenefitsComponent: (payload: any) =>
        this.post(`${endpoint}/post_employee_benefits_component`, payload),

      postCompanyDetailsComponent: (payload: any) =>
        this.post(`${endpoint}/post_company_details_component`, payload),

      postCompanyDefinedEmployeeSetupComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_defined_employee_setup_component`,
          payload,
        ),

      postCompanyPaymentSetupComponent: (payload: any) =>
        this.post(`${endpoint}/post_company_payment_setup_component`, payload),

      postCompanyAuthorizationDocumentsComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_authorization_documents_component`,
          payload,
        ),

      postCompanyTaxDocumentsComponent: (payload: any) =>
        this.post(`${endpoint}/post_company_tax_documents_component`, payload),

      postCompanyTaxSetupComponent: (payload: any) =>
        this.post(`${endpoint}/post_company_tax_setup_component`, payload),

      postCompanyReportsComponent: (payload: any) =>
        this.post(`${endpoint}/post_company_reports_component`, payload),

      postCompanyAccountingIntegrationComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_accounting_integration_component`,
          payload,
        ),

      postCompanyTermsOfServiceComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_terms_of_service_component`,
          payload,
        ),

      postCompanyPreviousPayrollProviderComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_previous_payroll_provider_component`,
          payload,
        ),

      postCompanyEarlyEnrollmentComponent: (payload: any) =>
        this.post(
          `${endpoint}/post_company_early_enrollment_component`,
          payload,
        ),

      postStartImplementation: () =>
        this.post(`${endpoint}/post_company_start_implementation`, {}),

      postCompanyVerificationDocuments: () =>
        this.post(`${endpoint}/post_company_verification_documents`, {}),

      postCancelImplementation: () =>
        this.post(`${endpoint}/post_company_cancel_implementation`, {}),

      postCompanyRequestEmbeddedSetup: () =>
        this.post(`${endpoint}/post_company_request_embedded_setup`, {}),

      getEnrollmentProfile: () =>
        this.get<ICheckEnrollmentProfile>(`${endpoint}/enrollment_profile`),
      updateEnrollmentProfile: (
        payload: IUpdateCheckEnrollmentProfilePayload,
      ) =>
        this.patch<{ body: string }>( //json
          `${endpoint}/update_enrollment_profile`,
          payload,
        ),

      appliedForIds: () =>
        this.get<IGetAppliedForIdsResponse>(`${endpoint}/applied_for_ids`),

      getPayrollJournal: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_payroll_journal`, options)
      },

      getPayrollSummary: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_payroll_summary`, options)
      },

      taxLiabilityReport: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_tax_liability_report`, options)
      },

      w2PreviewReport: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_w2_preview`, options)
      },

      w4ExemptStatusReport: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_w4_exemption_status`, options)
      },

      paydays: (options: Partial<ApiClientRequestOption> = {}) => {
        return this.get(`${endpoint}/get_company_paydays`, options)
      },

      taxElections: () => this.get(`${endpoint}/get_company_tax_elections`),
      listCompanyFederalEinVerification: () =>
        this.get(`${endpoint}/list_company_federal_ein_verifications`),
      listCompanyTaxDeposits: (query: any) =>
        this.get(`${endpoint}/list_company_tax_deposits`, { query }),

      contractorPaymentsReport: (
        options: Partial<ApiClientRequestOption> = {},
      ) =>
        this.get(`${endpoint}/get_company_contractor_payments_report`, options),

      retrieveWorkerReciprocityElections: (
        query: IGetRetrieveWorkerReciprocityElectionsParams,
      ) =>
        this.get(`${endpoint}/retrieve_worker_reciprocity_elections`, {
          query,
        }),
      updateWorkerReciprocityElections: (
        payload: IUpdateWorkerReciprocityElectionPayload,
      ) =>
        this.patch(`${endpoint}/update_worker_reciprocity_elections`, payload),

      getWorkerCompanyAttributes: (query: IGetWorkerCompanyAttributesParams) =>
        this.get<IGetWorkerCompanyAttributesResponse>(
          `${endpoint}/get_worker_company_attributes`,
          { query },
        ),

      updateWorkerCompanyAttributes: (
        payload: IUpdateWorkerCompanyAttributesPayload,
      ) => this.patch(`${endpoint}/update_worker_company_attributes`, payload),

      postPartner: (payload: { partnerName: string }) =>
        this.post(`${endpoint}/partner`, payload),

      getAccesses: () =>
        this.get<ICheckAccessesGetResponse>(`${endpoint}/accesses`),
      getUsageOptions: () =>
        this.get<ICheckUsageOptionsGetResponse>(`${endpoint}/usage_options`),
      getUsageSummaries: (query: ICheckUsageSummariesGetParams) =>
        this.get<ICheckUsageSummariesGetResponse>(
          `${endpoint}/usage_summaries`,
          { query },
        ),
      getUsageRecords: (query: ICheckUsageRecordsGetParams) =>
        this.get<ICheckUsageRecordsGetResponse>(`${endpoint}/usage_records`, {
          query,
        }),
    }
  }

  get fuse() {
    return {
      createSession: () =>
        this.post<IFuseCreateSessionResponse>('/fuse_create_session'),
      createLinkToken: (payload: IFuseCreateLinkTokenPayload) =>
        this.post<IFuseCreateLinkTokenResponse>(
          '/fuse_create_link_token',
          payload,
        ),
      exchangePublicToken: (payload: IFuseExchangePublicTokenPayload) =>
        this.post<string>('/fuse_exchange_public_token', payload),
      getPaymentSources: () => this.get('/payment_sources'),
      updateDefaultPaymentSource: (id: number) =>
        this.post(
          '/payment_sources/update_default',
          {},
          {
            query: { id },
          },
        ),
      deletePaymentSources: (id: number) =>
        this.delete(`/payment_sources/${id}`),
    }
  }

  get parsedEmails() {
    const endpoint = '/parsed_emails'
    return {
      endpoint,
      getById: (id: number) =>
        this.get<IGetParsedEmailResponse>(`${endpoint}/${id}`),
      get: (query: IGetParsedEmailsParams = {}) =>
        this.get<IGetParsedEmailsResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      update: (id: number, payload: IUpdateParsedEmailPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
    }
  }

  get url() {
    const endpoint = '/url'
    return {
      getById: (
        id: number,
        query: {
          model:
            | 'Load'
            | 'Invoice'
            | 'UploadedFile'
            | 'Document'
            | 'Company'
            | 'Statement'
          urlName: string
          userAccessId?: number
          convertToBase64?: boolean
        },
      ) =>
        this.get<{ url: string; base64Image?: string }>(`${endpoint}/${id}`, {
          query,
        }),
    }
  }

  get flagableConfigurations() {
    const endpoint = '/flagable_configurations'
    return {
      endpoint,
      get: () =>
        this.get<{ flagableConfigurations: IFlagableConfiguration[] }>(
          endpoint,
        ),
      create: (payload: IFlagableConfigurationFormData) =>
        this.post<ICreateFlagableConfigurationResponse>(endpoint, payload),
      update: (id: number, payload: Partial<IFlagableConfigurationFormData>) =>
        this.patch<IUpdateFlagableConfigurationResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      getById: (id: number) =>
        this.get<IFlagableConfiguration>(`${endpoint}/${id}`),
      delete: (id: number) =>
        this.delete<IDeleteFlagableConfigurationResponse>(`${endpoint}/${id}`),
    }
  }

  get creditReferences() {
    const endpoint = '/credit_references'
    return {
      create: (payload: ICreditReferenceFormData) =>
        this.post<ICreateCreditReferenceResponse>(endpoint, payload),
    }
  }

  get personLinks() {
    const endpoint = '/person_links'
    return {
      create: (payload: IPersonLinkFormData) => this.post(endpoint, payload),
    }
  }

  get personalGuarantees() {
    const endpoint = '/personal_guarantees'
    return {
      create: (payload: IPersonalGuaranteeFormData) =>
        this.post<ICreatePersonalGuarantee>(endpoint, payload),
    }
  }

  get scheduleLoads() {
    const endpoint = '/schedule_loads'
    return {
      endpoint,
      get: (query: IGetScheduleLoadsParams) => {
        return this.get<IGetScheduleLoadsResponse>(endpoint, { query })
      },
      new: (query: IGetMockScheduleLoadsParams) =>
        this.get<IGetMockScheduleLoadsPayload>(`${endpoint}/new`, { query }),
      createNew: (payload: ICreateMockScheduleLoadPayload) =>
        this.post<ICreateMockScheduleLoadResponse>(`${endpoint}/new`, payload),
      update: (id: number, payload: IUpdateScheduleLoadFormData) =>
        this.patch<IUpdateScheduleLoadResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get schedules() {
    const endpoint = '/schedules'
    return {
      endpoint,
      get: (query?: IGetSchedulesParams) =>
        this.get<IGetSchedulesResponse>(endpoint, { query }),
      getById: (id: number) => this.get<ISchedule>(`${endpoint}/${id}`),
      update: (id: number, payload: IUpdateSchedulePayload) =>
        this.patch<IUpdateScheduleResponse>(`${endpoint}/${id}`, payload),
      create: (payload: any) => this.post<ISchedule>(endpoint, payload),
    }
  }

  get uoms() {
    const endpoint = '/uoms'
    return {
      endpoint,
      get: () => this.get<IGetUomsResponse>(endpoint),
      createUom: (payload: ICreateUomPayload) =>
        this.post<IUom>(`${endpoint}`, payload),
    }
  }

  get events() {
    const endpoint = '/events'
    return {
      endpoint,
      get: (query: IGetEventsParams) =>
        this.get<IGetEventsResponse>(endpoint, { query }),
      create: (payload: ICreateEventPayload) =>
        this.post<IEvent>(endpoint, payload),
      update: (id: number, payload: IUpdateEventPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get offdays() {
    const endpoint = '/offdays'
    return {
      endpoint,
      get: (query: IGetOffdaysParams) =>
        this.get<IGetOffdaysResponse>(endpoint, { query }),
      create: (payload: ICreateOffdayPayload) =>
        this.post<ICreateOffdayResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateOffdayPayload) =>
        this.patch<IUpdateOffdayResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete<IOffday>(`${endpoint}/${id}`),
    }
  }

  get allowedHierarchies() {
    const endpoint = '/allowed_hierarchies'
    return {
      endpoint,
      get: (query?: Partial<IGetAllowedHierarchiesParams>) =>
        this.get<IGetAllowedHierarchiesResponse>(endpoint, { query }),
      new: () => this.get<IGetAllowedHierarchiesNewResponse>(`${endpoint}/new`),
      create: (payload: ICreateAllowedHierarchyPayload) =>
        this.post<ICreateAllowedHierarchyResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateAllowedHierarchyPayload) =>
        this.patch<IUpdateAllowedHierarchyResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      reorder: (query: IGetReorderAllowedHierarchies) =>
        this.get(`${endpoint}/0/edit`, { query }),
    }
  }

  get hierarchies() {
    const endpoint = '/hierarchies'
    return {
      endpoint,
      get: (query?: Partial<IGetHierarchiesParams>) =>
        this.get<IGetHierarchiesResponse>(endpoint, { query }),
      create: (payload: ICreateHierarchyPayload) =>
        this.post<ICreateHierarchyResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateHierarchyPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      reorder: (query: IGetReorderHierarchies) =>
        this.get(`${endpoint}/0/edit`, { query }),
    }
  }

  get hierarchyRows() {
    const endpoint = '/hierarchy_rows'
    return {
      endpoint,
      get: (query?: Partial<IGetHierarchyRowsParams>) =>
        this.get<IGetHierarchyRowsResponse>(endpoint, { query }),
      create: (payload: ICreateHierarchyRowPayload) =>
        this.post<IHierarchyRowCreateResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateHierarchyRowPayload) =>
        this.patch<IHierarchyRowUpdateResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      reorder: (query: IGetReorderHierarchyRows) =>
        this.get(`${endpoint}/0/edit`, { query }),
    }
  }

  get terminalGroups() {
    const endpoint = '/terminal_groups'
    return {
      endpoint,
      get: () => this.get<ITerminalGroup[]>(endpoint),
    }
  }

  get areas() {
    const endpoint = '/areas'
    return {
      endpoint,
      get: () => this.get<IGetAreasResponse>(endpoint),
    }
  }

  get zones() {
    const endpoint = '/zones'
    return {
      endpoint,
      get: () => this.get<IGetZonesResponse>(endpoint),
    }
  }

  get markets() {
    const endpoint = '/markets'
    return {
      endpoint,
      get: () => this.get<IMarket[]>(endpoint),
    }
  }

  get minorMarkets() {
    const endpoint = '/minor_markets'
    return {
      endpoint,
      get: () => this.get<IMinorMarket[]>(endpoint),
    }
  }

  get glCodes() {
    const endpoint = '/gl_codes'
    return {
      endpoint,
      get: () => this.get<IGetGlCodesResponse>(endpoint),
      create: (payload: ICreateGlCodePayload) =>
        this.post<ICreateGlCodeResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateGlCodePayload) =>
        this.patch<IUpdateGlCodeResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get earningCodes() {
    const endpoint = '/earning_codes'
    return {
      get: (query: IGetEarningCodeParams) =>
        this.get<IGetEarningCodeResponse>(endpoint, { query }),
      create: (payload: any) => this.post(endpoint, payload),
      update: (id: number, payload: any) =>
        this.post(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get earningRates() {
    const endpoint = '/earning_rates'
    return {
      get: (query: IGetEarningRateParams) =>
        this.get<IGetEarningRateResponse>(endpoint, { query }),
      create: (payload: ICreateEarningRatePayload) =>
        this.post(endpoint, payload),
      update: (id: number, payload: IUpdateEarningRatePayload) =>
        this.patch<IGetEarningRateResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get sensors() {
    const endpoint = '/sensors'
    return {
      endpoint,
      get: (query: IGetSensorParams) => this.get(endpoint, { query }),
    }
  }

  get paymentTypes() {
    const endpoint = '/payment_types'
    return {
      endpoint,
      get: () => this.get<IGetPaymentTypesResponse>(endpoint),
      create: (payload: ICreatePaymentTypePayload) =>
        this.post(endpoint, payload),
      update: (id: number, payload: IUpdatePaymentTypePayload) =>
        this.patch(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get payments() {
    const endpoint = '/payments'
    return {
      endpoint,
      get: (query: IGetPaymentsParams) =>
        this.get<IGetPaymentsResponse>(endpoint, { query }),
      create: (payload: ICreatePaymentPayload, query?: ICreatePaymentParams) =>
        this.post<ICreatePaymentResponse>(endpoint, payload, { query }),
      update: (id: number, payload: IUpdatePaymentPayload) =>
        this.patch<IUpdatePaymentResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      getById: (id: number) => this.get<IPayment>(`${endpoint}/${id}`),
    }
  }

  get adjustmentNotes() {
    const endpoint = '/adjustment_notes'
    return {
      endpoint,
      get: (query: IGetAdjustmentNotesParams) =>
        this.get<IGetAdjustmentNotesResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (payload: ICreateAdjustmentNotePayload) =>
        this.post<ICreateAdjustmentNoteResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateAdjustmentNotePayload) =>
        this.patch<IUpdateAdjustmentNoteResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get paymentPayables() {
    const endpoint = '/payment_payables'
    return {
      endpoint,
      get: (query: IGetPaymentPayablesParams) =>
        this.get<IGetPaymentPayableResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (payload: ICreatePaymentPayablePayload) =>
        this.post<ICreatePaymentPayableResponse>(endpoint, payload),
    }
  }

  get paymentSchedules() {
    const endpoint = '/payment_schedules'
    return {
      endpoint,
      new: (payload: IGetNewPaymentSchedulesPayload) =>
        this.post<IGetNewPaymentSchedulesResponse>(`${endpoint}/new`, payload),
      create: (payload: ICreatePaymentSchedulePayload) =>
        this.post(endpoint, payload),
    }
  }

  get autoExtras() {
    const endpoint = '/auto_extras'
    return {
      endpoint,
      get: (query: IGetAutoExtrasParams) =>
        this.get<IGetAutoExtrasResponse>(endpoint, { query }),
      create: (payload: ICreateAutoExtraPayload) =>
        this.post<ICreateAutoExtraResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateAutoExtraPayload) =>
        this.patch<IUpdateAutoExtraResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get pricings() {
    const endpoint = '/pricings'
    return {
      endpoint,
      get: (query: IGetPricingsParams) =>
        this.get<IGetPricingsResponse>(endpoint, { query }),
      create: (payload: ICreatePricingPayload) =>
        this.post<ICreatePricingResponse>(endpoint, payload),
      getById: (id: number) => this.get<IPricing>(`${endpoint}/${id}`),
      delete: (id: number, payload?: { invoiceId?: number }) =>
        this.delete(`${endpoint}/${id}`, payload),
      update: (id: number, payload: IUpdatePricingPayload) =>
        this.patch(`${endpoint}/${id}`, payload),
    }
  }

  get productGlCodes() {
    const endpoint = '/product_gl_codes'
    return {
      endpoint,
      get: (query: IGetProductGlCodesParams) =>
        this.get<IGetProductGlCodesResponse>(endpoint, { query }),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      create: (payload: ICreateProductGlCodePayload) =>
        this.post<ICreateProductGlCodeResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateProductGlCodePayload) =>
        this.patch<IUpdateProductGlCodeResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get taxes() {
    const endpoint = '/taxes'
    return {
      endpoint,
      get: (query: IGetTaxesParams) =>
        this.get<IGetTaxesResponse>(endpoint, { query }),
      create: (payload: ICreateTaxPayload) =>
        this.post<ICreateTaxResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateTaxPayload) =>
        this.patch<IUpdateTaxResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get productTaxOverrides() {
    const endpoint = '/product_tax_overrides'
    return {
      endpoint,
      get: (query: IGetProductTaxOverridesParams) =>
        this.get<IGetProductTaxOverridesResponse>(endpoint, { query }),
      create: (payload: ICreateProductTaxOverridePayload) =>
        this.post<ICreateProductTaxOverrideResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateProductTaxOverridePayload) =>
        this.patch<IUpdateProductTaxOverrideResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get paymentTerms() {
    const endpoint = '/payment_terms'
    return {
      endpoint,
      get: (query: IGetPaymentTermsParams) =>
        this.get<IGetPaymentTermsResponse>(endpoint, { query }),
      create: (payload: ICreatePaymentTermPayload) =>
        this.post<ICreatePaymentTermResponse>(endpoint, payload),
      update: (id: number, payload: IUpdatePaymentTermPayload) =>
        this.patch<IUpdatePaymentTermResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get statements() {
    const endpoint = '/statements'
    return {
      endpoint,
      get: () => this.get<IGetStatementsResponse>(endpoint),
      create: (payload: ICreateStatementPayload) =>
        this.post<ICreateStatementResponse>(endpoint, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get settings() {
    const endpoint = '/settings'
    return {
      endpoint,
      new: () => this.get<IGetSettingNewValuesResponse>(`${endpoint}/new`),
      get: (query: IGetSettingsParams) =>
        this.get<IGetSettingsResponse>(endpoint, { query }),
      create: (payload: ICreateSettingPayload) =>
        this.post<ICreateSettingResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateSettingPayload) =>
        this.patch<IUpdateSettingResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get bundles() {
    const endpoint = '/bundles'
    return {
      endpoint,
      get: (query: IGetBundlesParams) =>
        this.get<IGetBundlesResponse>(endpoint, { query }),
      create: (payload: ICreateBundlePayload) =>
        this.post<ICreateBundleResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateBundlePayload) =>
        this.patch<IUpdateBundleResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get bundleItems() {
    const endpoint = '/bundle_items'
    return {
      endpoint,
      get: (query?: IGetBundleItemsParams) =>
        this.get<IGetBundleItemsResponse>(endpoint, { query }),
      create: (payload: ICreateBundleItemPayload) =>
        this.post<ICreateBundleItemResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateBundleItemPayload) =>
        this.patch<IUpdateBundleItemResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get rerank() {
    const endpoint = '/rerank'
    return {
      endpoint,
      rerank: (payload: IRerankPayload) => this.post(endpoint, payload),
    }
  }

  get cos() {
    const endpoint = '/cos'
    return {
      createAccount: (payload: ICreateCOSAccountPayload) =>
        this.post(`${endpoint}/accounts`, payload),
      createAccountUser: (payload: any) =>
        this.post(`${endpoint}/account_users`, payload),
      login: (payload: any) =>
        this.post(`${endpoint}/account_users/${payload.userAccessId}/login`),
    }
  }

  get campaigns() {
    const endpoint = '/campaigns'
    return {
      endpoint,
      get: (query?: IGetCampaignsParams) =>
        this.get<IGetCampaignsResponse>(endpoint, { query }),
      create: (payload: ICreateCampaignPayload) =>
        this.post<ICreateCampaignResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateCampaignPayload) =>
        this.patch<IUpdateCampaignResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get calculations() {
    const endpoint = '/calculations'
    return {
      endpoint,
      get: (query?: any) =>
        this.get<IGetCalculationsResponse>(endpoint, { query }),
      getById: (id: number, query?: IGetCalculationParams) =>
        this.get<ICalculation>(`${endpoint}/${id}`, { query }),
      create: (payload: ICreateCalculationPayload) =>
        this.post<ICreateCalculationResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateCalculationPayload) =>
        this.patch<IUpdateCalculationResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
      new: () => this.get<IGetNewCalculationResponse>(`${endpoint}/new`),
    }
  }

  get rewardSettings() {
    const endpoint = '/reward_settings'
    return {
      endpoint,
      get: () => this.get<IGetRewardSettingsResponse>(endpoint),
      create: (payload: ICreateRewardSettingPayload) =>
        this.post<ICreateRewardSettingResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateRewardSettingPayload) =>
        this.patch<IUpdateRewardSettingResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get prizes() {
    const endpoint = '/prizes'
    return {
      endpoint,
      get: (query: IGetPrizesParams) =>
        this.get<IGetPrizesResponse>(endpoint, { query }),
      create: (payload: ICreatePrizePayload) =>
        this.post<ICreatePrizeResponse>(endpoint, payload),
      update: (id: number, payload: IUpdatePrizePayload) =>
        this.patch<IUpdatePrizeResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get contests() {
    const endpoint = '/contests'
    return {
      endpoint,
      get: (query: IGetContestsParams) =>
        this.get<IGetContestsResponse>(endpoint, { query }),
      create: (payload: ICreateContestPayload) =>
        this.post<ICreateContestResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateContestPayload) =>
        this.patch<IUpdateContestResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get rewards() {
    const endpoint = '/rewards'
    return {
      endpoint,
      get: (query: IGetRewardsParams) =>
        this.get<IGetRewardsResponse>(endpoint, { query }),
      create: (payload: ICreateRewardPayload) =>
        this.post<ICreateRewardResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateRewardPayload) =>
        this.patch<IUpdateRewardResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get mixDetails() {
    const endpoint = '/mix_details'
    return {
      endpoint,
      get: (query: IGetMixDetailsParams) =>
        this.get<IGetMixDetailResponse>(endpoint, { query }),
      create: (payload: ICreateMixDetailPayload) =>
        this.post<ICreateMixDetailResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateMixDetailPayload) =>
        this.patch<IUpdateMixDetailResponse>(`${endpoint}/${id}`, payload),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get redemptions() {
    const endpoint = '/redemptions'
    return {
      endpoint,
      get: (query: IGetRedemptionsParams) =>
        this.get<IGetRedemptionsResponse>(endpoint, { query }),
      create: (payload: ICreateRedemptionPayload) =>
        this.post<ICreateRedemptionResponse>(endpoint, payload),
      getById: async (id: number) => {
        const { url } = await this.patch(`${endpoint}/${id}`)
        const parsedData = JSON.parse(url)
        return {
          id: parsedData.reward?.id,
          link: parsedData.reward?.link,
          errors: parsedData.errors?.message,
        }
      },
    }
  }

  get stripe() {
    const endpoint = '/stripe'
    return {
      endpoint,
      createCustomer: (payload: IStripeCustomerCreatePayload) =>
        this.post<ICompany>(`${endpoint}/create_customer`, payload),
      getPaymentMethods: (query: IStripePaymentMethodGetListParams) =>
        this.get<IStripePaymentMethodGetListResponse>(
          `${endpoint}/payment_methods`,
          { query },
        ),
      setupIntent: (payload: IStripeSetupIntentPayload) =>
        this.post<IStripeSetupIntentResponse>(
          `${endpoint}/setup_intent`,
          payload,
        ),
      setupIntentSucceeded: (payload: IStripeSetupIntentSucceededPayload) =>
        this.post(`${endpoint}/setup_intent_succeeded`, payload),
      createAccount: (payload: IStripeCreateAccountPayload) =>
        this.post<IStripeCreateAccountResponse>(
          `${endpoint}/create_account`,
          payload,
        ),
      createAccountSession: (payload: IStripeCreateAccountSessionPayload) =>
        this.post<IStripeCreateAccountResponse>(
          `${endpoint}/create_account_session`,
          payload,
        ),
      createPaymentIntent: (payload: IStripeCreatePaymentIntentPayload) =>
        this.post<IStripeCreatePaymentIntentResponse>(
          `${endpoint}/payment_intent`,
          payload,
        ),
      getStripeAccount: () =>
        this.get<IStripeGetAccountResponse>(`${endpoint}/get_stripe_account`),
    }
  }

  get groups() {
    const endpoint = '/groups'
    return {
      endpoint,
      get: (query?: IGetGroupsParams) =>
        this.get<IGetGroupsResponse>(endpoint, { query }),
      create: (payload: ICreateGroupPayload) =>
        this.post<ICreateGroupResponse>(endpoint, payload),
      update: (id: number, payload: IUpdateGroupPayload) =>
        this.patch<IUpdateGroupResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get productTypes() {
    const endpoint = '/product_types'
    return {
      endpoint,
      get: () => this.get<IGetProductTypesResponse>(endpoint),
    }
  }

  get sellerToBuyerTerminals() {
    const endpoint = '/seller_to_buyer_terminals'
    return {
      endpoint,
      get: (query: ISellerToBuyerTerminalGetListParams) =>
        this.get<ISellerToBuyerTerminalGetListResponse>(endpoint, { query }),
      create: (payload: ISellerToBuyerTerminalCreatePayload) =>
        this.post<ISellerToBuyerTerminalCreateResponse>(endpoint, payload),
      update: (id: number, payload: ISellerToBuyerTerminalCreatePayload) =>
        this.patch<ISellerToBuyerTerminalCreateResponse>(
          `${endpoint}/${id}`,
          payload,
        ),
      delete: (id: number) => this.delete(`${endpoint}/${id}`),
    }
  }

  get workTypes() {
    const endpoint = '/work_types'
    return {
      endpoint,
      get: () => this.get<IWorkTypeGetListResponse>(endpoint),
      create: (payload: IWorkTypeCreatePayload) =>
        this.post<IWorkTypeCreateResponse>(endpoint, payload),
      update: (id: number, payload: IWorkTypeUpdatePayload) =>
        this.patch<IWorkTypeUpdateResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get workForces() {
    const endpoint = '/work_forces'
    return {
      endpoint,
      get: () => this.get<IWorkForceGetListResponse>(endpoint),
      create: (payload: IWorkForceCreatePayload) =>
        this.post<IWorkForceCreateResponse>(endpoint, payload),
      update: (id: number, payload: IWorkForceUpdatePayload) =>
        this.patch<IWorkForceUpdateResponse>(`${endpoint}/${id}`, payload),
    }
  }

  get groupTerminals() {
    const endpoint = '/group_terminals'
    return {
      endpoint,
      get: () => this.get<IGroupTerminalGetList>(endpoint),
    }
  }

  get testResults() {
    const endpoint = '/test_results'
    return {
      endpoint,
      create: (payload: ITestResultCreatePayload) =>
        this.post<ITestResultCreateResponse>(endpoint, payload),
      new: () => this.get<ITestResultNewResponse>(`${endpoint}/new`),
      get: (query: ITestResultGetParams) =>
        this.get<ITestResultGetResponse>(endpoint, { query }),
      update: (payload: ITestResultUpdatePayload) =>
        this.patch<ITestResultUpdateResponse>(
          `${endpoint}/${payload.id}`,
          payload,
        ),
    }
  }

  get loadStatusTable() {
    const endpoint = '/load_status_rows'
    return {
      endpoint,
      get: (query: ILoadStatusTableRowGetParams) =>
        this.get<ILoadStatusTableRowGetResponse>(endpoint, { query }),
    }
  }

  get weights() {
    const endpoint = '/weights'
    return {
      endpoint,
      get: (query: IWeightGetListParams) =>
        this.get<IWeightGetListResponse>(endpoint, { query }),
    }
  }

  get emailInvoices() {
    const endpoint = '/email_invoices'
    return {
      endpoint,
      create: (
        payload: IEmailInvoicesCreatePayload,
        query?: {
          documentToInclude?: 'invoice' | 'tickets' | 'combined' | 'separate'
        },
      ) => this.post<{ message: string }>(endpoint, payload, { query }),
    }
  }

  get uploads() {
    const endpoint = '/uploads'
    return {
      endpoint,
      create: (payload: IUploadCreatePayload) =>
        this.post<IUploadCreateResponse>(endpoint, payload, {
          isFormData: true,
        }),
      get: (query: IUploadGetListParams) =>
        this.get<IUploadGetListResponse>(endpoint, { query }),
    }
  }

  get sentEmails() {
    const endpoint = '/sent_emails'
    return {
      endpoint,
      get: (query: IGetSentEmailsParams) =>
        this.get<{ sentEmails: ISentEmail[] }>(endpoint, { query }),
    }
  }
}

export const apiClient = new ApiClient()

export default ApiClient
