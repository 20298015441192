import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useLoadDocument from './loadDocumentHook'
import { useLocation } from 'react-router-dom'
import { IonRow, IonCol } from '@ionic/react'
import DocumentsHeader from './DocumentsHeader'
import TableView from './TableView'
import moment from 'moment'
import { produce } from 'immer'
import clsx from 'clsx'
import { updateCompleteFilters } from '~/redux/actions/documentsActions'

import './styles.scss'
import { apiClient } from '~/api/ApiClient'

const InvoiceDocumentList = ({
  documentRef,
  isGrid,
  storageUrl,
  onDoneSelection,
  setSavedFilters,
}) => {
  const dispatch = useDispatch()
  const { invoice } = useSelector(state => state.invoice)
  const [, setIsEmbedLoading] = useState(true)
  const documentsRef = useRef(documents)
  const [columnsDisplayed, setColumnsDisplayed] = useState([
    {
      label: '',
      sortField: 'checkbox',
      sorted: true,
      onDoneSelection: onDoneSelection,
    },
    {
      label: 'Date',
      sortField: 'createdAt',
      sorted: true,
    },
    {
      label: 'Name',
      sortField: 'name',
      sorted: true,
    },
    {
      label: 'User Email',
      sortField: 'userEmail',
      sorted: true,
    },
    {
      label: 'Document Type',
      sortField: 'docType',
      sorted: true,
    },
    {
      label: 'Buyer Terminal',
      sortField: 'buyerTerminalId',
      sorted: true,
    },
    {
      label: 'Seller Terminal',
      sortField: 'sellerTerminalId',
      sorted: true,
    },
    {
      label: 'Status',
      sortField: 'status',
      sorted: true,
    },
    {
      label: 'Expand',
      sortField: 'expand',
      sorted: true,
    },
  ])

  const location = useLocation()
  const {
    filters,
    isLoading,
    isLoadingMore,
    documents,
    loadDocumentRef,
    sortOptions,
    setDocuments,
    onSortChange,
    onHeaderSort,
    onUpdateFilter,
    onUpdateIonFilter,
    onSearchKeyChange,
    onCreatedDateRangeChange,
    onFiltersReset,
    onLoadDocuments,
    totalItems,
    startRange,
    endRange,
    perPage,
    page,
    handleChangePage,
    handleChangePerPage,
  } = useLoadDocument(location, true, false)

  useEffect(() => {
    setReduxFilters()
  }, [setReduxFilters])

  //Call load file every time click on assign image button
  const setReduxFilters = useCallback(async () => {
    setSavedFilters(filters)

    const params = new URL(`${process.env.REACT_APP_API_URL}${storageUrl}`)
      .searchParams
    const sellerId = params.get('sellerId')
    const buyerTerminalId = invoice.buyerTerminalId || 'null'
    const defaultFilterValues = {
      docType: [2],
      status: ['New'],
      sellers: [+sellerId],
      buyerTerminals: [buyerTerminalId],
    }

    const newFilters = Object.assign({}, filters, defaultFilterValues)
    dispatch(updateCompleteFilters(newFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, filters, setSavedFilters, dispatch])

  const onUpdate = useCallback(
    async document => {
      const result = await apiClient.documents
        .update(document.id, {
          name: document.name,
        })
        .catch(err => {
          alert(err.message)
        })
      if (result) {
        //update at local
        setDocuments(documents.map(d => (d.id == document.id ? document : d)))
      }
    },
    [documents, setDocuments],
  )

  const onUpdateSeller = useCallback(
    async document => {
      try {
        const { id, sellerId, sellerName, sellerLogo } = document
        await apiClient.documents.update(id, { sellerId })
        if (
          filters.sellers.length == 0 ||
          filters.sellers.some(id => id == sellerId)
        ) {
          //does new seller exist in filters
          setDocuments(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(doc => doc.id === id)

              draft[index].sellerId = sellerId
              draft[index].sellerName = sellerName
              draft[index].sellerLogo = sellerLogo
            }),
          )
        } else {
          setDocuments(prev =>
            produce(prev, draft => {
              const index = draft.findIndex(doc => doc.id === id)
              if (index !== -1) draft.splice(index, 1)
            }),
          )
        }
      } catch (error) {
        alert(error.message)
      }
    },
    [setDocuments, filters],
  )

  const onUpdateDocType = useCallback(
    async document => {
      try {
        const { id, docType } = document
        await apiClient.documents.update(id, { docType })
        setDocuments(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(doc => doc.id === id)
            draft[index].docType = docType
          }),
        )
      } catch (error) {
        alert(error.message)
      }
    },
    [setDocuments],
  )

  const onUpdateBuyerTerminal = useCallback(
    async (id, buyerTerminalId) => {
      try {
        await apiClient.documents.update(id, { buyerTerminalId })
        setDocuments(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(doc => doc.id === id)
            draft[index].buyerTerminalId = buyerTerminalId
          }),
        )
      } catch (error) {
        alert(error.message)
      }
    },
    [setDocuments],
  )

  const onUpdateSellerTerminal = useCallback(
    async (id, sellerTerminalId) => {
      try {
        await apiClient.documents.update(id, { sellerTerminalId })
        setDocuments(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(doc => doc.id === id)
            draft[index].sellerTerminalId = sellerTerminalId
          }),
        )
      } catch (error) {
        alert(error.message)
      }
    },
    [setDocuments],
  )

  const onAdd = useCallback(
    files => {
      const _files = files.map(f => ({
        ...f,
        name: f.name,
        url: f.file.url,
        createdAt: moment(f.createdAt).format('YYYY, MMM-D HH:mm'),
      }))
      const allFiles = [...documents, ..._files]
      setDocuments(allFiles)
    },
    [documents, setDocuments],
  )

  useEffect(() => {
    documentRef.current = {
      reloadDocuments: () => {
        //reload all documents
        loadDocumentRef.current(true)
      },
      addDocuments: documents => {
        onAdd(documents)
      },
    }
  }, [documentRef, loadDocumentRef, onAdd])

  useEffect(() => {
    setIsEmbedLoading(true)

    const showEmbed = async fn => {
      await fn(2500)
      setIsEmbedLoading(false)
    }
    const sleep = ms => {
      return new Promise(result => setTimeout(result, ms))
    }
    if (isGrid) {
      showEmbed(sleep)
    }
  }, [setIsEmbedLoading, isGrid])

  /**
   * a callback event is called as soon as creating a load successfully
   * @param {object} createdLoad this is a new created load
   */
  const afterCreateLoad = useCallback(
    createdLoad => {
      setDocuments(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === createdLoad.id)
          if (index !== -1) {
            // remove if it contains a status which is different to current filters
            if (
              !filters.status.includes(createdLoad.status) &&
              filters.status.length > 0
            ) {
              draft.splice(index, 1)
            } else {
              draft[index].status = createdLoad.status
            }
          }
        }),
      )
    },
    [filters.status, setDocuments],
  )

  /**
   * a callback event is called as soon as deleting a document successfully
   * @param {object} deleteDocument this is a deleted document
   */
  const afterDeleteDocument = useCallback(
    deletedDocument => {
      setDocuments(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === deletedDocument.id)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }),
      )
    },
    [setDocuments],
  )

  useEffect(() => {
    documentsRef.current = documents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  const handleChangeColumnDisplayed = useCallback(
    newValue => {
      setColumnsDisplayed(newValue)
    },
    [setColumnsDisplayed],
  )

  return (
    <>
      <div className='DocumentList__container'>
        <div
          className={clsx('DocumentList__container', { tableView: !isGrid })}
        >
          <IonRow lines='none'>
            <IonCol
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <DocumentsHeader
                filters={filters}
                onUpdateFilter={onUpdateFilter}
                onCreatedDateRangeChange={onCreatedDateRangeChange}
                onUpdateIonFilter={onUpdateIonFilter}
                onSearchKeyChange={onSearchKeyChange}
                onFiltersReset={onFiltersReset}
                sortOptions={sortOptions}
                onSortChange={onSortChange}
                columnsDisplayed={columnsDisplayed}
                onChangeColumnsDisplayed={handleChangeColumnDisplayed}
              />
            </IonCol>
          </IonRow>
          <TableView
            isLoading={isLoading}
            documents={documents}
            isLoadingMore={isLoadingMore}
            onUpdateSeller={onUpdateSeller}
            onUpdateDocType={onUpdateDocType}
            onLoadDocuments={onLoadDocuments}
            onUpdate={onUpdate}
            sortOptions={sortOptions}
            onHeaderSort={onHeaderSort}
            afterCreateLoad={afterCreateLoad}
            afterDeleteDocument={afterDeleteDocument}
            onUpdateBuyerTerminal={onUpdateBuyerTerminal}
            onUpdateSellerTerminal={onUpdateSellerTerminal}
            columnsDisplayed={columnsDisplayed}
            totalItems={totalItems}
            startRange={startRange}
            endRange={endRange}
            perPage={perPage}
            page={page}
            onChangePage={handleChangePage}
            onChangePerPage={handleChangePerPage}
            setDocuments={setDocuments}
          />
        </div>
      </div>
    </>
  )
}

export default InvoiceDocumentList
