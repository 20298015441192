import {
  DropdownWithCustomChildren,
  IConcordFormDropdownProps,
} from '~/components/shared'
import './styles.scss'
import { IHierarchyFormProps } from '../HierarchyForm'
import { useMemo } from 'react'
import { useQueryAllowedHierarchies } from '~/hooks/useQueryData'
import useGetHierarchyLabel from '~/hooks/useGetHierarchyLabel'
import _ from 'lodash'
import clsx from 'clsx'
import CustomAllowedHierarchyOption from './CustomAllowedHierarchyOption'

export interface IAllowedHierarchySelectorProps {
  hierarchyItem?: IHierarchyFormProps['item']
  value?: any
  error?: string
  onChange?: IConcordFormDropdownProps<any>['onChange']
}

function AllowedHierarchySelector(props: IAllowedHierarchySelectorProps) {
  const { hierarchyItem, value, error, onChange } = props

  const { allowedHierarchyOptions, findAllowedHierarchyById } =
    useQueryAllowedHierarchies()

  const { getHierarchyLabel } = useGetHierarchyLabel()

  const allowedHierarchy = findAllowedHierarchyById(value)

  const allowedHierarchyOpts = useMemo(() => {
    if (hierarchyItem) {
      return _.orderBy(allowedHierarchyOptions, ['rank'], ['asc']).map(data => {
        const { sellerSideType, buyerSideType, value } = data
        const label = getHierarchyLabel({
          sellerSideType,
          buyerSideType,
          sellerSideId:
            sellerSideType === 'Seller'
              ? hierarchyItem?.sellerId
              : hierarchyItem?.sellerTerminalId,
          buyerSideId:
            buyerSideType === 'Buyer'
              ? hierarchyItem?.buyerId
              : hierarchyItem?.buyerTerminalId,
        })

        return {
          value,
          label,
          buyerSideType,
          sellerSideType,
          item: data.item,
        }
      })
    }
    return _.orderBy(allowedHierarchyOptions, ['rank'], ['asc'])
  }, [allowedHierarchyOptions, getHierarchyLabel, hierarchyItem])

  return (
    <div>
      <DropdownWithCustomChildren
        options={allowedHierarchyOpts}
        value={value}
        className={clsx(
          'make-custom-dropdown-inline no-hover w-100 AllowedHierarchySelector__container',
        )}
        styles={{
          container: provided => ({
            ...provided,
            maxWidth: 'initial',
            padding: '8px 0',
            borderRadius: 4,
            width: '100%',
            border: '2px dashed #e5e5e5',
            borderWidth: allowedHierarchy ? 0 : 2,
            borderColor: error ? 'red' : '#e5e5e5',
          }),
          valueContainer: provided => ({
            ...provided,
            display: 'block',
          }),
        }}
        onChange={onChange}
        components={{
          Option: CustomAllowedHierarchyOption,
        }}
      >
        {allowedHierarchy ? (
          <div className='AllowedHierarchySelector__labelGrid'>
            <div style={{ width: 210, fontWeight: 600 }}>
              {_.startCase(allowedHierarchy.sellerSideType) || '-'}:{' '}
            </div>

            {allowedHierarchy.buyerSideType && (
              <div style={{ width: 210, fontWeight: 600 }}>
                | {_.startCase(allowedHierarchy.buyerSideType) || '-'}:{' '}
              </div>
            )}
          </div>
        ) : (
          <div>
            <div style={{ marginLeft: 4 }}>
              Select Allowed Hierarchy{' '}
              <span className='ConcordFormLayout__asterisk'>*</span>
            </div>
          </div>
        )}
      </DropdownWithCustomChildren>
      {error && (
        <div
          className='error'
          style={{
            marginTop: 4,
            fontSize: 13,
          }}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export default AllowedHierarchySelector
