import { ISentEmail } from '~/types/models/IInvoice'

import './styles.scss'
import moment from 'moment'
import { ToolTipOverlay } from '../ToolTipOverlay'
import { Badge } from 'react-bootstrap'

export interface ISentEmailCardProps {
  sentEmail: ISentEmail
  index: number
}

function SentEmailCard(props: ISentEmailCardProps) {
  const { sentEmail, index } = props

  return (
    <div className='SentEmailCard__container'>
      <Badge className='counter'>{index + 1}</Badge>
      <div className='header'>
        <h3>{sentEmail.subject}</h3>
        <ToolTipOverlay
          content={`Status Code: ${sentEmail.statusCode}`}
          allowToShow={Boolean(sentEmail.statusCode)}
          placement='top'
        >
          <p className={`status ${sentEmail.status}`}>{sentEmail.status}</p>
        </ToolTipOverlay>
      </div>
      <div className='email-details'>
        <p>
          <strong>To:</strong> {sentEmail.to}
        </p>

        {sentEmail.ccEmails && sentEmail.ccEmails.length > 0 && (
          <p className='cc-emails'>
            <strong>CC:</strong> {sentEmail.ccEmails.join(', ')}
          </p>
        )}

        <p>
          <strong>From:</strong> {sentEmail.from}
        </p>
        {sentEmail.eventTimestamp && (
          <p>
            <strong>Latest Update:</strong>{' '}
            {moment(sentEmail.eventTimestamp * 1000).format('MMM-D H:mm:ss')}
          </p>
        )}
        <p>
          <strong>Category:</strong> {sentEmail.category}
        </p>
        <p>
          <strong>Open Count:</strong> {sentEmail.openCount} |{' '}
          <strong>Click Count:</strong> {sentEmail.clickCount}
        </p>
      </div>
      <div className='email-content'>
        <div dangerouslySetInnerHTML={{ __html: sentEmail.content }} />
      </div>
    </div>
  )
}

export default SentEmailCard
