import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'
import { getRegex } from '~/components/company/DocumentList/DialogEmailContent/HighLightText/utils'
import MultipleHighLightText from '~/components/company/DocumentList/DialogEmailContent/MultipleHighLightText'
import {
  ContainerSearchBar,
  CopyIcon,
  RefreshIcon,
  ReusableButton,
} from '~/components/shared'
import { toastMessages } from '~/constants/toast-status-text'
import { IBuyerSellerRegexp } from '~/types/models/IBuyerSellerRegexp'
import { ILoad } from '~/types/models/ILoad'
import { RegexpList } from './RegexpList'
import getCountMatches from '~/utils/getCountMatches'

import './styles.scss'

export interface ITesseractTextSectionProps {
  tesseractText?: string
  loadId?: number
  regexp?: string
  onChangeRegexp?: (regexp?: string) => void
  afterRerunOcr?: (load: ILoad) => void
  regexpsData: IBuyerSellerRegexp[]
  afterUpdateRegexp: (regexp: IBuyerSellerRegexp) => void
  className?: string
  showRegexpList?: boolean
  regexIdSelected?: number
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  onSelectRegex?: (regexp: IBuyerSellerRegexp) => void
}

function TesseractTextSection(props: ITesseractTextSectionProps) {
  const {
    tesseractText,
    loadId,
    // regexp,
    regexpsData,
    className,
    showRegexpList,
    regexIdSelected,
    searchText,
    setSearchText,
    onChangeRegexp,
    afterRerunOcr,
    afterUpdateRegexp,
    onSelectRegex,
  } = props

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [countMatchingWords, setCountMatchingWords] = useState(0)
  const [isRunningOCR, setIsRunningOCR] = useState(false)

  const [menuProps, toggleMenu] = useMenuState()
  const containerRef = useRef<any>()

  const regexSelected = useMemo(() => {
    if (regexIdSelected && regexpsData) {
      return regexpsData?.find(({ id }) => id === regexIdSelected)
    }
    return undefined
  }, [regexIdSelected, regexpsData])

  const matchingRegexps = useMemo(
    () =>
      (regexpsData || []).filter(({ regexp }) => {
        const regex = getRegex(regexp)
        const countMatches = getCountMatches(tesseractText || '', regex)
        return countMatches > 0
      }),
    [tesseractText, regexpsData],
  )

  const unmatchingRegexps = useMemo(() => {
    if (regexpsData && tesseractText) {
      const matchingRegexpIds = matchingRegexps.map(({ id }) => id)
      return regexpsData.filter(({ id }) => !matchingRegexpIds.includes(id))
    }
    return []
  }, [tesseractText, matchingRegexps, regexpsData])

  const onCopyContent = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      const content = window?.getSelection()?.toString()
      setSearchText(content || '')
      if (content) {
        setAnchorPoint({
          x: event.clientX,
          y: event.clientY + 12,
        })
        toggleMenu(true)
      } else {
        setAnchorPoint({
          x: 0,
          y: 0,
        })
        toggleMenu(false)
      }
    },
    [setSearchText, toggleMenu],
  )

  const onRunOCR = useCallback(async () => {
    setIsRunningOCR(true)
    try {
      const response = await apiClient.loads.rerunLoadOcr(loadId as number)
      if (response.ocrErrors?.message) {
        toast.error(response.ocrErrors?.message)
      } else {
        afterRerunOcr && afterRerunOcr(response)
        toast.success(toastMessages.updateSuccess)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsRunningOCR(false)
    }
  }, [afterRerunOcr, loadId])

  useEffect(() => {
    if (searchText.length) {
      const elements = document.getElementsByClassName(
        'TesseractTextSection__regexSearchVal',
      )
      setCountMatchingWords(elements.length)
    } else {
      setCountMatchingWords(0)
    }
  }, [searchText])

  useEffect(() => {
    if (regexSelected?.regexp) {
      setSearchText(regexSelected.regexp)
      containerRef.current!.scrollIntoView()
    } else {
      setSearchText('')
    }
  }, [regexSelected?.regexp, setSearchText])

  return (
    <>
      <div
        className={clsx('TesseractTextSection__section', className)}
        ref={containerRef}
      >
        <div className='TesseractTextSection__regexContent'>
          <div>
            <ContainerSearchBar
              searchBarValue={searchText}
              onSearchBarChange={(val: string) => {
                setSearchText(val)
              }}
            />

            {searchText.length ? (
              <Badge bg='secondary' style={{ marginLeft: 8 }}>
                {countMatchingWords}
              </Badge>
            ) : null}

            <ReusableButton
              tooltipContent={
                tesseractText ? 'Rerun Matching Process' : 'Run OCR'
              }
              style={{ marginLeft: 8 }}
              onClick={onRunOCR}
              isLoading={isRunningOCR}
            >
              <RefreshIcon color='white' />
            </ReusableButton>
          </div>

          <div onMouseUp={onCopyContent}>
            <MultipleHighLightText
              regexes={[
                // {
                //   regex: regexp,
                //   className: 'TesseractTextSection__regex',
                // },
                {
                  regex: getRegex(searchText),
                  className: 'TesseractTextSection__regexSearchVal',
                },
              ]}
            >
              {tesseractText}
            </MultipleHighLightText>
          </div>
        </div>

        {showRegexpList && (
          <div>
            <RegexpList
              label='Matching'
              regexpsData={matchingRegexps}
              isOpenByDefault={matchingRegexps.length > 0}
              content={tesseractText}
              canClick
              onClick={onSelectRegex}
              regexSelected={regexSelected}
              afterUpdate={afterUpdateRegexp}
            />
            <RegexpList
              label='Not Matching'
              regexpsData={unmatchingRegexps}
              content={tesseractText}
              afterUpdate={afterUpdateRegexp}
            />
          </div>
        )}
      </div>
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
      >
        <MenuItem
          style={{ fontSize: 13 }}
          onClick={() => {
            onChangeRegexp && onChangeRegexp(searchText)
          }}
        >
          <CopyIcon />
          <span style={{ marginLeft: 4 }}>Replace regexp with this text</span>
        </MenuItem>
        {/* <MenuItem
          style={{ fontSize: 13 }}
          onClick={() => {
            const splittedRegex = (searchText || '').split('|')
            onChangeRegexp &&
              onChangeRegexp(
                [...splittedRegex, searchText]
                  .filter(text => text?.length > 0)
                  .join('|'),
              )
          }}
        >
          <BranchCreateIcon />
          <span style={{ marginLeft: 4 }}>Combine with current regexp</span>
        </MenuItem> */}
      </ControlledMenu>
    </>
  )
}

export default TesseractTextSection
