import { Body } from '~/components'
import { UploadsContainerTable } from './UploadsContainerTable'

export const UploadsContainer = () => {
  return (
    <>
      <Body>
        <UploadsContainerTable />
      </Body>
    </>
  )
}
