import { CommonDialogV2 } from '~/components/shared'

import './styles.scss'
import { TicketView } from '~/components/shared/TicketView'

interface IDialogTicketViewProps {
  isOpen?: boolean
  onClose?: () => void
  loadId: number
}

function DialogTicketView(props: IDialogTicketViewProps) {
  const { isOpen, onClose, loadId } = props

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      isHiddenOkButton
      className='DialogTicketView__container'
      size='lg'
    >
      <TicketView loadId={loadId} />
    </CommonDialogV2>
  )
}
export default DialogTicketView
