import { useEffect, useCallback, useState } from 'react'

import {
  IonList,
  IonItem,
  IonLabel,
  IonChip,
  IonIcon,
  IonText,
} from '@ionic/react'
import { CommonDialog } from '~/components/shared'

import PropTypes from 'prop-types'
import { produce } from 'immer'
import * as Icons from 'ionicons/icons'
import moment from 'moment'
import { toast } from 'react-toastify'
import { apiClient } from '~/api/ApiClient'

const OrderTrackingDialogLoadStatus = props => {
  const {
    statuses,
    open,
    isUpdate,
    load,
    loading,
    onReloadData,
    onClose,
    ...dialogProps
  } = props

  const [loadingState, setLoadingState] = useState(false)
  const [loadingStatuses, setLoadingStatuses] = useState([])
  const [statusesState, setStatusesState] = useState([])

  useEffect(() => {
    setStatusesState(statuses)
  }, [statuses])

  const handleChangeLoadingStatuses = useCallback(statusId => {
    setLoadingStatuses(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(statusId)
        if (index === -1) {
          draft.push(statusId)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const removeStatus = useCallback(status => {
    setStatusesState(prev =>
      produce(prev, draft => {
        const index = draft.findIndex(item => item.id === status.id)
        if (index > -1) {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const handleUpdateLoadTime = useCallback(
    status => async () => {
      setLoadingState(true)
      handleChangeLoadingStatuses(status.id)
      try {
        const { load: loadResponse } = await apiClient.loadTimes.create({
          loadTime: {
            loadId: load.id,
            loadStatusRowId: status.id,
            time: new Date().toISOString(),
          },
        })
        if (loadResponse.currentStatus == 'Delivery Complete') {
          onReloadData && onReloadData()
          onClose && onClose()
        } else {
          removeStatus(status)
        }
        toast.success('Update status of load successfully')
      } catch {
        toast.error('There was a problem while trying to get data')
      } finally {
        setLoadingState(false)
        handleChangeLoadingStatuses(status.id)
      }
    },
    [load.id, onClose, onReloadData, handleChangeLoadingStatuses, removeStatus],
  )

  const renderStatus = useCallback(() => {
    if (statusesState.length === 0 && !loading && !loadingState) {
      return (
        <div
          style={{
            fontSize: 16,
            textAlign: 'center',
            width: '100%',
            marginTop: 4,
          }}
        >
          No status
        </div>
      )
    }

    return statusesState.map(status => (
      <IonItem key={status.id}>
        <IonLabel>
          {status.rank} - {status.name}
        </IonLabel>
        <div slot='end'>
          {!isUpdate && (
            <>
              <IonChip color='danger'>
                {Math.round(status.duration / 60)} min
              </IonChip>
              <IonChip color='concord'>
                {moment(status.time).format('H:mm')}
              </IonChip>
            </>
          )}

          {isUpdate && (
            <IonItem
              disabled={loadingStatuses.includes(status.id)}
              color={status.color}
              key={status.id}
              button
              onClick={handleUpdateLoadTime(status)}
            >
              <IonIcon icon={Icons[status.icon]} slot='start' />
              <IonText>{status.timeName}</IonText>
            </IonItem>
          )}
        </div>
      </IonItem>
    ))
  }, [
    statusesState,
    isUpdate,
    handleUpdateLoadTime,
    loadingStatuses,
    loading,
    loadingState,
  ])

  return (
    <CommonDialog
      open={open}
      loading={loading || loadingState}
      title='Statuses'
      onClose={onClose}
      {...dialogProps}
    >
      <IonList>{renderStatus()}</IonList>
    </CommonDialog>
  )
}

OrderTrackingDialogLoadStatus.propsTypes = {
  statuses: PropTypes.array,
  isUpdate: PropTypes.bool,
  load: PropTypes.object,
  onLoading: PropTypes.func,
  onReloadData: PropTypes.func,
  onClose: PropTypes.func,
}

OrderTrackingDialogLoadStatus.defaultProps = {}

export default OrderTrackingDialogLoadStatus
