import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '~/components'
import { toastMessages } from '~/constants/toast-status-text'
import { IStripePaymentMethodElementProps } from './type'
import { Alert } from 'react-bootstrap'

function StripePaymentMethodElement(props: IStripePaymentMethodElementProps) {
  const { afterCreate, onClose } = props

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const onSubmitPaymentMethod = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault()
    setError('')

    setIsLoading(true)
    try {
      if (!stripe || !elements) {
        toast.error(toastMessages.serverError)
        return
      }

      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
      })

      if (result.error) {
        setError(result.error.message || '')
      } else {
        afterCreate && afterCreate(result)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={onSubmitPaymentMethod}>
      {error && (
        <Alert variant='danger' style={{ fontSize: 13, marginBottom: 8 }}>
          {error}
        </Alert>
      )}
      <PaymentElement />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          style={{ marginTop: 8, width: '100%' }}
          label='Submit'
          type='submit'
          expand='full'
          loading={isLoading}
        />
        {onClose && (
          <Button
            style={{ marginTop: 8, width: '100%' }}
            label='Close'
            expand='full'
            color='fleet'
            loading={isLoading}
            onClick={onClose}
          />
        )}
      </div>
    </form>
  )
}

export default StripePaymentMethodElement
