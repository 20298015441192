import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { produce } from 'immer'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { IStatement } from '~/types/models/IStatement'

const useModifyStatements = () => {
  const queryClient = useQueryClient()

  const sessionUser: IUser = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['statements', sessionUser?.id],
    [sessionUser?.id],
  )

  const addStatement = useCallback(
    (data: IStatement) => {
      queryClient.setQueryData<IStatement[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const removeStatement = useCallback(
    (id: number) => {
      queryClient.setQueryData<IStatement[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addStatement,
    removeStatement,
  }
}

export default useModifyStatements
