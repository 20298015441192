import { IBuyerSellerRegexp } from '~/types/models/IBuyerSellerRegexp'
import './styles.scss'
import { useQueryCompanies } from '~/hooks/useQueryData'
import buildObjectName from '~/utils/buildObjectName'
import { Badge } from 'react-bootstrap'
import { useMemo } from 'react'
import getCountMatches from '~/utils/getCountMatches'
import { getRegex } from '~/components/company/DocumentList/DialogEmailContent/HighLightText/utils'
import clsx from 'clsx'
import { EditIcon } from '~/components/shared'

export interface IRegexpItemProps {
  regexp: IBuyerSellerRegexp
  content?: string
  canClick?: boolean
  selected?: boolean
  onClick?: (regexp: IBuyerSellerRegexp) => void
  onClickEdit?: (regexp: IBuyerSellerRegexp) => void
}

function RegexpItem(props: IRegexpItemProps) {
  const { regexp, content, canClick, selected, onClick, onClickEdit } = props

  const { findCompanyById } = useQueryCompanies({})

  const seller = findCompanyById(regexp.sellerId)

  const regexpFormatted = useMemo(() => {
    if (regexp.regexp) {
      if (regexp.regexp.includes('/')) {
        return regexp.regexp
      }
      return `${regexp.regexp}`
    }
  }, [regexp.regexp])

  const regex = getRegex(regexp.regexp)

  const countMatches = getCountMatches(content || '', regex)

  return (
    <div
      className={clsx('RegexpItem__container', {
        clickable: canClick,
      })}
      onClick={() => {
        onClick && onClick(regexp)
      }}
    >
      <span
        className='clickable RegexpItem__editIcon'
        onClick={event => {
          event.stopPropagation()
          onClickEdit && onClickEdit(regexp)
        }}
      >
        <EditIcon color='var(--bs-warning)' />
      </span>
      <div>
        Regexp:{' '}
        <span
          style={{
            fontWeight: 600,
            color: 'var(--bs-gray-700)',
            backgroundColor: selected ? 'yellow' : undefined,
          }}
        >
          {regexpFormatted}
        </span>
      </div>
      <div>
        Field: <span>{regexp.field}</span>
      </div>
      <div>Seller: {buildObjectName(seller)}</div>

      <div>
        Count: <Badge bg='secondary'>{countMatches}</Badge>
      </div>
    </div>
  )
}

export default RegexpItem
