import { useCallback, useMemo, useState } from 'react'
import { useQueryPayments } from '~/hooks/useQueryData'
import { useConfirmationProvider } from '~/contexts'

import { IFilterColumn } from '~/types/models/ICommonModel'
import { IPayment } from '~/types/models/IPayment'
import moment from 'moment'
import { EYesNo } from '~/types/enums/ECommonEnum'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { RTCell, ToggleListGridView } from '~/components/shared'
import { PaymentCard } from './PaymentCard'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { useIsMobile } from '~/hooks/useIsMobile'
import { useMount } from 'react-use'
import { ColumnFiltersState } from '@tanstack/react-table'

const usePayments = () => {
  const { confirmation } = useConfirmationProvider()

  const isMobile = useIsMobile()

  const [paymentSelected, setPaymentSelected] =
    useState<IPayment | undefined>(undefined)
  const [sortOptions, setSortOptions] = useState<IFilterColumn[]>([
    {
      label: 'ID #',
      sortField: 'id',
      isAsc: false,
      sorted: false,
    },
    {
      label: 'Payment Type',
      sortField: 'paymentType',
      isAsc: false,
      sorted: false,
    },
  ])
  const [stripePayment, setStripePayment] = useState({
    isOpen: false,
    clientSecret: '',
    payment: undefined as undefined | IPayment,
  })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'paymentDate',
      value: {
        startDate: moment()
          .add({ days: -1 })
          .set({ hours: 0, minutes: 0, seconds: 0 })
          .toDate(),
        endDate: moment().set({ hours: 23, minutes: 59, seconds: 59 }).toDate(),
      },
    },
    {
      id: 'buyerId',
      value: [],
    },
    {
      id: 'paymentTypeId',
      value: [],
    },
    {
      id: 'amount',
      value: {},
    },
  ])

  const filterData = useMemo(() => {
    const data: Record<string, any> = {}
    columnFilters.forEach(({ id, value }) => {
      data[id] = value
    })
    return data
  }, [columnFilters])

  const [isShowCreate, setIsShowCreate] = useState(false)
  const [isGrid, setIsGrid] = useState(false)

  const {
    paymentsData,
    isLoadingPaymentsData,
    addPayment,
    updatePayment,
    removePayment,
    refetchPaymentsData,
  } = useQueryPayments({
    filters: filterData,
  })

  const renderHeader = useCallback(
    () => ({
      headerTitle: 'Payments',
      rightButton: (
        <>
          <ToggleListGridView
            isGrid={isGrid}
            onChange={() => {
              setIsGrid(prev => !prev)
            }}
          />
        </>
      ),
    }),
    [isGrid],
  )

  const onSortChange = useCallback((newSorts: IFilterColumn[]) => {
    setSortOptions(newSorts)
  }, [])

  const onToggleFormModal = useCallback(() => {
    setIsShowCreate(prev => !prev)
  }, [])

  const onCloseDialog = useCallback(() => {
    setIsShowCreate(false)
    setPaymentSelected(undefined)
  }, [])

  const onClickEdit = useCallback((payment: IPayment) => {
    setIsShowCreate(true)
    setPaymentSelected(payment)
  }, [])

  const onCellEditEnd = useCallback(
    async (value: any, cell: RTCell<IPayment, unknown>) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id
        const { errors, ...response } = await apiClient.payments.update(rowId, {
          payment: {
            [columnField]: value,
          },
        })
        if (errors.length) {
          toast.error(errors[0])
        } else {
          updatePayment(response.id, response)
          toast.success(toastMessages.updateSuccess)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      }
    },
    [updatePayment],
  )

  const onClickRemove = useCallback(async (payment: IPayment) => {
    const result = await confirmation({
      message: 'Are you sure you want to delete this payment?',
    })
    if (result === EYesNo.Yes) {
      apiClient.payments.delete(payment.id)
      removePayment(payment.id)
      toast.success(toastMessages.deleteSuccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const afterCreatePayment = useCallback(
    (newPayment: IPayment) => {
      addPayment(newPayment)
      onCloseDialog()
    },
    [addPayment, onCloseDialog],
  )

  const afterProcessPayment = useCallback(
    () => {
      refetchPaymentsData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const afterUpdatePayment = useCallback(
    (newPayment: IPayment) => {
      updatePayment(newPayment.id, newPayment)
      if (newPayment.id === paymentSelected?.id) {
        setPaymentSelected(newPayment)
      }
      onCloseDialog()
    },
    [onCloseDialog, paymentSelected?.id, updatePayment],
  )

  const onClickProcessPayment = useCallback(async (payment: IPayment) => {
    const res = await apiClient.payments.getById(payment.id)
    if (res.clientSecret) {
      setStripePayment({
        isOpen: true,
        clientSecret: res.clientSecret,
        payment,
      })
    } else {
      toast.error('Token is not found!')
    }
  }, [])

  const onCloseStripePayment = useCallback(() => {
    setStripePayment({
      isOpen: false,
      clientSecret: '',
      payment: undefined,
    })
  }, [])

  const renderTable = useCallback(() => {
    if (isGrid) {
      return (
        <IonGrid>
          <IonRow>
            {paymentsData.map((payment: IPayment) => (
              <IonCol key={payment.id} size={isMobile ? '12' : '4'}>
                <PaymentCard
                  paymentData={payment}
                  onClickEdit={onClickEdit}
                  onClickRemove={onClickRemove}
                  onProcessPayment={onClickProcessPayment}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )
    }
    return null // if null, return table, otherwise, return card above
  }, [
    isGrid,
    isMobile,
    onClickEdit,
    onClickRemove,
    onClickProcessPayment,
    paymentsData,
  ])

  useMount(() => {
    if (isMobile) {
      setIsGrid(true)
    }
  })

  return {
    sortOptions,
    onSortChange,
    onToggleFormModal,
    onCloseDialog,
    isShowCreate,
    isLoadingPaymentsData,
    paymentsData,
    paymentSelected,
    stripePayment,
    columnFilters,
    afterCreatePayment,
    afterUpdatePayment,
    onCellEditEnd,
    renderHeader,
    renderTable,
    afterProcessPayment,
    onCloseStripePayment,
    onClickEdit,
    onClickRemove,
    onClickProcessPayment,
    setColumnFilters,
  }
}

export default usePayments
