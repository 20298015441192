export enum EEventCategory {
  ticketImage = 'ticket_image',
  driverSignature = 'driver_signature',
  buyerSignature = 'buyer_signature',
  podBefore = 'pod_before_image',
  podAfter = 'pod_after_image',
  codeScan = 'code_scan',
}

export enum EEventSourceType {
  Load = 'Load',
}
