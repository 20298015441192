import _ from 'lodash'
import { useMemo } from 'react'
import { CompanyInfo, IRTColumnDef } from '~/components/shared'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import { useQueryCompanies } from '~/hooks/useQueryData'
import { useQueryUploads } from '~/hooks/useQueryData/useQueryUploads/useQueryUploads'
import { IUpload } from '~/types/models/IUpload'

export const useUploadsTable = () => {
  const {
    uploadsData: uploads,
    isLoading: isUploadsLoading,
    createUpload,
  } = useQueryUploads()
  const { findCompanyById } = useQueryCompanies({})

  const { getDateTimeFormat, getTimeFormat } = useDateTimeFormat()

  const columns = useMemo<IRTColumnDef<IUpload>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => {
          const cellData = cell.getValue<string>()
          return _.startCase(cellData)
        },
      },
      {
        accessorKey: 'companyId',
        header: 'Company',
        Cell({ cell }) {
          const cellData = cell.getValue<number>()
          const company = findCompanyById(cellData)
          return (
            <CompanyInfo
              hideAnchor
              searchableGoogle={false}
              company={{
                value: company?.id,
                label: company?.name || '',
                name: company?.name,
              }}
              defaultCompanyCode={company?.code}
              // companyType={EScope.seller}
            />
          )
        },
      },
      {
        accessorKey: 'uploadType',
        header: 'Upload Type',
        Cell: ({ cell }) => {
          const cellData = cell.getValue<string>()
          return _.startCase(cellData)
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({ cell }) => {
          const cellData = cell.getValue<string>()
          return `${getDateTimeFormat(
            cellData,
            'YYYY-MM-DD',
          )} @ ${getTimeFormat(cellData)}`
        },
      },
      {
        accessorKey: 'updatedAt',
        header: 'Updated At',
        Cell: ({ cell }) => {
          const cellData = cell.getValue<string>()
          return `${getDateTimeFormat(
            cellData,
            'YYYY-MM-DD',
          )} @ ${getTimeFormat(cellData)}`
        },
      },
    ],
    [findCompanyById, getDateTimeFormat, getTimeFormat],
  )
  return {
    columns,
    uploads,
    isUploadsLoading,
    createUpload,
  }
}
