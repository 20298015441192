import _ from 'lodash'
import { EAddressType, EAddressTypeStr } from '~/types/enums/EAddress'
import { EEarningCodeType } from '~/types/enums/EEarningCode'
import { EOffDayStatus, EOffdayRequestType } from '~/types/enums/EOffday'
import { EOrderStatus } from '~/types/enums/EOrder'
import { EProductGroupOrderType } from '~/types/enums/EProductGroup'
import { ECheckCompanyProcessingPeriod } from '~/types/enums/ECheck'
import { EPaymentType } from '~/types/enums/EPaymentType'
import { EPricingType } from '~/types/enums/EPricing'
import { ETaxType } from '~/types/enums/ETax'
import { ETermType } from '~/types/enums/EPaymentTerm'
import { EDaysOfWeek, EStatus, EViewOption } from '~/types/enums/ECommonEnum'
import {
  ECampaignCadence,
  ECampaignMinMax,
  ECampaignType,
} from '~/types/enums/ECampaign'
import { ERewardSettingProvider } from '~/types/enums/ERewardSetting'
import { EEmailTypes } from '~/types/enums/EEmail'
import { EPaymentMethodGroupByOption } from '~/types/enums/EStripePaymentMethod'
import {
  EOrderType,
  EQtyType,
  ESellerProductMaterialType,
} from '~/types/enums/ESellerProduct'
import { EFixedPrice } from '~/types/enums/EFixedPrice'

export const NOT_SET = 'Not Set'
export const PERSON = 'Person'
export const COMPANY = 'Company'

export const MILLISECONDS_WAIT_TO_LOAD_COMPONENT = 300

export const STORAGE_KEY_DATE_FORMAT = 'STORAGE_KEY_DATE_FORMAT'

export const DRIVER_GROUP_ID = 65

export const DEFAULT_DATE_FORMAT = 'MMM-d-YYYY'

export const NOT_SET_OPTION = {
  label: NOT_SET,
  value: null,
}

export const START_TIME_INTERVAL_OPTIONS = [
  {
    value: 1,
    label: '1 min',
  },
  {
    value: 2,
    label: '2 min',
  },
  {
    value: 5,
    label: '5 min',
  },
  {
    value: 10,
    label: '10 min',
  },
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 20,
    label: '20 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 60,
    label: '60 min',
  },
]

export const DATE_FORMAT_OPTIONS = [
  {
    label: 'Date as text (Jan-21-2024)',
    value: 'MMM-d-yyyy',
  },
  {
    label: 'yyyy/MM/dd',
    value: 'yyyy/MM/dd',
  },
  {
    label: 'dd/MM/yyyy',
    value: 'dd/MM/yyyy',
  },
  {
    label: 'Date as text (Jan-21 2024 (Sun))',
    value: 'MMM-d yyyy (eee)',
  },
]

export const TIME_FORMAT_OPTIONS = [
  {
    label: 'Military Time (ex: 16:00)',
    value: 'H:mm',
  },
  {
    label: 'Standard Time (ex: 4:00 PM)',
    value: 'h:mm aa',
  },
]

export const COMPARISON_MODE = {
  DEFAULT: 'DEFAULT',
  FLAG: 'FLAG',
  NOFLAG: 'NOFLAG',
}

export const COMPARISON_MODE_MATCH = {
  DEFAULT: 'DEFAULT',
  MATCH: 'MATCH',
  NOMATCH: 'NOMATCH',
}

export const FLAG_OPTIONS = [
  { label: 'FLAG', value: 'FLAG' },
  { label: 'NOFLAG', value: 'NOFLAG' },
]
export const MATCH_OPTIONS = [
  { label: 'ALL', value: 'null' },
  { label: 'MATCH', value: 'true' },
  { label: 'NO MATCH', value: 'false' },
]

export const PARSER_COLUMN_MODEL_OPTIONS = [
  { label: 'Load', value: 'Load' },
  { label: 'Invoice', value: 'Invoice' },
  { label: 'BillLine', value: 'BillLine' },
]

export const PARSER_COLUMN_COLUMN_OPTIONS = [
  { label: 'Line Date', value: 'line_date' },
  { label: 'Ticket Num', value: 'ticket_num' },
  { label: 'Qty', value: 'qty' },
  { label: 'Price', value: 'price' },
  { label: 'Gross', value: 'gross' },
  { label: 'Product Name', value: 'product_name' },
  { label: 'Discount', value: 'discount' },
  { label: 'Product Code', value: 'product_code' },
]

export const BILL_LINE_SORTING_FIELD_OPTIONS = [
  {
    label: 'Ticket Num',
    value: 'ticket_num',
  },
  {
    label: 'Line Date',
    value: 'line_date',
  },
  {
    label: 'Secondary Num',
    value: 'secondary_num',
  },
  {
    label: 'Product Name',
    value: 'product_name',
  },
  {
    label: 'Qty',
    value: 'qty',
  },
  {
    label: 'Category',
    value: 'category',
  },
  {
    label: 'Product Code',
    value: 'product_code',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Total',
    value: 'total',
  },
  {
    label: 'Id',
    value: 'id',
  },
]

export const BILL_LINE_MATCH_OPTIONS = [
  { label: 'Ticket Num', value: 'ticket_num' },
  { label: 'Line Date', value: 'line_date' },
  { label: 'Secondary Num', value: 'secondary_num' },
  { label: 'Product Name', value: 'product_name' },
  { label: 'Qty', value: 'qty' },
  { label: 'Category', value: 'category' },
]

export const EXCLUDED_BILL_LINE_FIELDS_OPTIONS = [
  { value: 'ticket_num', label: 'Ticket #' },
  { value: 'line_date', label: 'Line Date' },
  { value: 'secondary_num', label: 'Secondary #' },
  { value: 'product_name', label: 'Product Name' },
  { value: 'qty', label: 'Quantity' },
  { value: 'category', label: 'Category' },
  { value: 'product_code', label: 'Product Code' },
  { value: 'price', label: 'Price' },
  { value: 'total', label: 'Total' },
  { value: 'id', label: 'id' },
]

export const FIXED_PRICE_ITEM_TYPE_OPTIONS = [
  { label: 'Materials', value: EFixedPrice.materials },
  { label: 'Delivery', value: EFixedPrice.delivery },
  { label: 'Material Delivery', value: EFixedPrice.material_delivery },
]

export const STATIC_VARIABLE_OPTIONS = [
  {
    label: '@st (Start time)',
    value: '@st',
  },
  {
    label: '@et (End time)',
    value: '@et',
  },
  {
    label: '@driver_fleet_id',
    value: '@driver_fleet_id',
  },
  {
    label: '@worker_id',
    value: '@worker_id',
  },
  {
    label: '@person_id',
    value: '@person_id',
  },
  {
    label: '@driver_id',
    value: '@driver_id',
  },
]

export const DELIVERY_METHOD_OPTIONS = [
  { label: 'Seller', value: 'Seller' },
  { label: 'Fleet', value: 'Fleet' },
  { label: 'Buyer', value: 'Buyer' },
  { label: 'SellerAndFleet', value: 'SellerAndFleet' },
  { label: 'SellerAndBuyer', value: 'SellerAndBuyer' },
  { label: 'FleetAndBuyer', value: 'FleetAndBuyer' },
  { label: 'All', value: 'All' },
]

export const BILLLING_METHOD_OPTIONS = [
  { label: 'Combined', value: 'Combined' },
  { label: 'Separate_Lines', value: 'Separate_Lines' },
  { label: 'Separate_Invoices', value: 'Separate_Invoices' },
]

export const REMINDER_CADENCE_OPTIONS = [
  { label: 'daily', value: 'daily' },
  { label: 'weekly', value: 'weekly' },
  { label: 'monthly', value: 'monthly' },
  { label: 'quarterly', value: 'quarterly' },
  { label: 'annually', value: 'annually' },
  { label: 'semi_annually', value: 'semi_annually' },
]

export const WEEKEND_TYPE_OPTIONS = [
  { label: 'Mon_Fri', value: 'Mon_Fri' },
  { label: 'Mon_Fri_x_2', value: 'Mon_Fri_x_2' },
  { label: 'Mon_Sat', value: 'Mon_Sat' },
]

export const PRODUCT_MATERIAL_TYPE_OPTIONS = [
  {
    label: 'Bundled material',
    value: ESellerProductMaterialType.bundled_material,
  },
  {
    label: 'Single material',
    value: ESellerProductMaterialType.single_material,
  },
  {
    label: 'Freight',
    value: ESellerProductMaterialType.freight,
  },
  {
    label: 'Not freight or material',
    value: ESellerProductMaterialType.not_freight_or_material,
  },
]

export const PRODUCT_QTY_TYPE_OPTIONS = [
  {
    label: 'Per qty',
    value: EQtyType.per_qty,
  },
  {
    label: 'Flat fee',
    value: EQtyType.per_order,
  },
  {
    label: 'Per load',
    value: EQtyType.per_load,
  },
]

export const PRODUCT_ORDER_TYPE_OPTIONS = [
  {
    label: 'Primary',
    value: EOrderType.primary,
  },
  {
    label: 'Extra',
    value: EOrderType.extra,
  },
  {
    label: 'Both',
    value: EOrderType.both,
  },
]

export const PAYMENT_METHOD_GROUP_BY_OPTIONS = [
  {
    label: 'Not set',
    value: null,
  },
  {
    label: 'Type',
    value: EPaymentMethodGroupByOption.type,
  },
  {
    label: 'Account type',
    value: EPaymentMethodGroupByOption.accountType,
  },
  {
    label: 'Card Type',
    value: EPaymentMethodGroupByOption.cardType,
  },
]

export const FILTER_OPTION_TYPES = {
  multipleSelect: 'multipleSelect',
  singleSelect: 'singleSelect',
  dateRange: 'dateRange',
  rangeNumber: 'rangeNumber',
}

export const CHECK_STATUS_COLOR = {
  blocking: 'danger',
  needs_attention: 'warning',
  completed: 'success',
}

export const FORM_FIELD_TYPE = {
  number: 'number',
  text: 'text',
  date: 'date',
  radio: 'radio',
  checkbox: 'checkbox',
  color: 'color',
  custom: 'custom',
  multipleSelect: 'multipleSelect',
  singleSelect: 'singleSelect',
  password: 'password',
  tags: 'tags',
  email: 'email',
}

export const DATA_GRID_CELL_TYPE = {
  boolean: 'boolean',
}

export const ADMIN_GROUP = 61

export const moduleName = {
  startTimes: 'startTimes',
  loads: 'loads',
  invoices: 'invoices',
  load: 'load',
  invoice: 'invoice',
  documents: 'documents',
  company: {
    home: 'home',
    users: 'users',
    parsers: 'parsers',
    sellers: 'sellers',
    buyers: 'buyers',
    fleets: 'fleets',
    emailParsers: 'email_parsers',
    parserColumnMappings: 'parser_column_mappings',
    products: 'products',
    fixedPrices: 'fixed_prices',
    sellerProducts: 'seller_products',
    terminals: 'terminals',
    buyerSellerProducts: 'buyer_seller_products',
    buyerTerminalReferences: 'buyer_terminal_references',
    referenceNames: 'reference_names',
    btfstps: 'btf_stps',
    dataCorrectionMethods: 'data_correction_methods',
    tags: 'tags',
    setup: 'setup',
    costCodes: 'cost_codes',
    userGroups: 'user_groups',
    productGroups: 'product_groups',
    workPayTypes: 'work_pay_types',
    sellerSetup: 'seller_setup',
    addresses: 'addresses',
    phoneNumbers: 'phone_numbers',
    emails: 'emails',
    bankAccounts: 'bank_accounts',
    payrolls: 'payrolls',
    flagableConfigurations: 'flagable_configurations',
    paySchedules: 'pay_schedules',
    payAttempts: 'pay_attempts',
    earningCodes: 'earning_codes',
    earningRates: 'earning_rates',
    offdays: 'offdays',
    shifts: 'shifts',
    allowedHierarchies: 'allowed_hierarchies',
    glCodes: 'gl_codes',
    workers: 'workers',
    paymentTypes: 'payment_types',
    hierarchies: 'hierarchies',
    hierarchyRows: 'hierarchy_rows',
    productGlCodes: 'product_gl_code',
    taxes: 'taxes',
    productTaxOverride: 'product_tax_override',
    paymentTerms: 'payment_terms',
    settings: 'settings',
    campaigns: 'campaigns',
    trucks: 'trucks',
    buyerSellerRegexps: 'buyer_seller_regexps',
    pricings: 'pricings',
    autoExtras: 'auto_extras',
    autoCharges: 'auto_charges',
    schedules: 'schedules',
  },
}

export const CREATE_EDIT = 'CREATE_EDIT'
export const USER_ACCESS = 'USER_ACCESS'

export const docTypes = {
  Invoice: 'Invoice',
  Load: 'Load',
}

export const DOCUMENT_FILTER_DOC_TYPE = {
  Load: 1,
  Invoice: 2,
}

export const userType = {
  buyer: 'buyer',
  seller: 'seller',
}

export const USER_SCOPE = {
  buyer: 'buyer',
  seller: 'seller',
  driver: 'driver',
  fleet: 'fleet',
  company: 'company',
  all: 'all',
  driver_fleet: 'driver_fleet',
}

export const PROFILE_SETTINGS = {
  settingGeneral: 'setting_general',
  myCompany: 'my_company',
  storedData: 'preferences',
  lastPage: 'last_page',
  deleteAccount: 'delete_account',
  personalDetails: 'personal_details',
  terminals: 'terminals',
  password: 'password',
  paymentMethods: 'payment_methods',
  myDefaultPreference: 'my_default_preference',
  contactInfo: 'contact_info',
  driverDetails: 'driver_details',
  driverFleet: 'driver_fleet',
  userAccess: 'user_access',
  bankAccounts: 'bank_accounts',
  company: 'compamy',
}

export const VIEW_MODE_OPTIONS = [
  { name: 'List', value: EViewOption.list },
  { name: 'Table', value: EViewOption.table },
]

export const DOC_TYPE_OPTIONS = [
  { label: 'Invoice', value: 'Invoice' },
  { label: 'Load', value: 'Load' },
]

export const PRODUCT_USAGE_OPTIONS = [
  {
    label: 'Product',
    value: 'product',
  },
  {
    label: 'Bundle Item',
    value: 'bundle_item',
  },
  {
    label: 'Both',
    value: 'both',
  },
]

export const concordDropdownType = {
  companyCreateEdit: 'companyCreateEdit',
  companyView: 'companyView',
}

export const dropdownType = {
  dropdown: 'dropdown',
  creatable: 'creatable',
  async: 'async',
}

export const notSetOption = {
  value: null,
  label: NOT_SET,
}

export const USER_STATUSES = {
  Active: 'success',
  Inactive: 'danger',
  Pending: 'primary',
}

export const STATUS_OPTIONS = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
]

export const companyTabQuery = {
  myCompany: 'my_company',
  sellers: 'sellers',
  buyers: 'buyers',
  general: 'general',
  noProduct: 'no_product',
  noProductGroup: 'no_product_group',
  allBuyerSellerProducts: 'all_buyer_seller_product',
}

export const DEBOUNCE_WAIT_NUMBER_FOR_SEARCHING = 1250 // milliseconds

export const ANDROID = 'android'
export const IOS = 'ios'
export const BROWSER = 'browser'
export const CORDOVA = 'cordova'
export const CAPACITOR = 'capacitor'
export const MOBILE_WEB = 'mobileweb'

export const AUTH_TOKEN = '3tA-txPSX5jEBkTt1kwcgw'
export const PARSER_NAME_BUTLER = 'butler'
export const PARSER_NAME_IMPRA = 'Impira'

export const LOAD_COLUMN_NAMES = {
  checkbox: 'checkbox',
  menuActions: 'menuActions',
  ticketNum: 'ticket_num',
  date: 'date',
  qty: 'qty',
  sellerBuyer: 'sellerBuyer',
  sellerTerminal: 'seller_terminal',
  buyerTerminal: 'buyer_terminal',
  buyerSellerProduct: 'buyer_seller_product',
  fleet: 'fleet',
  primaryParserStatus: 'primary_parser_status',
  thumbnail: 'thumbnail',
}

export const LOAD_COLUMN_TYPES = {
  date: 'date',
  number: 'number',
  select: 'select',
  menuActions: 'menuActions',
  text: 'text',
}

export const LEFT = 'left'
export const RIGHT = 'right'

export const PDF_TYPE = 'application/pdf'
export const IMAGE_TYPE = 'image/png'
export const NONE_TYPE = 'none'

export const BUTLER_URL = 'https://app.butlerlabs.ai/model'

export const DROPDOWN_MIN_WIDTH = 350

export const DROPDOWN_VISIBLE_DATA = {
  matchDataOnly: 'matched_data_only',
  rawDataOnly: 'raw_data_only',
  rawDataIfNoMatch: 'raw_data_if_no_match',
}

export const MOMENT_STANDARD_DATE_FORMAT = 'MM-DD-YYYY'

export const BASE_URL_WITHOUT_API_V1 = _.replace(
  process.env.REACT_APP_API_URL,
  new RegExp('/api/v1', 'i'),
  '',
)

export const ALL_OPTION = 'ALL_OPTION'
export const OTHER_OPTION = 'OTHER_OPTION'

export const tablesWithoutColumnConfigurations = [
  'company_cost_codes',
  'company_data_correction_methods',
  'company_product_groups',
  'company_setup',
]

export const mainTables = ['documents', 'loads', 'invoices']
export const MOBILE_BREAKPOINT = 650
export const fleetRelationships = ['Fleet', 'Both']

export const BILL_LINE_EXPAND_ROW_TYPE = {
  lookingUp: 'lookingUp',
  butlerBlock: 'butlerBlock',
  matched: 'matched',
}

// eslint-disable-next-line no-useless-escape
export const REGEX_CHECK_URL_IS_LOAD_SHOW_PAGE = /\/loads\/([^\/]+)\/?$/g
export const REGEX_GET_START_TIME_FROM_MESSAGE =
  /\w{3}, \w{3}-(\d{2}|\d{1}) (\d{2}|\d{1}):\d{2}\w{2}/g
export const REGEX_CHECK_URL =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/g
export const REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS = /(.*?)\.\.(\.?)(.*)/
export const REGEX_US_PHONE_NUMBERS =
  /^(?:\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/
export const REGEX_CHECK_EMAIL =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const REGEX_CHECK_FORMULA =
  /^(?!.*\s)(?=.*[a-zA-Z0-9])[a-zA-Z0-9+\-*/%()@^]*$/

export const DRIVER_TRUCK_STATUS = {
  working: 'working',
  off: 'off',
  break: 'break',
}

export const DAY = {
  value: 'day',
  label: 'Day',
  maxHours: 24,
}

export const WEEK = {
  value: 'week',
  label: 'Week',
  maxHours: 168,
}

export const BI_WEEK = {
  value: 'bi_week',
  label: 'Bi week',
  maxHours: 336,
}

export const MONTH = {
  value: 'month',
  label: 'Month',
  maxHours: 744,
}

export const REGULAR_OPTION = {
  label: 'Regular',
  value: 'regular',
}

export const OFFCYCLE_OPTION = {
  label: 'Offcycle',
  value: 'offcycle',
}

export const WEEKLY_OPTION = {
  value: 'weekly',
  label: 'Weekly',
}

export const BIWEEKLY_OPTION = {
  value: 'biweekly',
  label: 'Biweekly',
}

export const SEMIMONTHLY = {
  value: 'semimonthly',
  label: 'Semimonthly',
}

export const MONTHLY = {
  value: 'monthly',
  label: 'Monthly',
}

export const WORK_PAY_RULE_PERIODS = [DAY, WEEK, BI_WEEK, MONTH]

export const COMPANY_PAY_FREQUENCY_OPTIONS = [
  WEEKLY_OPTION,
  BIWEEKLY_OPTION,
  SEMIMONTHLY,
  MONTHLY,
  NOT_SET_OPTION,
]

export const DAYS_OF_WEEK = [
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
]

export const CORP_TYPE_OPTIONS = [
  {
    label: 'Not set',
    value: null,
  },
  {
    label: 'S Corporation',
    value: 's_corporation',
  },
  {
    label: 'C Corporation',
    value: 'c_corporation',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'Sole Proprietorship',
    value: 'sole_proprietorship',
  },
]

export const DATA_PARSER_LOAD_TYPES = [
  { label: 'Impira', value: 'Impira' },
  { label: 'CaptureFast', value: 'CaptureFast' },
  { label: 'AWS', value: 'AWS' },
  { label: 'HITL', value: 'HITL' },
  { label: 'Butler', value: 'butler' },
]

export const DATA_PARSER_INVOICE_TYPES = [
  { label: 'Impira', value: 'Impira' },
  { label: 'Veryfi', value: 'Veryfi' },
  { label: 'AWS', value: 'AWS' },
  { label: 'HITL', value: 'HITL' },
  { label: 'Butler', value: 'butler' },
]

export const START_TIME_COLOR = {
  red: 'var(--bs-red)',
  blue: 'var(--bs-blue)',
  green: 'var(--bs-green)',
  yellow: 'var(--bs-yellow)',
  purple: 'var(--bs-purple)',
  orange: 'var(--bs-orange)',
  black: 'var(--bs-dark)',
  dark: 'var(--bs-dark)',
  gray: 'var(--bs-gray)',
  white: '#fff',
}

export const START_TIME_SHORTCUT_OPTIONS = [
  {
    label: '6:00',
    value: '6:00',
    color: 'red',
  },
  {
    label: '6:15',
    value: '6:15',
    color: 'black',
  },
  {
    label: '6:30',
    value: '6:30',
    color: 'yellow',
  },
  {
    label: '6:45',
    value: '6:45',
    color: 'gray',
  },
  {
    label: '7:00',
    value: '7:00',
    color: 'green',
  },
  {
    label: '7:15',
    value: '7:15',
    color: 'orange',
  },
  {
    label: '7:30',
    value: '7:30',
    color: 'purple',
  },
  {
    label: '7:45',
    value: '7:45',
    color: 'red',
  },
  {
    label: '8:00',
    value: '8:00',
    color: 'orange',
  },
  {
    label: '8:15',
    value: '8:15',
    color: 'yellow',
  },
  {
    label: '8:30',
    value: '8:30',
    color: 'navy',
  },
  {
    label: '8:45',
    value: '8:45',
    color: 'indigo',
  },
  {
    label: '9:00',
    value: '9:00',
    color: 'lime',
  },
]

export const ORDER_STATUS_OPTIONS = [
  {
    label: 'Request',
    value: EOrderStatus.Requested,
  },
]

export const STATUS_LOWER_OPTIONS = [
  {
    label: 'Active',
    value: EStatus.Active.toLowerCase(),
  },
  {
    label: 'Inactive',
    value: EStatus.Inactive.toLowerCase(),
  },
]

export const STATUSES_OF_TRUCK_AT_TERMINAL = [
  'Delivery Complete',
  'Awaiting Ticket',
]

export const DRIVER_STATUS = {
  working: 'working',
}
export const SESSION_STORAGE_PREVIOUS_URL_KEY = 'previousUrl'

export const REGEX_SPLIT_REGEX_TO_STRING = /(\/?)(.+)\1([a-z]*)/i
export const REGEX_PHONE_NUMBERS =
  /^(?:\+?\d{1,3}[- ]?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{2}[- ]?\d{2}$/
export const LOADS_NOT_SET_DATE_FIELDS = ['date']
export const INVOICES_NOT_SET_DATE_FIELDS = ['date']

export const ADDRESSABLE_TYPE = {
  company: 'Company',
  driver: 'Person',
}

export const SIGNER_MYSELF_VALUE = 'me'

export const SIGNER_CUSTOM_ENTRY_OPTION = {
  label: 'Custom Entry',
  value: 'custom_entry',
}

export const SIGNER_OTHER_USER_OPTION = {
  label: 'Other User',
  value: 'other_user',
}

export const ADDRESS_TYPE_OPTIONS = [
  {
    label: 'Mailing',
    value: EAddressTypeStr.mailing,
    numValue: EAddressType.mailing,
    isDriverOption: true,
  },
  {
    label: 'Office',
    value: EAddressTypeStr.office,
    numValue: EAddressType.office,
    isCompanyOption: true,
  },
  {
    label: 'AR',
    value: EAddressTypeStr.ar,
    numValue: EAddressType.ar,
    isCompanyOption: true,
  },
  {
    label: 'AP',
    value: EAddressTypeStr.ap,
    numValue: EAddressType.ap,
    isCompanyOption: true,
  },
  {
    label: 'Residence',
    value: EAddressTypeStr.residence,
    numValue: EAddressType.residence,
    isDriverOption: true,
  },
  {
    label: 'HR',
    value: EAddressTypeStr.hr,
    numValue: EAddressType.hr,
    isCompanyOption: true,
  },
  {
    label: 'Physical',
    value: EAddressTypeStr.physical,
    numValue: EAddressType.physical,
    isCompanyOption: true,
  },
  {
    label: 'Biling',
    value: EAddressTypeStr.billing,
    numValue: EAddressType.billing,
    isCompanyOption: true,
  },
  {
    label: 'Location',
    value: EAddressTypeStr.location,
    numValue: EAddressType.location,
    isCompanyOption: true,
  },
  {
    label: 'Other',
    value: EAddressTypeStr.other,
    numValue: EAddressType.other,
    isCompanyOption: true,
  },
]

export const OTHER_VALUE = 'other'
export const REQUEST_OFF_DAY_TYPE_OPTIONS = [
  {
    label: 'Vacation',
    value: EOffdayRequestType.vacation,
  },
  {
    label: 'Injury',
    value: EOffdayRequestType.injury,
  },
  {
    label: 'Sick',
    value: EOffdayRequestType.sick,
  },
  {
    label: 'Other',
    value: EOffdayRequestType.other,
  },
]

export const ORDER_TYPE_OPTIONS = [
  {
    label: 'Primary',
    value: EProductGroupOrderType.primary,
  },
  {
    label: 'Extra',
    value: EProductGroupOrderType.extra,
  },
  {
    label: 'Both',
    value: EProductGroupOrderType.both,
  },
]

export const MAP_PRODUCT_GROUP_ORDER_TYPE = {
  primary: EProductGroupOrderType.primary,
  extra: EProductGroupOrderType.extra,
  both: EProductGroupOrderType.both,
}

export const OFF_DAY_STATUS_OPTIONS = [
  {
    label: 'Approved',
    value: EOffDayStatus.approved,
  },
  {
    label: 'Requested',
    value: EOffDayStatus.requested,
  },
  {
    label: 'Denied',
    value: EOffDayStatus.denied,
  },
]

export const TERM_TYPE_OPTIONS = [
  {
    label: 'Days after invoice',
    value: ETermType.daysAfterInvoice,
  },
  {
    label: 'Days after invoice month',
    value: ETermType.daysAfterInvoiceMonth,
  },
  {
    label: 'Of current month',
    value: ETermType.currentMonth,
  },
  {
    label: 'End of month',
    value: ETermType.endOfMonth,
  },
]

export const COMPANY_EMAIL_TYPES = {
  general: {
    label: 'General',
    value: 'general',
    numValue: EEmailTypes.general,
  },
  ap: {
    label: 'AP',
    value: 'ap',
    numValue: EEmailTypes.ap,
  },
  ar: {
    label: 'AR',
    value: 'ar',
    numValue: EEmailTypes.ar,
  },
  sales: {
    label: 'Sales',
    value: 'sales',
    numValue: EEmailTypes.sales,
  },
  qc: {
    label: 'QC',
    value: 'qc',
    numValue: EEmailTypes.qc,
  },
  operations: {
    label: 'Operations',
    value: 'operations',
    numValue: EEmailTypes.operations,
  },
  hr: {
    label: 'HR',
    value: 'hr',
    numValue: EEmailTypes.hr,
  },
  mgmt: {
    label: 'MGMT',
    value: 'mgmt',
    numValue: EEmailTypes.mgmt,
  },
}

export const CHECK_COMPANY_BUSINESS_TYPE_OPTIONS = [
  {
    label: 'Sole proprietorship',
    value: 'sole_proprietorship',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'C-Corporation',
    value: 'c_corporation',
  },
  {
    label: 'S-Corporation',
    value: 's_corporation',
  },
  {
    label: 'LLC',
    value: 'llc',
  },
]

export const TAX_TYPE_OPTIONS = [
  {
    label: 'Percentage',
    value: ETaxType.percentage,
  },
  {
    label: 'Flat Fee',
    value: ETaxType.flatFee,
  },
]

export const CHECK_COMPANY_INDUSTRY_TYPE_OPTIONS = [
  {
    label: 'Auto or Machine Sales',
    value: 'auto_or_machine_sales',
  },
  {
    label: 'Auto or Machine Repair',
    value: 'auto_or_machine_repair',
  },
  {
    label: 'Arts or Entertainment or Recreation',
    value: 'arts_or_entertainment_or_recreation',
  },
  {
    label: 'Cleaning Services',
    value: 'cleaning_services',
  },
  {
    label: 'Consulting Services',
    value: 'consulting_services',
  },
  {
    label: 'Educational Services',
    value: 'educational_services',
  },
  {
    label: 'Family Care Services',
    value: 'family_care_services',
  },
  {
    label: 'Financial Services',
    value: 'financial_services',
  },
  {
    label: 'Food and Beverage Retail or Wholesale',
    value: 'food_and_beverage_retail_or_wholesale',
  },
  {
    label: 'General Construction or General Contracting',
    value: 'general_construction_or_general_contracting',
  },
  {
    label: 'Health Care',
    value: 'health_care',
  },
  {
    label: 'Hospitality or Accommodation',
    value: 'hospitality_or_accommodation',
  },
  {
    label: 'HVAC or Plumbing or Electrical Contracting',
    value: 'hvac_or_plumbing_or_electrical_contracting',
  },
  {
    label: 'Legal Services',
    value: 'legal_services',
  },
  {
    label: 'Non Food Retail or Wholesale',
    value: 'non_food_retail_or_wholesale',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Personal Care Services',
    value: 'personal_care_services',
  },
  {
    label: 'Real Estate',
    value: 'real_estate',
  },
  {
    label: 'Restaurant',
    value: 'restaurant',
  },
  {
    label: 'Scientific or Technical Services',
    value: 'scientific_or_technical_services',
  },
  {
    label: 'Security Services',
    value: 'security_services',
  },
  {
    label: 'Staffing',
    value: 'staffing',
  },
  {
    label: 'Tobacco or Alcohol Sales',
    value: 'tobacco_or_alcohol_sales',
  },
  {
    label: 'Transportation',
    value: 'transportation',
  },
]

export const CHECK_COMPANY_PAY_FREQUENCY_OPTIONS = [
  {
    label: 'Annually',
    value: 'annually',
  },
  {
    label: 'Bi-weekly',
    value: 'biweekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Semi-monthly',
    value: 'semimonthly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
]

export const CHECK_COMPANY_PROCESSING_PERIOD_OPTIONS = [
  {
    label: 'One day',
    value: ECheckCompanyProcessingPeriod.oneDay,
  },
  {
    label: 'Two day',
    value: ECheckCompanyProcessingPeriod.twoDay,
  },
  {
    label: 'Four day',
    value: ECheckCompanyProcessingPeriod.fourDay,
  },
]

export const PHONE_TYPE_OPTIONS = [
  {
    label: 'General',
    value: 'general',
    numValue: 0,
  },
  {
    label: 'AP',
    value: 'ap',
    numValue: 1,
  },
  {
    label: 'AR',
    value: 'ar',
    numValue: 2,
  },
  {
    label: 'Sales',
    value: 'sales',
    numValue: 3,
  },
  {
    label: 'QC',
    value: 'qc',
    numValue: 4,
  },
  {
    label: 'Operations',
    value: 'operations',
    numValue: 5,
  },
  {
    label: 'HR',
    value: 'hr',
    numValue: 6,
  },
  {
    label: 'MGMT',
    value: 'mgmt',
    numValue: 7,
  },
  {
    label: 'Purchasing',
    value: 'purchasing',
    numValue: 8,
  },
  {
    label: 'Mobile',
    value: 'mobile',
    numValue: 9,
  },
  {
    label: 'Work',
    value: 'work',
    numValue: 10,
  },
  {
    label: 'Home',
    value: 'home',
    numValue: 11,
  },
]

export const PHONEABLE_TYPE_OPTIONS = [
  {
    label: 'Person',
    value: PERSON,
  },
  {
    label: 'Company',
    value: COMPANY,
  },
  // {
  //   label: 'Terminal',
  //   value: 'Terminal',
  // },
]

export const PRICING_TYPE_OPTIONS = [
  {
    label: 'Per Qty',
    value: EPricingType.perQty,
  },
  {
    label: 'Per Loads',
    value: EPricingType.perLoad,
  },
  {
    label: 'Flat Fee',
    value: EPricingType.flatFee,
  },
  {
    label: 'Percentage',
    value: EPricingType.percentage,
  },
]

export const CAMPAIGN_TYPE_OPTIONS = Object.keys(ECampaignType).map(field => ({
  label: _.startCase(field),
  value: ECampaignType[field],
}))

export const CAMPAIGN_CADENCE_OPTIONS = Object.keys(ECampaignCadence).map(
  field => ({
    label: _.startCase(field),
    value: ECampaignCadence[field],
  }),
)

export const CAMPAIGN_MIN_MAX_OPTIONS = Object.keys(ECampaignMinMax).map(
  field => ({
    label: _.startCase(field),
    value: ECampaignMinMax[field],
  }),
)

export const REWARD_SETTING_PROVIDER_OPTIONS = Object.keys(
  ERewardSettingProvider,
).map(field => ({
  label: _.startCase(field),
  value: ERewardSettingProvider[field],
}))

export const EMAIL_TYPE_OPTIONS = Object.keys(COMPANY_EMAIL_TYPES).map(
  field => COMPANY_EMAIL_TYPES[field],
)

export const EMAIL_TYPE_NUM_VALUE_OPTIONS = EMAIL_TYPE_OPTIONS.map(
  ({ label, numValue }) => ({
    label,
    value: numValue,
  }),
)

export const LOAD_STATUS = {
  error: 'Error',
}

export const BANK_ACOUNTABLE_TYPE_OPTIONS = [
  {
    label: 'Person',
    value: PERSON,
  },
  {
    label: 'Company',
    value: COMPANY,
  },
]

export const COMPANY_SUB_TAB_VALUES = {
  address: 'address',
  payroll: 'payroll',
  email: 'emails',
  phoneNumbers: 'phone_numbers',
  bankAccounts: 'bank_accounts',
  companyDetails: 'company_details',
  settingAutomation: 'settings_automations',
  financialApplicationData: 'financial_application_data',
}

export const COMPANY_INVOICE_INTERVAL_OPTIONS = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Trimonthly', value: 'Trimonthly' },
  { label: 'Bimonthly', value: 'Bimonthly' },
  { label: 'Monthly', value: 'Monthly' },
]

export const ALLOW_COMMUNICATION_CHANNEL_OPTIONS = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Push',
    value: 'push',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Call',
    value: 'call',
  },
]

export const EARNING_ROW_PAY_TYPE_OPTIONS = [
  {
    label: 'Hourly',
    value: 'hourly',
  },
  {
    label: 'Salaried',
    value: 'salaried',
  },
  {
    label: 'Overtime',
    value: 'overtime',
  },
  {
    label: 'Double overtime',
    value: 'double_overtime',
  },
  {
    label: 'Paid holiday',
    value: 'paid_holiday',
  },
  {
    label: 'Pto',
    value: 'pto',
  },
  {
    label: 'Sick',
    value: 'sick',
  },
  {
    label: 'Piece',
    value: 'piece',
  },
  {
    label: 'Rest and recovery',
    value: 'rest_and_recovery',
  },
  {
    label: 'Non productive',
    value: 'non_productive',
  },
]

export const EARNING_CODE_TYPE_OPTIONS = [
  {
    label: 'Hourly',
    value: EEarningCodeType.hourly,
  },
  {
    label: 'Salaried',
    value: EEarningCodeType.salaried,
  },
  {
    label: 'Overtime',
    value: EEarningCodeType.overtime,
  },
  {
    label: 'Double overtime',
    value: EEarningCodeType.doubleOvertime,
  },
  {
    label: 'Paid holiday',
    value: EEarningCodeType.paidHoliday,
  },
  {
    label: 'Paid time off',
    value: EEarningCodeType.pto,
  },
  {
    label: 'Sick',
    value: EEarningCodeType.sick,
  },
  {
    label: 'Piece',
    value: EEarningCodeType.piece,
  },
  {
    label: 'Rest and recovery',
    value: EEarningCodeType.restAndRecovery,
  },
  {
    label: 'Non-productive',
    value: EEarningCodeType.nonProductive,
  },
]

export const PRODUCTABLE_TYPE_OPTIONS = [
  {
    label: 'Seller Product',
    value: 'SellerProduct',
  },
  {
    label: 'Product Group',
    value: 'ProductGroup',
  },
]

export const PAYROLL_STATUS = {
  PROGRESS: 'progress',
  DRAFT: 'draft',
  PENDING: 'pending',
  PROCESSING: 'processing',
  PAID: 'paid',
}

export const PAYROLL_ACTION = {
  SEND: 'send',
  PREVIEW: 'preview',
  REOPEN: 'reopen',
  PAPER: 'paper',
  CASH: 'cash',
  APPROVE: 'approve',
}

export const FONT_SIZES = {
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
}

export const TRUCK_TAB = {
  TRUCK_INFO: 'truck_info',
  TRUCK_FLEET: 'truck_fleet',
}

export const DUE_DATE_DAYS = [0, 30, 60, 90, 91]
export const DUE_DATE_PERIOD = 30

export const MATERIAL_TYPES = {
  0: 'bundled_material',
  1: 'single_material',
  2: 'freight',
  3: 'not_freight_or_material',
}

export const SCHEDULE_COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#daddf2',
  '#799187',
]

export const WORKER_TYPES = {
  driver: 'driver',
  operations: 'operations',
  back_office: 'back_office',
  plant: 'plant',
  mechanic: 'mechanic',
}

export const WORKER_STATUSES = {
  active: 'active',
  inactive: 'inactive',
  leave: 'leave',
}

export const WORKER_TAX_TYPES = {
  employee: 'employee',
  contractor: 'contractor',
}

export const WORKER_PAYMENT_METHOD_PREFERENCES = {
  directDeposit: 'direct_deposit',
  manual: 'manual',
}
export const IN_SERVICE_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: 'Credit Card',
    value: EPaymentType.creditCard,
  },
  {
    label: 'ACH',
    value: EPaymentType.ach,
  },
  {
    label: 'Check',
    value: EPaymentType.check,
  },
  {
    label: 'Wire',
    value: EPaymentType.wire,
  },
  {
    label: 'Lockbox',
    value: EPaymentType.lockbox,
  },
  {
    label: 'Debit Card',
    value: EPaymentType.debitCard,
  },
  {
    label: 'Cash',
    value: EPaymentType.cash,
  },
  {
    label: 'Zelle',
    value: EPaymentType.zelle,
  },
  {
    label: 'Venmo',
    value: EPaymentType.venmo,
  },
]

export const isStartWithEmp = workerUid => _.startsWith(workerUid, 'emp')

export const DATE_TIME_FORMAT = {
  MDY: 'MM-DD-YYYY',
  YMD: 'YYYY-MM-DD',
  DMY: 'DD-MM-YYYY',
  firstWeekDayFormat: 'ddd, MMM-D-YYYY',
  secondyWeekDayFormat: 'MMM-D-YYYY (ddd)',
}

export const DAYS_OF_WEEK_OPTIONS = [
  {
    label: EDaysOfWeek.Sunday.slice(0, 3),
    value: 0,
  },
  {
    label: EDaysOfWeek.Monday.slice(0, 3),
    value: 1,
  },
  {
    label: EDaysOfWeek.Tuesday.slice(0, 3),
    value: 2,
  },
  {
    label: EDaysOfWeek.Wednesday.slice(0, 3),
    value: 3,
  },
  {
    label: EDaysOfWeek.Thursday.slice(0, 3),
    value: 4,
  },
  {
    label: EDaysOfWeek.Friday.slice(0, 3),
    value: 5,
  },
  {
    label: EDaysOfWeek.Saturday.slice(0, 3),
    value: 6,
  },
]

export const ACTIVE_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
]
