import { useController, useFormContext } from 'react-hook-form'

import { ColorSelector } from '~/components/shared'

function ColorSelectorWithFormControl(props) {
  const { control, name, onChange, ...restProps } = props

  const { field, fieldState } = useController({
    name,
    control,
  })

  const methods = useFormContext()

  const handleChange = (event, { value }) => {
    if (typeof onChange === 'function') {
      onChange && onChange(value, { ...methods, name })
    } else {
      field.onChange(value)
    }
  }

  return (
    <ColorSelector
      {...restProps}
      name={name}
      value={field.value}
      onChange={handleChange}
      error={fieldState.error?.message}
    />
  )
}

export default ColorSelectorWithFormControl
