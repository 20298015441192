import { useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'

import usePusher from './usePusher'
import { useCustomQueryDataOfDriver } from '../useQueryData'
import { App } from '@capacitor/app'
import { useMount } from 'react-use'

const usePusherDriver = () => {
  const {
    userInfo,
    refetchUserInfo,
    isLoadsDataFetched,
    isUserInfoFetched,
    refetchLoadsData,
    refetchEventsData,
  } = useCustomQueryDataOfDriver()

  const channelName = useMemo(
    () =>
      userInfo?.driverFleet?.id ? `driver_${userInfo?.driverFleet?.id}` : null,
    [userInfo?.driverFleet?.id],
  )

  const handleTruckEvent = useCallback(() => {
    refetchUserInfo()
    toast.success('New truck info')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadEvent = useCallback(
    data => {
      if (data.load) {
        refetchUserInfo()
        refetchLoadsData()
      }
    },
    [refetchLoadsData, refetchUserInfo],
  )

  const events = useMemo(
    () => [
      {
        name: 'truck_change',
        handler: handleTruckEvent,
      },
      {
        name: 'load',
        handler: handleLoadEvent,
      },
    ],
    [handleTruckEvent, handleLoadEvent],
  )

  usePusher({
    channelName,
    events,
    canRegisterEvents: isLoadsDataFetched || isUserInfoFetched,
  })

  useMount(() => {
    App.addListener('resume', () => {
      refetchUserInfo()
      refetchLoadsData()
      refetchEventsData()
    })
  })

  return {}
}

export default usePusherDriver
