import { useEffect, useState } from 'react'

import { CommonDialogV2, ReusableButton } from '~/components/shared'
import { SketchPicker } from 'react-color'

import './styles.scss'

function ColorPickerDialog(props) {
  const { isOpen, onClose, onSelectColor, ...dialogProps } = props

  const [colorSelected, setColorSelected] = useState('')

  const onClickUpdate = () => {
    onSelectColor && onSelectColor(colorSelected)
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setColorSelected('')
    }
  }, [isOpen])

  return (
    <CommonDialogV2
      className='ColorSelector__colorPicker'
      size='sm'
      isHiddenHeader
      isOpen={isOpen}
      onClose={onClose}
      {...dialogProps}
    >
      <SketchPicker
        color={colorSelected}
        onChangeComplete={color => {
          setColorSelected(color.hex)
        }}
      />

      <div style={{ margin: '8px' }}>
        <ReusableButton
          style={{ width: '100%', fontSize: 13 }}
          isDisabled={!colorSelected}
          onClick={onClickUpdate}
        >
          Update
        </ReusableButton>
      </div>
    </CommonDialogV2>
  )
}

export default ColorPickerDialog
