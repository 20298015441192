import { components } from 'react-select'
import clsx from 'clsx'

import './styles.scss'
import { colord } from 'colord'

function CustomColorOption(props) {
  const { data, selectProps } = props

  const isCustomColor =
    colord(selectProps.value?.value).isValid() && data.value === 'custom'

  return (
    <components.Option
      {...props}
      className={clsx(
        'd-flex align-items-center CustomColorOption__optionContainer',
        { isCustomColor },
      )}
    >
      <div className='CustomColorOption__optionLabel'>
        {isCustomColor ? (
          <span>{selectProps.value?.value}</span>
        ) : (
          <span>{data.label}</span>
        )}
      </div>
      <div
        style={{
          width: 15,
          height: 15,
          background: isCustomColor ? selectProps.value?.value : data.color,
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: 8,
        }}
      />
    </components.Option>
  )
}

export default CustomColorOption
