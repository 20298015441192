import { useState, useMemo, useCallback, useEffect } from 'react'
import useDocumentTableColumns from './useDocumentTableColumns'
import useDocumentMenu from '~/hooks/useDocumentMenu'
import {
  LayerIcon,
  OpenIcon,
  ReusableTable,
  RTActionButtonMenu,
} from '~/components/shared'
import { useSelector, useDispatch } from 'react-redux'
import { useDeepCompareEffect, useWindowSize } from 'react-use'
import { useModalDownloadProgress } from '~/components/shared'
import { useConfirmationProvider } from '~/contexts'

import { DocumentView, PdfViewer } from '~/components/shared'
import DialogCreateLoad from '~/components/company/DocumentList/DialogCreateLoad'
import DialogEmailContent from '../DialogEmailContent'
import DialogSplitInvoice from './DialogSplitInvoice'
import _ from 'lodash'
import { produce } from 'immer'
import PropTypes from 'prop-types'
import { convertDocumentList } from '../helpers'
import { getDocumentURL } from '~/utils/documentUtils'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { updateCompanyCheckForSplit } from '~/redux/actions/sessionActions'
import { docType } from '~/components/company/DocumentList/helpers'
import { apiClient } from '~/api/ApiClient'

import 'react-base-table/styles.css'
import './TableView.scss'
import { BASE_URL_WITHOUT_API_V1 } from '~/utils/constants'
import {
  EFieldType,
  ERTDisplayColumnId,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { onUpdateUiFilter } from '~/redux/actions/documentsActions'
import documentsUiSlice from '~/redux/reducers/ui/documentsSlice'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'

const DocumentTableView = props => {
  const {
    documents,
    sortOptions,
    afterCreateLoad,
    totalItems,
    startRange,
    afterDeleteDocument,
    onUpdateSeller,
    onUpdateDocType,
    onUpdateBuyerTerminal,
    onUpdateSellerTerminal,
    perPage,
    page,
    onChangePage,
    onChangePerPage,
    isLoading,
    setDocuments,
    updateDocumentSellers,
    onUpdateMultipleDocument,
    onSortChange,
  } = props
  const [selectedDocument, setSelectedDocument] = useState({
    dialogType: null, // pdf-review | create-load | email-content
    document: {},
  })
  const { downloadFile } = useModalDownloadProgress()

  const [documentUrl, setDocumentUrl] = useState('')
  const [loadingCompanySeller, setLoadingCompanySeller] = useState(false)
  const [loadingSplitInvoiceDialog, setLoadingSplitInvoiceDialog] =
    useState(false)
  const [loadingDocuments, setLoadingDocuments] = useState([])
  const [rerunEmailLoading, setRerunEmailLoading] = useState(false)

  const [rowSelection, setRowSelection] = useState({})

  const dispatch = useDispatch()
  const myCurrentCompany = useSelector(selectMyCurrentCompany)
  const documentFilters = useSelector(state => state.ui.documents.filters) //modernize

  const { sellerTerminalOptions, buyerTerminalOptions } = useQueryTerminals()

  const bulkDocs = Object.keys(rowSelection).map(id => +id)

  const { confirmation } = useConfirmationProvider()
  const windowSize = useWindowSize()

  const { renderDocumentMenu } = useDocumentMenu()
  const { sellerCompanyOptions, findCompanyById, updateCompany } =
    useQueryCompanies({})

  /**
   * a callback event is used to close specific dialog, based on dialogType
   * set dialogType = null to close all dialogs
   */
  const handleCloseDialog = useCallback(() => {
    setSelectedDocument({ dialogType: null, document: {} })
  }, [])

  /**
   * a callback event is used to open create-load dialog
   * @param {Event} event
   * @param {object} document is selected documemt
   */
  const onOpenCreateLoadDialog = useCallback((event, document) => {
    setSelectedDocument({
      dialogType: 'create-load',
      document,
    })
  }, [])

  /**
   * a callback event is used to open pdf-review dialog
   * @param {string} url from getDocumentURL api
   * @param {object} document is selected document
   */
  const onOpenPdfReview = useCallback((url, document) => {
    setDocumentUrl(url)
    setSelectedDocument({
      dialogType: 'pdf-review',
      document,
    })
  }, [])

  const onOpenEmailContent = useCallback(async document => {
    setSelectedDocument({
      dialogType: 'email-content',
      document,
    })
  }, [])

  const onSplitInvoice = useCallback(document => {
    setSelectedDocument({
      document,
      dialogType: 'split-invoice',
    })
  }, [])

  const handleParseInvoice = useCallback(
    document => {
      if (document?.docType != 'Invoice') return

      const invoiceImage = {
        documentId: document.id,
        fileName: document.displayName || document.name,
        fileType: document.fileType,
        buyerId: document.buyerId,
        sellerId: document.sellerId,
      }
      setLoadingDocuments(
        produce(loadingDocuments, draft => {
          draft.unshift(document.id)
        }),
      )

      apiClient.invoice
        .createInvoiceFromDocuments({
          invoice: invoiceImage,
        })
        .then(response => {
          toast.success(toastMessages.parse)
          setDocuments(
            produce(documents, draft => {
              const index = draft.findIndex(d => d.id === document.id)
              if (index !== -1) {
                draft[index].status = response?.status
                draft[index].invoiceId = response?.invoiceId
              }
            }),
          )
        })
        .catch(err => {
          if (
            err?.errors?.documentId &&
            err.errors.documentId.includes('has already been taken')
          ) {
            toast.error(toastMessages.invoiceDuplicate)
          } else {
            toast.error(toastMessages.createError)
          }
        })
        .finally(() => {
          setLoadingDocuments(
            produce(loadingDocuments, draft => {
              const index = draft.findIndex(d => d === document.id)
              if (index !== -1) draft.splice(index, 1)
            }),
          )
        })
    },
    [loadingDocuments, documents, setDocuments],
  )

  const onUpdateTagLinks = useCallback(
    (id, tagId) => {
      setDocuments(
        produce(documents, draft => {
          const index = draft.findIndex(d => d.id === id)
          if (index !== -1) {
            if (draft[index].tagIds.includes(tagId)) {
              draft[index].tagIds = draft[index].tagIds.filter(
                tid => tid !== tagId,
              )
            } else {
              draft[index].tagIds.push(tagId)
            }
          }
        }),
      )
    },
    [documents, setDocuments],
  )

  /**
   * create a menu event to pass to "renderDocumentMenu" as 2nd param
   */
  const getMenuEvent = useCallback(
    document => ({
      afterCreateLoad,
      onOpenCreateLoadDialog,
      onOpenPdfReview,
      afterDeleteDocument,
      onOpenEmailContent,
      onSplitInvoice,
      handleParseInvoice,
      onUpdateTagLinks,
      onOpenNewTab: () => handleOpenNewTab(document)(),
      onOpenNewWindow: () => handleOpenNewWindow(document)(),
    }),
    [
      afterCreateLoad,
      afterDeleteDocument,
      onOpenCreateLoadDialog,
      onOpenEmailContent,
      onOpenPdfReview,
      onSplitInvoice,
      handleParseInvoice,
      onUpdateTagLinks,
      handleOpenNewTab,
      handleOpenNewWindow,
    ],
  )

  /**
   * create a context menu based on a selected document
   * @param {object} document this is a selected document
   */
  const renderContextMenu = useCallback(
    document => renderDocumentMenu(document, getMenuEvent(document)),
    [getMenuEvent, renderDocumentMenu],
  )

  /**
   * add a children as an array to show view detail when toggle expand / collapse button
   * see https://autodesk.github.io/react-base-table/examples/detail-view
   */
  const documentData = useMemo(() => {
    const newDocument = convertDocumentList(documents, startRange)

    newDocument.forEach(document => {
      document.children = [
        {
          id: `${document.id}-detail`,
          document,
        },
      ]
    })

    return newDocument
  }, [documents, startRange])

  /**
   * this func is fired after edit inline a specific document name
   * @param {object} currentDoc this is a document which is being updated
   * @param {object} selectedDoc this is a document which is selected in the document options
   */
  const onUpdateSellerValue = useCallback(
    (currentDoc, selectedDoc) => {
      if (currentDoc.sellerId === selectedDoc.value) return
      const sellerId = selectedDoc.value
      const sellerName = selectedDoc.label
      const sellerLogo = selectedDoc.image
      onUpdateSeller(
        { ...currentDoc, sellerId, sellerName, sellerLogo },
        selectedDoc,
      )
    },
    [onUpdateSeller],
  )

  /**
   * this func is fired after selecting a specific type of document
   * @param {object} currentDocType this is a document which is being updated
   * @param {object} selectedDocType this is a document which is selected in the document options
   */
  const onUpdateDocTypeValue = useCallback(
    (currentDocType, selectedDocType) => {
      if (currentDocType.docType !== selectedDocType.value) {
        onUpdateDocType(
          { ...currentDocType, docType: selectedDocType.value },
          selectedDocType,
        )
      }
    },
    [onUpdateDocType],
  )

  const onUpdateBuyerTerminalValue = useCallback(
    (currentDoc, selectedBuyer) => {
      if (currentDoc.buyerTerminalId !== selectedBuyer.value) {
        onUpdateBuyerTerminal(currentDoc.id, selectedBuyer.value)
      }
    },
    [onUpdateBuyerTerminal],
  )

  const onUpdateSellerTerminalValue = useCallback(
    (currentDoc, selectedSeller) => {
      if (currentDoc.sellerTerminalId !== selectedSeller.value) {
        onUpdateSellerTerminal(currentDoc.id, selectedSeller.value)
      }
    },
    [onUpdateSellerTerminal],
  )

  /**
   * this func is used to get a pdf url and then add it to document url list
   * @param {object} document a selected document
   */
  // const handleGetUrlDoc = useCallback(
  //   async document => {
  //     const test = documentUrls.some(d => d.id == document.id)

  //     if (!test) {
  //       const url = await getDocumentURL(document)

  //       setDocumentUrls([...documentUrls, { id: document.id, url }])
  //     }
  //   },
  //   [documentUrls],
  // )

  /**
   * this func is used to reload a specific pdf document
   * @param {object} document
   */
  const handleReloadUrlDoc = useCallback(
    async document => {
      const url = await getDocumentURL(document)

      setDocuments(prev =>
        produce(prev, draft => {
          const index = draft.findIndex(({ id }) => id === document.id)
          if (index !== -1) {
            draft[index].url = url
          }
        }),
      )
    },
    [setDocuments],
  )

  const handleSubmitSplitInvoice = useCallback(
    async formValues => {
      const { document } = selectedDocument
      const invoice = {
        documentId: document.id,
        fileName: document.displayName || document.name,
        fileType: document.fileType,
        buyerId: document.buyerId,
        sellerId: document.seller.id,
        pageRanges: formValues,
      }
      setLoadingSplitInvoiceDialog(true)
      try {
        const { documents } =
          await apiClient.invoice.createInvoiceFromDocuments({
            invoice,
          })
        setDocuments(prev => [...documents, ...prev])
        handleCloseDialog()
        toast.success('Split invoices successfully!')
      } catch (error) {
        toast.error('An error has occurred.')
      } finally {
        setLoadingSplitInvoiceDialog(false)
      }
    },
    [handleCloseDialog, selectedDocument, setDocuments],
  )

  /**
   * open a new tab base on document and its url
   * @param {object} document
   */
  const handleOpenNewTab = useCallback(
    document => async () => {
      const url = await getDocumentURL(document)
      window.open(url, '_blank')
    },
    [],
  )

  /**
   * open a new window based on document and its url
   * @param {object} document
   */
  const handleOpenNewWindow = useCallback(
    document => async () => {
      const url = await getDocumentURL(document)
      window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
    },
    [],
  )

  // const changePage = useCallback(
  //   amount => {
  //     const nextPage = page + amount
  //     onChangePage(null, nextPage)
  //   },
  //   [page, onChangePage],
  // )

  // const onChangeRow = useCallback(
  //   async (doc, rowIndex) => {
  //     tableRef.current.scrollToItem(rowIndex, 'start')
  //     await handleGetUrlDoc(doc)
  //   },
  //   [tableRef, handleGetUrlDoc],
  // )

  // const { expandedRow, setExpandedRow } = useKeyStrokesDocuments(
  //   documents,
  //   onChangeRow,
  //   changePage,
  //   handleParseInvoice,
  // )

  const afterSubmitCreateCompany = useCallback(
    async ({ id, name, code, imageUrl }, rowData, handleCloseDropdown) => {
      setLoadingCompanySeller(true)
      try {
        const documentId = rowData.id
        await apiClient.documents.update(documentId, { sellerId: id })
        setDocuments(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === documentId)
            if (index !== -1) {
              draft[index].sellerId = id
              draft[index].sellerName = `${code} - ${name}`
              draft[index].sellerLogo = imageUrl
            }
          }),
        )
        handleCloseDropdown && handleCloseDropdown()
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoadingCompanySeller(false)
      }
    },
    [setDocuments],
  )

  // const handleDbClickOpenDocument = useCallback(
  //   ({ rowData, rowIndex }) => {
  //     handleExpandColumns(rowData, rowIndex)()
  //   },
  //   [handleExpandColumns],
  // )

  const getSellerCheckForSplit = useCallback(
    sellerId => {
      const findSeller = findCompanyById(sellerId)

      if (!findSeller) {
        return false
      }

      const { checkForSplit } = findSeller

      return checkForSplit
    },
    [findCompanyById],
  )

  const { columns } = useDocumentTableColumns({
    sellerTerminalOptions,
  })

  const submitBulkDocs = useCallback(
    (onSubmit, value) => {
      bulkDocs.forEach(document => {
        onSubmit(
          documents.find(d => d.id == document),
          value,
        )
      })
    },
    [documents, bulkDocs],
  )

  const parseBulkDocuments = useCallback(() => {
    bulkDocs.forEach(id => {
      const document = documents.find(d => d.id == id)
      if (document.docType == 'Invoice') handleParseInvoice(document)
    })
  }, [documents, bulkDocs, handleParseInvoice])

  const createLoadsFromBulkDocuments = useCallback(async () => {
    try {
      for (const id of bulkDocs) {
        const document = documents.find(d => d.id == id)
        if (document.docType == 'Load') {
          const result = await apiClient.documents.createLoad(id, document)
          toast.success(toastMessages.createSuccess)
          afterCreateLoad && afterCreateLoad(result)
        }
      }
    } catch (error) {
      toast.error(toastMessages.createError)
    }
  }, [documents, bulkDocs, afterCreateLoad])

  const deleteBulkDocuments = useCallback(async () => {
    try {
      const action = await confirmation({
        message: `Do you want to delete ${bulkDocs.length} documents?`,
      })

      if (action === EYesNo.Yes) {
        for (const id of bulkDocs) {
          const result = await apiClient.documents.delete(id)
          afterDeleteDocument && afterDeleteDocument(result)
          toast.success(toastMessages.deleteSuccess)
        }
      }
    } catch (error) {
      toast.error(toastMessages.createError)
    }
  }, [afterDeleteDocument, bulkDocs, confirmation])

  const afterEditCompany = useCallback(
    updatedCompany => {
      const sellerLogo = updatedCompany.logo
      const sellerName = `${updatedCompany.code} - ${updatedCompany.name}`
      const sellerId = updatedCompany.id
      updateDocumentSellers &&
        updateDocumentSellers({
          sellerId,
          sellerName,
          sellerLogo,
        })

      updateCompany(sellerId, {
        name: updatedCompany.name,
        imageUrl: updatedCompany.logo,
        code: updatedCompany.code,
      })
    },
    [updateDocumentSellers, updateCompany],
  )

  const onUpdateCompany = useCallback(
    updatedCompany => {
      dispatch(updateCompanyCheckForSplit(updatedCompany.checkForSplit))
    },
    [dispatch],
  )

  const handleChangePage = useCallback(
    (event, newPage) => {
      onChangePage && onChangePage(event, newPage)
    },
    [onChangePage],
  )

  const handleRerunEmail = useCallback(
    ({ documents }) => {
      onUpdateMultipleDocument && onUpdateMultipleDocument(documents)
    },
    [onUpdateMultipleDocument],
  )

  const hanldRerunEmailDocuments = useCallback(async () => {
    setRerunEmailLoading(true)
    try {
      const calledApis = bulkDocs.map(id =>
        apiClient.emails.update(
          id,
          {},
          {
            baseUrl: BASE_URL_WITHOUT_API_V1,
          },
        ),
      )
      const response = await Promise.all(calledApis)
      let newDocuments = []
      response.forEach(({ documents }) => {
        newDocuments = [...newDocuments, ...documents]
      })
      newDocuments = _.uniqBy(newDocuments, 'id')
      setRowSelection({})
      onUpdateMultipleDocument &&
        onUpdateMultipleDocument({ documents: newDocuments })
    } catch (error) {
      toast.error(toastMessages.serverError)
    } finally {
      setRerunEmailLoading(false)
    }
  }, [bulkDocs, onUpdateMultipleDocument])

  const handleDownloadPDFs = useCallback(async () => {
    const downloadUrls = []
    bulkDocs.forEach(id => {
      const findDocument = documentData.find(item => item.id === id)
      if (findDocument) {
        const { url, name } = findDocument

        downloadUrls.push(downloadFile(url, name))
      }
    })
    setRowSelection({})

    await Promise.race(downloadUrls)
  }, [bulkDocs, documentData, downloadFile])

  const onCellEditEnd = useCallback(
    async (value, cell) => {
      try {
        const { column, row } = cell
        const columnField = column.id
        const rowId = row.original.id

        const response = await apiClient.documents.update(rowId, {
          [columnField]: value,
        })
        setDocuments(prev =>
          produce(prev, draft => {
            const index = draft.findIndex(({ id }) => id === rowId)
            if (index !== -1) {
              draft[index] = {
                ...draft[index],
                ...response,
              }
            }
          }),
        )
        toast.success(toastMessages.updateSuccess)
      } catch (error) {
        console.log('error', error)
        toast.error(error.message)
      }
    },
    [setDocuments],
  )

  const renderFooterActions = useCallback(
    () => [
      {
        label: 'Doc Type',
        type: EFieldType.singleSelect,
        options: docType,
        variant: 'outline-primary',
        onClick: (event, selectedDoc) =>
          submitBulkDocs(onUpdateDocTypeValue, selectedDoc),
      },
      {
        label: 'Seller',
        type: EFieldType.singleSelect,
        variant: 'outline-primary',
        options: sellerCompanyOptions,
        onClick: (event, selectedDoc) =>
          submitBulkDocs(onUpdateSellerValue, selectedDoc),
      },
      {
        label: 'Buyer Terminal',
        type: EFieldType.singleSelect,
        variant: 'outline-primary',
        options: buyerTerminalOptions,
        onClick: (event, selectedDoc) =>
          submitBulkDocs(onUpdateBuyerTerminalValue, selectedDoc),
      },
      {
        label: 'Seller Terminal',
        type: EFieldType.singleSelect,
        variant: 'outline-primary',
        options: sellerTerminalOptions,
        onClick: (event, selectedDoc) =>
          submitBulkDocs(onUpdateSellerTerminalValue, selectedDoc),
      },
      {
        label: 'Send Invoices to Parser',
        onClick: parseBulkDocuments,
      },
      {
        label: 'Re-run Email',
        onClick: hanldRerunEmailDocuments,
        loading: rerunEmailLoading,
      },
      {
        label: 'Auto Create Loads',
        onClick: createLoadsFromBulkDocuments,
      },
      {
        label: 'Download PDFs',
        onClick: handleDownloadPDFs,
        variant: 'info',
      },
      {
        label: 'Delete',
        onClick: deleteBulkDocuments,
        variant: 'danger',
      },
    ],
    [
      buyerTerminalOptions,
      createLoadsFromBulkDocuments,
      deleteBulkDocuments,
      handleDownloadPDFs,
      hanldRerunEmailDocuments,
      onUpdateBuyerTerminalValue,
      onUpdateDocTypeValue,
      onUpdateSellerTerminalValue,
      onUpdateSellerValue,
      parseBulkDocuments,
      rerunEmailLoading,
      sellerCompanyOptions,
      sellerTerminalOptions,
      submitBulkDocs,
    ],
  )

  useEffect(() => {
    setRowSelection([])
  }, [documents])

  useDeepCompareEffect(() => {
    setSelectedDocument(prev =>
      produce(prev, draft => {
        if (_.size(draft.document) > 0) {
          const index = _.findIndex(documents, { id: draft.document.id })
          if (index > 0) {
            const { sellerId, sellerLogo, sellerName } = documents[index]
            draft.document = {
              ...documents[index],
              seller: {
                id: sellerId,
                value: sellerId,
                image: sellerLogo,
                label: sellerName,
              },
            }
          }
        }
      }),
    )
  }, [documents])

  const documentFiltersToColumnFilters = useMemo(
    () =>
      Object.keys(documentFilters).map(field => ({
        id: field,
        value: documentFilters[field],
      })),
    [documentFilters],
  )

  const sortOptionsToSorting = useMemo(
    () =>
      sortOptions
        .filter(({ sorted }) => sorted)
        .map(({ key, isAsc }) => ({
          id: key,
          desc: !isAsc,
        })),
    [sortOptions],
  )

  const onSortingChange = useCallback(
    newValue => {
      const newSorting = newValue()
      const options = newSorting.map(({ id, desc }) => ({
        columnName: id,
        key: id,
        isAsc: !desc,
        sorted: true,
        sortField: id,
      }))
      onSortChange(options)
    },
    [onSortChange],
  )

  const onPaginationChange = useCallback(
    callback => {
      const { pageIndex, pageSize } = callback({
        pageIndex: page - 1,
        pageSize: perPage,
      })
      handleChangePage(undefined, pageIndex + 1)
      onChangePerPage(undefined, pageSize)
    },
    [handleChangePage, onChangePerPage, page, perPage],
  )

  const onColumnFiltersChange = useCallback(
    callback => {
      const newValue = callback(documentFiltersToColumnFilters)
      const filterValue = {}
      newValue.forEach(({ id, value }) => {
        filterValue[id] = value
      })
      dispatch(onUpdateUiFilter(filterValue))
    },
    [dispatch, documentFiltersToColumnFilters],
  )

  const onGlobalFilterChange = useCallback(
    newValue => {
      dispatch(documentsUiSlice.actions.updateSearchWord(newValue))
    },
    [dispatch],
  )

  const conditionsToShowSplittingInvoice = useCallback(
    document => [
      {
        message: (
          <div>
            Seller company should enable <strong>check_for_split</strong> in
            <br /> <strong>Company Edit page</strong> {'=>'}{' '}
            <strong>Settings & Automation tab</strong>
          </div>
        ),
        isHidden: getSellerCheckForSplit(document.sellerId),
      },
      {
        message: (
          <div>
            Doc type should be <strong>Invoice</strong>
          </div>
        ),
        isHidden: document.docType === 'Invoice',
      },
      {
        message: (
          <div>
            Status should be <strong>New</strong>
          </div>
        ),
        isHidden: document.status === 'New',
      },
      {
        message: (
          <div>
            Total page should be <strong>greater than 1</strong>
          </div>
        ),
        isHidden: document.totalPages > 1,
      },
    ],
    [getSellerCheckForSplit],
  )

  const getSplittingInvoiceTooltip = useCallback(
    document => {
      const conditions = conditionsToShowSplittingInvoice(document)
      const actualMessages = conditions.filter(({ isHidden }) => !isHidden)

      return (
        <ul>
          {actualMessages.map(({ message }, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      )
    },
    [conditionsToShowSplittingInvoice],
  )

  const renderRowActions = useCallback(
    ({ row, table, cell }) => {
      const { docType, sellerId, sellerName, status, totalPages } = row.original
      const sellerCheckForSplit = getSellerCheckForSplit(sellerId)
      const splitIconTooltip = sellerName
        ? `${sellerName} usually requires splitting into multiple files, Click here to split.`
        : ''
      const isShownSplitIcon =
        sellerCheckForSplit &&
        docType === 'Invoice' &&
        status === 'New' &&
        totalPages > 1

      return [
        {
          icon: <OpenIcon color='white' />,
          tooltipProps: {
            content: 'Open Detail View',
            placement: 'top',
          },
          href: `/documents/${row.original.id}`,
        },
        {
          render: () => <RTActionButtonMenu table={table} cell={cell} />,
        },
        {
          icon: <LayerIcon color='white' />,
          tooltipProps: {
            content: splitIconTooltip,
            placement: 'top',
            className: 'DocumentTableView__splitInvoiceButton',
          },
          isDisabled: !isShownSplitIcon,
          tooltipContentWhenDisabled: getSplittingInvoiceTooltip(row.original),
          onClick: () => onSplitInvoice(row.original),
        },
      ]
    },
    [getSellerCheckForSplit, onSplitInvoice, getSplittingInvoiceTooltip],
  )

  const renderDetailPanel = useCallback(
    ({ row }) => {
      const rowData = row.original
      return (
        <div className='DocumentsTableView__expandRow'>
          <DocumentView
            contentHeight={410}
            document={rowData.url}
            onReloadData={() => handleReloadUrlDoc(rowData)}
            ratio={1.5}
            noHover
            extraButtons
            fileExtension={rowData.fileExtension}
            menu={renderContextMenu(rowData)}
          />
        </div>
      )
    },
    [handleReloadUrlDoc, renderContextMenu],
  )

  return (
    <>
      <div style={{ width: '100%', height: 'calc(100vh - 165px)' }}>
        <div style={{ marginTop: 12 }}>
          <ReusableTable
            columns={columns}
            data={documentData}
            tableHeight={windowSize.height - 265}
            manualFiltering
            enableTopToolbar
            enableRowSelection
            enableColumnPinning
            enablePagination
            enableBottomToolbar
            manualPagination
            manualSorting
            enableRowActions
            enableCellActions
            enableFooterActions={bulkDocs.length > 0}
            enableCompanyView
            companyViewProps={{
              name: 'documents',
              description: 'Manage your company documents',
              isHiddenTopButton: true,
            }}
            rowCount={totalItems}
            state={{
              isLoading,
              columnFilters: documentFiltersToColumnFilters,
              globalFilter: documentFilters.seacrhWord,
              sorting: sortOptionsToSorting,
              pagination: {
                pageIndex: page - 1,
                pageSize: perPage,
              },
              rowSelection,
            }}
            initialState={{
              columnPinning: {
                left: [
                  ERTDisplayColumnId.select,
                  ERTDisplayColumnId.actions,
                  ERTDisplayColumnId.expand,
                ],
                right: ['fileType', 'status'],
              },
            }}
            displayColumnDefOptions={{
              [ERTDisplayColumnId.actions]: {
                maxSize: 100,
                minSize: 100,
              },
              [ERTDisplayColumnId.select]: {
                size: 30,
              },
            }}
            onColumnFiltersChange={onColumnFiltersChange}
            onPaginationChange={onPaginationChange}
            onSortingChange={onSortingChange}
            onRowSelectionChange={setRowSelection}
            onGlobalFilterChange={onGlobalFilterChange}
            onCellEditEnd={onCellEditEnd}
            renderFooterActions={renderFooterActions}
            renderCellActionMenuItems={({ row }) => {
              const menuItems = renderContextMenu(row.original)
              return menuItems
            }}
            renderDetailPanel={renderDetailPanel}
            renderRowActions={renderRowActions}
          />
        </div>
      </div>

      <PdfViewer
        value={selectedDocument.document.fileExtension == 'pdf'}
        document={documentUrl}
        showPopOver={selectedDocument.dialogType === 'pdf-review'}
        setShowPopOver={handleCloseDialog}
        onOpenNewTab={() => handleOpenNewTab(selectedDocument.document)()}
        onOpenNewWindow={() => handleOpenNewWindow(selectedDocument.document)()}
      />

      <DialogCreateLoad
        document={selectedDocument.document}
        isOpen={selectedDocument.dialogType === 'create-load'}
        onClose={handleCloseDialog}
        onDidDismiss={handleCloseDialog}
        afterCreateLoad={afterCreateLoad}
        afterDeleteDocument={afterDeleteDocument}
      />

      <DialogEmailContent
        isOpen={selectedDocument.dialogType === 'email-content'}
        document={selectedDocument.document}
        loadingCompanySeller={loadingCompanySeller}
        onClose={handleCloseDialog}
        onRerunEmail={handleRerunEmail}
        onUpdateSeller={onUpdateSellerValue}
        afterEditCompany={afterEditCompany}
        afterSubmitCreateCompany={afterSubmitCreateCompany}
      />

      <DialogSplitInvoice
        document={selectedDocument.document}
        isOpen={selectedDocument.dialogType === 'split-invoice'}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitSplitInvoice}
        loading={loadingSplitInvoiceDialog}
        currentCompany={myCurrentCompany}
        onUpdateCompany={onUpdateCompany}
      />
    </>
  )
}

DocumentTableView.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  sellers: PropTypes.arrayOf(PropTypes.object),
  sortOptions: PropTypes.array,
  afterCreateLoad: PropTypes.func,
  filterOptions: PropTypes.object,
  columnsDisplayed: PropTypes.array,
  totalItems: PropTypes.number,
  startRange: PropTypes.number,
  endRange: PropTypes.number,
  isLoading: PropTypes.bool,

  afterDeleteDocument: PropTypes.func,
  onUpdateSeller: PropTypes.func,
  onUpdateDocType: PropTypes.func,
  onLoadDocuments: PropTypes.func,
  onUpdate: PropTypes.func,
  onHeaderSort: PropTypes.func,
  onUpdateBuyerTerminal: PropTypes.func,
  onUpdateSellerTerminal: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePerPage: PropTypes.func,
}

export default DocumentTableView
