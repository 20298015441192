import React from 'react'
import useTruckInfoBox from './useTruckInfoBox'

import { When } from 'react-if'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import { IonBadge, IonChip, IonIcon } from '@ionic/react'

import { closeOutline } from 'ionicons/icons'
import buildFullName from '~/utils/buildFullName'
import { getInitName } from '~/utils'

import type { ITruckInfoBoxProps } from './type'

import './styles.scss'
import { colord } from 'colord'
import getColor from '~/utils/getColor'
import { ScheduleCardOnMap } from '../ScheduleCardOnMap'
import * as IonIcons from 'ionicons/icons'

function TruckInfoBox(props: ITruckInfoBoxProps) {
  const {
    truckLocation,
    truck,
    isOpenInfoBox,
    renderTruckInfo,
    truckDetails,
    driverFleetOfTruck,
    isShowDriverInfo,
    location,
    onOpenTruckInfo,
    onCloseTruckInfo,
    onToggleDriverInfo,
    onToggleGpsJson,
    truckFleet,
    showTruckInfo,
    loadData,
    onShowTruckInfo,
    onToggleShowScheduleCard,
    showScheduleCard,
    loadStatus,
  } = useTruckInfoBox(props)

  return (
    <>
      <When condition={Boolean(truckLocation)}>
        <InfoBox
          zIndex={1000}
          position={truckLocation as google.maps.LatLng}
          options={{
            closeBoxURL: '',
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(-12, 10),
          }}
        >
          <div className='TruckInfoBox__truckName' onClick={onOpenTruckInfo}>
            {truck.name}
          </div>
        </InfoBox>
      </When>
      <When condition={Boolean(isOpenInfoBox && truckLocation)}>
        <InfoBox
          zIndex={1000}
          position={truckLocation as google.maps.LatLng}
          options={{
            closeBoxURL: '',
            enableEventPropagation: true,
            alignBottom: true,
            pixelOffset: new window.google.maps.Size(30, 10),
            boxStyle: {
              minWidth: '45px',
            },
            isHidden: false,
          }}
        >
          <div className='TruckInfoBox__truckInfo'>
            <div className='closeIcon' onClick={onCloseTruckInfo}>
              <IonIcon icon={closeOutline} />
            </div>

            <div>
              <IonChip
                style={{
                  backgroundColor: truckFleet?.color,
                  color: truckFleet?.color
                    ? colord(getColor(truckFleet.color)).isDark()
                      ? 'black'
                      : 'white'
                    : undefined,
                }}
                onClick={onShowTruckInfo}
              >
                {truck?.name}
              </IonChip>

              <IonBadge
                onClick={onToggleShowScheduleCard}
                color={loadStatus?.color}
                style={{
                  verticalAlign: 'middle',
                  marginLeft: 4,
                }}
              >
                {loadStatus && (
                  <IonIcon
                    style={{ verticalAlign: 'middle' }}
                    icon={(IonIcons as any)[loadStatus?.icon || '']}
                  />
                )}

                <span style={{ marginLeft: 4, verticalAlign: 'middle' }}>
                  {loadData ? loadData.status : 'Awaiting Ticket'}
                </span>
              </IonBadge>
            </div>

            {showTruckInfo && (
              <div>
                {renderTruckInfo}
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontWeight: 600 }}>Truck details: </span>
                  <span>{truckDetails}</span>
                </div>

                <div style={{ marginTop: 4 }} onClick={onToggleGpsJson}>
                  <span>
                    <span style={{ fontWeight: 600 }}>Speed</span>:{' '}
                    {location?.speed} - Direction: {location?.direction}
                  </span>
                </div>
              </div>
            )}

            <div>
              {driverFleetOfTruck && (
                <IonChip
                  color='concord'
                  className='driverInfoContainer'
                  onClick={onToggleDriverInfo}
                >
                  <div className='driverAvatarAndName'>
                    <div className='driverInitialName'>
                      <span>
                        {getInitName(buildFullName(driverFleetOfTruck?.person))}
                      </span>
                    </div>
                  </div>
                  <div>{buildFullName(driverFleetOfTruck?.person)}</div>
                </IonChip>
              )}
            </div>

            {isShowDriverInfo && (
              <div>
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontWeight: 600 }}>Email: </span>
                  <a href={`mailto:${driverFleetOfTruck?.user?.email}`}>
                    {driverFleetOfTruck?.user?.email}
                  </a>
                </div>
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontWeight: 600 }}>Phone: </span>
                  <a href={`tel:${driverFleetOfTruck?.user?.phoneNumber}`}>
                    {driverFleetOfTruck?.user?.phoneNumber}
                  </a>
                </div>
              </div>
            )}
          </div>
        </InfoBox>
      </When>
      {loadData && showScheduleCard ? (
        <ScheduleCardOnMap loadId={loadData?.id} truck={truck} />
      ) : null}
    </>
  )
}
export default React.memo(TruckInfoBox)
