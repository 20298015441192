import { useQuery } from 'react-query'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { selectSessionUser } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { IUser } from '~/types/models/IUser'
import { apiClient } from '~/api/ApiClient'
import { useMemo } from 'react'
import useModifyUploads from './useModifyUploads'

export const useQueryUploads = () => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'uploads',
      sessionUser?.id,
      // buildGetUrl(apiClient.uploads.endpoint, params),
    ],
    queryFn: async () => {
      const { uploads } = await apiClient.uploads.get({})
      return uploads
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
  })

  const { createUpload } = useModifyUploads()

  const uploadsData = useMemo(() => data || [], [data])

  return {
    uploadsData,
    isLoading,
    createUpload,
  }
}
