import { EColor } from '~/types/enums/ECommonEnum'

export const COLOR_OPTIONS = [
  { label: 'blue', value: 'blue', color: EColor.blue },
  { label: 'red', value: 'red', color: EColor.red },
  { label: 'green', value: 'green', color: EColor.green },
  { label: 'yellow', value: 'yellow', color: EColor.yellow },
  { label: 'purple', value: 'purple', color: EColor.purple },
  { label: 'orange', value: 'orange', color: EColor.orange },
  { label: 'black', value: 'black', color: EColor.black },
  { label: 'gray', value: 'gray', color: EColor.gray },
  { label: 'cyan', value: 'cyan', color: EColor.cyan },
  { label: 'teal', value: 'teal', color: EColor.teal },
  { label: 'pink', value: 'pink', color: EColor.pink },
  { label: 'magenta', value: 'magenta', color: EColor.magenta },
  { label: 'brown', value: 'brown', color: EColor.brown },
  { label: 'lime', value: 'lime', color: EColor.lime },
  { label: 'indigo', value: 'indigo', color: EColor.indigo },
  { label: 'navy', value: 'navy', color: EColor.navy },
  { label: 'gold', value: 'gold', color: EColor.gold },
  { label: 'Custom', value: 'custom' },
]
