import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { produce } from 'immer'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import { ITruck } from '~/types/models/ITruck'
import { useSelector } from 'react-redux'
import { selectSessionUser } from '~/redux/selectors'
import { IUser } from '~/types/models/IUser'
import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'

// params: Partial<IGetTruckFleetsParams> = {}, doesn't exist atm
const useModifyTruckFleets = () => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const queryClient = useQueryClient()
  const queryKey = useMemo(
    () => [
      'truckFleets',
      sessionUser?.id,
      buildGetUrl(apiClient.truckFleets.endpoint), //params
    ],
    [sessionUser?.id],
  )
  const addTruckFleet = useCallback(
    (data: ITruckFleet) => {
      queryClient.setQueryData<ITruckFleet[] | undefined>(queryKey, oldData => {
        if (oldData) {
          return [data, ...oldData]
        }
        return [data]
      })
    },
    [queryClient, queryKey],
  )

  const updateTruckFleet = useCallback(
    (id: number, data: ITruckFleet) => {
      queryClient.setQueryData<ITruckFleet[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = {
                ...draft[index],
                ...data,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const updateTruck = useCallback(
    (truckId: number, data: Partial<ITruck>) => {
      queryClient.setQueryData<ITruckFleet[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.truckId === truckId)
            if (draft[index]) {
              draft[index].truck = {
                ...draft[index].truck,
                ...data,
              }
              draft[index].truckId = truckId
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  const removeTruckFleet = useCallback(
    (id: number) => {
      queryClient.setQueryData<ITruckFleet[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft.splice(index, 1)
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    addTruckFleet,
    updateTruckFleet,
    removeTruckFleet,
    updateTruck,
  }
}

export default useModifyTruckFleets
