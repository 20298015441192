const getCountMatches = (input: string, pattern: RegExp) => {
  try {
    const flags = pattern.flags.includes('g')
      ? pattern.flags
      : pattern.flags + 'g'
    const globalPattern = new RegExp(pattern.source, flags)

    const matches = input.match(globalPattern)

    return matches ? matches.filter(Boolean).length : 0
  } catch (error) {
    console.log('error', error)
    return 0
  }
}

export default getCountMatches
