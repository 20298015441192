import type { IPaymentBillLinesTableProps } from './type'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useQueryBillLines,
  useQueryBuyerSellerProducts,
  useQueryInvoices,
  useQueryTerminals,
} from '~/hooks/useQueryData'
import { IBillLine } from '~/types/models/IBillLine'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import { useSelector } from 'react-redux'
import { selectMyCurrentCompany } from '~/redux/selectors'
import {
  CompanyInfo,
  IRTColumnDef,
  LoadIcon,
  ReusableTable,
  RTCell,
  RTRow,
} from '~/components/shared'
import DialogTicketView from './DialogTicketView'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
} from '~/types/enums/ECommonEnum'
import { ExpandedState, RowSelectionState } from '@tanstack/react-table'
import { IInvoice } from '~/types/models/IInvoice'
import { ICompany } from '~/types/models/ICompany'
import _ from 'lodash'
import { apiClient } from '~/api/ApiClient'
import { IPaymentPayable } from '~/types/models/IPaymentSchedule'
import clsx from 'clsx'

const PaymentBillLinesTable = React.forwardRef(
  (props: IPaymentBillLinesTableProps, ref) => {
    const { buyerId, billLinesDetails, paymentDate } = props

    const [billLineRowSelection, setBillLineRowSelection] =
      useState<RowSelectionState>({})
    const [invoiceRowSelection, setInvoiceRowSelection] =
      useState<RowSelectionState>({})
    const [expandedInvoiceRows, setExpandedInvoiceRows] =
      useState<ExpandedState>({})
    const [paymentPayables, setPaymentPayables] = useState<IPaymentPayable[]>(
      [],
    )
    const [amountUserEnters, setAmountUserEnters] = useState<
      Record<number | string, number | string>
    >({})

    const [ticketModalState, setTicketModalState] = useState({
      isOpen: false,
      loadId: null as null | number,
    })

    const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

    const { invoicesData, isLoadingInvoicesData, isInvoicesFetched } =
      useQueryInvoices(
        {
          source: 'Load',
          perPage: 999,
          filters: {
            buyerId,
          },
        },
        { enabled: Boolean(buyerId) },
      )

    const { findTerminalById } = useQueryTerminals()

    const { billLinesData, isLoadingBillLinesData, isBillLinesFetched } =
      useQueryBillLines(
        {
          filters: {
            invoiceId: invoicesData.map(({ id }) => id),
          },
          perPage: 999,
        },
        { enabled: Boolean(invoicesData.length) },
      )

    const billLinesSelected = useMemo(() => {
      const ids = Object.keys(billLineRowSelection).map(id => parseInt(id))
      if (ids.length) {
        return billLinesData.filter(b => ids.includes(b.id))
      }
      return []
    }, [billLineRowSelection, billLinesData])

    const isAmountEnteredGreaterThanMaxTotal = useMemo(() => {
      if (billLinesSelected.length === 0) {
        return false
      }

      const total = Object.keys(amountUserEnters).reduce(
        (total, id) => (total += Number(amountUserEnters[id] || 0)),
        0,
      )
      const maxTotal = paymentPayables.reduce(
        (total, { maxToApply }) => (total += Number(maxToApply)),
        0,
      )

      return maxTotal < total
    }, [amountUserEnters, billLinesSelected.length, paymentPayables])

    const invoiceIdSelected: string | null = Object.keys(invoiceRowSelection)[0]

    const { buyerSellerProducts, isLoadingBuyerSellerProducts } =
      useQueryBuyerSellerProducts({
        filters: {
          joinsSellerProduct: true,
        },
      })

    const isTableLoading =
      isLoadingBillLinesData ||
      isLoadingInvoicesData ||
      isLoadingBuyerSellerProducts

    const getPaymentPayableByBillLineId = useCallback(
      (id: number) =>
        paymentPayables.find(({ billLineId }) => billLineId === id),
      [paymentPayables],
    )

    const getHasZeroOrNegativeValue = useCallback(
      (billLineId: number) => {
        const paymentPayable = getPaymentPayableByBillLineId(billLineId)
        if (paymentPayable && Number(paymentPayable.maxToApply) === 0) {
          return false
        }
        const amount = amountUserEnters[billLineId]
        return Number(amount) <= 0
      },
      [amountUserEnters, getPaymentPayableByBillLineId],
    )

    const canSubmit = useMemo(() => {
      const hasZeroOrNegativeValue = Object.keys(amountUserEnters).some(id =>
        getHasZeroOrNegativeValue(Number(id)),
      )
      return !isAmountEnteredGreaterThanMaxTotal && !hasZeroOrNegativeValue
    }, [
      amountUserEnters,
      getHasZeroOrNegativeValue,
      isAmountEnteredGreaterThanMaxTotal,
    ])

    const getBillLineCellClassName = useCallback(
      ({ row, cell }: { row: RTRow<IBillLine>; cell: RTCell<IBillLine> }) => {
        if (cell.column.id === 'allocatedAmount') {
          const paymentPayable = getPaymentPayableByBillLineId(row.original.id)
          const className = clsx({
            isReadOnly: Number(paymentPayable?.maxToApply) === 0,
            grayBackground: Number(paymentPayable?.maxToApply) === 0,
            // redBackground:
            //   Number(paymentPayable?.maxToApply) <
            //     Number(amountUserEnters[row.original.id]) ||
            //   getHasZeroOrNegativeValue(row.original.id),
          })
          return className
        }
        return ''
      },
      [getPaymentPayableByBillLineId],
    )

    const getBillLinesBasedOnInvoiceId = useCallback(
      (invoiceId: number) =>
        billLinesData.filter(b => b.invoiceId === invoiceId),
      [billLinesData],
    )

    const onOpenTicketModal = useCallback(
      (billLine: IBillLine) => () => {
        setTicketModalState({
          isOpen: true,
          loadId: billLine.loadId,
        })
      },
      [],
    )

    const columns = useMemo<IRTColumnDef<IBillLine>[]>(
      () => [
        {
          header: 'Date',
          accessorKey: 'lineDate',
          align: 'center',
          size: 100,
        },
        {
          header: 'Ticket #',
          accessorKey: 'ticketNum',
          size: 100,
        },
        {
          header: 'Product',
          accessorKey: 'buyerSellerProductId',
          Cell({ cell }) {
            const cellData = cell.getValue<number>()
            const bsp = buyerSellerProducts.find(({ id }) => cellData === id)
            if (bsp) {
              return `${bsp.sellerProductCode} - ${bsp.sellerProductName}`
            }
            return '-'
          },
          size: 120,
        },

        {
          header: 'Qty',
          accessorKey: 'qty',
          align: 'right',
          size: 80,
        },
        {
          header: 'Price',
          accessorKey: 'price',
          align: 'right',
          size: 100,
          Cell({ cell }) {
            const cellData = cell.getValue<string | null>()
            if (cellData) {
              return formatCurrencyToDollar.format(Number(cellData))
            }
            return '-'
          },
        },
        {
          header: 'Discount',
          accessorKey: 'discount',
          align: 'right',
          size: 120,
          Cell({ cell }) {
            const cellValue = cell.getValue<string>()
            if (cellValue) {
              return formatCurrencyToDollar.format(Number(cellValue))
            }
            return '-'
          },
        },
        {
          header: 'Net Price',
          accessorKey: 'netPrice',
          align: 'right',
          Cell({ cell }) {
            const cellValue = cell.getValue<string>()
            if (cellValue) {
              return formatCurrencyToDollar.format(Number(cellValue))
            }
            return '-'
          },
        },
        {
          header: 'Total',
          accessorKey: 'total',
          align: 'right',
          size: 100,
          Cell({ cell }) {
            const cellValue = cell.getValue<string>()
            return formatCurrencyToDollar.format(Number(cellValue))
          },
          Footer({ table }) {
            const totalVal = table.options.data.reduce((total, billLine) => {
              return (total += Number(billLine.total))
            }, 0)

            return formatCurrencyToDollar.format(totalVal)
          },
        },
        {
          header: 'Amount Applied',
          id: 'allocatedAmount',
          align: 'right',
          enableSorting: false,
          enableEditing: row => Boolean(billLineRowSelection[row.original.id]),
          editVariant: EFieldType.number,
          editNumberFieldProps: () => ({
            decimalScale: 2,
            fixedDecimalScale: true,
            prefix: '$',
          }),
          cellProps: ({ row }) => {
            let error = ''
            const paymentPayable = getPaymentPayableByBillLineId(
              row.original.id,
            )
            if (
              Number(paymentPayable?.maxToApply) <
              Number(amountUserEnters[row.original.id])
            ) {
              error = `value can't be higher than total (${formatCurrencyToDollar.format(
                Number(paymentPayable?.maxToApply),
              )})`
            } else if (getHasZeroOrNegativeValue(row.original.id)) {
              error = "value can't be below $0.01"
            }
            return {
              error,
            }
          },
          accessorFn: rowData => amountUserEnters[rowData.id],
          Footer() {
            const total = Object.keys(amountUserEnters).reduce(
              (total, id) => (total += Number(amountUserEnters[id] || 0)),
              0,
            )
            return (
              <span
                className={clsx({
                  error: isAmountEnteredGreaterThanMaxTotal,
                })}
              >
                {formatCurrencyToDollar.format(total)}
              </span>
            )
          },
        },
        {
          header: 'Buyer Terminal',
          accessorKey: 'buyerTerminalId',
          Cell({ cell }) {
            const cellValue = cell.getValue<number>()
            if (cellValue) {
              const terminal = findTerminalById(cellValue)
              return terminal?.code || '-'
            }
            return '-'
          },
        },
      ],
      [
        amountUserEnters,
        billLineRowSelection,
        buyerSellerProducts,
        getHasZeroOrNegativeValue,
        getPaymentPayableByBillLineId,
        isAmountEnteredGreaterThanMaxTotal,
        findTerminalById,
      ],
    )

    const invoiceColumns = useMemo<IRTColumnDef<IInvoice>[]>(
      () => [
        {
          header: 'Invoice #',
          accessorKey: 'num',
          size: 100,
        },
        {
          header: 'Due Date',
          accessorKey: 'dueDate',
          align: 'center',
          size: 100,
        },
        {
          header: 'Start Date',
          accessorKey: 'startDate',
          align: 'center',
          size: 120,
        },
        {
          header: 'Buyer',
          accessorKey: 'buyer',
          minSize: 200,
          Cell({ cell }) {
            const cellValue = cell.getValue<ICompany>()
            return (
              <CompanyInfo
                searchableGoogle={false}
                hideAnchor
                company={{
                  value: cellValue?.id,
                  label: cellValue
                    ? `${cellValue.code} - ${cellValue.name}`
                    : '',
                  code: cellValue.code,
                  name: cellValue.name,
                }}
                companyType={EScope.buyer}
              />
            )
          },
        },
        {
          header: 'Buyer Terminal',
          accessorKey: 'buyerTerminalId',
          Cell({ cell }) {
            const cellValue = cell.getValue<number>()
            if (cellValue) {
              const terminal = findTerminalById(cellValue)
              return terminal?.code || '-'
            }
            return '-'
          },
        },
        {
          header: 'Total',
          id: 'total',
          size: 100,
          align: 'right',
          accessorFn: row => formatCurrencyToDollar.format(Number(row.total)),
        },
        {
          header: 'Qty',
          accessorKey: 'qty',
          align: 'right',
          size: 80,
        },
        {
          header: 'Balance',
          id: 'balance',
          align: 'right',
          size: 100,
          accessorFn: row => formatCurrencyToDollar.format(Number(row.balance)),
        },
      ],
      [findTerminalById],
    )

    const onCellEditEnd = useCallback(
      (value: string | number, cell: RTCell<IBillLine, unknown>) => {
        const { column, row } = cell
        const columnField = column.id
        if (columnField === 'allocatedAmount') {
          setAmountUserEnters(prev => ({
            ...prev,
            [row.original.id]: value,
          }))
        }
      },
      [],
    )

    const fetchPaymentSchedulesNew = useCallback(async () => {
      const apiCalls = billLinesSelected.map(({ id, total }) =>
        apiClient.paymentSchedules.new({
          paymentSchedule: {
            invoiceId: invoiceIdSelected ? Number(invoiceIdSelected) : -1,
            amount: total,
            sellerId: currentCompany.id,
            billLineIds: [id],
            paymentDate,
          },
        }),
      )
      if (apiCalls.length) {
        const response = await Promise.all(apiCalls)
        const newAmountEntered: Record<number, number | string> = {}
        const data = response.map(({ paymentPayables }) => {
          const [firstItem] = paymentPayables
          newAmountEntered[firstItem.billLineId] = firstItem.amount
          return firstItem
        })
        setAmountUserEnters(newAmountEntered)
        setPaymentPayables(data)
      } else {
        setAmountUserEnters({})
        setPaymentPayables([])
      }
    }, [billLinesSelected, currentCompany.id, invoiceIdSelected, paymentDate])

    useEffect(() => {
      setExpandedInvoiceRows(invoiceRowSelection)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceRowSelection])

    useEffect(() => {
      if (ref) {
        ;(ref as any).current = {
          expandedInvoiceRows,
          setExpandedInvoiceRows,
          billLineRowSelection,
          setBillLineRowSelection,
          billLinesSelected,
          amountUserEnters,
          canSubmit,
        }
      }
    }, [
      billLineRowSelection,
      expandedInvoiceRows,
      billLinesSelected,
      ref,
      amountUserEnters,
      isAmountEnteredGreaterThanMaxTotal,
      canSubmit,
    ])

    useEffect(() => {
      if (isInvoicesFetched && isBillLinesFetched && billLinesDetails?.length) {
        const billLines = billLinesDetails.map(({ billLineId }) =>
          billLinesData.find(({ id }) => billLineId === id),
        )
        const newBillLineRowSelection = {} as Record<string, boolean>
        billLines.forEach(b => {
          if (b) {
            newBillLineRowSelection[b.id] = true
          }
        })
        const invoiceIds: number[] = _(billLines)
          .map('invoiceId')
          .uniq()
          .value()
        const newInvoiceRowSelection = {} as Record<string, boolean>
        invoiceIds.forEach(id => {
          newInvoiceRowSelection[id] = true
        })

        setInvoiceRowSelection(newInvoiceRowSelection)
        setBillLineRowSelection(newBillLineRowSelection)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvoicesFetched, isBillLinesFetched, billLinesDetails])

    useEffect(() => {
      if (billLinesSelected.length) {
        fetchPaymentSchedulesNew()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billLinesSelected])

    return (
      <>
        <ReusableTable
          columns={invoiceColumns}
          data={invoicesData}
          tableHeight={450}
          enableColumnPinning
          enableRowSelection
          enableMultiRowSelection={false}
          manualExpanding
          getRowId={row => row.id + ''}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.select],
              right: ['total'],
            },
            columnVisibility: {
              [ERTDisplayColumnId.expand]: false,
            },
          }}
          state={{
            rowSelection: invoiceRowSelection,
            expanded: expandedInvoiceRows,
            isLoading: isLoadingInvoicesData,
          }}
          onRowSelectionChange={setInvoiceRowSelection}
          onExpandedChange={setExpandedInvoiceRows}
          renderDetailPanel={({ row }) => {
            const billLinesFiltered = getBillLinesBasedOnInvoiceId(
              row.original.id,
            )

            return (
              <ReusableTable
                data={billLinesFiltered}
                columns={columns}
                tableHeight={250}
                enableRowActions
                enableColumnPinning
                enableRowSelection
                getCellClassName={getBillLineCellClassName}
                state={{
                  isLoading: isTableLoading,
                  rowSelection: billLineRowSelection,
                  columnPinning: {
                    left: [
                      ERTDisplayColumnId.select,
                      ERTDisplayColumnId.actions,
                    ],
                    right: billLinesSelected.length
                      ? ['total', 'allocatedAmount']
                      : ['total'],
                  },
                }}
                displayColumnDefOptions={{
                  [ERTDisplayColumnId.actions]: {
                    size: 65,
                  },
                  [ERTDisplayColumnId.select]: {
                    size: 30,
                  },
                }}
                renderRowActions={({ row }) => [
                  {
                    icon: <LoadIcon color='white' />,
                    isDisabled: !row.original.loadId,
                    onClick: onOpenTicketModal(row.original),
                  },
                ]}
                getRowId={row => row.id?.toString()}
                onCellEditEnd={onCellEditEnd}
                onRowSelectionChange={setBillLineRowSelection}
              />
            )
          }}
        />

        <DialogTicketView
          isOpen={ticketModalState.isOpen}
          loadId={ticketModalState.loadId as number}
          onClose={() => {
            setTicketModalState({
              isOpen: false,
              loadId: null,
            })
          }}
        />
      </>
    )
  },
)

export default PaymentBillLinesTable
